import React, { useState, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import { useSelector } from 'react-redux';
import GamefiedAIAssistant from '../redstone/intercom';
import axios from 'axios';
import {
    Users,
    Mail,
    Globe,
    MessageCircle,
    Search,
    Filter,
    AlertCircle,
    Star,
    Zap,
    EyeIcon,
    X,
    FileText // Added for summary icon
} from 'lucide-react';

const DefaultProfileIcon = ({ name }) => {
    // Generate a background color based on the prospect's name
    const getColorFromName = (name) => {
        let hash = 0;
        for (let i = 0; i < name.length; i++) {
            hash = name.charCodeAt(i) + ((hash << 5) - hash);
        }
        const hue = hash % 360;
        return `hsl(${hue}, 70%, 50%)`;
    };

    // Get initials from name
    const getInitials = (name) => {
        return name
            ? name.split(' ').map(n => n[0]).slice(0, 2).join('').toUpperCase()
            : 'NA';
    };

    return (
        <div
            className="w-10 h-10 rounded-full flex items-center justify-center text-white font-bold"
            style={{ backgroundColor: getColorFromName(name || 'Unknown') }}
        >
            {getInitials(name)}
        </div>
    );
};

const ProspectManagement = () => {
    const { userInfo } = useSelector((state) => state.auth);
    const [prospects, setProspects] = useState([]);
    const [filteredProspects, setFilteredProspects] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [goldenLeadSet, setGoldenLeadSet] = useState([]);
    const [isGoldenLeadModalOpen, setIsGoldenLeadModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedProspectTip, setSelectedProspectTip] = useState(null);
    const { user } = useUser();

    // Fetch Prospects API Call
    const fetchProspects = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/alleads', {
                cid:user?.id
            });

            // Log the response data to understand its structure
            console.log('API Response:', response.data);

            // Extract leads from the response
            const prospectData = response.data.leads || [];

            setProspects(prospectData);
            setFilteredProspects(prospectData);
        } catch (error) {
            console.error('Failed to fetch prospects', error);
            setError(error.message);
            setProspects([]);
            setFilteredProspects([]);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (user?.id) {
            fetchProspects();
        }
    }, [userInfo]);

    const handleSearch = (e) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);

        const filtered = (prospects || []).filter(
            prospect =>
                prospect.name?.toLowerCase().includes(term) ||
                prospect.company?.toLowerCase().includes(term) ||
                prospect.email?.toLowerCase().includes(term)
        );
        setFilteredProspects(filtered);
    };

    const getValidityColor = (score) => {
        const numScore = parseInt(score);
        if (numScore > 80) return 'bg-green-500/10 text-green-400';
        if (numScore > 60) return 'bg-yellow-500/10 text-yellow-400';
        return 'bg-red-500/10 text-red-400';
    };

    const createGoldenLeadSet = () => {
        const highScoreProspects = (prospects || []).filter(
            prospect => parseInt(prospect.validityscore) > 85
        );
        setGoldenLeadSet(highScoreProspects);
    };

    const handleProspectUpdate = async (prospect, status) => {
        try {
            // Placeholder for update API call
            const updatedProspect = {
                ...prospect,
                responseStatus: status
            };

            const updatedProspects = (prospects || []).map(p =>
                p.duserId === prospect.duserId ? updatedProspect : p
            );
            setProspects(updatedProspects);
            setFilteredProspects(updatedProspects);
        } catch (error) {
            console.error('Failed to update prospect', error);
        }
    };

    const syncGoldenLeadSet = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/addp', {
                prospects: goldenLeadSet,
                leadSets: 'Golden Lead Set',
                userId: userInfo._id
            });
            setIsGoldenLeadModalOpen(true);
        } catch (error) {
            console.error('Failed to sync golden lead set', error);
        } finally {
            setIsLoading(false);
        }
    };

    if (error) {
        return (
            <div className="min-h-screen bg-gray-50 p-6 flex items-center justify-center"> {/* Updated bg-black to bg-gray-50 */}
                <div className="text-center">
                    <AlertCircle className="mx-auto w-12 h-12 text-red-500 mb-4" />
                    <h2 className="text-xl font-bold text-gray-900 mb-2">Error Loading Prospects</h2> {/* Updated text-white to text-gray-900 */}
                    <p className="text-gray-600 mb-4">{error}</p> {/* Updated text-gray-400 to text-gray-600 */}
                    <button
                        onClick={fetchProspects}
                        className="inline-flex justify-center items-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-gray-400 disabled:cursor-not-allowed"
                    >
                        Retry
                    </button>
                </div>
            </div>
        );
    }

    if (isLoading) {
        return (
            <div className="min-h-screen bg-gray-50 p-6 flex items-center justify-center"> {/* Updated bg-black to bg-gray-50 */}
                <div className="text-center">
                    <Users className="mx-auto w-12 h-12 text-blue-500 animate-pulse mb-4" />
                    <p className="text-gray-600">Loading prospects...</p> {/* Updated text-gray-400 to text-gray-600 */}
                </div>
            </div>
        );
    }
    const openAIProspectAnalysis = (prospect) => {
        setSelectedProspectTip(prospect);
    };

    const closeAIProspectAnalysis = () => {
        setSelectedProspectTip(null);
    };
    return (
        <div className="flex-1 p-6 bg-gray-50"> {/* Using same base div as PMFAnalysisPage */}
            <div className="max-w-4xl mx-auto"> {/* Container for content centering */}
                <h2 className="text-2xl font-bold text-gray-900 mb-4 flex items-center"> {/* Consistent header style */}
                    <Users className="mr-2 text-blue-500" /> Prospect Management
                </h2>

                <div className="bg-white rounded-lg shadow-md p-6 mb-8 border border-gray-100"> {/* Card style container */}
                    {/* Header Buttons and Search */}
                    <div className="flex justify-between items-center mb-6">
                        <div className="flex space-x-2">
                            <button
                                onClick={createGoldenLeadSet}
                                className="inline-flex justify-center items-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 disabled:bg-gray-400 disabled:cursor-not-allowed" // Consistent button style
                            >
                                <Star className="mr-1 w-4 h-4" /> Create Golden Lead Set
                            </button>
                            {goldenLeadSet.length > 0 && (
                                <button
                                    onClick={syncGoldenLeadSet}
                                    className="inline-flex justify-center items-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:bg-gray-400 disabled:cursor-not-allowed" // Consistent button style
                                >
                                    <Zap className="mr-1 w-4 h-4" /> Sync Golden Lead Set
                                </button>
                            )}
                        </div>
                    </div>

                    {/* Search and Filters */}
                    <div className="mb-6 flex space-x-4">
                        <div className="relative flex-grow">
                            <input
                                type="text"
                                placeholder="Search prospects..."
                                value={searchTerm}
                                onChange={handleSearch}
                                className="w-full bg-gray-100 border-gray-300 text-gray-900 px-4 py-2 rounded-lg pl-10 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm border" // More modern input style
                            />
                            <Search className="absolute left-3 top-3 w-5 h-5 text-gray-500" />
                        </div>
                        <button className="inline-flex justify-center items-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-gray-400 disabled:cursor-not-allowed"> {/* Styled filter button */}
                            <Filter className="mr-2 w-4 h-4" /> Filters
                        </button>
                    </div>

                    {/* Prospects Grid */}
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {(filteredProspects || []).map((prospect) => (
                            <div
                                key={prospect.duserId || Math.random()}
                                className="bg-gray-50 rounded-lg shadow-md p-4 hover:shadow-lg transition-shadow duration-200 border border-gray-100" // Card styling for prospects
                            >
                                <div className="flex justify-between items-start mb-3">
                                    <div className="flex items-center space-x-3">
                                        {prospect.profilePicture ? (
                                            <img
                                                src={prospect.profilePicture}
                                                alt={prospect.name}
                                                className="w-10 h-10 rounded-full object-cover"
                                            />
                                        ) : (
                                            <DefaultProfileIcon name={prospect.name} />
                                        )}
                                        <div>
                                            <h2 className="text-lg font-semibold text-gray-900">{prospect.name}</h2>
                                            <p className="text-sm text-gray-600">{prospect.company}</p>
                                        </div>
                                    </div>
                                    <span
                                        className={`
                                            px-2 py-1 rounded-full text-xs font-medium
                                            ${getValidityColor(prospect.validityscore)}
                                        `}
                                    >
                                        {prospect.validityscore}%
                                    </span>
                                </div>

                                <div className="space-y-2 mb-3">
                                    <div className="flex items-center text-sm text-gray-700"> {/* Updated text color */}
                                        <Mail className="w-4 h-4 mr-2 text-blue-500" />
                                        <span>{prospect.email}</span>
                                    </div>
                                    <div className="flex items-center text-sm text-gray-700"> {/* Updated text color */}
                                        <Globe className="w-4 h-4 mr-2 text-green-500" />
                                        <a
                                            href={prospect.website}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-blue-500 hover:underline"
                                        >
                                            {prospect.website}
                                        </a>
                                    </div>
                                </div>
                                <div className="bg-gray-100 p-3 rounded-lg relative mb-3 border border-gray-200"> {/* Updated card style */}
                                    <h3 className="text-sm font-medium text-gray-800 mb-2 flex items-center"> {/* Updated text color */}
                                        <FileText className="w-4 h-4 mr-2 text-blue-500" />
                                        Prospect Summary
                                    </h3>
                                    <p className="text-xs text-gray-600 line-clamp-3">{prospect.summary || 'No summary available'}</p> {/* Updated text color */}
                                </div>

                                <div className="bg-gray-100 p-3 rounded-lg relative border border-gray-200"> {/* Updated card style */}
                                    <h3 className="text-sm font-medium text-gray-800 mb-2 flex items-center"> {/* Updated text color */}
                                        <AlertCircle className="w-4 h-4 mr-2 text-yellow-500" />
                                        Conversion Tip
                                    </h3>
                                    <p className="text-xs text-gray-600 line-clamp-2">{prospect.tipforconversion}</p> {/* Updated text color */}
                                    <button
                                        onClick={() => openAIProspectAnalysis(prospect)}
                                        className="absolute top-2 right-2 text-blue-500 hover:text-blue-700" // Updated hover color
                                    >
                                        <EyeIcon className="w-4 h-4" />
                                    </button>
                                </div>
                                <div className="mt-4 space-y-2">
                                    <button
                                        onClick={() => handleProspectUpdate(prospect, 'received_response')}
                                        className="inline-flex justify-center items-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-gray-400 disabled:cursor-not-allowed w-full" // Consistent button style + w-full
                                    >
                                        <MessageCircle className="mr-1 w-4 h-4" /> Received Response
                                    </button>
                                    <button
                                        onClick={() => handleProspectUpdate(prospect, 'revalidate')}
                                        className="inline-flex justify-center items-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 disabled:bg-gray-400 disabled:cursor-not-allowed w-full" // Consistent button style + w-full
                                    >
                                        <Zap className="mr-1 w-4 h-4" /> No Response & Revalidate
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Empty State */}
                    {(!filteredProspects || filteredProspects.length === 0) && (
                        <div className="text-center py-10 bg-gray-50 rounded-lg shadow-md border border-gray-100"> {/* Updated empty state style */}
                            <Users className="mx-auto w-12 h-12 text-gray-400 mb-4" />
                            <p className="text-gray-600">No prospects found</p>
                        </div>
                    )}
                </div>

                {/* Golden Lead Set Success Modal */}
                {isGoldenLeadModalOpen && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white p-6 rounded-lg max-w-sm w-full shadow-lg border border-gray-200"> {/* Updated modal style */}
                            <div className="text-center">
                                <Zap className="mx-auto w-12 h-12 text-green-500 mb-4" />
                                <h2 className="text-xl font-bold text-gray-900 mb-2">Golden Lead Set Created!</h2> {/* Updated text color */}
                                <p className="text-gray-600 mb-4">Let's craft our game plan for these top prospects.</p> {/* Updated text color */}
                                <button
                                    onClick={() => setIsGoldenLeadModalOpen(false)}
                                    className="inline-flex justify-center items-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:bg-gray-400 disabled:cursor-not-allowed" // Consistent button style
                                >
                                    Let's Go!
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {selectedProspectTip && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
                    <div className="bg-white rounded-lg max-w-xl w-full shadow-2xl border border-gray-100"> {/* Updated modal card style */}
                        {/* Header */}
                        <div className="flex justify-between items-center p-4 border-b border-gray-200"> {/* Updated border color */}
                            <h2 className="text-xl font-bold text-gray-900">AI Prospect Analysis</h2> {/* Updated text color */}
                            <button
                                onClick={closeAIProspectAnalysis}
                                className="text-gray-600 hover:text-gray-900 p-2 rounded-full hover:bg-gray-100 transition-colors" // Updated button style
                            >
                                <X className="w-6 h-6" />
                            </button>
                        </div>

                        {/* Content */}
                        <div className="p-6"> {/* Updated padding */}
                            {/* Prospect Header */}
                            <div className="flex items-center mb-4">
                                {selectedProspectTip.profilePicture ? (
                                    <img
                                        src={selectedProspectTip.profilePicture}
                                        alt={selectedProspectTip.name}
                                        className="w-16 h-16 rounded-full object-cover mr-4"
                                    />
                                ) : (
                                    <DefaultProfileIcon name={selectedProspectTip.name} className="mr-4" />
                                )}
                                <div>
                                    <h3 className="text-lg font-semibold text-gray-900">{selectedProspectTip.name}</h3> {/* Updated text color */}
                                    <p className="text-sm text-gray-600">{selectedProspectTip.company}</p> {/* Updated text color */}
                                </div>
                            </div>

                            {/* Detailed Summary */}
                            <div className="bg-gray-100 p-4 rounded-lg mb-4 border border-gray-200"> {/* Updated card style */}
                                <h4 className="text-sm font-medium text-gray-800 mb-2 flex items-center"> {/* Updated text color */}
                                    <FileText className="w-4 h-4 mr-2 text-blue-500" />
                                    Detailed Prospect Summary
                                </h4>
                                <p className="text-sm text-gray-700">{selectedProspectTip.summary || 'No detailed summary available'}</p> {/* Updated text color */}
                            </div>

                            {/* Detailed Conversion Tip */}
                            <div className="bg-gray-100 p-4 rounded-lg mb-4 border border-gray-200"> {/* Updated card style */}
                                <h4 className="text-sm font-medium text-gray-800 mb-2 flex items-center"> {/* Updated text color */}
                                    <AlertCircle className="w-4 h-4 mr-2 text-yellow-500" />
                                    Detailed Conversion Tip
                                </h4>
                                <p className="text-sm text-gray-700">{selectedProspectTip.tipforconversion}</p> {/* Updated text color */}
                            </div>

                            {/* AI Insights */}
                            <div className="bg-gray-100 p-4 rounded-lg border border-gray-200"> {/* Updated card style */}
                                <h4 className="text-sm font-medium text-gray-800 mb-2"> {/* Updated text color */}
                                    AI-Powered Insights
                                </h4>
                                <ul className="space-y-2 text-xs text-gray-700"> {/* Updated text color */}
                                    <li>• Recommended approach: Personalized outreach</li>
                                    <li>• Communication channel: {selectedProspectTip.preferredChannel || 'Not specified'}</li>
                                    <li>• Potential pain points: {selectedProspectTip.painPoints || 'Further investigation needed'}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <GamefiedAIAssistant />
        </div>
    );
};

export default ProspectManagement;