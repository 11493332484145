import React, { useState, useEffect } from 'react';
import {
  Search, Bell, ChevronDown, HelpCircle,
  Filter, Download, Mail, Check, AlertTriangle,
  ExternalLink, RefreshCw, Users
} from 'lucide-react';
import axios from 'axios';

const ProspectsView = () => {
  const [prospects, setProspects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('all');

  useEffect(() => {
    fetchProspects();
  }, []);

  const fetchProspects = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/alleads', {
        userId: "avs"
      });

      // Ensure the response data is an array
      if (Array.isArray(response.data)) {
        setProspects(response.data);
      } else {
        console.error('Expected an array but received:', response.data);
        setProspects([]);
      }

      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching prospects:', error);
      setIsLoading(false);
    }
  };

  const filteredProspects = Array.isArray(prospects) ? prospects.filter(prospect => {
    const matchesSearch =
      prospect.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      prospect.company.toLowerCase().includes(searchTerm.toLowerCase()) ||
      prospect.email.toLowerCase().includes(searchTerm.toLowerCase());

    if (selectedFilter === 'all') return matchesSearch;
    if (selectedFilter === 'valid') return matchesSearch && prospect.emailValidity === 'valid';
    if (selectedFilter === 'invalid') return matchesSearch && prospect.emailValidity === 'invalid';
    if (selectedFilter === 'pending') return matchesSearch && prospect.mailsent === 'false';
    return matchesSearch;
  }) : [];

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="bg-gray-900 sticky top-0 z-10">
        <div className="max-w-7xl mx-auto">
          <div className="flex h-16 items-center justify-between px-4">
            <div className="flex items-center space-x-8">
              <div className="flex items-center space-x-2">
                <span className="text-white font-bold">EchidnaMail</span>
                <span className="text-red-500 font-bold">v2</span>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <button className="p-2 text-gray-300 hover:text-white hover:bg-gray-800 rounded-full relative">
                <Bell size={20} />
                <span className="absolute top-1 right-1 h-2 w-2 bg-blue-500 rounded-full"></span>
              </button>
              <div className="flex items-center space-x-3 ml-4 px-3 py-2 bg-gray-800 rounded">
                <div className="h-8 w-8 bg-blue-600 rounded-full flex items-center justify-center text-white text-sm font-medium">
                  EC
                </div>
                <div>
                  <div className="text-gray-200 text-sm">Enterprise</div>
                  <div className="text-gray-400 text-xs">us-west-2</div>
                </div>
                <ChevronDown size={16} className="text-gray-400" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="flex items-center justify-between mb-8">
          <div>
            <div className="flex items-center space-x-4">
              <h1 className="text-2xl font-semibold text-gray-900">Prospects</h1>
              <span className="px-3 py-1 bg-blue-50 text-blue-700 text-sm font-medium rounded-full">
                {filteredProspects.length} Total
              </span>
            </div>
            <p className="mt-2 text-sm text-gray-600">Manage and monitor your prospect database</p>
          </div>
          <div className="flex items-center space-x-4">
            <button onClick={fetchProspects} className="flex items-center space-x-2 px-4 py-2 bg-white border border-gray-200 rounded-lg text-gray-700 hover:bg-gray-50">
              <RefreshCw size={20} />
              <span className="text-sm font-medium">Refresh</span>
            </button>
            <button className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center space-x-2">
              <Download size={20} />
              <span className="text-sm font-medium">Export</span>
            </button>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
          <div className="p-4 border-b border-gray-200 bg-gray-50">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-4 flex-1">
                <div className="relative flex-1 max-w-lg">
                  <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                  <input
                    type="text"
                    placeholder="Search prospects..."
                    className="pl-10 pr-4 py-2 w-full bg-white border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <select
                  className="px-4 py-2 bg-white border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  value={selectedFilter}
                  onChange={(e) => setSelectedFilter(e.target.value)}
                >
                  <option value="all">All Prospects</option>
                  <option value="valid">Valid Emails</option>
                  <option value="invalid">Invalid Emails</option>
                  <option value="pending">Pending Outreach</option>
                </select>
              </div>
            </div>
          </div>

          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="bg-gray-50">
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Company</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Score</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {isLoading ? (
                  <tr>
                    <td colSpan="6" className="px-6 py-4 text-center text-gray-500">
                      Loading prospects...
                    </td>
                  </tr>
                ) : filteredProspects.map((prospect) => (
                  <tr key={prospect.did} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">{prospect.name}</div>
                      <div className="text-sm text-gray-500">{prospect.leadSet || 'No Set'}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{prospect.company}</div>
                      <a href={prospect.website} target="_blank" rel="noopener noreferrer" className="text-sm text-blue-600 hover:text-blue-800 flex items-center space-x-1">
                        <span>Website</span>
                        <ExternalLink size={14} />
                      </a>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{prospect.email}</div>
                      <div className={`text-sm ${prospect.emailValidity === 'valid' ? 'text-green-600' : 'text-red-600'}`}>
                        {prospect.emailValidity === 'valid' ? 'Valid' : 'Invalid'}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                        prospect.mailsent === 'true'
                          ? 'bg-green-100 text-green-800'
                          : 'bg-yellow-100 text-yellow-800'
                      }`}>
                        {prospect.mailsent === 'true' ? 'Contacted' : 'Pending'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">{prospect.validityscore}%</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <button
                        onClick={() => {/* Add view details handler */}}
                        className="text-blue-600 hover:text-blue-900 font-medium"
                      >
                        View Details
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProspectsView;
