import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './Upload.css';
import Navbar from '../../components/navbar/Navbar';

const Upload = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const { userInfo } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [productType, setProductType] = useState('');
  const [serverLocation, setServerLocation] = useState('');
  const [envi, setEnvi] = useState('Original');


  const handleFileUpload = async (event) => {
    event.preventDefault();
    if (!selectedFile) {
      alert('Please select a file to upload.');
      return;
    }

   

    const userId = userInfo._id;

    const fileNameWithExtension = selectedFile.name;
    const fileNameWithoutExtension = fileNameWithExtension.split('.').slice(0, -1).join('');
    sessionStorage.setItem('fileNameWithoutExtension', fileNameWithoutExtension);
    sessionStorage.setItem('envi', envi);
    sessionStorage.setItem('serverLocation', serverLocation);

    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('userId', userId);
    formData.append('fileName', fileNameWithoutExtension);
    formData.append('fileType', selectedFile.type);
    formData.append('productType', productType);
    formData.append('serverLocation', serverLocation);
    formData.append('envi', envi);

    function getServerInfo(serverLocation) {
      let url;
      switch (serverLocation) {
        case 'Tokyo':
          url = 'https://web-production-5ee8.up.railway.app/products/Tokyoupload';
          break;
        case 'Mumbai':
          url = 'https://web-production-5ee8.up.railway.app/products/Mumbaiupload';
          break;
        case 'Paris':
          url = 'https://web-production-5ee8.up.railway.app/products/Parisupload';
          break;
        case 'California':
          url = 'https://web-production-5ee8.up.railway.app/products/Californiaupload';
          break;
        default:
          throw new Error('Invalid server location');
      }
      return url;
    }
    
    try {
      const postUrl = getServerInfo(serverLocation);
      const response = await fetch(postUrl, {
        method: 'POST',
        body: formData,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer ' + userInfo._id,
        },
      });

      if (response.ok) {
        const responseData = await response.json();

        try {
          const stripeResponse = await fetch(`https://web-production-5ee8.up.railway.app/payments/pay/${fileNameWithoutExtension}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              fileNameWithoutExtension: responseData.fileNameWithoutExtension,
            }),
          });

          if (stripeResponse.ok) {
            const checkoutSessionData = await stripeResponse.json();
            const stripeCheckoutURL = checkoutSessionData.url;

            // Redirect the user to the Stripe Checkout URL
            window.location.href = stripeCheckoutURL;
          } else {
            console.error('Failed to get Stripe Checkout URL.');
            alert('Failed to get Stripe Checkout URL.');
          }
        } catch (error) {
          console.log(error);
          alert('Failed to get Stripe Checkout URL.');
        }

        console.log(responseData);
        alert('File uploaded successfully.');
        setSelectedFile(null);
      } else {
        console.error('File upload failed.');
        alert('File upload failed.');
      }
    } catch (error) {
      console.log(error);
      alert('File upload failed.');
    }
  };

  const handleSelectChange = (event) => {
    setProductType(event.target.value);
  };

  const envichange = (event) => {
    const newEnvi = event.target.value;
    console.log('Selected environment:', newEnvi); // Debugging line
    setEnvi(newEnvi);
    sessionStorage.setItem('envi', newEnvi);
  };
  
  

  const handleServerSelectChange = (event) => {
    setServerLocation(event.target.value);
  };

  return (
    <div className="h-screen w-screen flex justify-center items-center relative upload-bg overflow-hidden">
    <div>
      <div>
        <Navbar />
        <div >
          <div className="alert alert-warning">
            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.31 16c-.77 1.333.192 3 1.732 3z"/>
            </svg>
            <span className="ml-2">File upload warning message here</span>
          </div>
        </div>
        <div className="h-screen w-screen flex justify-center items-center" style={{ paddingBottom: '200px' }}>
          <div className='bottom-[10rem]'>
          <div className="input-button relative">
            <div className="absolute top-[-5rem]">
              <input
                type="text"
                placeholder="Enter product name"
                className="input input-bordered w-full max-w-2xl"
                value={fileName}
                onChange={(event) => {
                  setFileName(event.target.value);
                }}
              />
            </div>
            <div className="absolute bottom-[-5rem]">
      <select className="select" onChange={handleSelectChange} value={productType}>
        <option disabled selected>Select the product type</option>
        <option value="Footwear">Footwear</option>
        <option value="Watches">Watches</option>
        <option value="Jewelry">Jewelry</option>
        <option value="Clothing">Clothing</option>
        <option value="Small accessories">Small accessories</option>
      </select>
    </div>
 
            <div className="absolute bottom-[-10rem]">
              <select className="select" onChange={handleServerSelectChange} value={serverLocation}>
                <option disabled selected>Select server location</option>
                <option value="Tokyo">Tokyo</option>
                <option value="Mumbai">Mumbai</option>
                <option value="Paris">Paris</option>
                <option value="California">California</option>
              </select>
            </div>

            <div className="absolute bottom-[-15rem]">
            <select className="select" onChange={envichange} value={envi}>
  <option value="" disabled>Select VR environment</option>
  <option value="Original">Original environment</option>
  <option value="Highperf">High performance</option>
</select>

            </div>

            <div>
            <button className="btn btn-primary absolute bottom-[-20rem]" onClick={handleFileUpload}>
          Upload File]
          </button>
            </div>
            <label className="form-control">
              <div className="label">
                <span className="label-text">Pick a file</span>
                <span className="label-text-alt">.glb file</span>
              </div>
              <input
                type="file"
                className="file-input file-input-bordered w-full max-w-xs"
                accept=".glb,.gltf,.glb-binary,.gltf+json"
                onChange={(event) => {
                  setSelectedFile(event.target.files[0])
                }}
              />
              <div className="label">
                <span className="label-text-alt"></span>
                <span className="label-text-alt"></span>
              </div>
            </label>
            {selectedFile && (
              <div>
                <p>{selectedFile.name}</p>
              </div>
            )}
          </div>
        </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Upload;
