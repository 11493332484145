import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  Send,
  Copy,
  X as CloseIcon,
  Circle,
  Check,
  User,
  Bot
} from 'lucide-react';

export function AgentChat() {
  const { agentId } = useParams();
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null); // State to hold error messages
  const [copiedIndex, setCopiedIndex] = useState(null);
  const [agentDetails, setAgentDetails] = useState({ name: '', logoUrl: '' });
  const chatEndRef = useRef(null);
  const inputRef = useRef(null);

  // --- Fetch Agent Details ---
  useEffect(() => {
    const fetchAgentDetails = async () => {
      if (!agentId) return;

      try {
        const response = await fetch('https://web-production-5ee8.up.railway.app/echidna/agentdeets', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ agentId }),
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch agent details: ${response.status}`);
        }

        const data = await response.json();
        if (data.success && data.agent) {
          setAgentDetails({
            name: data.agent.name || `Agent ${agentId}`,
            logoUrl: data.agent.logoUrl || ''
          });
        } else {
            // Handle case where API call succeeded but didn't return expected data
            console.warn("Agent details response missing agent data:", data);
            setAgentDetails({ name: `Agent ${agentId}`, logoUrl: '' }); // Set default name
        }
      } catch (err) {
        console.error("Error fetching agent details:", err);
        // Set a default name even if fetching fails, so the UI isn't broken
        setAgentDetails({ name: `Agent ${agentId}`, logoUrl: '' });
        // Don't set the main 'error' state here to avoid disrupting the chat
      }
    };

    fetchAgentDetails();
  }, [agentId]);

  // --- Helper Functions ---
  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const fetchAIResponse = useCallback(async (currentMessage, currentHistory) => {
    if (!agentId) {
      setError("Agent ID is missing.");
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    setError(null); // Clear previous errors
    const apiUrl = `https://web-production-5ee8.up.railway.app/echidna/agents/v2/East-us/${agentId}`;

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          message: currentMessage,
          history: currentHistory // Sending the correctly formatted history
        }),
      });

      // --- MODIFIED ERROR HANDLING ---
      if (!response.ok) {
        // Check specifically for 429 Too Many Requests
        if (response.status === 429) {
          // Set specific error message for rate limiting
          setError("The hourly request limit for this agent has been exceeded. Please try again in some time.");
        } else {
          // Handle other HTTP errors
          let errorData;
          let detailMessage = response.statusText; // Default to status text
          try {
            // Try to get more specific error message from response body
            errorData = await response.json();
            // Use 'details' if available (matching the error format you received), otherwise 'message', fallback to status text
            detailMessage = errorData?.details || errorData?.message || response.statusText;
          } catch (parseError) {
            // If response body is not JSON or empty, stick with statusText
            console.warn("Could not parse error response JSON:", parseError);
          }
          setError(`API Error: ${response.status} - ${detailMessage}`);
        }
        setIsLoading(false); // Ensure loading state is turned off on error
        return; // Stop execution since there was an error
      }
      // --- END OF MODIFIED ERROR HANDLING ---

      const data = await response.json();
      const aiText = typeof data.message === 'string' ? data.message : 'Received invalid response format.';
      setMessages(prev => [...prev, { sender: 'ai', text: aiText }]);

    } catch (err) {
      // Handle network errors (e.g., fetch itself failed)
      console.error("Fetch Error:", err);
      // Avoid setting error if it was already set by the 429/other HTTP check
      if (!error) {
          setError(err.message || "An error occurred while fetching the response. Check your network connection.");
      }
    } finally {
      // This will run regardless of success or caught error
      setIsLoading(false);
      inputRef.current?.focus();
    }
  }, [agentId, error]); // Added 'error' to dependency array for the catch block logic

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    const textarea = event.target;
    textarea.style.height = 'auto'; // Reset height
    textarea.style.height = `${textarea.scrollHeight}px`; // Set to content height
  };

  const handleSendMessage = useCallback(async (event = null) => {
    if (event && typeof event.preventDefault === 'function') {
      event.preventDefault();
    }
    const trimmedMessage = inputValue.trim();
    if (!trimmedMessage || isLoading) return;

    setError(null); // Clear error when user sends a new message

    const userMessage = { sender: 'user', text: trimmedMessage };

    // --- CORRECTED HISTORY PREPARATION ---
    // Prepare history: Map the current messages state to the required format {sender, text}
    // This now matches the requirement indicated by the backend error message.
    const historyForAPI = messages.map(msg => ({
        sender: msg.sender,
        text: msg.text
    }));
    // --- END OF CORRECTION ---


    // Update local state *immediately* for better UX
    // The API call will use the history *before* this new message
    setMessages(prevMessages => [...prevMessages, userMessage]);
    setInputValue('');
    if (inputRef.current) {
      inputRef.current.style.height = 'auto'; // Reset textarea height after sending
    }

    // Pass the message just sent and the history *before* that message to the API call
    await fetchAIResponse(trimmedMessage, historyForAPI);

  }, [inputValue, isLoading, messages, fetchAIResponse]); // Ensure fetchAIResponse is stable

  const handleCopy = (textToCopy, index) => {
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        setCopiedIndex(index);
        setTimeout(() => setCopiedIndex(null), 1500); // Hide after 1.5s
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
        // Optionally: Set an error state or alert user about copy failure
      });
  };

  const handleKeyDown = (event, action) => {
    // Send on Enter (not Shift+Enter) in textarea
    if (event.key === 'Enter' && !event.shiftKey && event.target.tagName === 'TEXTAREA') {
      event.preventDefault();
      handleSendMessage();
    }
    // Trigger button action on Enter or Space
    else if ((event.key === 'Enter' || event.key === ' ') && event.target.getAttribute('role') === 'button') {
      event.preventDefault(); // Prevent default space scroll or enter submit
      action?.(); // Call the action if provided (e.g., handleCopy, handleSendMessage for the button)
    }
  };

  // --- Styled Component Constants (Neo-Minimalist Design) ---
  const BG_COLOR = 'bg-black';
  const INPUT_BG_COLOR = 'bg-zinc-900';
  const AI_MSG_BG = 'bg-zinc-900';
  const USER_MSG_BG = 'bg-zinc-800';
  const ICON_COLOR = 'text-zinc-500 hover:text-white transition-all duration-200';
  const BUTTON_COLOR = 'bg-white text-black hover:bg-zinc-200 transition-all duration-200';
  const DISABLED_BUTTON = 'bg-zinc-800 text-zinc-600 cursor-not-allowed'; // Added cursor-not-allowed

  // --- Disclaimer Text ---
  const disclaimerText = agentDetails.name
    ? `${agentDetails.name} can make mistakes. Verify important information.`
    : `Echidna Agent ${agentId || 'N/A'} can make mistakes. Verify important information.`;

  return (
    <div className={`flex flex-col h-screen ${BG_COLOR} text-white font-sans`}>
      {/* Header with Agent Name and Logo */}
      <div className="p-4 border-b border-zinc-800 flex items-center justify-between flex-shrink-0">
        <div className="flex items-center space-x-3">
          {agentDetails.logoUrl ? (
            <img
              src={agentDetails.logoUrl}
              alt={`${agentDetails.name} logo`}
              className="h-8 w-8 rounded-full bg-zinc-800 object-cover"
              onError={(e) => { e.target.style.display = 'none'; /* Hide if fails */ }} // Basic error handling for image
            />
          ) : (
            <div className="h-8 w-8 rounded-full bg-zinc-800 flex items-center justify-center">
              <Bot size={16} className="text-white" />
            </div>
          )}
          <h1 className="text-lg font-medium tracking-tight truncate">
             {agentDetails.name || `Agent ${agentId || '...'}`} {/* Show loading indicator or ID if name not loaded */}
          </h1>
        </div>
        {/* Optional: Add close/back button if needed */}
      </div>

      {/* Content Area */}
      <div className="flex-grow overflow-y-auto py-6 px-4 md:px-0">
        {/* Conditional Rendering: Empty State or Messages */}
        {messages.length === 0 && !isLoading && !error && ( // Only show empty state if no messages, not loading, and no errors
          <div className="flex flex-col justify-center items-center h-full p-4 space-y-4 text-center">
            {/* You can enhance the empty state with the logo too */}
            {agentDetails.logoUrl ? (
                <img src={agentDetails.logoUrl} alt="" className="h-16 w-16 rounded-full mb-4 bg-zinc-800 object-cover"/>
            ) : (
                <div className="h-16 w-16 rounded-full bg-zinc-800 flex items-center justify-center mb-4">
                 <Bot size={32} className="text-white" />
                </div>
            )}
            <h1 className="text-2xl sm:text-3xl font-light tracking-tight text-white">
                {agentDetails.name ? `Chat with ${agentDetails.name}` : 'How can I assist?'}
            </h1>
            <p className="text-zinc-400 max-w-md">
              Start the conversation by typing your message below.
            </p>
          </div>
        )}

        {/* Chat Messages Area */}
        {(messages.length > 0 || isLoading || error) && ( // Show this container if there are messages OR loading OR an error
          <div className="max-w-2xl lg:max-w-3xl mx-auto px-4 space-y-6">
            {messages.map((msg, index) => (
              <div key={index} className={`flex ${msg.sender === 'user' ? 'justify-end' : 'justify-start'}`}>
                {/* User Message */}
                {msg.sender === 'user' && (
                  <div className="flex items-start space-x-2 max-w-[85%]">
                    <div className={`${USER_MSG_BG} px-4 py-3 rounded-2xl text-white shadow-sm inline-block`}>
                      <p style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{msg.text}</p>
                    </div>
                    <div className="mt-1 flex-shrink-0 w-6 h-6 rounded-full bg-zinc-700 flex items-center justify-center">
                      <User size={14} className="text-white" />
                    </div>
                  </div>
                )}

                {/* AI Message */}
                {msg.sender === 'ai' && (
                  <div className="flex items-start space-x-2 max-w-[85%]">
                    <div className="flex-shrink-0 w-6 h-6 rounded-full bg-zinc-700 flex items-center justify-center mt-1">
                      {agentDetails.logoUrl ? (
                        <img
                          src={agentDetails.logoUrl}
                          alt="Agent"
                          className="w-full h-full rounded-full object-cover"
                          onError={(e) => {
                              // Attempt to hide the img tag and show the fallback Bot icon if image fails
                              const parentDiv = e.target.parentElement;
                              e.target.style.display = 'none'; // Hide img tag
                              // Check if fallback already added to prevent duplicates on multiple errors
                              if (!parentDiv.querySelector('.fallback-bot-icon')) {
                                  const fallback = document.createElement('div');
                                  fallback.className = 'fallback-bot-icon'; // Add class for identification
                                  // Render Bot icon using lucide-react's typical SVG output or a simple placeholder
                                  // Note: Directly adding complex SVG here is tricky. A simple text or pre-rendered icon might be better.
                                  // For simplicity, let's just ensure the space is there. The parent bg covers it.
                                  // Or better, use the conditional rendering below.
                              }
                          }}
                         />
                      ) : (
                        <Bot size={14} className="text-white" />
                      )}
                       {/* Fallback Icon logic can be simplified with conditional rendering if onError handler complex */}
                       {!agentDetails.logoUrl && <Bot size={14} className="text-white" /> }

                    </div>
                    <div className={`${AI_MSG_BG} px-4 py-3 rounded-2xl text-white shadow-sm group relative`}> {/* Added relative for positioning copy */}
                      <p style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }} className="mb-2">{msg.text}</p>

                      {/* Interaction Icon - Appears on hover */}
                      <div className="absolute bottom-1 right-1 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                         <div
                            role="button"
                            tabIndex={0}
                            aria-label={copiedIndex === index ? "Copied" : "Copy message"}
                            onKeyDown={(e) => handleKeyDown(e, () => handleCopy(msg.text, index))}
                            onClick={() => handleCopy(msg.text, index)}
                            className="p-1.5 rounded-full bg-zinc-700 hover:bg-zinc-600 cursor-pointer inline-flex items-center"
                          >
                            {copiedIndex === index ? (
                               <Check size={14} className="text-green-400" />
                            ) : (
                               <Copy size={14} className="text-zinc-300" />
                            )}
                          </div>
                        </div>
                    </div>
                  </div>
                )}
              </div>
            ))}

            {/* Loading Indicator */}
            {isLoading && (
              <div className="flex justify-start items-center py-3">
                 <div className="flex items-start space-x-2 max-w-[85%]">
                    <div className="flex-shrink-0 w-6 h-6 rounded-full bg-zinc-700 flex items-center justify-center mt-1">
                       {agentDetails.logoUrl ? ( <img src={agentDetails.logoUrl} alt="" className="w-full h-full rounded-full object-cover"/>) : (<Bot size={14} className="text-white" />)}
                    </div>
                     <div className={`${AI_MSG_BG} px-4 py-3 rounded-2xl text-white shadow-sm inline-flex items-center space-x-2`}>
                         <Circle size={14} className="animate-spin text-zinc-400" />
                         <span className="text-sm text-zinc-400">Thinking...</span>
                     </div>
                 </div>
              </div>
            )}

            {/* Error Display */}
            {error && ( // This will now display the specific 429 message when relevant
              <div className="mx-auto px-4">
                 <div className="text-red-400 text-sm text-center mt-2 bg-red-900/20 p-3 rounded-lg border border-red-800/50">
                    {error} {/* Displays the error message set in fetchAIResponse */}
                 </div>
              </div>
            )}

            {/* Scroll Anchor */}
            <div ref={chatEndRef} />
          </div>
        )}
      </div>

      {/* Input Area */}
      <div className={`w-full py-4 px-4 ${BG_COLOR} border-t border-zinc-900 flex-shrink-0`}>
        <form onSubmit={handleSendMessage} className="flex items-end w-full max-w-2xl lg:max-w-3xl mx-auto rounded-xl overflow-hidden shadow-md border border-zinc-800 focus-within:border-zinc-700 transition-colors duration-200 bg-zinc-900">
          <textarea
            ref={inputRef}
            rows="1"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={(e) => handleKeyDown(e, handleSendMessage)} // Use consolidated handler
            placeholder={`Message ${agentDetails.name || 'Agent'}...`}
            disabled={isLoading}
            className="flex-grow bg-transparent border-none focus:ring-0 focus:outline-none text-white placeholder-zinc-500 resize-none py-3 px-4 max-h-40 overflow-y-auto"
            style={{ scrollbarWidth: 'none' }} // For Firefox
            aria-label="Chat input"
          />

          {/* Send Button Wrapper Div */}
          <div className="p-2"> {/* Padding around the button */}
            <button
              type="submit" // Use button type submit for form
              aria-label="Send message"
              disabled={isLoading || !inputValue.trim()} // Disable logic
              className={`p-2 flex items-center justify-center rounded-lg transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-zinc-900 focus:ring-white ${
                (inputValue.trim() && !isLoading)
                  ? `${BUTTON_COLOR} cursor-pointer` // Add cursor-pointer only when active
                  : `${DISABLED_BUTTON}` // Includes cursor-not-allowed
              }`}
            >
              {isLoading ? (
                <Circle size={18} className="animate-spin text-zinc-400" /> // Spinner color adjusted
              ) : (
                <Send size={18} strokeWidth={2.5} />
              )}
            </button>
          </div>
        </form>

        {/* Disclaimer */}
        <p className="text-xs text-zinc-500 text-center mt-3 px-4">{disclaimerText}</p>
      </div>
    </div>
  );
}