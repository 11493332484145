import React, { useState } from 'react';
import { 
  TrendingUp, 
  BarChart2, 
  Signal, 
  TrendingDown, 
  AlertTriangle,
  Zap,
  Target,
  Warehouse,
  Scale,
  Users,
  ArrowRight 
} from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';
import logo from './logo.png';

// Market Volatility Chart
const MarketVolatilityChart = () => {
  const [volatilityData, setVolatilityData] = useState([
    { name: 'Jan', volatility: 15 },
    { name: 'Feb', volatility: 22 },
    { name: 'Mar', volatility: 18 },
    { name: 'Apr', volatility: 25 },
    { name: 'May', volatility: 20 },
    { name: 'Jun', volatility: 28 }
  ]);

  return (
    <div className="bg-gray-900/70 rounded-lg border border-gray-800 p-6 h-full">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold text-gray-200">Market Volatility Index</h3>
        <Signal className="w-5 h-5 text-blue-500" />
      </div>
      <ResponsiveContainer width="100%" height={350}>
        <LineChart data={volatilityData}>
          <XAxis dataKey="name" stroke="#6b7280" />
          <YAxis stroke="#6b7280" />
          <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
          <Tooltip 
            contentStyle={{ backgroundColor: '#1f2937', border: 'none' }} 
            labelStyle={{ color: '#f3f4f6' }}
          />
          <Line 
            type="monotone" 
            dataKey="volatility" 
            stroke="#3b82f6" 
            strokeWidth={3} 
            dot={{ stroke: '#3b82f6', strokeWidth: 2, r: 5 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

// Lead Management Table
const LeadManagementTable = () => {
  const [leads, setLeads] = useState([
    { 
      name: 'TechCorp Enterprises', 
      prospect: 'Sarah Johnson',
      potential: 'High'
    },
    { 
      name: 'GlobalInc Solutions', 
      prospect: 'Michael Chen',
      potential: 'Medium'
    },
    { 
      name: 'StartupX Innovations', 
      prospect: 'Emily Rodriguez',
      potential: 'Low'
    },
    { 
      name: 'InnoTech Dynamics', 
      prospect: 'David Kim',
      potential: 'Medium'
    },
    { 
      name: 'RapidGrowth Ventures', 
      prospect: 'Alex Martinez',
      potential: 'High'
    }
  ]);

  return (
    <div className="bg-gray-900/70 rounded-lg border border-gray-800 p-6 h-full">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold text-gray-200">Lead Management</h3>
        <div className="flex items-center space-x-3">
          <Users className="w-5 h-5 text-blue-500" />
          <button 
            onClick={() => console.log('See All Leads')}
            className="flex items-center text-sm text-blue-400 hover:text-blue-300 transition-colors"
          >
            See All
            <ArrowRight className="w-4 h-4 ml-1" />
          </button>
        </div>
      </div>
      <div className="space-y-3">
        {leads.map((lead, index) => (
          <div 
            key={index} 
            className="flex justify-between items-center p-3 bg-gray-800/50 rounded-lg hover:bg-gray-800 transition-all duration-300"
          >
            <div>
              <div className="text-gray-200 font-semibold">{lead.name}</div>
              <div className="text-xs text-gray-400">{lead.prospect}</div>
            </div>
            <span className={`
              px-2 py-1 rounded-full text-xs
              ${lead.potential === 'High' ? 'bg-green-500/10 text-green-400' : 
                lead.potential === 'Medium' ? 'bg-yellow-500/10 text-yellow-400' : 
                'bg-red-500/10 text-red-400'}
            `}>
              {lead.potential}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

// Other small components (keep their existing implementations)
const ProspectSentimentAnalysis = () => {
  const [sentimentData, setSentimentData] = useState({
    positiveRate: 65,
    neutralRate: 25,
    negativeRate: 10
  });

  return (
    <div className="bg-gray-900/70 rounded-lg border border-gray-800 p-6 h-full">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold text-gray-200">Prospect Sentiment</h3>
        <BarChart2 className="w-5 h-5 text-blue-500" />
      </div>
      <div className="space-y-3">
        {[
          { label: 'Positive Engagement', value: sentimentData.positiveRate, color: 'bg-green-500' },
          { label: 'Neutral Interactions', value: sentimentData.neutralRate, color: 'bg-gray-500' },
          { label: 'Negative Signals', value: sentimentData.negativeRate, color: 'bg-red-500' }
        ].map((item, index) => (
          <div key={index} className="flex items-center space-x-3">
            <div className={`h-2 w-2 rounded-full ${item.color}`}></div>
            <div className="flex justify-between w-full">
              <span className="text-gray-400 text-sm">{item.label}</span>
              <span className="text-gray-200 font-semibold">{item.value}%</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const CommunicationEffectivenessMetrics = () => {
  const [metrics, setMetrics] = useState({
    responseRate: 85,
    engagementScore: 72,
    avgResponseTime: 4.2
  });

  return (
    <div className="bg-gray-900/70 rounded-lg border border-gray-800 p-6 h-full">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold text-gray-200">Communication Effectiveness</h3>
        <TrendingUp className="w-5 h-5 text-blue-500" />
      </div>
      <div className="space-y-3">
        {[
          { label: 'Response Rate', value: `${metrics.responseRate}%`, icon: TrendingUp, color: 'text-green-500' },
          { label: 'Engagement Score', value: metrics.engagementScore, icon: BarChart2, color: 'text-blue-500' },
          { label: 'Avg. Response Time (hrs)', value: metrics.avgResponseTime, icon: TrendingDown, color: 'text-gray-400' }
        ].map((metric, index) => (
          <div key={index} className="flex justify-between items-center">
            <div className="flex items-center space-x-2">
              <metric.icon className={`w-4 h-4 ${metric.color}`} />
              <span className="text-gray-400 text-sm">{metric.label}</span>
            </div>
            <span className="text-gray-200 font-semibold">{metric.value}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

const NavigationSection = () => {
    const navigationItems = [
      {
        title: 'AI Deal Room',
        description: 'Advanced deal tracking & insights',
        icon: Zap,
        color: 'text-blue-500',
        onClick: () => {
          console.log('Navigating to AI Deal Room');
          // Add actual navigation logic
        }
      },
      {
        title: 'Outreach Infrastructure',
        description: 'Communication & engagement platform',
        icon: Target,
        color: 'text-green-500',
        onClick: () => {
          console.log('Navigating to Outreach Infrastructure');
          // Add actual navigation logic
        }
      },
      {
        title: 'Prospects Engine',
        description: 'Intelligent prospect management',
        icon: Warehouse,
        color: 'text-purple-500',
        onClick: () => {
          console.log('Navigating to Prospects Engine');
          // Add actual navigation logic
        }
      },
      {
        title: 'Competitive Intelligence',
        description: 'Market & competitor analysis',
        icon: Scale,
        color: 'text-red-500',
        onClick: () => {
          console.log('Navigating to Competitive Intelligence');
          // Add actual navigation logic
        }
      }
    ];
  
    return (
      <div className="bg-gray-900/70 rounded-lg border border-gray-800 p-6 h-full">
        <h3 className="text-lg font-semibold text-gray-200 mb-4">Quick Access</h3>
        <div className="space-y-3">
          {navigationItems.map((item, index) => (
            <button 
              key={index} 
              onClick={item.onClick}
              className="w-full flex items-center space-x-3 p-3 bg-gray-800/50 hover:bg-gray-800 rounded-lg transition-all duration-300"
            >
              <item.icon className={`w-6 h-6 ${item.color}`} />
              <div className="text-left flex-grow">
                <div className="text-gray-200 font-semibold">{item.title}</div>
                <div className="text-xs text-gray-400">{item.description}</div>
              </div>
              <ArrowRight className="w-5 h-5 text-gray-400 opacity-50 group-hover:opacity-100" />
            </button>
          ))}
        </div>
      </div>
    );
  };
  
  // [Rest of the previous implementation remains the same]
  
  // Modify the main dashboard layout to include NavigationSection
  export const EnterpriseDashboard = () => {
    return (
      <div className="min-h-screen bg-black text-white p-8">
        <div className="container mx-auto space-y-8">
          <div className="flex justify-between items-center mb-6">
            <img src={logo} alt="Logo" className="w-9" />
            <h1 className="text-2xl font-bold text-gray-200">Enterprise Dashboard</h1>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            <div className="lg:col-span-2 grid grid-cols-1 gap-6">
              <MarketVolatilityChart />
              <div className="grid grid-cols-2 gap-6">
                <ProspectSentimentAnalysis />
                <CommunicationEffectivenessMetrics />
              </div>
            </div>
            <div className="lg:col-span-1 flex flex-col gap-6">
              <LeadManagementTable />
              <NavigationSection />
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default EnterpriseDashboard;