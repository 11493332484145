import React, { useState, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import {
  BarChart2,
  AlertCircle,
  Info,
  Target,
  MessageSquare,
  Users,
  Award,
  ChevronDown,
  ChevronUp,
  Loader
} from 'lucide-react';
import {
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  ResponsiveContainer,
  BarChart,
  Bar,
  Tooltip,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Cell
} from 'recharts';

const PMFAnalysisPage = () => {
  const { user } = useUser();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [analysis, setAnalysis] = useState(null);
  const [pipelineExists, setPipelineExists] = useState(false);
  const [progress, setProgress] = useState(0);
  const [expandedSections, setExpandedSections] = useState({});

  const toggleSection = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  useEffect(() => {
    const checkPipelineExists = async () => {
      if (!user) return;

      try {
        const response = await fetch('https://web-production-5ee8.up.railway.app/echidna/checkpipeline', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId: user.id }),
        });
        const data = await response.json();

        setPipelineExists(response.ok && data.hasPipeline);

        if (!(response.ok && data.hasPipeline)) {
          setError("Data pipeline setup required for PMF analysis.");
        }
      } catch (err) {
        console.error("Error checking pipeline existence:", err);
        setError("Could not verify data pipeline status.");
      }
    };

    checkPipelineExists();
  }, [user]);

  const runPMFAnalysis = async () => {
    if (!user) {
      setError("User authentication required.");
      return;
    }

    if (!pipelineExists) {
      setError("Data pipeline setup required for PMF analysis.");
      return;
    }

    setLoading(true);
    setError(null);
    setAnalysis(null);

    const interval = setInterval(() => {
      setProgress(prev => {
        const newProgress = prev + 8;
        return newProgress >= 90 ? 90 : newProgress;
      });
    }, 800);

    try {
      const response = await fetch(`https://web-production-5ee8.up.railway.app/echidna/pmf-analysis/${user.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();

      clearInterval(interval);
      setProgress(100);

      if (response.ok) {
        setTimeout(() => {
          setAnalysis(data.analysis);
          setExpandedSections({ pmfScoreBreakdown: true });
        }, 500);
      } else {
        setError(data.message || 'Unable to generate PMF analysis');
        console.error('Analysis failed:', data.message);
      }
    } catch (err) {
      clearInterval(interval);
      setError('Server connection error. Please try again later.');
      console.error('Error fetching PMF analysis:', err);
    } finally {
      setTimeout(() => {
        setLoading(false);
        setProgress(0);
      }, 500);
    }
  };

  // Function to safely render a value, handling objects and arrays
  const renderValue = (value) => {
    if (value === null || value === undefined) {
      return "N/A";
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        return (
          <ul className="list-disc pl-5 space-y-1">
            {value.map((item, index) => (
              <li key={index}>{renderValue(item)}</li>
            ))}
          </ul>
        );
      }

      // If it has score and analysis/reasoning
      if (value.score !== undefined && (value.analysis || value.reasoning)) {
        return (
          <div className="p-4 bg-white rounded-lg shadow-sm border border-gray-100">
            <div className="flex items-start">
              <div className={`text-white text-sm font-bold rounded-full w-8 h-8 flex items-center justify-center mr-3 ${getScoreColor(value.score)}`}>
                {value.score}
              </div>
              <p className="text-gray-700">{value.analysis || value.reasoning}</p>
            </div>
          </div>
        );
      }

      // If it has other fields specific to recommendations
      if (value.area && value.recommendation) {
        return (
          <div className="p-4 bg-white rounded-lg shadow-sm border border-gray-100">
            <h5 className="font-medium text-gray-800">{value.area}</h5>
            <p className="text-gray-700 mt-2">{value.recommendation}</p>
          </div>
        );
      }

      // For other objects
      return (
        <div className="pl-4 border-l-2 border-gray-200">
          {Object.entries(value).map(([subKey, subValue]) => (
            <div key={subKey} className="mb-3">
              <span className="font-medium text-gray-700">{subKey.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}:</span>{' '}
              {renderValue(subValue)}
            </div>
          ))}
        </div>
      );
    }

    return String(value);
  };

  const getScoreColor = (score) => {
    if (score >= 8) return "bg-emerald-500";
    if (score >= 6) return "bg-blue-500";
    if (score >= 4) return "bg-amber-500";
    return "bg-red-500";
  };

  const getSectionIcon = (section) => {
    switch (section) {
      case 'pmfScoreBreakdown':
        return <BarChart2 size={20} className="text-blue-600 mr-3" />;
      case 'valuePropositionAnalysis':
        return <Award size={20} className="text-indigo-600 mr-3" />;
      case 'targetAudienceAlignment':
        return <Target size={20} className="text-emerald-600 mr-3" />;
      case 'messagingClarity':
        return <MessageSquare size={20} className="text-amber-600 mr-3" />;
      case 'painPointAddressing':
        return <AlertCircle size={20} className="text-red-600 mr-3" />;
      case 'recommendations':
        return <Users size={20} className="text-teal-600 mr-3" />;
      default:
        return null;
    }
  };

  // Get nice display name for section keys
  const getSectionTitle = (section) => {
    const displayNames = {
      pmfScoreBreakdown: 'PMF Score Breakdown',
      valuePropositionAnalysis: 'Value Proposition Analysis',
      targetAudienceAlignment: 'Target Audience Alignment',
      messagingClarity: 'Messaging Clarity',
      painPointAddressing: 'Pain Point Analysis',
      recommendations: 'Recommendations'
    };

    return displayNames[section] || section.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
  };

  // Function to render PMF score radar chart
  const renderPMFRadarChart = () => {
    if (!analysis || !analysis.pmfScoreBreakdown || !analysis.pmfScoreBreakdown.categories) return null;

    const categories = analysis.pmfScoreBreakdown.categories.map(cat => ({
      subject: cat.name,
      score: cat.score,
      fullMark: 10
    }));

    return (
      <div className="mt-6 mb-8 bg-white p-6 rounded-lg shadow-sm border border-gray-100">
        <h4 className="text-lg font-medium text-gray-800 mb-4 text-center">PMF Score Breakdown</h4>
        <ResponsiveContainer width="100%" height={320}>
          <RadarChart cx="50%" cy="50%" outerRadius="80%" data={categories}>
            <PolarGrid strokeDasharray="3 3" />
            <PolarAngleAxis dataKey="subject" tick={{ fill: '#4B5563', fontSize: 12 }} />
            <PolarRadiusAxis angle={90} domain={[0, 10]} />
            <Radar name="PMF Score" dataKey="score" stroke="#3B82F6" fill="#3B82F6" fillOpacity={0.6} />
            <Tooltip contentStyle={{ borderRadius: '0.375rem', boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)' }} />
          </RadarChart>
        </ResponsiveContainer>
      </div>
    );
  };

  // Function to render key metrics chart
  const renderKeyMetricsChart = () => {
    if (!analysis) return null;

    // Extract key scores from various sections
    const metrics = [
      { name: 'Overall PMF', value: analysis.summary.pmfScore },
      { name: 'Value Prop.', value: analysis.valuePropositionAnalysis?.valuePropositionClarity?.score || 0 },
      { name: 'Audience', value: analysis.targetAudienceAlignment?.audienceClarity?.score || 0 },
      { name: 'Messaging', value: analysis.messagingClarity?.messagingClarity?.score || 0 },
      { name: 'Pain Points', value: analysis.painPointAddressing?.painPointClarity?.score || 0 },
    ];

    return (
      <div className="mt-6 mb-8 bg-white p-6 rounded-lg shadow-sm border border-gray-100">
        <h4 className="text-lg font-medium text-gray-800 mb-4 text-center">Key PMF Metrics</h4>
        <ResponsiveContainer width="100%" height={320}>
          <BarChart data={metrics} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.4} />
            <XAxis dataKey="name" axisLine={false} tickLine={false} />
            <YAxis domain={[0, 10]} axisLine={false} tickLine={false} />
            <Tooltip contentStyle={{ borderRadius: '0.375rem', boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)' }} />
            <Bar dataKey="value" name="Score" radius={[4, 4, 0, 0]}>
              {metrics.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={
                  entry.value >= 8 ? '#10B981' :
                    entry.value >= 6 ? '#3B82F6' :
                      entry.value >= 4 ? '#F59E0B' :
                        '#EF4444'
                } />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };

  // Function to render recommendations chart
  const renderRecommendationsChart = () => {
    if (!analysis || !analysis.recommendations) return null;

    // Take top recommendations for chart
    const recommendationData = analysis.recommendations
      .filter(rec => rec.area !== "Overall PMF")  // Exclude overall PMF
      .slice(0, 5)  // Take only top 5
      .map(rec => ({
        name: rec.area.split(':')[0],  // Only the main area name
        currentScore: rec.currentScore,
        potentialScore: Math.min(10, rec.currentScore + 2)  // Show potential improvement
      }));

    return (
      <div className="mt-6 mb-8 bg-white p-6 rounded-lg shadow-sm border border-gray-100">
        <h4 className="text-lg font-medium text-gray-800 mb-4 text-center">Improvement Opportunities</h4>
        <ResponsiveContainer width="100%" height={320}>
          <BarChart data={recommendationData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.4} />
            <XAxis dataKey="name" axisLine={false} tickLine={false} />
            <YAxis domain={[0, 10]} axisLine={false} tickLine={false} />
            <Tooltip contentStyle={{ borderRadius: '0.375rem', boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)' }} />
            <Legend />
            <Bar dataKey="currentScore" name="Current" fill="#3B82F6" radius={[4, 4, 0, 0]} />
            <Bar dataKey="potentialScore" name="Potential" fill="#10B981" radius={[4, 4, 0, 0]} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };

  // Function to render each analysis section
  const renderAnalysisSection = (section, data) => {
    if (!data) return null;

    const isExpanded = expandedSections[section] || false;
    const sectionIcon = getSectionIcon(section);

    return (
      <div className="bg-white rounded-lg shadow-md overflow-hidden mb-6 border border-gray-100">
        <button
          onClick={() => toggleSection(section)}
          className="flex items-center justify-between w-full p-6 bg-white hover:bg-gray-50 transition-colors duration-150 focus:outline-none"
        >
          <div className="flex items-center">
            {sectionIcon}
            <h3 className="text-xl font-semibold text-gray-800">{getSectionTitle(section)}</h3>
          </div>
          {isExpanded ? <ChevronUp size={20} className="text-gray-500" /> : <ChevronDown size={20} className="text-gray-500" />}
        </button>

        {isExpanded && (
          <div className="p-6 border-t border-gray-100">
            {/* Special handling for PMF Score section with chart */}
            {section === 'pmfScoreBreakdown' && renderPMFRadarChart()}

            {/* Special handling for Recommendations section with chart */}
            {section === 'recommendations' && renderRecommendationsChart()}

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {Object.entries(data).map(([key, value]) => {
                // Skip categories for pmfScoreBreakdown as they're shown in the chart
                if (section === 'pmfScoreBreakdown' && key === 'categories') return null;

                return (
                  <div key={key} className="p-5 border border-gray-100 rounded-lg bg-gray-50 shadow-sm">
                    <h4 className="text-md font-medium text-gray-800 mb-3">
                      {key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                    </h4>
                    <div className="text-gray-700">
                      {renderValue(value)}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="flex-1 p-6 bg-gray-50 min-h-screen">
      <div className="max-w-5xl mx-auto">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">Product-Market Fit Analysis</h2>

        <div className="bg-white rounded-lg shadow-lg p-8 mb-8 border border-gray-100">
          <div className="flex flex-col md:flex-row md:items-start">
            <div className="flex-1">
              <h3 className="text-xl font-semibold text-gray-800 mb-4">Product-Market Fit Assessment</h3>
              <p className="text-gray-600 mb-6">
                Analyze how well your product addresses customer needs and evaluate your positioning in the market.
              </p>

              <div className="mb-8">
                <h4 className="text-md font-medium text-gray-700 mb-3">Analysis Components:</h4>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                  <div className="bg-white p-3 rounded-lg border border-gray-100 shadow-sm flex items-center">
                    <div className="w-2 h-6 bg-blue-500 rounded-full mr-3"></div>
                    <span className="text-gray-700">Value proposition clarity</span>
                  </div>
                  <div className="bg-white p-3 rounded-lg border border-gray-100 shadow-sm flex items-center">
                    <div className="w-2 h-6 bg-emerald-500 rounded-full mr-3"></div>
                    <span className="text-gray-700">Target audience alignment</span>
                  </div>
                  <div className="bg-white p-3 rounded-lg border border-gray-100 shadow-sm flex items-center">
                    <div className="w-2 h-6 bg-amber-500 rounded-full mr-3"></div>
                    <span className="text-gray-700">Messaging effectiveness</span>
                  </div>
                  <div className="bg-white p-3 rounded-lg border border-gray-100 shadow-sm flex items-center">
                    <div className="w-2 h-6 bg-red-500 rounded-full mr-3"></div>
                    <span className="text-gray-700">Pain point addressing</span>
                  </div>
                </div>
              </div>

              {error && (
                <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-4 rounded-lg mb-6 flex items-center shadow-sm" role="alert">
                  <AlertCircle size={20} className="mr-3" />
                  <span>{error}</span>
                </div>
              )}

              {!pipelineExists && (
                <div className="bg-amber-50 border border-amber-200 text-amber-700 px-4 py-4 rounded-lg mb-6 flex items-center shadow-sm" role="alert">
                  <Info size={20} className="mr-3" />
                  <span>Data pipeline setup required before running analysis.</span>
                </div>
              )}

              {loading && (
                <div className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <span className="text-sm text-gray-600 font-medium">Analyzing product-market fit</span>
                    <span className="text-sm text-gray-600 font-medium">{progress}%</span>
                  </div>
                  <div className="h-3 bg-gray-100 rounded-full overflow-hidden shadow-inner">
                    <div
                      className="h-full bg-blue-500 rounded-full transition-all duration-500"
                      style={{ width: `${progress}%` }}
                    ></div>
                  </div>
                </div>
              )}

              <button
                onClick={runPMFAnalysis}
                disabled={loading || !pipelineExists}
                className="inline-flex justify-center items-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-lg text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-gray-400 disabled:cursor-not-allowed transition-colors duration-150"
              >
                {loading ? (
                  <div className="flex items-center">
                    <Loader size={18} className="mr-2 animate-spin" />
                    Processing Analysis
                  </div>
                ) : (
                  "Run PMF Analysis"
                )}
              </button>
            </div>

            <div className="hidden md:flex md:items-center md:justify-center ml-8 p-6">
              <div className="bg-blue-50 p-8 rounded-full">
                <Target size={100} className="text-blue-400" />
              </div>
            </div>
          </div>
        </div>

        {analysis && (
          <div className="mb-8">
            <div className="bg-gradient-to-r from-blue-50 to-indigo-50 rounded-lg shadow-lg p-8 mb-6 border border-blue-100">
              <h3 className="text-2xl font-semibold text-gray-800 mb-6 text-center">PMF Analysis Summary</h3>

              <div className="flex items-center justify-center mb-8">
                <div className={`text-5xl font-bold ${getScoreColor(analysis.summary.pmfScore)} text-white rounded-full w-40 h-40 flex items-center justify-center shadow-lg border-4 border-white`}>
                  {analysis.summary.pmfScore}/10
                </div>
              </div>

              {renderKeyMetricsChart()}

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
                <div className="p-5 bg-white rounded-lg border border-gray-100 shadow-sm">
                  <span className="text-sm text-gray-500 block mb-1">Analysis Date</span>
                  <p className="text-xl font-semibold text-gray-900">{new Date(analysis.summary.timestamp).toLocaleDateString()}</p>
                </div>
                <div className="p-5 bg-white rounded-lg border border-gray-100 shadow-sm">
                  <span className="text-sm text-gray-500 block mb-1">PMF Status</span>
                  <p className={`text-xl font-semibold ${analysis.summary.pmfScore >= 7 ? 'text-emerald-600' : analysis.summary.pmfScore >= 5 ? 'text-amber-600' : 'text-red-600'}`}>
                    {analysis.summary.pmfScore >= 7 ? 'Strong Fit' : analysis.summary.pmfScore >= 5 ? 'Moderate Fit' : 'Needs Improvement'}
                  </p>
                </div>
              </div>
            </div>

            {renderAnalysisSection('pmfScoreBreakdown', analysis.pmfScoreBreakdown)}
            {renderAnalysisSection('valuePropositionAnalysis', analysis.valuePropositionAnalysis)}
            {renderAnalysisSection('targetAudienceAlignment', analysis.targetAudienceAlignment)}
            {renderAnalysisSection('messagingClarity', analysis.messagingClarity)}
            {renderAnalysisSection('painPointAddressing', analysis.painPointAddressing)}
            {renderAnalysisSection('recommendations', analysis.recommendations)}
          </div>
        )}
      </div>
    </div>
  );
};

export default PMFAnalysisPage;