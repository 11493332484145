import React, { useState } from 'react';
import { PlusCircle, Check, Download, Zap, X, Info, AlertCircle } from 'react-feather';

const IMap = () => {
  // State for nodes, connections, and UI
  const [nodes, setNodes] = useState([]);
  const [connections, setConnections] = useState([]);
  const [selectedNode, setSelectedNode] = useState(null);
  const [connectingFrom, setConnectingFrom] = useState(null);
  const [aiMode, setAiMode] = useState('idle');
  const [aiSuggestion, setAiSuggestion] = useState(null);
  const [validationResults, setValidationResults] = useState([]);
  const [contextMenu, setContextMenu] = useState(null);
  const [history, setHistory] = useState([]);
  const [future, setFuture] = useState([]);

  // Node types and templates
  const nodeTypes = {
    default: { color: '#ffffff', shape: 'rectangle' },
    decision: { color: '#f0f4ff', shape: 'diamond' },
    action: { color: '#e6f7ff', shape: 'rectangle' },
    outcome: { color: '#f0fff4', shape: 'ellipse' },
  };

  const nodeTemplates = {
    problem: { title: 'Problem', content: 'Describe the problem...', type: 'default' },
    solution: { title: 'Solution', content: 'Describe the solution...', type: 'action' },
  };

  // Save state for undo/redo
  const saveState = () => {
    setHistory([...history, { nodes, connections }]);
    setFuture([]);
  };

  // Undo functionality
  const undo = () => {
    if (history.length > 0) {
      const previousState = history[history.length - 1];
      setFuture([...future, { nodes, connections }]);
      setNodes(previousState.nodes);
      setConnections(previousState.connections);
      setHistory(history.slice(0, -1));
    }
  };

  // Redo functionality
  const redo = () => {
    if (future.length > 0) {
      const nextState = future[future.length - 1];
      setHistory([...history, { nodes, connections }]);
      setNodes(nextState.nodes);
      setConnections(nextState.connections);
      setFuture(future.slice(0, -1));
    }
  };

  // Add a new node
  const addNode = (x, y, type = 'default') => {
    const newNode = {
      id: `node-${Date.now()}`,
      title: 'New Node',
      content: '',
      type,
      x,
      y,
      status: 'draft',
    };
    setNodes([...nodes, newNode]);
    saveState();
  };

  // Update node content
  const updateNodeContent = (id, field, value) => {
    setNodes(nodes.map(node =>
      node.id === id ? { ...node, [field]: value } : node
    ));

    // Trigger AI thinking when content changes
    if (field === 'content' && value.length > 10) {
      triggerAiThinking(id, value);
    }
  };

  // Connect nodes
  const startConnection = (nodeId) => {
    setConnectingFrom(nodeId);
  };

  const completeConnection = (toNodeId) => {
    if (connectingFrom && connectingFrom !== toNodeId) {
      const connectionExists = connections.some(
        conn => conn.from === connectingFrom && conn.to === toNodeId
      );

      if (!connectionExists) {
        const newConnection = {
          id: `conn-${Date.now()}`,
          from: connectingFrom,
          to: toNodeId,
          type: 'default',
        };
        setConnections([...connections, newConnection]);
        saveState();
      }
      setConnectingFrom(null);
    }
  };

  // Delete node
  const deleteNode = (id) => {
    setNodes(nodes.filter(node => node.id !== id));
    setConnections(connections.filter(conn =>
      conn.from !== id && conn.to !== id
    ));
    if (selectedNode === id) {
      setSelectedNode(null);
    }
    saveState();
  };

  // Simulated AI functionality
  const triggerAiThinking = (nodeId, content) => {
    setAiMode('thinking');

    // Simulate AI processing time
    setTimeout(() => {
      const aiResponse = simulateAiResponse(content, nodes, connections);
      setAiSuggestion({
        nodeId,
        suggestions: aiResponse.suggestions,
        validation: aiResponse.validation,
      });
      setAiMode('suggesting');
    }, 1500);
  };

  // Simulate AI response
  const simulateAiResponse = (content, allNodes, allConnections) => {
    let suggestions = [];
    let validation = { valid: true, messages: [] };

    // Simple keyword-based suggestions
    if (content.toLowerCase().includes('problem')) {
      suggestions.push({
        type: 'node',
        title: 'Root Cause Analysis',
        content: 'Consider analyzing the root cause of the problem...',
      });
    }

    if (content.toLowerCase().includes('solution')) {
      suggestions.push({
        type: 'node',
        title: 'Implementation Plan',
        content: 'Outline the steps to implement the solution...',
      });
    }

    // Simple validation
    if (content.length < 20) {
      validation.valid = false;
      validation.messages.push('This idea needs more detail to be actionable');
    }

    return { suggestions, validation };
  };

  // Apply AI suggestion
  const applySuggestion = (suggestion) => {
    if (suggestion.type === 'node') {
      const parentNode = nodes.find(node => node.id === aiSuggestion.nodeId);
      if (parentNode) {
        const newNode = {
          id: `node-${Date.now()}`,
          title: suggestion.title,
          content: suggestion.content,
          type: 'default',
          x: parentNode.x + 250,
          y: parentNode.y + 50,
          status: 'draft',
        };
        setNodes([...nodes, newNode]);

        const newConnection = {
          id: `conn-${Date.now()}`,
          from: parentNode.id,
          to: newNode.id,
          type: 'ai-suggested',
        };
        setConnections([...connections, newConnection]);
        saveState();
      }
    }
    setAiSuggestion(null);
    setAiMode('idle');
  };

  // Validate the entire flowchart
  const validateFlowchart = () => {
    setAiMode('thinking');

    setTimeout(() => {
      const results = [];

      // Check for empty nodes
      nodes.forEach(node => {
        if (!node.content.trim()) {
          results.push({
            nodeId: node.id,
            valid: false,
            message: 'Empty node detected',
          });
        }
      });

      // Check for disconnected nodes
      if (nodes.length > 1) {
        const connectedNodes = new Set();
        connections.forEach(conn => {
          connectedNodes.add(conn.from);
          connectedNodes.add(conn.to);
        });

        nodes.forEach(node => {
          if (!connectedNodes.has(node.id)) {
            results.push({
              nodeId: node.id,
              valid: false,
              message: 'Disconnected node',
            });
          }
        });
      }

      // Check for circular references
      connections.forEach(conn => {
        if (connections.some(c => c.from === conn.to && c.to === conn.from)) {
          results.push({
            connectionId: conn.id,
            valid: false,
            message: 'Circular reference detected',
          });
        }
      });

      // If no issues found
      if (results.length === 0) {
        results.push({
          valid: true,
          message: 'Flowchart structure looks good!',
        });
      }

      setValidationResults(results);
      setAiMode('idle');

      // Update node statuses based on validation
      const updatedNodes = nodes.map(node => {
        const nodeResult = results.find(r => r.nodeId === node.id);
        if (nodeResult) {
          return { ...node, status: nodeResult.valid ? 'valid' : 'invalid' };
        }
        return { ...node, status: 'valid' };
      });

      setNodes(updatedNodes);
    }, 2000);
  };

  // Clear validation results
  const clearValidation = () => {
    setValidationResults([]);
    setNodes(nodes.map(node => ({ ...node, status: 'draft' })));
  };

  // Export flowchart data
  const exportData = () => {
    const data = { nodes, connections };
    const jsonString = JSON.stringify(data, null, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = 'flowchart.json';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  // Handle node right-click for context menu
  const handleNodeRightClick = (e, node) => {
    e.preventDefault();
    setContextMenu({ node, x: e.clientX, y: e.clientY });
  };

  // Handle context menu actions
  const handleContextMenuAction = (action, node) => {
    if (action === 'delete') deleteNode(node.id);
    else if (action === 'duplicate') addNode(node.x + 20, node.y + 20, node.type);
    setContextMenu(null);
  };

  // Render connection lines
  const renderConnection = (conn) => {
    const fromNode = nodes.find(n => n.id === conn.from);
    const toNode = nodes.find(n => n.id === conn.to);

    if (!fromNode || !toNode) return null;

    const fromX = fromNode.x + 125;
    const fromY = fromNode.y + 75;
    const toX = toNode.x + 125;
    const toY = toNode.y + 75;

    const path = `M ${fromX} ${fromY} C ${fromX + 50} ${fromY}, ${toX - 50} ${toY}, ${toX} ${toY}`;

    return (
      <svg
        key={conn.id}
        className="absolute top-0 left-0 w-full h-full pointer-events-none z-0"
      >
        <path
          d={path}
          stroke={conn.type === 'ai-suggested' ? '#555' : '#222'}
          strokeWidth="2"
          fill="none"
          strokeDasharray={conn.type === 'ai-suggested' ? '5,5' : ''}
        />
        <polygon
          points="0,-6 12,0 0,6"
          fill={conn.type === 'ai-suggested' ? '#555' : '#222'}
          transform={`translate(${toX}, ${toY}) rotate(${Math.atan2(toY - fromY, toX - fromX) * 180 / Math.PI})`}
        />
      </svg>
    );
  };

  return (
    <div className="flex h-screen w-full bg-white text-black overflow-hidden">
      {/* Left sidebar */}
      <div className="w-64 border-r border-gray-200 p-4 flex flex-col">
        <h1 className="text-xl font-medium mb-6">iMap</h1>

        <div className="space-y-6 flex-grow">
          <button
            className="flex items-center space-x-2 text-sm hover:bg-gray-100 p-2 w-full text-left"
            onClick={() => addNode(300, 200)}
          >
            <PlusCircle className="w-4 h-4" />
            <span>Add New Idea</span>
          </button>

          <button
            className="flex items-center space-x-2 text-sm hover:bg-gray-100 p-2 w-full text-left"
            onClick={validateFlowchart}
          >
            <Check className="w-4 h-4" />
            <span>Validate Flowchart</span>
          </button>

          <button
            className="flex items-center space-x-2 text-sm hover:bg-gray-100 p-2 w-full text-left"
            onClick={exportData}
          >
            <Download className="w-4 h-4" />
            <span>Save Flowchart</span>
          </button>

          <button
            className="flex items-center space-x-2 text-sm hover:bg-gray-100 p-2 w-full text-left"
            onClick={undo}
          >
            <span>Undo</span>
          </button>

          <button
            className="flex items-center space-x-2 text-sm hover:bg-gray-100 p-2 w-full text-left"
            onClick={redo}
          >
            <span>Redo</span>
          </button>
        </div>

        <div className="mt-auto pt-4 border-t border-gray-200">
          <div className="text-xs text-gray-500 mb-2">AI Assistant</div>
          <div className={`flex items-center space-x-2 text-sm p-2 ${aiMode === 'idle' ? 'opacity-50' : 'opacity-100'}`}>
            <Zap className="w-4 h-4" />
            <span>{aiMode === 'thinking' ? 'Analyzing...' : aiMode === 'suggesting' ? 'Suggestion Ready' : 'Idle'}</span>
          </div>
        </div>
      </div>

      {/* Main workspace */}
      <div
        className="flex-grow relative overflow-auto bg-gray-50"
        style={{ height: '100vh' }}
        onDrop={(e) => {
          const type = e.dataTransfer.getData('nodeType');
          const { x, y } = { x: e.clientX, y: e.clientY };
          addNode(x, y, type);
        }}
        onDragOver={(e) => e.preventDefault()}
      >
        {/* Render connections */}
        {connections.map(conn => renderConnection(conn))}

        {/* Render nodes */}
        {nodes.map(node => (
          <div
            key={node.id}
            className={`absolute w-64 bg-white border ${node.status === 'invalid' ? 'border-gray-300 border-l-4 border-l-red-500' : node.status === 'valid' ? 'border-gray-300 border-l-4 border-l-green-500' : 'border-gray-300'} p-4 rounded shadow-sm cursor-move ${selectedNode === node.id ? 'ring-1 ring-black' : ''}`}
            style={{
              left: `${node.x}px`,
              top: `${node.y}px`,
              backgroundColor: nodeTypes[node.type].color,
              borderRadius: nodeTypes[node.type].shape === 'diamond' ? '0' : '4px',
              transform: nodeTypes[node.type].shape === 'diamond' ? 'rotate(45deg)' : 'none',
            }}
            onClick={() => setSelectedNode(node.id)}
            onContextMenu={(e) => handleNodeRightClick(e, node)}
          >
            <div className="flex justify-between items-start mb-2">
              <input
                type="text"
                className="font-medium border-none p-0 bg-transparent focus:outline-none focus:ring-0 w-full"
                value={node.title}
                onChange={(e) => updateNodeContent(node.id, 'title', e.target.value)}
                onClick={(e) => e.stopPropagation()}
              />
              <div className="flex space-x-1">
                <button
                  className="p-1 hover:bg-gray-100 rounded"
                  onClick={(e) => { e.stopPropagation(); startConnection(node.id); }}
                >
                  <svg viewBox="0 0 24 24" width="14" height="14" stroke="currentColor" fill="none">
                    <polyline points="7 17 17 7" />
                    <polyline points="7 7 17 7 17 17" />
                  </svg>
                </button>
                <button
                  className="p-1 hover:bg-gray-100 rounded"
                  onClick={(e) => { e.stopPropagation(); deleteNode(node.id); }}
                >
                  <X className="w-3 h-3" />
                </button>
              </div>
            </div>
            <textarea
              className="w-full border-none bg-transparent text-sm resize-none focus:outline-none focus:ring-0 min-h-12"
              placeholder="Describe your idea..."
              value={node.content}
              onChange={(e) => updateNodeContent(node.id, 'content', e.target.value)}
              onClick={(e) => e.stopPropagation()}
              rows={3}
            />
          </div>
        ))}
      </div>

      {/* Right panel - AI suggestions and validation */}
      <div className="w-72 border-l border-gray-200 p-4 flex flex-col">
        <h2 className="text-lg font-medium mb-4">AI Insights</h2>

        {/* AI Suggestions */}
        {aiMode === 'thinking' && (
          <div className="flex items-center space-x-2 p-4 bg-gray-50 rounded mb-4">
            <div className="animate-pulse h-2 w-2 rounded-full bg-black"></div>
            <div className="animate-pulse h-2 w-2 rounded-full bg-black" style={{ animationDelay: '0.2s' }}></div>
            <div className="animate-pulse h-2 w-2 rounded-full bg-black" style={{ animationDelay: '0.4s' }}></div>
            <span className="text-sm text-gray-500">Analyzing your flowchart...</span>
          </div>
        )}

        {aiSuggestion && (
          <div className="mb-6 border-b border-gray-200 pb-4">
            <div className="flex items-center space-x-2 mb-3">
              <Zap className="w-4 h-4" />
              <h3 className="text-sm font-medium">Suggested Additions</h3>
            </div>

            {aiSuggestion.suggestions.length > 0 ? (
              aiSuggestion.suggestions.map((suggestion, idx) => (
                <div key={idx} className="mb-3 p-3 bg-gray-50 rounded text-sm">
                  <div className="font-medium mb-1">{suggestion.title}</div>
                  <p className="text-xs text-gray-700 mb-2">{suggestion.content}</p>
                  <button
                    className="text-xs py-1 px-2 border border-black hover:bg-black hover:text-white transition-colors"
                    onClick={() => applySuggestion(suggestion)}
                  >
                    Add to Flowchart
                  </button>
                </div>
              ))
            ) : (
              <div className="text-sm text-gray-500">No suggestions at this time.</div>
            )}
          </div>
        )}

        {/* Validation Results */}
        {validationResults.length > 0 && (
          <div className="mb-4">
            <div className="flex items-center justify-between mb-3">
              <div className="flex items-center space-x-2">
                <Check className="w-4 h-4" />
                <h3 className="text-sm font-medium">Validation Results</h3>
              </div>
              <button
                className="text-xs"
                onClick={clearValidation}
              >
                Clear
              </button>
            </div>

            {validationResults.map((result, idx) => (
              <div
                key={idx}
                className={`mb-2 p-2 rounded text-xs flex items-start space-x-2 ${result.valid ? 'bg-green-50' : 'bg-red-50'}`}
              >
                {result.valid ? (
                  <Check className="w-3 h-3 text-green-600 mt-0.5" />
                ) : (
                  <AlertCircle className="w-3 h-3 text-red-600 mt-0.5" />
                )}
                <span className={result.valid ? 'text-green-800' : 'text-red-800'}>
                  {result.message}
                </span>
              </div>
            ))}
          </div>
        )}

        {/* Node Details */}
        {selectedNode && (
          <div className="mt-auto pt-4 border-t border-gray-200">
            <div className="flex items-center space-x-2 mb-3">
              <Info className="w-4 h-4" />
              <h3 className="text-sm font-medium">Selected Node</h3>
            </div>

            <div className="text-xs text-gray-500">
              <div className="mb-1">
                ID: <span className="font-mono">{selectedNode}</span>
              </div>
              <div className="mb-1">
                Status: {nodes.find(n => n.id === selectedNode)?.status || 'unknown'}
              </div>
              <div className="mb-1">
                Connected to: {connections.filter(c => c.from === selectedNode || c.to === selectedNode).length} node(s)
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Context Menu */}
      {contextMenu && (
        <div
          className="fixed bg-white border rounded shadow-lg z-50"
          style={{ left: contextMenu.x, top: contextMenu.y }}
        >
          <button
            className="block w-full text-left p-2 hover:bg-gray-100"
            onClick={() => handleContextMenuAction('delete', contextMenu.node)}
          >
            Delete
          </button>
          <button
            className="block w-full text-left p-2 hover:bg-gray-100"
            onClick={() => handleContextMenuAction('duplicate', contextMenu.node)}
          >
            Duplicate
          </button>
        </div>
      )}
    </div>
  );
};

export default IMap;