import React, { useState, useReducer } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  LineChart,
  Line
} from 'recharts';
import {
  TrendingUp,
  Search,
  Target,
  Zap,
  AlertTriangle,
  FileText,
  BarChart2,
  Star,
  Globe,
  Activity,
  Layers,
  Award,
  Compass
} from 'lucide-react';

// Reducer for managing competitor tracking state
const competitorReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_COMPETITOR':
      return {
        ...state,
        competitors: [...state.competitors, action.payload]
      };
    case 'UPDATE_TRACKING':
      return {
        ...state,
        competitors: state.competitors.map(comp => 
          comp.id === action.payload.id 
            ? { ...comp, ...action.payload.updates } 
            : comp
        )
      };
    default:
      return state;
  }
};

const CompetitorIntelligenceDashboard = () => {
  // Initial state with sample competitors
  const initialState = {
    competitors: [
      {
        id: 1,
        name: 'TechRival Solutions',
        marketShare: 35,
        competitiveScore: 82,
        recentChanges: [
          'New AI feature launched',
          'Pricing adjustment in enterprise tier'
        ],
        swotAnalysis: {
          strengths: ['Advanced AI capabilities', 'Strong enterprise presence'],
          weaknesses: ['Limited customization', 'Higher pricing'],
          opportunities: ['Emerging AI markets', 'Remote work tools'],
          threats: ['Increasing competition', 'Rapidly changing tech landscape']
        }
      },
      {
        id: 2,
        name: 'InnovateX Platform',
        marketShare: 25,
        competitiveScore: 75,
        recentChanges: [
          'Expanded integration partnerships',
          'UI/UX redesign announced'
        ],
        swotAnalysis: {
          strengths: ['Flexible integration', 'User-friendly design'],
          weaknesses: ['Less robust enterprise features'],
          opportunities: ['SMB market expansion', 'Vertical-specific solutions'],
          threats: ['Funding challenges', 'Large enterprise competitors']
        }
      }
    ]
  };

  const [state, dispatch] = useReducer(competitorReducer, initialState);

  // Sample data for various charts and insights
  const competitivePositioningData = [
    { name: 'Market Share', value: 35 },
    { name: 'Customer Satisfaction', value: 82 },
    { name: 'Feature Completeness', value: 75 }
  ];

  const marketTrendsData = [
    { month: 'Jan', trend: 45 },
    { month: 'Feb', trend: 53 },
    { month: 'Mar', trend: 62 },
    { month: 'Apr', trend: 58 }
  ];

  const aiInsights = [
    {
      title: 'TechRival Pricing Strategy',
      recommendation: 'Adjust pricing to match value proposition',
      confidence: 'High'
    },
    {
      title: 'Market Opportunity',
      recommendation: 'Explore vertical-specific AI solutions',
      confidence: 'Medium'
    }
  ];

  return (
    <div className="min-h-screen bg-black text-white p-4">
      <div className="container mx-auto">
        {/* Header */}
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold text-gray-200 flex items-center">
            <Compass className="mr-2 text-blue-500" /> Echidna mail
          </h1>
          <div className="flex space-x-2">
            <button className="bg-blue-600 hover:bg-blue-700 px-3 py-1 rounded text-sm flex items-center">
              <Search className="mr-1 w-4 h-4" /> Add Competitor
            </button>
          </div>
        </div>

        {/* Main Dashboard Grid */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          {/* Competitor Overview */}
          <div className="lg:col-span-2 bg-gray-900/70 rounded-lg p-4">
            <h2 className="text-lg font-semibold mb-4 flex items-center">
              <Layers className="mr-2 text-green-500" /> Competitor Landscape
            </h2>
            <div className="grid grid-cols-2 gap-4">
              {state.competitors.map(competitor => (
                <div 
                  key={competitor.id} 
                  className="bg-gray-800 rounded p-3 hover:bg-gray-700 transition"
                >
                  <div className="flex justify-between items-center mb-2">
                    <h3 className="text-sm font-semibold">{competitor.name}</h3>
                    <span className={`
                      px-2 py-1 rounded-full text-xs
                      ${competitor.competitiveScore > 80 ? 'bg-green-500/10 text-green-400' : 'bg-yellow-500/10 text-yellow-400'}
                    `}>
                      Score: {competitor.competitiveScore}
                    </span>
                  </div>
                  <div className="text-xs text-gray-400 mb-2">
                    Market Share: {competitor.marketShare}%
                  </div>
                  <div className="text-xs text-gray-300">
                    Recent Changes:
                    <ul className="list-disc list-inside mt-1">
                      {competitor.recentChanges.map((change, idx) => (
                        <li key={idx}>{change}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* AI Recommendations */}
          <div className="space-y-4">
            <div className="bg-gray-900/70 rounded-lg p-4">
              <h2 className="text-lg font-semibold mb-4 flex items-center">
                <Zap className="mr-2 text-yellow-500" /> AI Strategic Insights
              </h2>
              <div className="space-y-2">
                {aiInsights.map((insight, index) => (
                  <div
                    key={index}
                    className="bg-gray-800 rounded p-2 flex justify-between items-center"
                  >
                    <div>
                      <div className="text-sm font-semibold">{insight.title}</div>
                      <div className="text-xs text-gray-400">{insight.recommendation}</div>
                    </div>
                    <span className={`
                      px-2 py-1 rounded-full text-xs
                      ${insight.confidence === 'High' ? 'bg-green-500/10 text-green-400' : 'bg-yellow-500/10 text-yellow-400'}
                    `}>
                      {insight.confidence}
                    </span>
                  </div>
                ))}
              </div>
            </div>

            {/* Competitive Positioning */}
            <div className="bg-gray-900/70 rounded-lg p-4">
              <h2 className="text-lg font-semibold mb-4 flex items-center">
                <Award className="mr-2 text-purple-500" /> Competitive Positioning
              </h2>
              <ResponsiveContainer width="100%" height={200}>
                <BarChart data={competitivePositioningData}>
                  <XAxis dataKey="name" stroke="#6b7280" />
                  <YAxis stroke="#6b7280" />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: '#1f2937',
                      border: 'none',
                      color: 'white'
                    }}
                  />
                  <Bar dataKey="value" fill="#3b82f6" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>

        {/* Market Trends and SWOT Analysis */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
          {/* Market Trends */}
          <div className="bg-gray-900/70 rounded-lg p-4">
            <h2 className="text-lg font-semibold mb-4 flex items-center">
              <Activity className="mr-2 text-teal-500" /> Market Trends
            </h2>
            <ResponsiveContainer width="100%" height={200}>
              <LineChart data={marketTrendsData}>
                <XAxis dataKey="month" stroke="#6b7280" />
                <YAxis stroke="#6b7280" />
                <Tooltip
                  contentStyle={{
                    backgroundColor: '#1f2937',
                    border: 'none',
                    color: 'white'
                  }}
                />
                <Line type="monotone" dataKey="trend" stroke="#10b981" />
              </LineChart>
            </ResponsiveContainer>
          </div>

          {/* SWOT Analysis */}
          <div className="bg-gray-900/70 rounded-lg p-4">
            <h2 className="text-lg font-semibold mb-4 flex items-center">
              <Target className="mr-2 text-red-500" /> SWOT Analysis
            </h2>
            <div className="grid grid-cols-2 gap-2">
              {state.competitors.map(competitor => (
                <div 
                  key={competitor.id} 
                  className="bg-gray-800 p-3 rounded"
                >
                  <h3 className="text-sm font-semibold mb-2">{competitor.name}</h3>
                  {Object.entries(competitor.swotAnalysis).map(([category, items]) => (
                    <div key={category} className="mb-2">
                      <div className={`
                        text-xs font-medium mb-1
                        ${
                          category === 'strengths' ? 'text-green-400' :
                          category === 'weaknesses' ? 'text-red-400' :
                          category === 'opportunities' ? 'text-blue-400' :
                          'text-yellow-400'
                        }
                      `}>
                        {category.toUpperCase()}
                      </div>
                      <ul className="text-xs text-gray-300 list-disc list-inside">
                        {items.map((item, idx) => (
                          <li key={idx}>{item}</li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompetitorIntelligenceDashboard;