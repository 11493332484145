import React, { useState, useEffect } from 'react';
import {
    Users,
    Plus,
    Building2,
    UserPlus,
    Mail,
    Globe,
    Edit2,
    Trash2,
    Info,
    CheckCircle,
    Cloud,
    XCircle,
    Search,
    Bell,
    ChevronDown
} from 'lucide-react';
import GamefiedAIAssistant from '../redstone/intercom';
import logo from './logo.png';
import axios from 'axios';
import { useSelector } from 'react-redux';
// Assuming you have a useUser hook defined elsewhere that correctly fetches user info
import { useUser } from '@clerk/clerk-react';


// Re-use EnterpriseHeader for consistent navbar
const EnterpriseHeader = () => (
    <div className="bg-gray-900 sticky top-0 z-10">
        <div className="max-w-7xl mx-auto">
            <div className="flex h-16 items-center justify-between px-4">
                <div className="flex items-center space-x-8">
                    <div className="flex items-center space-x-2">
                        <img src={logo} alt="EchidnaMail" className="h-8" />
                        <span className="text-white font-bold">EchidnaMail</span>
                        <span className="text-red-500 font-bold">v2</span>
                    </div>
                    <div className="relative">
                        <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                        <input
                            type="text"
                            placeholder="Search services, documentation, or resources"
                            className="pl-10 pr-4 py-2 w-96 bg-gray-800 border border-gray-700 rounded text-gray-200 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-white text-gray-700"
                        />
                    </div>
                </div>
                <div className="flex items-center space-x-4">
                    <NavButton>API Reference</NavButton>
                    <NavButton>Enterprise Support</NavButton>
                    <button className="p-2 text-gray-300 hover:text-white hover:bg-gray-800 rounded-full relative">
                        <Bell size={20} />
                        <span className="absolute top-1 right-1 h-2 w-2 bg-blue-500 rounded-full"></span>
                    </button>
                    <div className="flex items-center space-x-3 ml-4 px-3 py-2 bg-gray-800 rounded cursor-pointer">
                        <div className="h-8 w-8 bg-blue-600 rounded-full flex items-center justify-center text-white text-sm font-medium">
                            EC
                        </div>
                        <div>
                            <div className="text-gray-200 text-sm">Enterprise</div>
                            <div className="text-gray-400 text-xs">us-west-2</div>
                        </div>
                        <ChevronDown size={16} className="text-gray-400" />
                    </div>
                </div>
            </div>
        </div>
    </div>
);


const NavButton = ({ children }) => (
    <button className="px-3 py-2 text-gray-300 hover:text-white hover:bg-gray-800 rounded text-sm font-medium transition-colors">
        {children}
    </button>
);


const OrganizationService = {
    async fetchOrganizations(user) { // Pass user directly here
        try {
            const userId = user?.id; // Access user.id as per user's instruction
            const response = await axios.post('https://web-production-5ee8.up.railway.app/auth/userorg', {
                cid: userId // Send cid as requested
            });
            return response.data.organizations;
        } catch (error) {
            console.error('Error fetching organizations:', error.response?.data || error.message);
            throw error;
        }
    },

    async updateMember(memberId, memberData) {
        try {
            const response = await axios.put(`https://web-production-5ee8.up.railway.app/auth/updateMember/${memberId}`, memberData);
            return response.data;
        } catch (error) {
            console.error('Error updating member:', error.response?.data || error.message);
            throw error;
        }
    },

    async addMember(orgId, memberData) {
        try {
            const response = await axios.post(`https://web-production-5ee8.up.railway.app/auth/addMember/${orgId}`, memberData);
            return response.data;
        } catch (error) {
            console.error('Error adding member:', error.response?.data || error.message);
            throw error;
        }
    },
    async createOrganization(orgData) {
        try {
            const response = await axios.post('https://web-production-5ee8.up.railway.app/auth/neworg', orgData);
            return response.data;
        } catch (error) {
            console.error('Error creating organization:', error.response?.data || error.message);
            throw error;
        }
    }
};

const EditMemberModal = ({
    isOpen,
    onClose,
    member,
    onUpdateMember
}) => {
    const [isAddDomainMode, setIsAddDomainMode] = useState(false);
    const [selectedService, setSelectedService] = useState({ name: 'Gmail', host: 'smtp.gmail.com' });
    const [customHost, setCustomHost] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [verificationStatus, setVerificationStatus] = useState(false);
        const { user, isLoaded, isSignedIn } = useUser();

    const { userInfo } = useSelector((state) => state.auth); // Keep useSelector for modal - might be needed here

    const popularServices = [
        { name: 'Gmail', host: 'smtp.gmail.com' },
        { name: 'Outlook', host: 'smtp.office365.com' },
        { name: 'Yahoo', host: 'smtp.mail.yahoo.com' },
        { name: 'Custom', host: '' },
    ];

    const resetForm = () => {
        setSelectedService({ name: 'Gmail', host: 'smtp.gmail.com' });
        setCustomHost('');
        setEmail('');
        setPassword('');
        setError('');
        setIsAddDomainMode(false);
    };

    const handleClose = () => {
        resetForm();
        onClose();
    };

    const checkDomainVerification = async () => {
        try {
            const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/verifycheck', {
                cid: user?.id, // replaced userId with cid - keep useSelector userInfo for modal
            });
            setVerificationStatus(response.data.verified);
        } catch (error) {
            console.error('Error checking domain verification:', error);
            setError('Failed to check domain verification status.');
        }
    };

    const handleAddDomain = async () => {
        try {
            await axios.post('https://web-production-5ee8.up.railway.app/auth/configuredomain', {
                email,
                password,
                host: selectedService.name === 'Custom' ? customHost : selectedService.host,
                cid: user?.id, // replaced userId with cid - keep useSelector userInfo for modal
            });

            onUpdateMember({
                ...member,
                linkedEmail: email
            });

            await checkDomainVerification();
            resetForm();
            onClose();
        } catch (err) {
            setError('Failed to add domain. Please check your inputs and try again.');
        }
    };

    useEffect(() => {
        checkDomainVerification();
    }, []);

    if (!isOpen) return null;

    return (
        <div
            className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50"
            onClick={handleClose}
        >
            <div
                className="bg-white rounded-xl p-8 w-96 relative shadow-xl"
                onClick={(e) => e.stopPropagation()}
            >
                <button
                    onClick={handleClose}
                    className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 transition-colors"
                >
                    <XCircle className="w-6 h-6" />
                </button>


                <h2 className="text-xl font-semibold mb-6 flex items-center text-gray-900">
                    <Edit2 className="mr-2 text-blue-600" /> Sending Domain
                </h2>
                <div className="space-y-5">
                    <div className="bg-gray-100 p-4 rounded-md">
                        <div className="font-semibold text-gray-900">{member.name}</div>
                        <div className="text-sm text-gray-600">{member.email}</div>
                    </div>

                    <div className={`p-5 rounded-lg ${verificationStatus ? 'bg-green-50/50 border-green-200' : 'bg-yellow-50/50 border-yellow-200'} border`}>
                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                {verificationStatus ? (
                                    <CheckCircle className="w-5 h-5 text-green-600 mr-2" />
                                ) : (
                                    <Cloud className="w-5 h-5 text-yellow-600 mr-2" />
                                )}
                                <span className={verificationStatus ? 'text-green-600' : 'text-yellow-600'}>
                                    {verificationStatus ? 'Domain Verified' : 'Domain Verification Pending'}
                                </span>
                            </div>
                        </div>
                    </div>

                    {!isAddDomainMode ? (
                        <button
                            onClick={() => setIsAddDomainMode(true)}
                            className="w-full bg-blue-600 text-white px-4 py-3 rounded-md hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors"
                        >
                            Add a New Sending Domain
                        </button>
                    ) : (
                        <>
                            <select
                                value={JSON.stringify(selectedService)}
                                onChange={(e) => setSelectedService(JSON.parse(e.target.value))}
                                className="w-full p-3 rounded-md border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 text-gray-700 bg-white"
                            >
                                {popularServices.map((service) => (
                                    <option key={service.name} value={JSON.stringify(service)}>
                                        {service.name}
                                    </option>
                                ))}
                            </select>

                            {selectedService.name === 'Custom' && (
                                <input
                                    type="text"
                                    placeholder="Custom SMTP Host"
                                    value={customHost}
                                    onChange={(e) => setCustomHost(e.target.value)}
                                    className="w-full p-3 rounded-md border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 text-gray-700 bg-white"
                                />
                            )}

                            <input
                                type="email"
                                placeholder="Email Address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="w-full p-3 rounded-md border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 text-gray-700 bg-white"
                            />

                            <input
                                type="password"
                                placeholder="Email Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="w-full p-3 rounded-md border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 text-gray-700 bg-white"
                            />

                            {error && (
                                <p className="text-red-500 text-sm mt-2">{error}</p>
                            )}

                            <div className="flex justify-end space-x-3 mt-5">
                                <button
                                    onClick={() => setIsAddDomainMode(false)}
                                    className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-300 focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 transition-colors"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleAddDomain}
                                    className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors"
                                    disabled={!email || !password}
                                >
                                    Link Domain
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};


// Create Organization Modal Component
const CreateOrgModal = ({ isOpen, onClose, onCreateOrg }) => {
    const [orgName, setOrgName] = useState('');
    const [domain, setDomain] = useState('');
    const [website, setWebsite] = useState('');
    const [productInfo, setProductInfo] = useState('');
        const { user, isLoaded, isSignedIn } = useUser();
    const { userInfo } = useSelector((state) => state.auth); // Keep useSelector for modal - might be needed here


    const handleSubmit = async () => {
        if (orgName && domain) {
            try {
                const response = await axios.post('https://web-production-5ee8.up.railway.app/auth/neworg', {
                    name: orgName,
                    domain: domain,
                    website: website,
                    productinfo: productInfo,
                    cid: user?.id // replaced userId with cid - keep useSelector userInfo for modal
                });

                console.log('Organization created:', response.data);
                onCreateOrg(); // Call the callback to refresh organizations list
                onClose();
            } catch (error) {
                console.error('Error creating organization:', error.response?.data || error.message);
            }
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
            <div className="bg-white rounded-xl p-8 w-96 shadow-xl">
                <h2 className="text-xl font-semibold mb-6 flex items-center text-gray-900">
                    <Building2 className="mr-2 text-blue-600" /> Create Organization
                </h2>
                <div className="space-y-5">
                    <input
                        type="text"
                        placeholder="Organization Name"
                        value={orgName}
                        onChange={(e) => setOrgName(e.target.value)}
                        className="w-full p-3 rounded-md border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 text-gray-700 bg-white"
                        required
                    />
                    <input
                        type="text"
                        placeholder="Domain (e.g. company.com)"
                        value={domain}
                        onChange={(e) => setDomain(e.target.value)}
                        className="w-full p-3 rounded-md border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 text-gray-700 bg-white"
                        required
                    />
                    <input
                        type="url"
                        placeholder="Company Website"
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                        className="w-full p-3 rounded-md border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 text-gray-700 bg-white"
                    />
                    <textarea
                        placeholder="Product Information"
                        value={productInfo}
                        onChange={(e) => setProductInfo(e.target.value)}
                        className="w-full p-3 rounded-md border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 text-gray-700 bg-white h-24 resize-none"
                    />
                    <div className="flex justify-end space-x-3 mt-5">
                        <button
                            onClick={onClose}
                            className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-300 focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 transition-colors"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleSubmit}
                            className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors"
                            disabled={!orgName || !domain}
                        >
                            Create
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

// Add Member Modal Component
const AddMemberModal = ({ isOpen, onClose, orgId, onAddMember }) => {
    const { userInfo } = useSelector((state) => state.auth); // Keep useSelector for modal - might be needed here
    const [name, setName] = useState('');
        const { user, isLoaded, isSignedIn } = useUser();
    const [email, setEmail] = useState('');
    const [uoc, setUoc] = useState('');

    const handleSubmit = () => {
        if (name && email && uoc) {
            onAddMember(orgId, {
                name,
                email,
                uoc,
                domain: email.split('@')[1],
                adminuserId: userInfo._id // replaced userId with cid - keep useSelector userInfo for modal
            });
            onClose();
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
            <div className="bg-white rounded-xl p-8 w-96 shadow-xl">
                <h2 className="text-xl font-semibold mb-6 flex items-center text-gray-900">
                    <UserPlus className="mr-2 text-blue-600" /> Add Member
                </h2>
                <div className="space-y-5">
                    <input
                        type="text"
                        placeholder="Full Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="w-full p-3 rounded-md border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 text-gray-700 bg-white"
                        required
                    />
                    <input
                        type="email"
                        placeholder="Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full p-3 rounded-md border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 text-gray-700 bg-white"
                        required
                    />
                    <div className="relative">
                        <div className="relative">
                            <input
                                type="text"
                                placeholder="Unique Org Code (UOC)"
                                value={uoc}
                                onChange={(e) => setUoc(e.target.value)}
                                className="w-full p-3 rounded-md border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 text-gray-700 bg-white pr-10"
                                required
                            />
                            <div className="group absolute right-3 top-1/2 transform -translate-y-1/2">
                                <Info
                                    className="text-gray-500 hover:text-gray-700 cursor-pointer transition-colors"
                                    size={20}
                                />
                                <div className="hidden group-hover:block absolute z-10 p-3 bg-gray-700 text-white text-xs rounded-md shadow-lg -right-2 top-full mt-2 w-72">
                                    UOC (Unique Organization Code) is found on your profile page. It's essential for adding members to your organization, ensuring secure and verified access.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-end space-x-3 mt-5">
                        <button
                            onClick={onClose}
                            className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-300 focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 transition-colors"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleSubmit}
                            className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors"
                            disabled={!name || !email || !uoc}
                        >
                            Add Member
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

// Main Organizations Page
const OrganizationsPage = () => {
    const [organizations, setOrganizations] = useState([]);
    const [isCreateOrgModalOpen, setIsCreateOrgModalOpen] = useState(false);
    const [selectedOrg, setSelectedOrg] = useState(null);
    const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false); // Corrected from useStat to useState
    const [editingMember, setEditingMember] = useState(null);
    // Replace useSelector with useUser hook here to get user info
    const { user, loading, error } = useUser(); // Use the imported hook

    useEffect(() => {
        const fetchOrgs = async () => {
            if (user && user.id) { // Check if user and user.id are available before fetching
                const orgs = await OrganizationService.fetchOrganizations(user); // Pass user to fetchOrganizations
                setOrganizations(orgs);
            }
        };
        fetchOrgs();
    }, [user]); // Depend on user to refetch when user info is available

    const handleCreateOrg = async () => { // Modified to just refresh org list
        setIsCreateOrgModalOpen(false);
        const orgs = await OrganizationService.fetchOrganizations(user);
        setOrganizations(orgs);
    };

    const handleUpdateMember = (updatedMember) => {
        const updatedOrgs = organizations.map(org => ({
            ...org,
            members: org.members.map(member =>
                member.id === updatedMember.id ? { ...member, ...updatedMember } : member
            )
        }));
        setOrganizations(updatedOrgs);
    };

    const handleAddMember = async (orgId, memberData) => {
        await OrganizationService.addMember(orgId, memberData);
        setIsAddMemberModalOpen(false);
        const orgs = await OrganizationService.fetchOrganizations(user);
        setOrganizations(orgs);
    };

    if (loading) {
        return <div>Loading Organizations...</div>; // Or a more sophisticated loading indicator
    }

    if (error || !user || !user.id) {
        return <div>Error loading organizations or user data.</div>; // Handle error and null user cases
    }


    return (
        <div className="min-h-screen bg-gray-50">
            <EnterpriseHeader />

            <div className="max-w-7xl mx-auto px-6 py-10">
                <div className="flex justify-between items-center mb-8">
                    <h1 className="text-3xl font-bold text-gray-900 flex items-center">
                        <Users className="mr-3 text-gray-700" size={32} /> Organizations
                    </h1>
                    <button
                        onClick={() => setIsCreateOrgModalOpen(true)}
                        className="bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded-md text-sm font-medium text-white flex items-center space-x-2 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors"
                    >
                        <Plus className="w-4 h-4" /> <span>Create Organization</span>
                    </button>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {organizations.map(org => (
                        <div
                            key={org.id}
                            className="bg-white rounded-xl p-6 shadow-md hover:shadow-lg transition-shadow duration-200"
                        >
                            <div className="flex justify-between items-start mb-5">
                                <h2 className="text-lg font-semibold flex items-center text-gray-900">
                                    <Building2 className="mr-2 text-blue-600" /> {org.name}
                                </h2>
                                <button
                                    onClick={() => {
                                        setSelectedOrg(org);
                                        setIsAddMemberModalOpen(true);
                                    }}
                                    className="bg-green-600 hover:bg-green-700 px-3 py-2 rounded-md text-sm font-medium text-white flex items-center space-x-2 focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 transition-colors"
                                >
                                    <UserPlus className="w-4 h-4" /> <span>Add Member</span>
                                </button>
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                {org.members.map(member => (
                                    <div
                                        key={member.id}
                                        className="bg-gray-50 rounded-md p-4"
                                    >
                                        <div className="flex justify-between items-center mb-3">
                                            <div>
                                                <div className="font-semibold text-gray-900">{member.name}</div>
                                                <div className="text-sm text-gray-600">{member.email}</div>
                                                {member.linkedEmail && (
                                                    <div className="text-xs text-green-600 mt-1 flex items-center">
                                                        <CheckCircle className="w-4 h-4 mr-1" />
                                                        Linked: {member.linkedEmail}
                                                    </div>
                                                )}
                                            </div>
                                            <span className={`
                                                    px-2 py-1 rounded-full text-xs font-medium
                                                    ${member.role === 'Admin'
                                                        ? 'bg-blue-100 text-blue-600'
                                                        : 'bg-gray-200 text-gray-600'}
                                                    `}>
                                                {member.role}
                                            </span>
                                        </div>
                                        <div className="flex items-center text-xs text-gray-500 mb-2">
                                            <Globe className="w-4 h-4 mr-2" /> {member.domain}
                                        </div>
                                        <div className="flex justify-end space-x-3">
                                            <Edit2
                                                className="w-4 h-4 text-gray-500 hover:text-blue-600 cursor-pointer transition-colors"
                                                onClick={() => setEditingMember(member)}
                                            />
                                            <Trash2 className="w-4 h-4 text-red-500 hover:text-red-700 transition-colors" />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>

                <CreateOrgModal
                    isOpen={isCreateOrgModalOpen}
                    onClose={() => setIsCreateOrgModalOpen(false)}
                    onCreateOrg={handleCreateOrg}
                />

                {selectedOrg && (
                    <AddMemberModal
                        isOpen={isAddMemberModalOpen}
                        onClose={() => setIsAddMemberModalOpen(false)}
                        orgId={selectedOrg.id}
                        onAddMember={handleAddMember}
                    />
                )}
                {editingMember && (
                    <EditMemberModal
                        isOpen={!!editingMember}
                        onClose={() => setEditingMember(null)}
                        member={editingMember}
                        onUpdateMember={handleUpdateMember}
                    />
                )}
            </div>
            <GamefiedAIAssistant />
        </div>
    );
};


export default OrganizationsPage;