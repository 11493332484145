import React, { useState, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import { ArrowRight, Download, BarChart, MessageSquare, Users, Clock, RefreshCw } from 'lucide-react';

const AgentAna = () => {
  const { user } = useUser();
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [analysis, setAnalysis] = useState(null);
  const [analysisLoading, setAnalysisLoading] = useState(false);

  useEffect(() => {
    if (user?.id) {
      fetchAgents();
    }
  }, [user]);

  const fetchAgents = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://web-production-5ee8.up.railway.app/echidna/myagents', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ cid: user?.id })
      });

      const data = await response.json();

      if (data.success) {
        setAgents(data.agents);
        if (data.agents.length > 0) {
          setSelectedAgent(data.agents[0]);
        }
      } else {
        setError(data.error || 'Failed to fetch agents');
      }
    } catch (err) {
      setError('Network error: Unable to fetch agents');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchAnalysis = async () => {
    if (!selectedAgent) return;

    setAnalysisLoading(true);
    try {
      const response = await fetch('https://web-production-5ee8.up.railway.app/echidna/analys', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          agentId: selectedAgent._id,
          cid: user?.id
        })
      });

      const data = await response.json();

      if (data.success) {
        setAnalysis(data.data); // Update to access data.data
      } else {
        setError(data.error || 'Failed to fetch analysis');
      }
    } catch (err) {
      setError('Network error: Unable to fetch analysis');
      console.error(err);
    } finally {
      setAnalysisLoading(false);
    }
  };

  const downloadPDF = () => {
    if (!analysis) return;

    const text = `
      Agent Analytics Report
      Agent: ${selectedAgent.agentName}
      Generated: ${new Date().toLocaleString()}

      Message Count: ${analysis.statistics.messageCount}
      Unique Users: ${analysis.statistics.uniqueUserCount}

      Analysis:
      ${analysis.analysis}
    `;

    const blob = new Blob([text], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${selectedAgent.agentName}-analysis.txt`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen w-full bg-black text-white">
        <div className="space-y-4 text-center">
          <RefreshCw className="animate-spin h-8 w-8 mx-auto text-white" />
          <p className="text-sm font-light tracking-wide">Loading agent data...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen w-full bg-black text-white">
        <div className="max-w-md px-6 py-8 border border-gray-700">
          <h2 className="text-xl font-normal mb-4">Error</h2>
          <p className="text-sm font-light">{error}</p>
          <button
            onClick={fetchAgents}
            className="mt-6 text-sm font-light border-b border-white pb-1 hover:opacity-70 transition-opacity"
          >
            Try again
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black text-white p-6 md:p-12">
      <header className="mb-16">
        <h1 className="text-2xl font-light tracking-tight">AgentAna</h1>
        <p className="text-sm font-light mt-1 tracking-wide">Digital minimalist agent analytics</p>
      </header>

      {agents.length === 0 ? (
        <div className="flex flex-col items-center justify-center py-20">
          <p className="text-lg font-light">No agents found</p>
          <p className="text-sm font-light mt-2 opacity-70">Create an agent to see analytics</p>
        </div>
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-12">
          <div className="lg:col-span-1">
            <h2 className="text-sm uppercase tracking-wider mb-6 opacity-70">Your Agents</h2>
            <div className="space-y-3">
              {agents.map(agent => (
                <div
                  key={agent._id}
                  className={`p-4 border transition-all duration-300 cursor-pointer ${
                    selectedAgent?._id === agent._id
                      ? 'border-white bg-white text-black'
                      : 'border-gray-700 hover:border-gray-500'
                  }`}
                  onClick={() => {
                    setSelectedAgent(agent);
                    setAnalysis(null);
                  }}
                >
                  <div className="flex justify-between items-center">
                    <div>
                      <h3 className="font-medium">{agent.agentName}</h3>
                      <p className="text-xs mt-1 opacity-70">
                        {new Date(agent.createdAt).toLocaleDateString()}
                      </p>
                    </div>
                    <ArrowRight className="h-4 w-4 opacity-50" />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="lg:col-span-2">
            {selectedAgent && (
              <div>
                <div className="flex justify-between items-start mb-12">
                  <div>
                    <h2 className="text-xl font-light">{selectedAgent.agentName}</h2>
                    <p className="text-sm font-light mt-1 opacity-70">
                      Created {new Date(selectedAgent.createdAt).toLocaleDateString()}
                    </p>
                  </div>

                  {!analysis && (
                    <button
                      onClick={fetchAnalysis}
                      disabled={analysisLoading}
                      className={`px-4 py-2 border border-white text-sm hover:bg-white hover:text-black transition-all ${
                        analysisLoading ? 'opacity-50 cursor-not-allowed' : ''
                      }`}
                    >
                      {analysisLoading ? (
                        <span className="flex items-center">
                          <RefreshCw className="animate-spin h-4 w-4 mr-2" />
                          Analyzing
                        </span>
                      ) : (
                        <span className="flex items-center">
                          <BarChart className="h-4 w-4 mr-2" />
                          Generate Analysis
                        </span>
                      )}
                    </button>
                  )}
                </div>

                {analysis ? (
                  <div className="space-y-8">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                      <div className="border border-gray-700 p-6 hover:border-gray-500 transition-all">
                        <MessageSquare className="h-5 w-5 mb-4 opacity-50" />
                        <p className="text-2xl font-light">{analysis.statistics.messageCount}</p>
                        <p className="text-xs uppercase tracking-wider mt-1 opacity-70">Messages</p>
                      </div>

                      <div className="border border-gray-700 p-6 hover:border-gray-500 transition-all">
                        <Users className="h-5 w-5 mb-4 opacity-50" />
                        <p className="text-2xl font-light">{analysis.statistics.uniqueUserCount}</p>
                        <p className="text-xs uppercase tracking-wider mt-1 opacity-70">Users</p>
                      </div>

                      <div className="border border-gray-700 p-6 hover:border-gray-500 transition-all">
                        <Clock className="h-5 w-5 mb-4 opacity-50" />
                        <p className="text-2xl font-light">
                          {new Date(selectedAgent.updatedAt).toLocaleDateString()}
                        </p>
                        <p className="text-xs uppercase tracking-wider mt-1 opacity-70">Last Updated</p>
                      </div>
                    </div>

                    <div className="border border-gray-700 p-8 hover:border-gray-500 transition-all">
                      <div className="flex justify-between items-center mb-6">
                        <h3 className="text-lg font-light">Analysis</h3>
                        <button
                          onClick={downloadPDF}
                          className="flex items-center text-sm opacity-70 hover:opacity-100 transition-opacity"
                        >
                          <Download className="h-4 w-4 mr-2" />
                          Download
                        </button>
                      </div>
                      <div className="prose prose-sm max-w-none font-light leading-relaxed">
                        <p className="whitespace-pre-line">{analysis.analysis}</p>
                      </div>
                    </div>

                    <button
                      onClick={() => {
                        setAnalysis(null);
                        fetchAnalysis();
                      }}
                      className="text-sm border-b border-white pb-1 hover:opacity-70 transition-opacity"
                    >
                      Regenerate Analysis
                    </button>
                  </div>
                ) : (
                  <div className="border border-gray-700 p-8 text-center">
                    <BarChart className="h-8 w-8 mx-auto mb-4 opacity-30" />
                    <h3 className="text-lg font-light mb-2">No Analysis Available</h3>
                    <p className="text-sm font-light opacity-70 max-w-md mx-auto mb-6">
                      Generate an analysis to see insights about user interactions with this agent.
                    </p>

                    <button
                      onClick={fetchAnalysis}
                      disabled={analysisLoading}
                      className={`px-4 py-2 border border-white text-sm hover:bg-white hover:text-black transition-all ${
                        analysisLoading ? 'opacity-50 cursor-not-allowed' : ''
                      }`}
                    >
                      {analysisLoading ? 'Analyzing...' : 'Generate Analysis'}
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AgentAna;