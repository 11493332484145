import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const EFoundry = () => {
  const navigate = useNavigate();
  const [hoveredDisc, setHoveredDisc] = useState(null);

  const handleMouseEnter = (discId) => {
    setHoveredDisc(discId);
  };

  const handleMouseLeave = () => {
    setHoveredDisc(null);
  };

  const handleDiscClick = (path) => {
    navigate(path);
  };

  const navigationDiscs = [
    { id: 'newAgent', label: 'New AI Agent', icon: '＋', path: '/echidna/copilot' },
    { id: 'viewAgents', label: 'View Agents', icon: '◎', path: '/echidna/efoundry/myagents' },
    { id: 'viewUsage', label: 'View Usage & Pricing', icon: '◈', path: '/echidna/foundry/stats' },
    { id: 'Analysis', label: 'Analysis', icon: '↗', path: '/echidna/foundry/agentanalytics' },
    { id: 'viewCredits', label: 'View Credits', icon: '◐', path: '#' }
  ];

  return (
    <div className="min-h-screen bg-black text-white flex flex-col">
      {/* Header */}
      <header className="py-8 px-12 border-b border-gray-800">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <img 
              src="https://cybverlake-test.s3.ap-south-1.amazonaws.com/ferer-removebg-preview.png" 
              alt="Echidna AI Foundry Logo" 
              className="w-10 h-10 object-contain"
            />
            <div>
              <h1 className="text-2xl font-light tracking-wider">ECHIDNA</h1>
              <p className="text-xs text-gray-400 mt-1 tracking-widest">AI FOUNDRY</p>
            </div>
          </div>
          <div>
            <a href="#" className="text-sm tracking-wide hover:text-gray-300 transition-colors duration-300">Documentation</a>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className="flex-grow px-12 py-16">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-3xl font-light mb-12">Dashboard</h2>
          
          {/* Navigation Discs */}
          <div className="grid grid-cols-5 gap-8 justify-items-center">
            {navigationDiscs.map((disc) => (
              <div
                key={disc.id}
                className="flex flex-col items-center"
              >
                <div
                  className={`w-24 h-24 rounded-full flex items-center justify-center cursor-pointer transition-all duration-300 ${
                    hoveredDisc === disc.id 
                      ? 'bg-white text-black transform scale-110' 
                      : 'bg-black border border-gray-800 hover:border-gray-600'
                  }`}
                  onMouseEnter={() => handleMouseEnter(disc.id)}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => handleDiscClick(disc.path)}
                >
                  <span className="text-2xl">{disc.icon}</span>
                </div>
                <p className={`mt-4 text-xs tracking-wider text-center transition-opacity duration-300 ${
                  hoveredDisc === disc.id ? 'opacity-100' : 'opacity-60'
                }`}>
                  {disc.label.toUpperCase()}
                </p>
              </div>
            ))}
          </div>
        </div>
      </main>

      {/* Footer */}
      <footer className="py-6 px-12 border-t border-gray-800">
        <div className="flex justify-between items-center">
          <p className="text-xs text-gray-400">© 2025 Echidna AI Foundry</p>
          <div className="flex space-x-8">
            <a href="#" className="text-xs text-gray-400 hover:text-white transition-colors duration-300">Support</a>
            <a href="#" className="text-xs text-gray-400 hover:text-white transition-colors duration-300">Privacy</a>
            <a href="#" className="text-xs text-gray-400 hover:text-white transition-colors duration-300">Terms</a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default EFoundry;