import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { ArrowRight, ChevronRight, Play, ChevronDown,Download, Menu, X, Check, BarChart3, Lock, Zap , Shield , Server , Clock , Terminal , Brain , Cpu , Globe} from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

// Navigation Component
const Navigation = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const products = [
    { name: "Cyberlake", description: "Enterprise analytics" },
    { name: "Neptaverse", description: "Extended reality" },
    { name: "Echidna Suite", description: "Sales automation" },
    { name: "CodegenV0", description: "AI Development" },
  ];

  return (
    <nav
      className={`fixed w-full z-50 transition-all duration-300 ${
        isScrolled ? "bg-black/80 backdrop-blur-md py-4" : "bg-transparent py-6"
      }`}
    >
      <div className="container mx-auto px-6">
        <div className="flex items-center justify-between">
          <img
            src="https://cybverlake-test.s3.ap-south-1.amazonaws.com/MBEES+2+transparent.png"
            alt="Metabees Logo"
            className="h-8 w-auto brightness-200"
          />
          <div className="hidden md:flex items-center space-x-8">
            {products.map((product, index) => (
              <a
                key={index}
                href={`${product.name.toLowerCase()}`}
                className="text-gray-300 hover:text-white transition-colors duration-300 text-sm font-medium"
              >
                {product.name}
              </a>
            ))}
            <a
              href="/Echidna-LLM"
              className="text-gray-300 hover:text-white transition-colors duration-300 text-sm font-medium"
            >
              Echidna LLM
            </a>
            <a
              href="/login"
              className="bg-white/10 backdrop-blur-sm border border-white/20 text-white px-6 py-2 rounded-lg hover:bg-white/20 transition-all duration-300"
            >
              Get Access
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};
// Hero Section Component
const Hero = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstallable, setIsInstallable] = useState(false);

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsInstallable(true);
    });

    window.addEventListener('appinstalled', () => {
      setIsInstallable(false);
      setDeferredPrompt(null);
    });
  }, []);

  const handleInstall = async () => {
    if (!deferredPrompt) return;
    
    deferredPrompt.prompt();
    const { outcome } = await deferredPrompt.userChoice;
    
    if (outcome === 'accepted') {
      setIsInstallable(false);
    }
    setDeferredPrompt(null);
  };

  return (
    <div className="relative h-screen">
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-r from-black/90 via-black/80 to-black/70 z-10" />
        <video
          autoPlay
          muted
          loop
          className="w-full h-full object-cover"
          poster="/api/placeholder/1920/1080"
        >
          <source src="https://cybverlake-test.s3.ap-south-1.amazonaws.com/853870-hd_1920_1080_25fps.mp4" type="video/mp4" />
        </video>
      </div>
      <div className="relative z-20 h-full flex items-center">
        <div className="container mx-auto px-4 sm:px-6">
          <div className="max-w-4xl">
            <div className="inline-block mb-6">
              <span className="px-3 py-1 rounded-full text-xs font-medium bg-blue-500/10 text-blue-400 border border-blue-500/20">
                Introducing Echidna LLM
              </span>
            </div>
            <h1 className="text-4xl sm:text-5xl md:text-7xl font-bold mb-6 leading-tight">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-white to-blue-400">
                Enterprise AI for the Next Generation
              </span>
            </h1>
            <p className="text-lg sm:text-xl md:text-2xl mb-8 text-gray-400 max-w-2xl">
              Pioneering the future with our proprietary LLM technology and comprehensive enterprise AI solutions.
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 max-w-3xl">
              <a href='/contact' className="w-full">
                <button className="w-full group bg-gradient-to-r from-blue-600 to-blue-500 px-6 sm:px-8 py-3 sm:py-4 rounded-lg hover:from-blue-500 hover:to-blue-400 transition-all duration-300 flex items-center justify-center text-sm sm:text-base">
                  Get Early Access
                  <ChevronRight className="ml-2 w-4 h-4 sm:w-5 sm:h-5 group-hover:translate-x-1 transition-transform" />
                </button>
              </a>
              <a href='/login' className="w-full">
                <button className="w-full group bg-transparent border border-blue-500 px-6 sm:px-8 py-3 sm:py-4 rounded-lg hover:bg-blue-500/10 transition-all duration-300 flex items-center justify-center text-blue-400 hover:text-blue-300 text-sm sm:text-base">
                  Try - Echidna mail 
                  <ChevronRight className="ml-2 w-4 h-4 sm:w-5 sm:h-5 group-hover:translate-x-1 transition-transform" />
                </button>
              </a>
              {isInstallable && (
                <button
                  onClick={handleInstall}
                  className="w-full group bg-transparent border border-green-500 px-6 sm:px-8 py-3 sm:py-4 rounded-lg hover:bg-green-500/10 transition-all duration-300 flex items-center justify-center text-green-400 hover:text-green-300 text-sm sm:text-base"
                >
                  Install metabees app
                  <Download className="ml-2 w-4 h-4 sm:w-5 sm:h-5 group-hover:translate-y-1 transition-transform" />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};



const SystemStatus = () => {
  const systems = [
    { name: "API Endpoints", status: "Operational", uptime: "99.99%" },
    { name: "Model Training", status: "Operational", uptime: "99.95%" },
    { name: "Dashboard", status: "Operational", uptime: "100%" },
    { name: "Data Processing", status: "Operational", uptime: "99.98%" }
  ];

  return (
    <section className="py-20 bg-black border-t border-gray-800">
      <div className="container mx-auto px-6">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">System Status</h2>
          <p className="text-gray-400">Real-time performance monitoring</p>
        </div>

        <div className="max-w-3xl mx-auto grid grid-cols-1 gap-4">
          {systems.map((system, index) => (
            <div 
              key={index}
              className="flex items-center justify-between bg-gray-900/50 rounded-lg p-6 border border-gray-800"
            >
              <div className="flex items-center space-x-4">
                <div className="h-3 w-3 rounded-full bg-green-500" />
                <span className="font-medium">{system.name}</span>
              </div>
              <div className="flex items-center space-x-6">
                <span className="text-gray-400">{system.status}</span>
                <span className="text-gray-400">{system.uptime}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};


// LLM Performance Chart Component
const LLMPerformanceChart = () => {
  const performanceData = [
    { name: 'General Knowledge', echidna: 92, gpt35: 89 },
    { name: 'Code Generation', echidna: 95, gpt35: 88 },
    { name: 'Data Analysis', echidna: 94, gpt35: 86 },
    { name: 'Enterprise Tasks', echidna: 96, gpt35: 85 },
    { name: 'Security', echidna: 93, gpt35: 84 }
  ];

  return (
    <div className="h-80">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={performanceData}>
          <CartesianGrid strokeDasharray="3 3" stroke="#333" />
          <XAxis dataKey="name" stroke="#666" />
          <YAxis stroke="#666" domain={[80, 100]} />
          <Tooltip 
            contentStyle={{ backgroundColor: '#1a1a1a', border: '1px solid #333' }}
            itemStyle={{ color: '#fff' }}
          />
          <Line 
            type="monotone" 
            dataKey="echidna" 
            stroke="#3b82f6" 
            strokeWidth={2}
            name="Echidna LLM"
          />
          <Line 
            type="monotone" 
            dataKey="gpt35" 
            stroke="#6b7280" 
            strokeWidth={2}
            name="GPT-3.5"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

const SecurityFeatures = () => {
  const features = [
    {
      icon: <Shield className="w-6 h-6 text-green-400" />,
      title: "Enterprise-Grade Security",
      description: "SOC 2 Type II certified with end-to-end encryption and advanced threat protection"
    },
    {
      icon: <Server className="w-6 h-6 text-blue-400" />,
      title: "Private Cloud Deployment",
      description: "Deploy in your own cloud environment with complete data isolation"
    },
    {
      icon: <Terminal className="w-6 h-6 text-purple-400" />,
      title: "API Security",
      description: "Secure API endpoints with role-based access control and audit logging"
    },
    {
      icon: <Clock className="w-6 h-6 text-red-400" />,
      title: "Real-time Monitoring",
      description: "24/7 security monitoring with automated threat detection and response"
    }
  ];

  return (
    <section className="py-20 bg-gradient-to-b from-gray-900 to-black">
      <div className="container mx-auto px-6">
        <div className="text-center mb-16">
          <span className="px-4 py-1 rounded-full text-xs font-medium bg-green-500/10 text-green-400 border border-green-500/20">
            Enterprise Security
          </span>
          <h2 className="text-3xl md:text-5xl font-bold mt-6 mb-4">
            Security First Architecture
          </h2>
          <p className="text-gray-400 max-w-2xl mx-auto text-lg">
            Built with enterprise-grade security at its core
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {features.map((feature, index) => (
            <div 
              key={index}
              className="bg-gradient-to-br from-gray-800/50 to-gray-900/50 rounded-xl p-8 border border-gray-800/50 hover:border-gray-700/50 transition-all duration-300"
            >
              <div className="flex items-center space-x-4 mb-4">
                {feature.icon}
                <h3 className="text-xl font-semibold">{feature.title}</h3>
              </div>
              <p className="text-gray-400">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

// API Documentation Preview
const APIDocPreview = () => {
  const [activeTab, setActiveTab] = useState('python');
  
  const codeExamples = {
    python: `import echidna

client = echidna.Client(api_key="your-api-key")

response = client.complete(
    prompt="Analyze this sales data",
    model="echidna-enterprise-v1",
    max_tokens=100
)

print(response.text)`,
    javascript: `import { EchidnaAPI } from '@echidna/sdk';

const client = new EchidnaAPI({
  apiKey: 'your-api-key'
});

const response = await client.complete({
  prompt: 'Analyze this sales data',
  model: 'echidna-enterprise-v1',
  maxTokens: 100
});

console.log(response.text);`
  };

  return (
    <section className="py-20 bg-black">
      <div className="container mx-auto px-6">
        <div className="max-w-4xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">
              Simple Integration
            </h2>
            <p className="text-gray-400">
              Get started with just a few lines of code
            </p>
          </div>

          <div className="bg-gray-900 rounded-xl overflow-hidden border border-gray-800">
            <div className="flex border-b border-gray-800">
              <button
                className={`px-6 py-3 text-sm font-medium ${
                  activeTab === 'python' 
                    ? 'bg-blue-500/10 text-blue-400 border-b-2 border-blue-500' 
                    : 'text-gray-400 hover:text-white'
                }`}
                onClick={() => setActiveTab('python')}
              >
                Python
              </button>
              <button
                className={`px-6 py-3 text-sm font-medium ${
                  activeTab === 'javascript' 
                    ? 'bg-blue-500/10 text-blue-400 border-b-2 border-blue-500' 
                    : 'text-gray-400 hover:text-white'
                }`}
                onClick={() => setActiveTab('javascript')}
              >
                JavaScript
              </button>
            </div>
            <div className="p-6">
              <pre className="text-gray-300 text-sm">
                <code>{codeExamples[activeTab]}</code>
              </pre>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};


// Products Grid Component
const ProductGrid = () => {
  const solutions = [
    {
      name: "Cyberlake",
      description: "Enterprise-grade analytics platform powered by advanced AI infrastructure",
      features: ["Real-time data processing", "Predictive analytics", "Custom dashboards", "Secure infrastructure"],
      icon: <BarChart3 className="w-6 h-6 text-blue-400" />
    },
    {
      name: "Neptaverse",
      description: "Revolutionary XR engine combining artificial intelligence with immersive experiences",
      features: ["Neural rendering", "AI environments", "Enterprise XR", "Real-time collaboration"],
      icon: <Zap className="w-6 h-6 text-purple-400" />
    },
    {
      name: "Echidna Suite",
      description: "Comprehensive sales automation powered by our proprietary LLM technology",
      features: ["AI-driven outreach", "Smart lead scoring", "Automated follow-ups", "Performance analytics"],
      icon: <BarChart3 className="w-6 h-6 text-green-400" />
    },
    {
      name: "CodegenV0",
      description: "Next-generation AI development platform for enterprise applications",
      features: ["Automated code generation", "Code review", "Security analysis", "Documentation"],
      icon: <Lock className="w-6 h-6 text-red-400" />
    }
  ];

  return (
    <section className="py-20 bg-gradient-to-b from-black via-gray-900 to-black">
      <div className="container mx-auto px-6">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-5xl font-bold mb-6">Enterprise AI Suite</h2>
          <p className="text-gray-400 max-w-2xl mx-auto text-lg">
            Comprehensive AI solutions designed for the modern enterprise
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {solutions.map((solution, index) => (
            <div 
              key={index}
              className="group relative overflow-hidden rounded-xl bg-gradient-to-br from-gray-800/50 to-gray-900/50 p-8 hover:from-gray-700/50 hover:to-gray-800/50 transition-all duration-300 border border-gray-800/50"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-blue-500/5 to-purple-500/5 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              <div className="relative z-10">
                <div className="flex items-center space-x-4 mb-4">
                  {solution.icon}
                  <h3 className="text-2xl font-semibold">{solution.name}</h3>
                </div>
                <p className="text-gray-400 mb-6">{solution.description}</p>
                <ul className="space-y-3 mb-6">
                  {solution.features.map((feature, idx) => (
                    <li key={idx} className="text-gray-500 flex items-center">
                      <Check className="w-4 h-4 mr-2 text-blue-400" />
                      {feature}
                    </li>
                  ))}
                </ul>
                <a href={`${solution.name.toLowerCase()}`} 
                   className="inline-flex items-center text-blue-400 hover:text-blue-300 transition-colors duration-300">
                  Learn More
                  <ArrowRight className="ml-2 h-5 w-5" />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

// LLM Section Component
const LLMSection = () => {
  return (
    <section id="llm" className="py-20 bg-gradient-to-b from-black to-gray-900">
      <div className="container mx-auto px-6">
        <div className="text-center mb-16">
          <span className="px-4 py-1 rounded-full text-xs font-medium bg-blue-500/10 text-blue-400 border border-blue-500/20">
            Revolutionary AI Technology
          </span>
          <h2 className="text-3xl md:text-5xl font-bold mt-6 mb-4">Echidna LLM</h2>
          <p className="text-gray-400 max-w-2xl mx-auto text-lg">
            Our proprietary large language model, optimized for enterprise applications
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center mb-16">
          <div>
            <h3 className="text-2xl font-bold mb-6">Performance Metrics</h3>
            <LLMPerformanceChart />
          </div>
          
          <div className="space-y-6">
            <div className="bg-gray-800/50 rounded-lg p-6 border border-gray-700">
              <h4 className="text-xl font-semibold mb-4">Superior Performance</h4>
              <p className="text-gray-400">
                Consistently outperforms GPT-3.5 across key enterprise metrics while costing 25% less.
              </p>
            </div>
            
            <div className="bg-gray-800/50 rounded-lg p-6 border border-gray-700">
              <h4 className="text-xl font-semibold mb-4">Enterprise Focus</h4>
              <p className="text-gray-400">
                Specifically trained on enterprise use cases, ensuring better understanding of business context.
              </p>
            </div>
            
            <div className="bg-gray-800/50 rounded-lg p-6 border border-gray-700">
              <h4 className="text-xl font-semibold mb-4">Competitive Pricing</h4>
              <p className="text-gray-400">
                Starting at $0.0015 per token - 25% less than GPT-3.5 with superior enterprise capabilities.
              </p>
            </div>
          </div>
        </div>

        <div className="mt-12 text-center">
          <a href='/contact'><button className="group bg-gradient-to-r from-blue-600 to-blue-500 px-8 py-4 rounded-lg hover:from-blue-500 hover:to-blue-400 transition-all duration-300 inline-flex items-center">
            Request API Access
            <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform" />
          </button></a>
        </div>
      </div>
    </section>
  );
};

const UseCases = () => {
  const cases = [
    {
      icon: <Brain className="w-6 h-6 text-purple-400" />,
      title: "Natural Language Processing",
      description: "Advanced text analysis and generation for enterprise documents",
      examples: ["Contract Analysis", "Report Generation", "Email Automation"]
    },
    {
      icon: <Cpu className="w-6 h-6 text-blue-400" />,
      title: "Predictive Analytics",
      description: "Data-driven forecasting for business intelligence",
      examples: ["Sales Forecasting", "Risk Assessment", "Market Analysis"]
    },
    {
      icon: <Globe className="w-6 h-6 text-green-400" />,
      title: "Multilingual Support",
      description: "Global communication and translation capabilities",
      examples: ["Real-time Translation", "Content Localization", "Global Support"]
    }
  ];

  return (
    <section className="py-20 bg-gradient-to-b from-black to-gray-900">
      <div className="container mx-auto px-6">
        <div className="text-center mb-16">
          <span className="px-4 py-1 rounded-full text-xs font-medium bg-purple-500/10 text-purple-400 border border-purple-500/20">
            Enterprise Solutions
          </span>
          <h2 className="text-3xl md:text-5xl font-bold mt-6 mb-4">
            Transformative Use Cases
          </h2>
          <p className="text-gray-400 max-w-2xl mx-auto text-lg">
            Discover how our AI solutions drive business value
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {cases.map((useCase, index) => (
            <div 
              key={index}
              className="group bg-gradient-to-br from-gray-800/50 to-gray-900/50 rounded-xl p-8 border border-gray-800/50 hover:border-gray-700/50 transition-all duration-300"
            >
              <div className="mb-6">{useCase.icon}</div>
              <h3 className="text-xl font-semibold mb-4">{useCase.title}</h3>
              <p className="text-gray-400 mb-6">{useCase.description}</p>
              <ul className="space-y-2">
                {useCase.examples.map((example, idx) => (
                  <li key={idx} className="text-gray-500 flex items-center">
                    <Check className="w-4 h-4 mr-2 text-purple-400" />
                    {example}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const CompareFeatures = () => {
  const features = [
    {
      category: "Core Features",
      items: [
        { name: "API Access", echidna: true, others: true },
        { name: "Custom Models", echidna: true, others: false },
        { name: "Enterprise Support", echidna: true, others: false },
        { name: "Real-time Processing", echidna: true, others: true }
      ]
    },
    {
      category: "Security",
      items: [
        { name: "SOC 2 Type II", echidna: true, others: true },
        { name: "Private Cloud", echidna: true, others: false },
        { name: "Custom Encryption", echidna: true, others: false },
        { name: "Access Controls", echidna: true, others: true }
      ]
    },
    {
      category: "Performance",
      items: [
        { name: "Sub-second Latency", echidna: true, others: false },
        { name: "Unlimited Scalability", echidna: true, others: true },
        { name: "99.99% Uptime", echidna: true, others: false },
        { name: "Global CDN", echidna: true, others: true }
      ]
    }
  ];

  return (
    <section className="py-20 bg-gradient-to-b from-gray-900 to-black">
      <div className="container mx-auto px-6">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">Feature Comparison</h2>
          <p className="text-gray-400">See how Echidna LLM stands out from the competition</p>
        </div>

        <div className="max-w-4xl mx-auto">
          {features.map((section, index) => (
            <div key={index} className="mb-12">
              <h3 className="text-xl font-semibold mb-6 text-blue-400">{section.category}</h3>
              <div className="space-y-4">
                {section.items.map((feature, idx) => (
                  <div 
                    key={idx}
                    className="grid grid-cols-3 bg-gray-900/50 rounded-lg p-4 border border-gray-800"
                  >
                    <span className="text-gray-300">{feature.name}</span>
                    <div className="text-center">
                      {feature.echidna ? (
                        <Check className="w-5 h-5 text-green-400 mx-auto" />
                      ) : (
                        <X className="w-5 h-5 text-red-400 mx-auto" />
                      )}
                    </div>
                    <div className="text-center">
                      {feature.others ? (
                        <Check className="w-5 h-5 text-gray-400 mx-auto" />
                      ) : (
                        <X className="w-5 h-5 text-red-400 mx-auto" />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const ROICalculator = () => {
  const [employees, setEmployees] = useState(100);
  const [hoursPerWeek, setHoursPerWeek] = useState(40);
  const [avgSalary, setAvgSalary] = useState(75000);

  const calculateROI = () => {
    const hourlyRate = avgSalary / (52 * 40);
    const weeklyHoursSaved = employees * (hoursPerWeek * 0.2); // Assumes 20% time savings
    const annualSavings = weeklyHoursSaved * hourlyRate * 52;
    const implementationCost = employees * 500; // Example implementation cost
    const roi = ((annualSavings - implementationCost) / implementationCost) * 100;
    
    return {
      timeSaved: Math.round(weeklyHoursSaved),
      annualSavings: Math.round(annualSavings),
      roi: Math.round(roi)
    };
  };

  const results = calculateROI();

  return (
    <section className="py-20 bg-black border-t border-gray-800">
      <div className="container mx-auto px-6">
        <div className="text-center mb-16">
          <span className="px-4 py-1 rounded-full text-xs font-medium bg-green-500/10 text-green-400 border border-green-500/20">
            ROI Calculator
          </span>
          <h2 className="text-3xl md:text-4xl font-bold mt-6 mb-4">
            Calculate Your Potential Savings
          </h2>
          <p className="text-gray-400 max-w-2xl mx-auto text-lg">
            See how much your organization could save with our AI solutions
          </p>
        </div>

        <div className="max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-12">
          <div className="space-y-8">
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-2">
                Number of Employees
              </label>
              <input
                type="range"
                min="10"
                max="1000"
                value={employees}
                onChange={(e) => setEmployees(Number(e.target.value))}
                className="w-full h-2 bg-gray-800 rounded-lg appearance-none cursor-pointer"
              />
              <span className="block mt-2 text-blue-400 font-medium">
                {employees} employees
              </span>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-400 mb-2">
                Hours Worked Per Week
              </label>
              <input
                type="range"
                min="10"
                max="60"
                value={hoursPerWeek}
                onChange={(e) => setHoursPerWeek(Number(e.target.value))}
                className="w-full h-2 bg-gray-800 rounded-lg appearance-none cursor-pointer"
              />
              <span className="block mt-2 text-blue-400 font-medium">
                {hoursPerWeek} hours
              </span>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-400 mb-2">
                Average Annual Salary
              </label>
              <input
                type="range"
                min="30000"
                max="200000"
                step="5000"
                value={avgSalary}
                onChange={(e) => setAvgSalary(Number(e.target.value))}
                className="w-full h-2 bg-gray-800 rounded-lg appearance-none cursor-pointer"
              />
              <span className="block mt-2 text-blue-400 font-medium">
                ${avgSalary.toLocaleString()}
              </span>
            </div>
          </div>

          <div className="bg-gray-900/50 rounded-xl p-8 border border-gray-800">
            <h3 className="text-xl font-semibold mb-6">Projected Annual Impact</h3>
            <div className="space-y-6">
              <div>
                <p className="text-gray-400 mb-2">Weekly Hours Saved</p>
                <p className="text-3xl font-bold text-green-400">
                  {results.timeSaved} hours
                </p>
              </div>
              <div>
                <p className="text-gray-400 mb-2">Annual Cost Savings</p>
                <p className="text-3xl font-bold text-green-400">
                  ${results.annualSavings.toLocaleString()}
                </p>
              </div>
              <div>
                <p className="text-gray-400 mb-2">Return on Investment</p>
                <p className="text-3xl font-bold text-green-400">
                  {results.roi}%
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

// Stats Section Component
const Stats = () => {
  const stats = [
    { value: "99.99%", label: "Uptime" },
    { value: "25%", label: "Cost Savings vs GPT-3.5" },
    { value: "24/7", label: "Enterprise Support" },
    { value: "5x", label: "Faster Response Time" }
  ];

  return (
    <section className="py-20 bg-black border-y border-gray-800">
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
          {stats.map((stat, index) => (
            <div key={index} className="text-center">
              <div className="text-4xl md:text-5xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-600 mb-2">
                {stat.value}
              </div>
              <div className="text-gray-400">{stat.label}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

// Main Component
const Echidna = () => {
  return (
    <div className="min-h-screen bg-black text-white font-sans">
      <Helmet>
        <title>Metabees - Enterprise AI Innovation</title>
        <meta name="description" content="Leading the future of enterprise AI with innovative solutions" />
      </Helmet>

      <Navigation />
      <Hero />
      <ProductGrid />
      <LLMSection />
      <SecurityFeatures />
      <APIDocPreview />
      <Stats />
      <UseCases />
      <CompareFeatures />
      <ROICalculator />
      <SystemStatus />
      
      {/* Trust Section */}
      <section className="py-20 bg-gradient-to-b from-black to-gray-900">
        <div className="container mx-auto px-6">
          <div className="text-center mb-16">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">Trusted by Global Leaders</h2>
            <p className="text-gray-400">Empowered by the world's most innovative enterprises</p>
          </div>
          <div className="flex justify-center items-center gap-16">
            <img src="/api/placeholder/150/50" alt="Microsoft" className="h-12 opacity-50 hover:opacity-100 transition-opacity duration-300" />
            <img src="/api/placeholder/150/50" alt="Amazon" className="h-12 opacity-50 hover:opacity-100 transition-opacity duration-300" />
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 bg-gradient-to-r from-blue-900/20 to-blue-800/20 border-t border-blue-500/20">
        <div className="container mx-auto px-6 text-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-6">Ready to Transform Your Enterprise?</h2>
          <p className="text-xl mb-8 text-gray-400 max-w-2xl mx-auto">
            Join industry leaders who have revolutionized their operations with Metabees AI solutions.
          </p>
          <a href='/contact'> <button className="group bg-gradient-to-r from-blue-600 to-blue-500 px-8 py-4 rounded-lg hover:from-blue-500 hover:to-blue-400 transition-all duration-300 flex items-center justify-center mx-auto">
            Schedule Enterprise Demo
            <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform" />
          </button></a>
        </div>
      </section>
    </div>
  );
};

export default Echidna;