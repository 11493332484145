import React, { useState, useEffect } from 'react';
 import {
  Mail, Search, Bell, ChevronDown, HelpCircle,
  Calendar, Clock, Users, Send, Info
 } from 'lucide-react';
 import GamefiedAIAssistant from '../redstone/intercom';
 import axios from 'axios';
 import { v4 as uuidv4 } from 'uuid'; // For generating unique IDs
 import { useUser } from '@clerk/clerk-react';
 import { useSelector } from 'react-redux';
 const NavButton = ({ children }) => (
  <button className="px-4 py-2 text-gray-300 hover:text-white hover:bg-gray-800 rounded-md text-sm font-medium transition-colors"> {/* Slightly more horizontal padding, rounded-md */}
   {children}
  </button>
 );

 const FormSection = ({ title, children }) => (
  <div className="bg-white rounded-xl shadow-md p-8 border border-gray-100">
   <h3 className="text-xl font-semibold text-gray-900 mb-8 flex items-center"> {/* Increased margin-bottom */}
    {title}
   </h3>
   <div className="space-y-8"> {/* Increased spacing */}
    {children}
   </div>
  </div>
 );

 const EnterpriseHeader = () => (
  <div className="bg-gray-900 sticky top-0 z-10">
   <div className="max-w-7xl mx-auto">
    <div className="flex h-16 items-center justify-between px-6">
     <div className="flex items-center space-x-8">
      <div className="flex items-center space-x-2">
       <span className="text-white font-bold text-lg">EchidnaMail</span>
       <span className="text-red-500 font-bold text-lg">v2</span>
      </div>
      <div className="relative">
       <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
       <input
        type="text"
        placeholder="Search services, documentation, or resources"
        className="pl-10 pr-4 py-2.5 w-96 bg-white border border-gray-300 rounded-md text-gray-900 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" // bg-white added, rounded-md
       />
      </div>
     </div>
     <div className="flex items-center space-x-4">
      <NavButton>API Reference</NavButton>
      <NavButton>Enterprise Support</NavButton>
      <button className="p-2 text-gray-300 hover:text-white hover:bg-gray-800 rounded-full relative">
       <Bell size={20} />
       <span className="absolute top-1 right-1 h-2 w-2 bg-blue-500 rounded-full"></span>
      </button>
      <div className="flex items-center space-x-3 ml-4 px-3 py-2 bg-gray-800 rounded-md cursor-pointer"> {/* rounded-md */}
       <div className="h-8 w-8 bg-blue-600 rounded-full flex items-center justify-center text-white text-sm font-medium">
        EC
       </div>
       <div>
        <div className="text-gray-200 text-sm">Enterprise</div>
        <div className="text-gray-400 text-xs">us-west-2</div>
       </div>
       <ChevronDown size={16} className="text-gray-400" />
      </div>
     </div>
    </div>
   </div>
  </div>
 );

 const NewCampaign = () => {
  const [campaignName, setCampaignName] = useState('');
  const [campaignType, setCampaignType] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [productFeatures, setProductFeatures] = useState('');
  const { user, isLoaded, isSignedIn } = useUser();
  const userInfo = useSelector((state) => state.auth.userInfo);
  const [painPoint, setPainPoint] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [writingStyle, setWritingStyle] = useState('');
  const [scheduleDate, setScheduleDate] = useState('');
  const [scheduleTime, setScheduleTime] = useState('');
  const [timeBetweenEmails, setTimeBetweenEmails] = useState('');
  const [leadSets, setLeadSets] = useState([]);
  const [isLoadingLeadSets, setIsLoadingLeadSets] = useState(true);
  const [selectedLeadSet, setSelectedLeadSet] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [transformedLeadSets, setTransformedLeadSets] = useState([]);
  const [isDomainVerified, setIsDomainVerified] = useState(false);
  const [domainVerificationError, setDomainVerificationError] = useState('');
  const [isVerifyingDomain, setIsVerifyingDomain] = useState(true); // Loading state for domain verification


  useEffect(() => {
   if (isLoaded && isSignedIn && user?.id) {
    checkDomainVerification();
    fetchLeadSets();
   } else if (!isSignedIn && isLoaded) {
    setIsVerifyingDomain(false); // Stop loading if not signed in, to avoid infinite loading
   }
  }, [isLoaded, isSignedIn, user]);

  const checkDomainVerification = async () => {
   if (!user?.id) return;
   setIsVerifyingDomain(true);
   try {
    const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/verifycheck', {
     cid: user?.id,
    });

    if (response.data && response.data.verified === true) {
     setIsDomainVerified(true);
     setDomainVerificationError('');
    } else {
     setIsDomainVerified(false);
     setDomainVerificationError('Your domain is not verified. Please verify your domain to create campaigns.');
    }

   } catch (err) {
    setIsDomainVerified(false);
    setDomainVerificationError('Failed to check domain verification status. Please try again later.');
    console.error("Verification check error:", err);
   } finally {
    setIsVerifyingDomain(false);
   }
  };


  const fetchLeadSets = async () => {
   setIsLoadingLeadSets(true);
   try {
    const cid = user?.id;
    if (!cid) return;

    const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/userleads', { cid });
    const data = response.data;

    if (data && data.leadList && Array.isArray(data.leadList)) {
     setLeadSets(data.leadList);
     setTransformedLeadSets(data.leadList.map(name => ({ name })));
     console.log('Lead sets fetched successfully:', data.leadList);
    } else {
     console.error('Unexpected data format received:', data);
     setLeadSets([]);
     setTransformedLeadSets([]);
    }
   } catch (error) {
    console.error('Error fetching lead sets:', error);
    setLeadSets([]);
    setTransformedLeadSets([]);
   } finally {
    setIsLoadingLeadSets(false);
   }
  };

  const handleSubmit = async (e) => {
   e.preventDefault();
   setIsLoading(true);

   const selectedLeadSetObj = transformedLeadSets.find(set => set.name === selectedLeadSet);
   if (!selectedLeadSetObj) {
    console.error("Selected lead set not found!");
    setIsLoading(false);
    return;
   }

   const campaignData = {
    campaignName,
    campaignType,
    selectedLeadSet: selectedLeadSetObj.name,
    productDescription,
    productFeatures,
    painPoint,
    additionalInfo,
    scheduleDate,
    scheduleTime,
    timeBetweenEmails,
    catchupSchedule: ['2023-12-01', '2023-12-08'],
    selectedCatchupType: 'type1',
    cid: user?.id,
   };

   try {
    const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/campaign', campaignData);
    console.log('Campaign created:', response.data);
    // Optionally redirect or show success message
   } catch (error) {
    console.error('Error creating campaign:', error);
    // Optionally show error message to user
   } finally {
    setIsLoading(false);
   }
  };

  if (isVerifyingDomain) {
   return <div className="min-h-screen bg-gray-50 flex justify-center items-center">Verifying Domain...</div>; // Or a more elaborate loading indicator
  }

  if (!isDomainVerified) {
   return (
    <div className="min-h-screen bg-gray-50 flex justify-center items-center">
     <div className="bg-white rounded-xl shadow-md p-8 border border-red-200">
      <h2 className="text-xl font-semibold text-red-700 mb-4">Domain Verification Required</h2>
      <p className="text-gray-700 mb-6">{domainVerificationError || 'Please verify your sending domain before creating campaigns.'}</p>
      {domainVerificationError && <button onClick={checkDomainVerification} className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 font-semibold">Retry Verification</button>}
     </div>
    </div>
   );
  }


  return (
   <div className="min-h-screen bg-gray-50">
    <EnterpriseHeader />

    <div className="max-w-7xl mx-auto px-6 py-12">
     <div className="flex items-center justify-between mb-12"> {/* Increased margin-bottom */}
      <div>
       <div className="flex items-center space-x-4">
        <h1 className="text-3xl font-bold text-gray-900">New Email Campaign</h1>
        <span className="px-3 py-1 bg-blue-100 text-blue-800 text-sm font-semibold rounded-full">
         Draft
        </span>
       </div>
       <p className="mt-2 text-lg text-gray-700">Craft a compelling email campaign to reach your audience.</p>
      </div>
      <div className="flex items-center space-x-4">
       <button className="flex items-center space-x-2 px-5 py-2.5 bg-white border border-gray-300 rounded-md text-gray-700 hover:bg-gray-100 font-medium"> {/* rounded-md */}
        <HelpCircle size={18} />
        <span className="text-sm font-medium">Campaign Guide</span>
       </button>
      </div>
     </div>

     <form onSubmit={handleSubmit} className="space-y-10"> {/* Increased spacing */}
      <FormSection title="Campaign Details">
       <div className="grid grid-cols-2 gap-10"> {/* Increased gap */}
        <div>
         <label className="block text-sm font-medium text-gray-700 mb-4"> {/* Increased margin-bottom */}
          Campaign Name
          <span className="text-red-500 ml-1">*</span>
         </label>
         <input
          type="text"
          value={campaignName}
          onChange={(e) => setCampaignName(e.target.value)}
          className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border border-gray-300 rounded-md px-4 py-3 bg-white" // bg-white, rounded-md
          placeholder="Enter campaign name"
          required
         />
        </div>

        <div>
         <label className="block text-sm font-medium text-gray-700 mb-4"> {/* Increased margin-bottom */}
          Campaign Type
          <span className="text-red-500 ml-1">*</span>
         </label>
         <select
          value={campaignType}
          onChange={(e) => setCampaignType(e.target.value)}
          className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border border-gray-300 rounded-md px-4 py-3 appearance-none bg-white" // bg-white, rounded-md
          required
         >
          <option value="">Select campaign type</option>
          <option value="promotional">Promotional Campaign</option>
          <option value="newsletter">Newsletter Series</option>
          <option value="drip">Drip Campaign</option>
          <option value="onboarding">Onboarding Sequence</option>
         </select>
        </div>

        <div>
         <label className="block text-sm font-medium text-gray-700 mb-4"> {/* Increased margin-bottom */}
          Lead Set
          <span className="text-red-500 ml-1">*</span>
         </label>
         <select
          value={selectedLeadSet}
          onChange={(e) => setSelectedLeadSet(e.target.value)}
          className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border border-gray-300 rounded-md px-4 py-3 appearance-none bg-white"
          required
         >
          <option value="">Select target audience</option>
          {isLoadingLeadSets ? (
           <option disabled>Loading lead sets...</option>
          ) : (
           transformedLeadSets.map((leadSet) => (
            <option key={leadSet.name} value={leadSet.name}>
             {leadSet.name}
            </option>
           ))
          )}
         </select>
        </div>

        <div>
         <label className="block text-sm font-medium text-gray-700 mb-4"> {/* Increased margin-bottom */}
          Writing Style
          <span className="text-red-500 ml-1">*</span>
         </label>
         <select
          value={writingStyle}
          onChange={(e) => setWritingStyle(e.target.value)}
          className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border border-gray-300 rounded-md px-4 py-3 appearance-none bg-white" // bg-white, rounded-md
          required
         >
          <option value="">Select tone of voice</option>
          <option value="professional">Professional & Formal</option>
          <option value="casual">Casual & Conversational</option>
          <option value="friendly">Friendly & Engaging</option>
          <option value="technical">Technical & Detailed</option>
         </select>
        </div>
       </div>
      </FormSection>

      <FormSection title="Content Information">
       <div className="space-y-8"> {/* Increased spacing */}
        <div>
         <label className="block text-sm font-medium text-gray-700 mb-4 flex items-center"> {/* Increased margin-bottom */}
          Product Description
          <span className="text-red-500 ml-1">*</span>
          <div className="group relative ml-2">
           <Info size={16} className="text-gray-400" />
           <div className="hidden group-hover:block absolute left-6 top-0 w-64 p-2 bg-gray-800 text-white text-xs rounded shadow-lg z-10">
            Describe your product's main value proposition and purpose
           </div>
          </div>
         </label>
         <textarea
          value={productDescription}
          onChange={(e) => setProductDescription(e.target.value)}
          className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border border-gray-300 rounded-md px-4 py-3 bg-white" // bg-white, rounded-md
          rows="6"
          placeholder="Describe your product's main features and benefits"
          required
         />
        </div>

        <div>
         <label className="block text-sm font-medium text-gray-700 mb-4 flex items-center"> {/* Increased margin-bottom */}
          Product Features
          <span className="text-red-500 ml-1">*</span>
         </label>
         <textarea
          value={Array.isArray(productFeatures) ? productFeatures.join('\n') : ''}
          onChange={(e) => {
           const textValue = e.target.value;
           const featuresArray = textValue.split('\n').filter(feature => feature.trim() !== '');
           setProductFeatures(featuresArray);
          }}
          className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border border-gray-300 rounded-md px-4 py-3 bg-white" // bg-white, rounded-md
          rows="6"
          placeholder="List key features and their benefits"
          required
         />
        </div>

        <div>
         <label className="block text-sm font-medium text-gray-700 mb-4 flex items-center"> {/* Increased margin-bottom */}
          Customer Pain Points
          <span className="text-red-500 ml-1">*</span>
         </label>
         <textarea
          value={painPoint}
          onChange={(e) => setPainPoint(e.target.value)}
          className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border border-gray-300 rounded-md px-4 py-3 bg-white" // bg-white, rounded-md
          rows="6"
          placeholder="Describe the problems your product solves"
          required
         />
        </div>

        <div>
         <label className="block text-sm font-medium text-gray-700 mb-4 flex items-center"> {/* Increased margin-bottom */}
          Additional Context
         </label>
         <textarea
          value={additionalInfo}
          onChange={(e) => setAdditionalInfo(e.target.value)}
          className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border border-gray-300 rounded-md px-4 py-3 bg-white" // bg-white, rounded-md
          rows="4"
          placeholder="Any additional information to consider"
         />
        </div>
       </div>
      </FormSection>

      <FormSection title="Schedule">
       <div className="grid grid-cols-3 gap-10"> {/* Increased gap */}
        <div>
         <label className="block text-sm font-medium text-gray-700 mb-4"> {/* Increased margin-bottom */}
          Start Date
          <span className="text-red-500 ml-1">*</span>
         </label>
         <div className="relative shadow-sm">
          <Calendar className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          <input
           type="date"
           value={scheduleDate}
           onChange={(e) => setScheduleDate(e.target.value)}
           className="block w-full pl-10 pr-4 py-3 bg-white border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent sm:text-sm" // rounded-md
           required
          />
         </div>
        </div>

        <div>
         <label className="block text-sm font-medium text-gray-700 mb-4"> {/* Increased margin-bottom */}
          Start Time
          <span className="text-red-500 ml-1">*</span>
         </label>
         <div className="relative shadow-sm">
          <Clock className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          <input
           type="time"
           value={scheduleTime}
           onChange={(e) => setScheduleTime(e.target.value)}
           className="block w-full pl-10 pr-4 py-3 bg-white border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent sm:text-sm" // rounded-md
           required
          />
         </div>
        </div>

        <div>
         <label className="block text-sm font-medium text-gray-700 mb-4"> {/* Increased margin-bottom */}
          Email Interval (Hours)
          <span className="text-red-500 ml-1">*</span>
         </label>
         <div className="relative shadow-sm">
          <input
           type="number"
           value={timeBetweenEmails}
           onChange={(e) => setTimeBetweenEmails(e.target.value)}
           className="block w-full px-4 py-3 bg-white border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent sm:text-sm" // rounded-md
           min="1"
           placeholder="Hours between emails"
           required
          />
         </div>
        </div>
       </div>
      </FormSection>

      <div className="flex justify-end space-x-6 pt-12"> {/* Increased spacing */}
       <button
        type="button"
        className="px-8 py-3 border border-gray-300 rounded-md text-gray-800 hover:bg-gray-100 font-semibold" // Slightly darker border, rounded-md
       >
        Save Draft
       </button>
       <button
        type="submit"
        disabled={isLoading}
        className="px-8 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 font-semibold flex items-center space-x-2 disabled:opacity-50 disabled:cursor-not-allowed" // rounded-md
       >
        {isLoading ? (
         <div className="flex items-center space-x-2">
          <span className="animate-spin h-5 w-5 border-2 border-white border-t-transparent rounded-full inline-block"></span>
          <span>Creating Campaign...</span>
         </div>
        ) : (
         <>
          <Send size={18} />
          <span>Create Campaign</span>
         </>
        )}
       </button>
      </div>
     </form>
    </div>
    <GamefiedAIAssistant />
   </div>
  );
 };

 export default NewCampaign;