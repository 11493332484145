import React, { useState , useEffect } from 'react';
 import { useUser } from '@clerk/clerk-react';
 import {
  MessageCircle,
  Brain,
  Layers,
  Database,
  Mail,
  ArrowRight,
  AlertTriangle,
  CheckCircle2,
  LineChart,
  Rocket,
  Info,
  Clock,
  Trophy, // Added Trophy icon
  X
 } from 'lucide-react';
 import { animated, useSpring, easings } from 'react-spring'; // Added easings
 import Navbar from '../../components/navbar/Navbar.jsx';
 import GamefiedAIAssistant from '../redstone/intercom.jsx';

 // Status configuration - moved outside components to avoid recreation
 const statusConfig = {
  operational: { bgColor: 'bg-green-100', textColor: 'text-green-700', icon: CheckCircle2, borderColor: 'border-green-200' },
  degraded: { bgColor: 'bg-yellow-100', textColor: 'text-yellow-800', icon: AlertTriangle, borderColor: 'border-yellow-200' },
  down: { bgColor: 'bg-red-100', textColor: 'text-red-700', icon: AlertTriangle, borderColor: 'border-red-200' },
  maintenance: { bgColor: 'bg-blue-100', textColor: 'text-blue-700', icon: Clock, borderColor: 'border-blue-200' }
 };

 // Maintenance popup component
 const MaintenancePopup = ({ isOpen, onClose, serviceName }) => {
  if (!isOpen) return null;

  return (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
  <div className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full mx-4">
  <div className="flex justify-between items-center mb-4">
  <h3 className="text-lg font-medium text-gray-900 flex items-center gap-2">
  <Clock className="w-5 h-5 text-blue-500" />
  Scheduled Maintenance
  </h3>
  <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
  <X className="w-5 h-5" />
  </button>
  </div>
  <div className="bg-blue-50 p-4 rounded-md border border-blue-200 mb-4">
  <p className="text-blue-700 text-sm">
  <strong>{serviceName}</strong> is currently down for scheduled maintenance.
  </p>
  <p className="text-blue-600 text-xs mt-2">
  We're performing system upgrades to improve performance and reliability.
  Service is expected to resume within 2 hours.
  </p>
  </div>
  <div className="flex justify-end">
  <button
  onClick={onClose}
  className="px-4 py-2 bg-blue-500 text-white text-sm font-medium rounded-md hover:bg-blue-600 transition-colors"
  >
  Acknowledge
  </button>
  </div>
  </div>
  </div>
  );
 };

 // Creating a separate component for product cards to properly use hooks
 const ProductCard = ({ product, onMaintenanceClick }) => {
  const isFoundry = product.id === 'echidna-ai-foundry'; // Check if it's the special product

  // Specific animation for the Echidna AI Foundry card to make it stand out
  const cardAnimation = useSpring({
    loop: true,
    ...(isFoundry && product.status === 'operational'
      ? {
          from: { scale: 1, boxShadow: '0 0 0px rgba(59, 130, 246, 0)' }, // Initial state (blue shadow base)
          to: [
            { scale: 1.02, boxShadow: '0 0 10px rgba(59, 130, 246, 0.4)' }, // Scale up, blue glow
            { scale: 1, boxShadow: '0 0 0px rgba(59, 130, 246, 0)' }      // Scale down, fade glow
          ],
          config: { duration: 2000, easing: easings.easeInOutSine }, // Smooth easing
        }
      : { // Default non-animated state for other cards or non-operational Foundry
          scale: 1,
          boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)', // Default shadow from Tailwind's shadow-sm
          config: { duration: 0 } // Apply immediately
        })
  });


  // Animation for the status badge itself (kept similar logic)
  const statusAnimation = useSpring({
    loop: true,
    ...(product.status === 'operational'
      ? {
          opacity: [0.8, 1, 0.8],
          transform: ['scale(1)', 'scale(1.05)', 'scale(1)'],
          config: { duration: 1500 },
        }
      : product.status === 'maintenance'
      ? {
          transform: ['rotate(0deg)', 'rotate(360deg)'],
          config: { duration: 3000 },
        }
      : product.status === 'degraded'
      ? {
          transform: ['translateX(0px)', 'translateX(2px)', 'translateX(-2px)', 'translateX(0px)'],
          config: { duration: 500 },
        }
      : product.status === 'down'
      ? {
          color: ['#f44336', '#d32f2f', '#f44336'], // Note: This animates text color, not the icon directly via style
          config: { duration: 750 },
        }
      : {})
  });

  const StatusIcon = statusConfig[product.status].icon;

  const handleClick = (e) => {
    if (product.status === 'maintenance' || product.status === 'down') {
      e.preventDefault();
      onMaintenanceClick(product.name);
    }
    // No prevention for operational/degraded Foundry - it should link normally
  };

  return (
    // Apply the card animation to the outermost div of the card
    <animated.div
      style={cardAnimation} // Apply the specific card animation here
      className="bg-white border border-gray-300 rounded-md overflow-hidden" // Added overflow-hidden for potential shadow clipping
    >
      <div className="bg-gray-50 px-4 py-3 border-b border-gray-300">
        <div className="flex items-center gap-3">
          <div className="p-1.5 bg-white rounded border border-gray-300">
            {React.createElement(product.icon, { className: "w-4 h-4 text-gray-600" })}
          </div>
          <div>
            <h3 className="text-sm text-gray-800 font-medium">{product.name}</h3>
            <p className="text-xs text-gray-600">{product.description}</p>
          </div>
        </div>
      </div>

      <div className="p-4">
        {/* Apply status animation to the status indicator div */}
        <animated.div style={statusAnimation} className={`flex items-center gap-1.5 p-2 rounded border ${statusConfig[product.status].borderColor} mb-3`}>
          {React.createElement(StatusIcon, { className: `w-3.5 h-3.5 ${statusConfig[product.status].textColor}` })}
          <span className={`text-xs font-medium ${statusConfig[product.status].textColor}`}>
            {product.status === 'operational' ? 'Operational' :
            product.status === 'degraded' ? 'Degraded' :
            product.status === 'maintenance' ? 'Scheduled Maintenance' : 'Down'}
          </span>
        </animated.div>

        <div className="grid grid-cols-2 gap-4">
          <div className="text-xs">
            <span className="block text-gray-500 mb-1">Uptime</span>
            <span className="font-medium text-gray-800">{product.metrics.uptime}</span>
          </div>
          <div className="text-xs">
            <span className="block text-gray-500 mb-1">Response Time</span>
            <span className="font-medium text-gray-800">{product.metrics.response}</span>
          </div>
        </div>

        <div className="mt-4 pt-3 border-t border-gray-200 flex justify-end">
          <a
            href={product.link}
            onClick={handleClick}
            className="text-xs text-blue-600 hover:text-blue-800 font-medium flex items-center gap-1"
          >
            <span>View Details</span>
            <Info className="w-3 h-3" />
          </a>
        </div>
      </div>
    </animated.div>
  );
 };


 const RedstonePromo = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
  const timer = setTimeout(() => {
  setIsVisible(true);
  }, 1000);

  return () => clearTimeout(timer);
  }, []);

  const popupAnimation = useSpring({
  opacity: isVisible ? 1 : 0,
  transform: isVisible ? 'scale(1)' : 'scale(0.9)',
  config: { tension: 250, friction: 20 }
  });

  const handleRedirect = () => {
  window.location.href = '/redstone/dash';
  };

  const handleClose = (e) => {
  e.stopPropagation();
  setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
  <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-20 backdrop-blur-sm">
  <animated.div
  style={popupAnimation}
  className="w-full max-w-md mx-4"
  >
  <div className="bg-white rounded-xl shadow-2xl border border-gray-200 overflow-hidden">
  <div className="relative p-6">
  <div
  onClick={handleClose}
  className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 transition-colors cursor-pointer"
  >
  <X className="w-5 h-5" />
  </div>

  <div className="flex flex-col items-center text-center">
  <div className="bg-gray-100 rounded-full p-4 mb-4">
  <Rocket className="w-8 h-8 text-gray-700" />
  </div>

  <h2 className="text-2xl font-bold text-gray-900 mb-3">
  Redstone AI is Live
  </h2>

  <p className="text-gray-600 mb-6 max-w-xs">
  Ship what your customers want with unprecedented speed and precision.
  Transform insights into product magic.
  </p>

  <div
  onClick={handleRedirect}
  className="w-full py-3 bg-black text-white rounded-md hover:bg-gray-800 transition-colors flex items-center justify-center gap-2 cursor-pointer"
  >
  Enter Dashboard
  <ArrowRight className="w-5 h-5" />
  </div>
  </div>
  </div>

  {/* Product Hunt Badge */}
  <div className="p-4 pt-0 flex justify-center">
  <a
  href="https://www.producthunt.com/posts/redstone-ai-ship-what-customers-want?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-redstone&#0045;ai&#0045;ship&#0045;what&#0045;customers&#0045;want"
  target="_blank"
  rel="noopener noreferrer"
  >
  <img
  src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=945849&theme=light&t=1742890984591"
  alt="Redstone AI – Ship What Customers Want"
  className="w-[250px] h-[54px]"
  />
  </a>
  </div>
  </div>
  </animated.div>
  </div>
  );
 };


 const ProductDashboard = () => {
  const { user } = useUser();
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [maintenancePopup, setMaintenancePopup] = useState({
  isOpen: false,
  serviceName: ''
  });

  // --- Added Echidna AI Foundry ---
  const products = [
    {
      id: 'echidna-chat',
      name: 'Echidna Chat',
      icon: MessageCircle,
      description: 'Enterprise Communication Platform',
      status: 'down',
      category: 'communication',
      metrics: {
        uptime: '0%',
        response: '-'
      },
      link: '/echidna/chat'
    },
    {
      id: 'echidna-llm',
      name: 'Echidna LLM',
      icon: Brain,
      description: 'Advanced Language Processing Engine',
      status: 'maintenance',
      category: 'ai',
      metrics: {
        uptime: '0%',
        response: '-'
      },
      link: '/products/echidna-llm'
    },
    {
      id: 'neptaverse',
      name: 'Neptaverse XR',
      icon: Layers,
      description: 'Extended Reality Engine',
      status: 'maintenance',
      category: 'xr',
      metrics: {
        uptime: '0%',
        response: '-'
      },
      link: '/neptaverse'
    },
    {
      id: 'data-pipeline',
      name: 'Data Pipeline (Cyberlake)',
      icon: Database,
      description: 'Advanced Analytics Infrastructure',
      status: 'operational',
      category: 'data',
      metrics: {
        uptime: '99.2%',
        response: '110ms'
      },
      link: '/echidnamail/dap'
    },
    {
      id: 'redstone-ai',
      name: 'Redstone AI',
      icon: Rocket,
      description: 'Customer-Driven Development Engine',
      status: 'operational',
      category: 'ai',
      metrics: {
        uptime: '99.95%',
        response: '70ms'
      },
      link: '/redstone/dash'
    },
    // --- New Product Added Here ---
    {
      id: 'echidna-ai-foundry',
      name: 'Echidna AI Foundry',
      icon: Trophy, // Using Trophy icon
      description: 'Integrated AI Model Development Platform',
      status: 'operational', // Set status (e.g., 'operational')
      category: 'ai',
      metrics: {
        uptime: '99.8%', // Example metric
        response: '95ms'  // Example metric
      },
      link: '/echidna/efoundry/dashboard' // Specified link
    },
    // --- End of New Product ---
    {
      id: 'arc-analytics',
      name: 'Arc Analytics',
      icon: LineChart,
      description: 'Intelligent Website Analysis',
      status: 'operational',
      category: 'data',
      metrics: {
        uptime: '99.96%',
        response: '85ms'
      },
      link: '/echidna/arc'
    },
    {
      id: 'echidna-mail',
      name: 'Echidna Mail',
      icon: Mail,
      description: 'Enterprise-Grade Secure Email',
      status: 'operational',
      category: 'communication',
      metrics: {
        uptime: '100%',
        response: '30ms'
      },
      link: '/echidnamail/dashboard'
    }
  ];
  // --- End of product list update ---


  const categories = [
    { id: 'all', name: 'All Services' },
    { id: 'communication', name: 'Communication' },
    { id: 'ai', name: 'Artificial Intelligence' },
    { id: 'data', name: 'Data & Analytics' },
    { id: 'xr', name: 'Extended Reality' }
  ];

  const filteredProducts = selectedCategory === 'all'
    ? products
    : products.filter(product => product.category === selectedCategory);

  const getStatusSummary = () => {
    const operational = products.filter(p => p.status === 'operational').length;
    const degraded = products.filter(p => p.status === 'degraded').length;
    const down = products.filter(p => p.status === 'down').length;
    const maintenance = products.filter(p => p.status === 'maintenance').length;

    if (down > 0) return { type: 'down', text: 'Service Outage' };
    if (maintenance > 0) return { type: 'maintenance', text: 'Scheduled Maintenance' };
    if (degraded > 0) return { type: 'degraded', text: 'Performance Degraded' };
    return { type: 'operational', text: 'All Systems Operational' };
  };

  const statusSummary = getStatusSummary();

  const handleMaintenanceClick = (serviceName) => {
    setMaintenancePopup({
      isOpen: true,
      serviceName
    });
  };

  const closeMaintenancePopup = () => {
    setMaintenancePopup({
      isOpen: false,
      serviceName: ''
    });
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <Navbar />

      <div className="px-4 sm:px-6 py-6 max-w-7xl mx-auto">
        <div className="mb-6">
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4 mb-4">
            <div>
              <h1 className="text-xl font-normal text-gray-900">Service Health Dashboard</h1>
              <p className="text-gray-600 mt-1 text-sm">View and monitor service availability and performance</p>
            </div>

            <div className={`inline-flex items-center gap-2 px-3 py-1.5 rounded ${statusConfig[statusSummary.type].bgColor}`}>
              {React.createElement(statusConfig[statusSummary.type].icon, {
                className: `w-4 h-4 ${statusConfig[statusSummary.type].textColor}`
              })}
              <span className={`text-sm font-medium ${statusConfig[statusSummary.type].textColor}`}>{statusSummary.text}</span>
            </div>
          </div>

          <div className="bg-white border border-gray-300 rounded-md shadow-sm overflow-hidden">
            <div className="bg-gray-50 border-b border-gray-300 px-4 py-2">
              <div className="flex gap-2 overflow-x-auto hide-scrollbar">
                {categories.map(category => (
                  <button
                    key={category.id}
                    onClick={() => setSelectedCategory(category.id)}
                    className={`px-3 py-1.5 rounded-md text-sm whitespace-nowrap transition-colors ${
                      selectedCategory === category.id
                        ? 'bg-white text-gray-800 border border-gray-300 shadow-sm'
                        : 'text-gray-700 hover:bg-gray-200'
                    }`}
                  >
                    {category.name}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {filteredProducts.map((product) => (
            <ProductCard
              key={product.id}
              product={product}
              onMaintenanceClick={handleMaintenanceClick}
            />
          ))}
        </div>
      </div>

      <MaintenancePopup
        isOpen={maintenancePopup.isOpen}
        onClose={closeMaintenancePopup}
        serviceName={maintenancePopup.serviceName}
      />
      <RedstonePromo />
        <div>
          <GamefiedAIAssistant />
        </div>

    </div>
  );
 };

 export default ProductDashboard;