import React, { useState, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import {
    BarChart2,
    AlertCircle,
    Info,
    Target,
    MessageSquare,
    Users,
    Award,
    ChevronDown,
    ChevronUp,
    Loader,
    TrendingUp,
    Shuffle,
    Lightbulb,
    ShoppingBag,
    Heart
} from 'lucide-react';
import {
    RadarChart,
    PolarGrid,
    PolarAngleAxis,
    PolarRadiusAxis,
    Radar,
    ResponsiveContainer,
    BarChart,
    Bar,
    Tooltip,
    CartesianGrid,
    XAxis,
    YAxis,
    Legend,
    Cell
} from 'recharts';

const MarketAnalysisPage = () => {
    const { user } = useUser();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [analysis, setAnalysis] = useState(null);
    const [pipelineExists, setPipelineExists] = useState(false);
    const [progress, setProgress] = useState(0);
    const [expandedSections, setExpandedSections] = useState({});

    const toggleSection = (section) => {
        setExpandedSections(prev => ({
            ...prev,
            [section]: !prev[section]
        }));
    };

    useEffect(() => {
        const checkPipelineExists = async () => {
            if (!user) return;

            try {
                const response = await fetch('https://web-production-5ee8.up.railway.app/echidna/checkpipeline', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ userId: user.id }),
                });
                const data = await response.json();

                setPipelineExists(response.ok && data.hasPipeline);

                if (!(response.ok && data.hasPipeline)) {
                    setError("Data pipeline setup required for market analysis.");
                }
            } catch (err) {
                console.error("Error checking pipeline existence:", err);
                setError("Could not verify data pipeline status.");
            }
        };

        checkPipelineExists();
    }, [user]);

    const runMarketAnalysis = async () => {
        if (!user) {
            setError("User authentication required.");
            return;
        }

        if (!pipelineExists) {
            setError("Data pipeline setup required for market analysis.");
            return;
        }

        setLoading(true);
        setError(null);
        setAnalysis(null);

        const interval = setInterval(() => {
            setProgress(prev => {
                const newProgress = prev + 8;
                return newProgress >= 90 ? 90 : newProgress;
            });
        }, 800);

        try {
            const response = await fetch(`https://web-production-5ee8.up.railway.app/echidna/market-analysis/${user.id}`, { // Changed endpoint
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = await response.json();

            clearInterval(interval);
            setProgress(100);

            if (response.ok) {
                setTimeout(() => {
                    setAnalysis(data.analysis);
                    setExpandedSections({ summarySection: true }); // Expand summary by default
                }, 500);
            } else {
                setError(data.message || 'Unable to generate market analysis');
                console.error('Analysis failed:', data.message);
            }
        } catch (err) {
            clearInterval(interval);
            setError('Server connection error. Please try again later.');
            console.error('Error fetching market analysis:', err);
        } finally {
            setTimeout(() => {
                setLoading(false);
                setProgress(0);
            }, 500);
        }
    };

    // Function to safely render a value, handling objects and arrays
    const renderValue = (value) => {
        if (value === null || value === undefined) {
            return "N/A";
        }

        if (typeof value === 'object') {
            if (Array.isArray(value)) {
                return (
                    <ul className="list-disc pl-5 space-y-1">
                        {value.map((item, index) => (
                            <li key={index}>{renderValue(item)}</li>
                        ))}
                    </ul>
                );
            }


            return (
                <div className="pl-4 border-l-2 border-gray-200">
                    {Object.entries(value).map(([subKey, subValue]) => (
                        <div key={subKey} className="mb-3">
                            <span className="font-medium text-gray-700">{subKey.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}:</span>{' '}
                            {renderValue(subValue)}
                        </div>
                    ))}
                </div>
            );
        }

        return String(value);
    };


    const getSectionIcon = (section) => {
        switch (section) {
            case 'summarySection':
                return <Info size={20} className="text-gray-600 mr-3" />;
            case 'industryIdentification':
                return <Award size={20} className="text-indigo-600 mr-3" />;
            case 'marketStateAnalysis':
                return <BarChart2 size={20} className="text-blue-600 mr-3" />;
            case 'competitiveLandscape':
                return <Target size={20} className="text-emerald-600 mr-3" />;
            case 'emergingTrends':
                return <TrendingUp size={20} className="text-purple-600 mr-3" />;
            case 'topSellingSoftware':
                return <ShoppingBag size={20} className="text-amber-600 mr-3" />;
            case 'customerPreferences':
                return <Heart size={20} className="text-red-600 mr-3" />;
            case 'strategicRecommendations':
                return <Lightbulb size={20} className="text-teal-600 mr-3" />;
            default:
                return <Shuffle size={20} className="text-gray-400 mr-3" />; // Default icon
        }
    };

    // Get nice display name for section keys
    const getSectionTitle = (section) => {
        const displayNames = {
            summarySection: 'Summary',
            industryIdentification: 'Industry Identification',
            marketStateAnalysis: 'Market State Analysis',
            competitiveLandscape: 'Competitive Landscape',
            emergingTrends: 'Emerging Trends',
            topSellingSoftware: 'Top Selling Software',
            customerPreferences: 'Customer Preferences',
            strategicRecommendations: 'Strategic Recommendations'
        };
        return displayNames[section] || section.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
    };


    return (
        <div className="container mx-auto px-5 py-10">
            <h1 className="text-3xl font-bold text-gray-900 mb-8 text-center">Market Analysis Dashboard</h1>

            {error && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-6" role="alert">
                    <strong className="font-bold">Error!</strong>
                    <span className="block sm:inline ml-2">{error}</span>
                </div>
            )}

            {!pipelineExists && !error && (
                <div className="bg-amber-100 border border-amber-400 text-amber-700 px-4 py-3 rounded relative mb-6" role="alert">
                    <strong className="font-bold">Pipeline Required!</strong>
                    <span className="block sm:inline ml-2">Please set up your data pipeline to run market analysis.</span>
                </div>
            )}


            <div className="text-center mb-8">
                <button
                    onClick={runMarketAnalysis}
                    disabled={loading || !pipelineExists}
                    className={`inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white ${pipelineExists ? 'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500' : 'bg-gray-400 cursor-not-allowed'}  focus:outline-none focus:ring-2 focus:ring-offset-2`}
                >
                    {loading ? <Loader className="animate-spin mr-3 h-5 w-5 text-white" /> : null}
                    Run Market Analysis
                </button>
            </div>

            {loading && (
                <div className="relative pt-1 mb-6">
                    <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-indigo-200">
                        <div style={{ width: `${progress}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-indigo-500 transition-all duration-500"></div>
                    </div>
                    <p className="text-sm text-gray-600 text-center">Analyzing Market Data... {progress}%</p>
                </div>
            )}

            {analysis && (
                <div className="space-y-6">

                    {/* Summary Section */}
                    <div className="bg-white rounded-lg shadow-sm border border-gray-100">
                        <button
                            className="flex items-center justify-between w-full p-4 hover:bg-indigo-50 focus:bg-indigo-50 active:bg-indigo-100 focus:outline-none rounded-md"
                            onClick={() => toggleSection('summarySection')}
                        >
                            <div className="flex items-center">
                                {getSectionIcon('summarySection')}
                                <span className="font-medium text-gray-800">{getSectionTitle('summarySection')}</span>
                            </div>
                            {expandedSections['summarySection'] ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                        </button>
                        {expandedSections['summarySection'] && (
                            <div className="p-4 pt-0">
                                {analysis.summary && renderValue(analysis.summary)}
                            </div>
                        )}
                    </div>


                    {/* Industry Identification Section */}
                    <div className="bg-white rounded-lg shadow-sm border border-gray-100">
                        <button
                            className="flex items-center justify-between w-full p-4 hover:bg-indigo-50 focus:bg-indigo-50 active:bg-indigo-100 focus:outline-none rounded-md"
                            onClick={() => toggleSection('industryIdentification')}
                        >
                            <div className="flex items-center">
                                {getSectionIcon('industryIdentification')}
                                <span className="font-medium text-gray-800">{getSectionTitle('industryIdentification')}</span>
                            </div>
                            {expandedSections['industryIdentification'] ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                        </button>
                        {expandedSections['industryIdentification'] && (
                            <div className="p-4 pt-0">
                                {analysis.industryIdentification && renderValue(analysis.industryIdentification)}
                            </div>
                        )}
                    </div>

                    {/* Market State Analysis Section */}
                    <div className="bg-white rounded-lg shadow-sm border border-gray-100">
                        <button
                            className="flex items-center justify-between w-full p-4 hover:bg-indigo-50 focus:bg-indigo-50 active:bg-indigo-100 focus:outline-none rounded-md"
                            onClick={() => toggleSection('marketStateAnalysis')}
                        >
                            <div className="flex items-center">
                                {getSectionIcon('marketStateAnalysis')}
                                <span className="font-medium text-gray-800">{getSectionTitle('marketStateAnalysis')}</span>
                            </div>
                            {expandedSections['marketStateAnalysis'] ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                        </button>
                        {expandedSections['marketStateAnalysis'] && (
                            <div className="p-4 pt-0">
                                {analysis.marketStateAnalysis && renderValue(analysis.marketStateAnalysis)}
                            </div>
                        )}
                    </div>

                    {/* Competitive Landscape Section */}
                    <div className="bg-white rounded-lg shadow-sm border border-gray-100">
                        <button
                            className="flex items-center justify-between w-full p-4 hover:bg-indigo-50 focus:bg-indigo-50 active:bg-indigo-100 focus:outline-none rounded-md"
                            onClick={() => toggleSection('competitiveLandscape')}
                        >
                            <div className="flex items-center">
                                {getSectionIcon('competitiveLandscape')}
                                <span className="font-medium text-gray-800">{getSectionTitle('competitiveLandscape')}</span>
                            </div>
                            {expandedSections['competitiveLandscape'] ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                        </button>
                        {expandedSections['competitiveLandscape'] && (
                            <div className="p-4 pt-0">
                                {analysis.competitiveLandscape && renderValue(analysis.competitiveLandscape)}
                            </div>
                        )}
                    </div>

                    {/* Emerging Trends Section */}
                    <div className="bg-white rounded-lg shadow-sm border border-gray-100">
                        <button
                            className="flex items-center justify-between w-full p-4 hover:bg-indigo-50 focus:bg-indigo-50 active:bg-indigo-100 focus:outline-none rounded-md"
                            onClick={() => toggleSection('emergingTrends')}
                        >
                            <div className="flex items-center">
                                {getSectionIcon('emergingTrends')}
                                <span className="font-medium text-gray-800">{getSectionTitle('emergingTrends')}</span>
                            </div>
                            {expandedSections['emergingTrends'] ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                        </button>
                        {expandedSections['emergingTrends'] && (
                            <div className="p-4 pt-0">
                                {analysis.emergingTrends && renderValue(analysis.emergingTrends)}
                            </div>
                        )}
                    </div>

                    {/* Top Selling Software Section */}
                    <div className="bg-white rounded-lg shadow-sm border border-gray-100">
                        <button
                            className="flex items-center justify-between w-full p-4 hover:bg-indigo-50 focus:bg-indigo-50 active:bg-indigo-100 focus:outline-none rounded-md"
                            onClick={() => toggleSection('topSellingSoftware')}
                        >
                            <div className="flex items-center">
                                {getSectionIcon('topSellingSoftware')}
                                <span className="font-medium text-gray-800">{getSectionTitle('topSellingSoftware')}</span>
                            </div>
                            {expandedSections['topSellingSoftware'] ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                        </button>
                        {expandedSections['topSellingSoftware'] && (
                            <div className="p-4 pt-0">
                                {analysis.topSellingSoftware && renderValue(analysis.topSellingSoftware)}
                            </div>
                        )}
                    </div>

                    {/* Customer Preferences Section */}
                    <div className="bg-white rounded-lg shadow-sm border border-gray-100">
                        <button
                            className="flex items-center justify-between w-full p-4 hover:bg-indigo-50 focus:bg-indigo-50 active:bg-indigo-100 focus:outline-none rounded-md"
                            onClick={() => toggleSection('customerPreferences')}
                        >
                            <div className="flex items-center">
                                {getSectionIcon('customerPreferences')}
                                <span className="font-medium text-gray-800">{getSectionTitle('customerPreferences')}</span>
                            </div>
                            {expandedSections['customerPreferences'] ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                        </button>
                        {expandedSections['customerPreferences'] && (
                            <div className="p-4 pt-0">
                                {analysis.customerPreferences && renderValue(analysis.customerPreferences)}
                            </div>
                        )}
                    </div>

                     {/* Strategic Recommendations Section */}
                     <div className="bg-white rounded-lg shadow-sm border border-gray-100">
                        <button
                            className="flex items-center justify-between w-full p-4 hover:bg-indigo-50 focus:bg-indigo-50 active:bg-indigo-100 focus:outline-none rounded-md"
                            onClick={() => toggleSection('strategicRecommendations')}
                        >
                            <div className="flex items-center">
                                {getSectionIcon('strategicRecommendations')}
                                <span className="font-medium text-gray-800">{getSectionTitle('strategicRecommendations')}</span>
                            </div>
                            {expandedSections['strategicRecommendations'] ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                        </button>
                        {expandedSections['strategicRecommendations'] && (
                            <div className="p-4 pt-0">
                                {analysis.strategicRecommendations && renderValue(analysis.strategicRecommendations)}
                            </div>
                        )}
                    </div>


                </div>
            )}
        </div>
    );
};

export default MarketAnalysisPage;