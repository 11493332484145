import React from 'react';
import { 
  Mail, Users, BarChart2, FileText, 
  Send, Clock, CheckCircle, AlertTriangle, 
  Download, Plus, Search, Filter, ChevronRight,
  Inbox, UserPlus, Settings
} from 'lucide-react';

const MetricCard = ({ icon: Icon, label, value, trend, color = "blue" }) => (
  <div className="bg-white p-4 rounded-lg shadow-sm">
    <div className="flex items-center space-x-2 mb-2">
      <Icon size={20} className="text-gray-500" />
      <div className="text-sm text-gray-600">{label}</div>
    </div>
    <div className="flex items-end space-x-2">
      <div className="text-2xl font-semibold text-gray-900">{value}</div>
      {trend && (
        <div className={`text-sm ${trend >= 0 ? 'text-green-600' : 'text-red-600'}`}>
          {trend >= 0 ? '+' : ''}{trend}%
        </div>
      )}
    </div>
  </div>
);

const CampaignCard = ({ name, status, sent, opened, clicked }) => (
  <div className="bg-white p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow">
    <div className="flex items-center justify-between mb-3">
      <h3 className="font-medium text-gray-900">{name}</h3>
      <span className={`px-2 py-1 rounded-full text-xs font-medium ${
        status === 'Active' ? 'bg-green-50 text-green-700' :
        status === 'Draft' ? 'bg-gray-50 text-gray-700' :
        'bg-blue-50 text-blue-700'
      }`}>
        {status}
      </span>
    </div>
    <div className="space-y-2">
      <div className="flex items-center justify-between text-sm">
        <span className="text-gray-600">Sent</span>
        <span className="font-medium">{sent}</span>
      </div>
      <div className="flex items-center justify-between text-sm">
        <span className="text-gray-600">Opened</span>
        <span className="font-medium">{opened}%</span>
      </div>
      <div className="flex items-center justify-between text-sm">
        <span className="text-gray-600">Clicked</span>
        <span className="font-medium">{clicked}%</span>
      </div>
    </div>
    <button className="mt-4 w-full px-4 py-2 text-sm font-medium text-blue-600 hover:bg-blue-50 rounded-lg transition-colors">
      View Details
    </button>
  </div>
);

const ProspectList = () => (
  <div className="bg-white rounded-lg shadow-sm">
    <div className="p-4 border-b border-gray-100">
      <div className="flex items-center justify-between mb-4">
        <h3 className="font-medium text-gray-900">Recent Prospects</h3>
        <button className="text-sm text-blue-600 font-medium">View All</button>
      </div>
      <div className="flex space-x-2">
        <div className="flex-1">
          <div className="relative">
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            <input
              type="text"
              placeholder="Search prospects..."
              className="pl-10 pr-4 py-2 w-full border border-gray-200 rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>
        <button className="p-2 border border-gray-200 rounded-lg hover:bg-gray-50">
          <Filter size={20} className="text-gray-500" />
        </button>
      </div>
    </div>
    <div className="divide-y divide-gray-100">
      {[
        { name: "Sarah Connor", email: "sarah@cyberdyne.com", status: "Qualified" },
        { name: "John Smith", email: "john@acme.co", status: "New" },
        { name: "Jane Doe", email: "jane@startup.io", status: "Contacted" },
      ].map((prospect, i) => (
        <div key={i} className="p-4 hover:bg-gray-50">
          <div className="flex items-center justify-between">
            <div>
              <div className="font-medium text-gray-900">{prospect.name}</div>
              <div className="text-sm text-gray-600">{prospect.email}</div>
            </div>
            <div className="flex items-center space-x-3">
              <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                prospect.status === 'Qualified' ? 'bg-green-50 text-green-700' :
                prospect.status === 'New' ? 'bg-blue-50 text-blue-700' :
                'bg-yellow-50 text-yellow-700'
              }`}>
                {prospect.status}
              </span>
              <ChevronRight size={20} className="text-gray-400" />
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
);

const EmailStatus = () => (
  <div className="bg-white rounded-lg shadow-sm">
    <div className="p-4 border-b border-gray-100">
      <h3 className="font-medium text-gray-900">Email Status</h3>
    </div>
    <div className="p-4">
      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <Send size={20} className="text-green-500" />
            <span className="text-sm text-gray-700">Delivered</span>
          </div>
          <span className="text-sm font-medium">98.5%</span>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <Clock size={20} className="text-yellow-500" />
            <span className="text-sm text-gray-700">Pending</span>
          </div>
          <span className="text-sm font-medium">1.2%</span>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <AlertTriangle size={20} className="text-red-500" />
            <span className="text-sm text-gray-700">Failed</span>
          </div>
          <span className="text-sm font-medium">0.3%</span>
        </div>
      </div>
      <div className="mt-4 pt-4 border-t border-gray-100">
        <button className="w-full px-4 py-2 text-sm font-medium text-blue-600 hover:bg-blue-50 rounded-lg transition-colors">
          View Detailed Report
        </button>
      </div>
    </div>
  </div>
);

const ComsDashboard = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="flex items-center justify-between mb-8">
          <div>
            <h1 className="text-2xl font-semibold text-gray-900">Communications Hub</h1>
            <p className="mt-2 text-sm text-gray-600">Manage your campaigns, prospects, and email performance</p>
          </div>
          <div className="flex items-center space-x-4">
            <button className="px-4 py-2 bg-white border border-gray-200 rounded-lg text-gray-700 hover:bg-gray-50">
              <Download size={20} />
            </button>
            <button className="px-4 py-2 bg-blue-600 text-white rounded-lg font-medium hover:bg-blue-700">
              <div className="flex items-center space-x-2">
                <Plus size={20} />
                <span>New Campaign</span>
              </div>
            </button>
          </div>
        </div>

        <div className="grid grid-cols-4 gap-4 mb-6">
          <MetricCard icon={Send} label="Total Sent" value="458.2K" trend={8.1} />
          <MetricCard icon={Inbox} label="Open Rate" value="32.7%" trend={-2.3} />
          <MetricCard icon={UserPlus} label="New Prospects" value="127" trend={12.5} />
          <MetricCard icon={Settings} label="Active Campaigns" value="7" />
        </div>

        <div className="grid grid-cols-3 gap-6 mb-6">
          <CampaignCard 
            name="Q1 Newsletter"
            status="Active"
            sent="24,123"
            opened="45.2"
            clicked="12.8"
          />
          <CampaignCard 
            name="Product Launch"
            status="Scheduled"
            sent="0"
            opened="0"
            clicked="0"
          />
          <CampaignCard 
            name="Customer Survey"
            status="Draft"
            sent="0"
            opened="0"
            clicked="0"
          />
        </div>

        <div className="grid grid-cols-3 gap-6">
          <div className="col-span-2">
            <ProspectList />
          </div>
          <EmailStatus />
        </div>
      </div>
    </div>
  );
};

export default ComsDashboard;