import React, { useState, useEffect, useRef } from 'react';
import { MessageSquare, Play, FileText, Send, ChevronDown, X, Volume2, VolumeX } from 'lucide-react';
import { useUser } from '@clerk/clerk-react';
import GamefiedAIAssistant from './intercom';

const ProdVali = () => {
  const [activeTab, setActiveTab] = useState('chat');
  const [persona, setPersona] = useState('cxo');
  const { user } = useUser();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [productInfo, setProductInfo] = useState('');
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showMaxMessagesPopup, setShowMaxMessagesPopup] = useState(false);
  const [aiMessageCount, setAiMessageCount] = useState(0);
  const [showDealClosedPopup, setShowDealClosedPopup] = useState(false);
  const [showDealCanceledPopup, setShowDealCanceledPopup] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [speechEnabled, setSpeechEnabled] = useState(true);
  const [speechSynthesisSupported, setSpeechSynthesisSupported] = useState(false);
  const [firstAiMessageReceived, setFirstAiMessageReceived] = useState(false);

  const synthesisRef = useRef(null);
  const messagesEndRef = useRef(null);
  const dropdownRef = useRef(null);

  const personas = {
    cxo: {
      title: 'Chief Executive Officer',
      description: 'Business strategy, market positioning, and overall value proposition.',
      voice: 'en-US-Standard-J',
    },
    cto: {
      title: 'Chief Technology Officer',
      description: 'Technical implementation, scalability, and innovation aspects.',
      voice: 'en-US-Standard-I',
    },
    cmo: {
      title: 'Chief Marketing Officer',
      description: 'Marketing strategy, customer acquisition, and brand positioning.',
      voice: 'en-US-Standard-F',
    },
    investor: {
      title: 'Potential Investor',
      description: 'Financial viability, market opportunity, and growth potential.',
      voice: 'en-US-Standard-B',
    },
    customer: {
      title: 'End Customer',
      description: 'User experience, product benefits, and pricing considerations.',
      voice: 'en-US-Standard-E',
    },
  };

  // Initialize speech synthesis
  useEffect(() => {
    if ('speechSynthesis' in window) {
      synthesisRef.current = window.speechSynthesis;
      setSpeechSynthesisSupported(true);
    }
  }, []);

  // Scroll to bottom of messages
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  // Check if max messages reached whenever messages are updated
  useEffect(() => {
    const aiMessages = messages.filter(msg => msg.sender === 'ai');
    setAiMessageCount(aiMessages.length);

    if (aiMessages.length >= 15) {
      setShowMaxMessagesPopup(true);
    }
  }, [messages]);

  // Cancel any ongoing speech when component unmounts
  useEffect(() => {
    return () => {
      if (synthesisRef.current) {
        synthesisRef.current.cancel();
      }
    };
  }, []);

  // Function to speak text
  const speakText = (text) => {
    if (!speechSynthesisSupported || !speechEnabled) return;

    if (synthesisRef.current.speaking) {
      synthesisRef.current.cancel();
    }

    const cleanText = text.replace(/<br>/g, '\n').replace(/<[^>]*>/g, '');

    const utterance = new SpeechSynthesisUtterance(cleanText);
    const voices = synthesisRef.current.getVoices();

    utterance.rate = 1.2;
    utterance.pitch = 1.0;
    utterance.volume = 1.0;

    if (voices.length > 0) {
      const voiceIndex = Math.min(Object.keys(personas).indexOf(persona), voices.length - 1);
      utterance.voice = voices[voiceIndex >= 0 ? voiceIndex : 0];
    }

    utterance.onstart = () => setIsSpeaking(true);
    utterance.onend = () => setIsSpeaking(false);
    utterance.onerror = (event) => {
      console.error('Speech synthesis error:', event);
      setIsSpeaking(false);
    };

    synthesisRef.current.speak(utterance);
  };

  // Toggle speech synthesis
  const toggleSpeech = () => {
    if (speechSynthesisSupported) {
      if (isSpeaking) {
        synthesisRef.current.cancel();
        setIsSpeaking(false);
      }
      setSpeechEnabled(!speechEnabled);
    }
  };

  // Handle sending a message
  const handleSendMessage = async (transcribedText = null) => {
    const messageText = transcribedText || inputValue;

    if (!messageText.trim() || isLoading) return;

    const newUserMessage = {
      sender: 'user',
      text: messageText,
      timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
    };

    setMessages([...messages, newUserMessage]);
    setInputValue('');
    setIsLoading(true);

    try {
      const chatHistory = messages.map(msg => ({
        role: msg.sender === 'user' ? 'user' : 'assistant',
        content: msg.text
      }));

      const response = await fetch('https://web-production-5ee8.up.railway.app/echidna/chatmessage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          message: messageText,
          persona: persona,
          productInfo: productInfo,
          chatHistory: chatHistory,
          cid: user?.id
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to get response');
      }

      const data = await response.json();
      const formattedResponse = data.response.replace(/\n\n/g, '<br><br>').replace(/\n/g, '<br>');

      const aiResponse = {
        sender: 'ai',
        text: formattedResponse,
        timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
      };

      setMessages(prevMessages => [...prevMessages, aiResponse]);

      if (!firstAiMessageReceived) {
        setFirstAiMessageReceived(true);
      }

      if (speechEnabled) {
        speakText(data.response);
      }

      if (data.dealStatus === 'close') {
        setTimeout(() => {
          setShowDealClosedPopup(true);
        }, 1000);
      } else if (data.dealStatus === 'cancel') {
        setTimeout(() => {
          setShowDealCanceledPopup(true);
        }, 1000);
      }

    } catch (error) {
      console.error('Error sending message:', error);

      const errorResponse = {
        sender: 'ai',
        text: "I'm sorry, I couldn't process your request. Please try again later.",
        timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
      };

      setMessages(prevMessages => [...prevMessages, errorResponse]);

      if (speechEnabled) {
        speakText(errorResponse.text);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const toggleDropdown = () => {
    if (firstAiMessageReceived) {
      setIsDropdownOpen(!isDropdownOpen);
    }
  };

  const selectPersona = (selected) => {
    if (firstAiMessageReceived) {
      setPersona(selected);
      setIsDropdownOpen(false);
      setMessages([]);
      setAiMessageCount(0);

      if (synthesisRef.current && synthesisRef.current.speaking) {
        synthesisRef.current.cancel();
        setIsSpeaking(false);
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const submitProductInfo = async () => {
    if (!productInfo.trim()) return;

    setIsInfoModalOpen(false);
    setIsLoading(true);

    try {
      const response = await fetch('https://web-production-5ee8.up.railway.app/echidna/chatmessage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          message: "INIT_CONVERSATION",
          persona: persona,
          productInfo: productInfo,
          chatHistory: [],
          cid: user?.id
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to get welcome message');
      }

      const data = await response.json();
      const formattedResponse = data.response ?
        data.response.replace(/\n\n/g, '<br><br>').replace(/\n/g, '<br>') :
        `Hello! I'm your ${personas[persona].title} AI agent. Tell me about your product, and I'll provide feedback from this perspective.`;

      const welcomeMessage = {
        sender: 'ai',
        text: formattedResponse,
        timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
      };

      setMessages([welcomeMessage]);
      setAiMessageCount(1);

      if (!firstAiMessageReceived) {
        setFirstAiMessageReceived(true);
      }

      if (speechEnabled) {
        speakText(data.response || welcomeMessage.text);
      }
    } catch (error) {
      console.error('Error initializing conversation:', error);

      const fallbackMessage = {
        sender: 'ai',
        text: `Hello! I'm your ${personas[persona].title} AI agent. Tell me about your product, and I'll provide feedback from this perspective.`,
        timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
      };

      setMessages([fallbackMessage]);
      setAiMessageCount(1);

      if (!firstAiMessageReceived) {
        setFirstAiMessageReceived(true);
      }

      if (speechEnabled) {
        speakText(fallbackMessage.text);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const restartConversation = () => {
    setMessages([]);
    setAiMessageCount(0);
    setShowMaxMessagesPopup(false);
    setShowDealClosedPopup(false);
    setShowDealCanceledPopup(false);
    setIsInfoModalOpen(true);
    setFirstAiMessageReceived(false);

    if (synthesisRef.current && synthesisRef.current.speaking) {
      synthesisRef.current.cancel();
      setIsSpeaking(false);
    }
  };

  // Loading animation component
  const LoadingAnimation = () => (
    <div className="flex items-center space-x-2">
      <div className="h-2 w-2 bg-blue-500 rounded-full animate-pulse"></div>
      <div className="h-2 w-2 bg-blue-500 rounded-full animate-pulse delay-100"></div>
      <div className="h-2 w-2 bg-blue-500 rounded-full animate-pulse delay-200"></div>
    </div>
  );

  return (
    <div className="bg-black text-white min-h-screen font-sans">
      {/* Header */}
      <header className="border-b border-gray-800 py-4 px-6">
        <div className="max-w-6xl mx-auto flex justify-between items-center">
          <h1 className="text-xl font-light tracking-tight">prodvali</h1>
          <div className="text-xs opacity-50">voice-enabled product validation</div>
        </div>
      </header>

      {/* Main Content */}
      <main className="max-w-6xl mx-auto px-6 py-8">
        {/* Tabs */}
        <div className="flex border-b border-gray-800 mb-8">
          <div
            className={`mr-8 pb-2 text-sm ${activeTab === 'chat' ? 'border-b border-white' : 'opacity-50'} cursor-pointer`}
            onClick={() => setActiveTab('chat')}
          >
            <div className="flex items-center">
              <MessageSquare size={16} className="mr-2" />
              AI CONVERSATIONS
            </div>
          </div>
          <div
            className={`mr-8 pb-2 text-sm ${activeTab === 'pitch' ? 'border-b border-white' : 'opacity-50'} cursor-pointer`}
            onClick={() => setActiveTab('pitch')}
          >
            <div className="flex items-center">
              <Play size={16} className="mr-2" />
              PRACTICE PITCH
            </div>
          </div>
          <div
            className={`pb-2 text-sm ${activeTab === 'analysis' ? 'border-b border-white' : 'opacity-50'} cursor-pointer`}
            onClick={() => setActiveTab('analysis')}
          >
            <div className="flex items-center">
              <FileText size={16} className="mr-2" />
              ANALYSIS
            </div>
          </div>
        </div>

        {/* Voice Controls */}
        {activeTab === 'chat' && (
          <div className="flex items-center mb-4">
            <div className="flex items-center mr-6">
              <span className="text-xs text-gray-400">
                Voice Input Not Supported
              </span>
            </div>
            <div className="flex items-center">
              <span className="text-xs text-gray-400">
                {speechSynthesisSupported ?
                  (speechEnabled ? "Voice Output On" : "Voice Output Off") :
                  "Voice Output Not Supported"}
              </span>
            </div>
          </div>
        )}

        {/* Product Info Modal */}
        {isInfoModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-95 flex items-center justify-center z-10">
            <div className="bg-gray-900 border border-gray-800 p-8 max-w-lg w-full">
              <h2 className="text-xl mb-6">Product Information</h2>
              <p className="text-gray-400 mb-4 text-sm">
                Enter details about your product to help our AI agents provide relevant feedback.
              </p>
              <textarea
                className="w-full bg-black border border-gray-800 p-4 h-40 mb-6 text-sm"
                placeholder="Describe your product, target audience, unique value proposition, etc."
                value={productInfo}
                onChange={(e) => setProductInfo(e.target.value)}
              ></textarea>
              <div
                className="border border-white px-6 py-2 text-sm hover:bg-white hover:text-black transition-colors cursor-pointer"
                onClick={submitProductInfo}
                disabled={isLoading}
              >
                {isLoading ? 'LOADING...' : 'START VALIDATION'}
              </div>
            </div>
          </div>
        )}

        {/* Max Messages Popup */}
        {showMaxMessagesPopup && (
          <div className="fixed inset-0 bg-black bg-opacity-95 flex items-center justify-center z-10">
            <div className="bg-gray-900 border border-gray-800 p-8 max-w-lg w-full">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl">Conversation Limit Reached</h2>
                <div
                  onClick={() => setShowMaxMessagesPopup(false)}
                  className="p-1 cursor-pointer"
                >
                  <X size={20} />
                </div>
              </div>
              <p className="text-gray-400 mb-6 text-sm">
                You've reached the maximum number of exchanges with this AI persona. Would you like to continue with a new conversation?
              </p>
              <div className="flex justify-center">
                <div
                  className="border border-white px-6 py-2 text-sm hover:bg-white hover:text-black transition-colors cursor-pointer"
                  onClick={restartConversation}
                >
                  RESTART CONVERSATION
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Chat Interface */}
        {activeTab === 'chat' && (
          <div className="flex flex-col h-full">
            {/* Persona Selector */}
            <div className="mb-6 relative" ref={dropdownRef}>
              <div
                className={`border border-gray-800 inline-block py-2 px-4 cursor-pointer ${!firstAiMessageReceived ? 'opacity-50' : ''}`}
                onClick={toggleDropdown}
              >
                <div className="flex items-center justify-between w-48">
                  <span className="text-sm">{personas[persona].title}</span>
                  <ChevronDown size={16} className="ml-2" />
                </div>
              </div>

              {isDropdownOpen && (
                <div className="absolute left-0 mt-1 border border-gray-800 bg-black w-48 z-10">
                  {Object.keys(personas).map((key) => (
                    <div
                      key={key}
                      className="p-3 text-sm cursor-pointer hover:bg-gray-900"
                      onClick={() => selectPersona(key)}
                    >
                      <div className="font-medium">{personas[key].title}</div>
                      <div className="text-xs text-gray-400 mt-1">{personas[key].description}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* Message Counter */}
            <div className="mb-2 text-xs text-gray-500">
              Messages: {aiMessageCount}/15
            </div>

            {/* Chat Messages */}
            <div className="flex-grow overflow-auto mb-6 border border-gray-800 p-6 h-96">
              {messages.length === 0 ? (
                <div className="h-full flex items-center justify-center text-gray-500 text-sm">
                  Select a persona and start your conversation
                </div>
              ) : (
                <div className="space-y-6">
                  {messages.map((message, index) => (
                    <div
                      key={index}
                      className={`flex ${message.sender === 'user' ? 'justify-end' : 'justify-start'}`}
                    >
                      <div
                        className={`max-w-3/4 ${
                          message.sender === 'user'
                            ? 'bg-blue-900 border border-blue-800'
                            : message.sender === 'system'
                            ? 'bg-gray-700 border border-gray-600'
                            : 'bg-gray-900 border border-gray-800'
                        } p-4 rounded-lg`}
                      >
                        <div
                          className="text-sm mb-1 leading-relaxed"
                          dangerouslySetInnerHTML={{ __html: message.text }}
                        ></div>
                        <div className="text-xs text-gray-500 flex justify-between">
                          <span>{message.timestamp}</span>
                          {message.sender === 'ai' && speechSynthesisSupported && (
                            <div
                              className="ml-4 text-xs opacity-75 hover:opacity-100 transition-opacity cursor-pointer"
                              onClick={() => speakText(message.text)}
                              title="Speak this message"
                            >
                              <Volume2 size={12} />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                  {isLoading && (
                    <div className="flex justify-start">
                      <div className="bg-gray-900 border border-gray-800 p-4 rounded-lg">
                        <div className="flex items-center">
                          <LoadingAnimation />
                          <span className="ml-3 text-sm">Thinking</span>
                        </div>
                      </div>
                    </div>
                  )}
                  <div ref={messagesEndRef} />
                </div>
              )}
            </div>

            {/* Message Input */}
            <div className="relative">
              <textarea
                className="w-full bg-black border border-gray-800 p-4 pr-12 h-24 text-sm"
                placeholder="Type your message..."
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyPress={handleKeyPress}
                disabled={isLoading}
              ></textarea>
              <div className="absolute right-3 bottom-3 flex">
                <div
                  className={`p-2 mr-2 ${isLoading ? 'bg-red-600' : 'hover:bg-gray-900'} transition-colors rounded-full cursor-pointer`}
                  onClick={() => handleSendMessage()}
                  disabled={isLoading || !inputValue.trim()}
                >
                  <Send size={18} />
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Pitch Practice Interface */}
        {activeTab === 'pitch' && (
          <div className="text-center py-12">
            <p className="text-lg mb-6">Practice your product pitch and receive AI feedback</p>
            <div className="border border-white px-8 py-3 hover:bg-white hover:text-black transition-colors cursor-pointer">
              START PITCH SESSION
            </div>
          </div>
        )}

        {/* Deal Closed Popup */}
        {showDealClosedPopup && (
          <div className="fixed inset-0 bg-black bg-opacity-95 flex items-center justify-center z-10">
            <div className="bg-gray-900 border border-green-800 p-8 max-w-lg w-full">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl text-green-400">Deal Successfully Closed!</h2>
                <div
                  onClick={() => setShowDealClosedPopup(false)}
                  className="p-1 cursor-pointer"
                >
                  <X size={20} />
                </div>
              </div>
              <p className="text-gray-300 mb-6 text-sm">
                Congratulations! You've successfully convinced the {personas[persona].title} to move forward with your product.
              </p>
              <div className="flex justify-center">
                <div
                  className="border border-green-400 text-green-400 px-6 py-2 text-sm hover:bg-green-400 hover:text-black transition-colors cursor-pointer"
                  onClick={restartConversation}
                >
                  START NEW VALIDATION
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Deal Canceled Popup */}
        {showDealCanceledPopup && (
          <div className="fixed inset-0 bg-black bg-opacity-95 flex items-center justify-center z-10">
            <div className="bg-gray-900 border border-red-800 p-8 max-w-lg w-full">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl text-red-400">Deal Canceled</h2>
                <div
                  onClick={() => setShowDealCanceledPopup(false)}
                  className="p-1 cursor-pointer"
                >
                  <X size={20} />
                </div>
              </div>
              <p className="text-gray-300 mb-6 text-sm">
                Unfortunately, the {personas[persona].title} has decided not to move forward with your product. Consider their feedback and try again with an improved pitch.
              </p>
              <div className="flex justify-center">
                <div
                  className="border border-red-400 text-red-400 px-6 py-2 text-sm hover:bg-red-400 hover:text-black transition-colors cursor-pointer"
                  onClick={restartConversation}
                >
                  START NEW VALIDATION
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Analysis Interface */}
        {activeTab === 'analysis' && (
          <div className="text-center py-12">
            <p className="text-lg mb-6">View detailed analysis of your product validation conversations</p>
            <div className="border border-white px-8 py-3 hover:bg-white hover:text-black transition-colors cursor-pointer">
              GENERATE ANALYSIS
            </div>
          </div>
        )}
      </main>

      {/* Footer */}
      <footer className="border-t border-gray-800 py-4 px-6 mt-8">
        <div className="max-w-6xl mx-auto flex justify-between items-center">
          <div className="text-xs text-gray-500">© 2023 ProdVali</div>
          <div className="text-xs text-gray-500">
            <a href="#" className="hover:text-white transition-colors">Privacy Policy</a>
            <span className="mx-2">|</span>
            <a href="#" className="hover:text-white transition-colors">Terms of Service</a>
          </div>
        </div>
      </footer>
      <GamefiedAIAssistant />
    </div>
  );
};

export default ProdVali;
