import React, { useState, useEffect } from 'react';
import { ArrowRight,Sparkles,Zap,Shield, ChevronDown, Star, User, Users, Code, Check,Play, MessageSquare, MousePointer, ChevronRight } from 'lucide-react';
import { useNavigate } from 'react-router';
// Hero Section
const Hero = () => {
  const [visible, setVisible] = useState(false);
  const [textReveal, setTextReveal] = useState(false);
  const [buttonReveal, setButtonReveal] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
      setVisible(true);

      const textTimer = setTimeout(() => {
          setTextReveal(true);
      }, 600);

      const buttonTimer = setTimeout(() => {
          setButtonReveal(true);
      }, 1200);

      return () => {
          clearTimeout(textTimer);
          clearTimeout(buttonTimer);
      };
  }, []);

  const handleTryForFree = () => {
      navigate('/signup'); // Redirect to the desired path
  };

  return (
      <section className="relative flex flex-col items-center justify-center min-h-screen p-8 overflow-hidden bg-black text-white">
          {/* Animated particles in background */}
          <div className="absolute inset-0 overflow-hidden opacity-20">
              {[...Array(20)].map((_, i) => (
                  <div
                      key={i}
                      className="absolute w-2 h-2 bg-red-500 rounded-full"
                      style={{
                          top: `${Math.random() * 100}%`,
                          left: `${Math.random() * 100}%`,
                          animation: `pulse ${3 + Math.random() * 4}s infinite alternate`,
                          opacity: 0.4 + Math.random() * 0.6
                      }}
                  />
              ))}
          </div>

          {/* Grid lines */}
          <div className="absolute inset-0 bg-grid-pattern opacity-10" />

          {/* Main content */}
          <div className={`max-w-5xl mx-auto text-center transition-all duration-1000 ease-out transform ${visible ? 'translate-y-0 opacity-100' : 'translate-y-8 opacity-0'}`}>
              {/* Logo/brand with animation */}
              <div className="relative mb-8">
                  <h1 className="text-7xl font-bold tracking-tight mb-6">
                      <span className="inline-block relative z-10">Red</span>
                      <span className="inline-block text-black bg-red-600 px-4 py-1 mx-1 skew-x-6 transform transition-transform hover:skew-x-0 duration-300">stone</span>
                      <span className="inline-block relative z-10">AI</span>
                  </h1>
                  {/* Animated underline */}
                  <div className={`h-1 bg-gradient-to-r from-transparent via-red-600 to-transparent max-w-md mx-auto transition-all duration-1500 ease-in-out ${textReveal ? 'w-full opacity-70' : 'w-0 opacity-0'}`} />
              </div>

              {/* Tagline with staggered text reveal */}
              <p className={`text-2xl font-light mb-16 max-w-2xl mx-auto transition-all duration-1000 ease-out delay-300 ${textReveal ? 'opacity-80' : 'opacity-0'}`}>
                  Ship exactly what your customers want.{' '}
                  <span className="font-medium text-red-500">
                      Validate through AI simulations.
                  </span>
              </p>

              {/* Feature highlights */}
              <div className={`flex flex-wrap justify-center gap-12 mb-16 transition-all duration-1000 ease-out delay-500 ${textReveal ? 'opacity-100' : 'opacity-0'}`}>
                  <div className="flex items-center gap-3">
                      <Zap size={22} className="text-red-500" />
                      <span className="text-lg">10x faster feedback</span>
                  </div>
                  <div className="flex items-center gap-3">
                      <Shield size={22} className="text-red-500" />
                      <span className="text-lg">Risk-free validation</span>
                  </div>
                  <div className="flex items-center gap-3">
                      <Code size={22} className="text-red-500" />
                      <span className="text-lg">API-first platform</span>
                  </div>
              </div>

              {/* CTA button with hover effects */}
              <div className={`flex flex-col items-center transition-all duration-1000 transform ${buttonReveal ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}`}>
                  <button
                      onClick={handleTryForFree}
                      className="bg-red-600 text-white px-8 py-4 font-medium flex items-center gap-2 hover:bg-red-700 transition-colors duration-300 relative overflow-hidden group"
                  >
                      <span className="relative z-10">Try for free now</span>
                      <ArrowRight size={18} className="relative z-10 transition-transform group-hover:translate-x-1" />
                      <div className="absolute bottom-0 left-0 w-full h-1 bg-white transform scale-x-0 group-hover:scale-x-100 transition-transform origin-left" />
                  </button>

                  {/* Product Hunt Badge */}
                  <div className={`mt-6 transition-all duration-1000 delay-500 ${buttonReveal ? 'opacity-100' : 'opacity-0'}`}>
                      <a 
                          href="https://www.producthunt.com/posts/redstone-ai-ship-what-customers-want?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-redstone&#0045;ai&#0045;ship&#0045;what&#0045;customers&#0045;want" 
                          target="_blank" 
                          rel="noopener noreferrer"
                      >
                          <img 
                              src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=945849&theme=light&t=1742890984591" 
                              alt="Redstone AI – Ship What Customers Want" 
                              className="w-[250px] h-[54px]"
                          />
                      </a>
                  </div>
              </div>
          </div>

          {/* Scroll indicator */}
          <div className="absolute bottom-8 flex justify-center w-full">
              <div className={`p-2 rounded-full border border-white/20 bg-black/50 backdrop-blur transition-all duration-1000 delay-1500 ${visible ? 'opacity-70' : 'opacity-0'}`}>
                  <ChevronDown className="animate-bounce text-red-500" size={24} />
              </div>
          </div>
      </section>
  );
};
// Features Section
const Features = () => {
    const [activeTab, setActiveTab] = useState(1);
    const [activeStep, setActiveStep] = useState(1);
    const [isInteracting, setIsInteracting] = useState(false);

    // Auto-advance steps when not interacting
    useEffect(() => {
      if (!isInteracting) {
        const interval = setInterval(() => {
          const currentFeature = features.find(f => f.id === activeTab);
          setActiveStep(prev => prev < currentFeature.steps.length ? prev + 1 : 1);
        }, 3000);

        return () => clearInterval(interval);
      }
    }, [activeTab, isInteracting]);

    const features = [
      {
        id: 1,
        title: "Review Analysis",
        description: "Discover exactly what to build next based on customer feedback.",
        icon: <Code size={24} strokeWidth={2} />,
        steps: [
          {
            title: "Gather Feedback",
            description: "We analyze your product reviews and competitors' customer feedback."
          },
          {
            title: "Identify Patterns",
            description: "AI detects recurring requests and underlying customer needs."
          },
          {
            title: "Prioritize Features",
            description: "Get a ranked list of features that will drive the most value."
          }
        ]
      },
      {
        id: 2,
        title: "AI Personas",
        description: "Test product ideas with simulated stakeholders before building.",
        icon: <Users size={24} strokeWidth={2} />,
        steps: [
          {
            title: "Choose Audience",
            description: "Select from personas like CEOs, investors, or different customer segments."
          },
          {
            title: "Present Concept",
            description: "Pitch your product idea in conversational language."
          },
          {
            title: "Receive Validation",
            description: "Get realistic feedback and suggestions for improvement."
          }
        ]
      }
    ];

    const currentFeature = features.find(f => f.id === activeTab);

    return (
      <section className="py-16 px-4 sm:px-8 bg-black text-white">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-3xl sm:text-4xl font-bold mb-2 text-center">
            Product Validation Suite
          </h2>
          <p className="text-gray-400 text-center mb-12 max-w-lg mx-auto">
            Two powerful tools to ensure you're building what customers actually want
          </p>

          {/* Interactive Tab Navigation */}
          <div className="flex flex-col sm:flex-row gap-4 mb-12 justify-center">
            {features.map(feature => (
              <div
                key={feature.id}
                onClick={() => {
                  setActiveTab(feature.id);
                  setActiveStep(1);
                  setIsInteracting(true);
                  setTimeout(() => setIsInteracting(false), 10000);
                }}
                className={`px-6 py-4 rounded-lg transition-all duration-300 group relative overflow-hidden cursor-pointer ${
                  activeTab === feature.id
                    ? 'bg-white text-black'
                    : 'bg-black text-white border border-gray-800 hover:border-red-500'
                }`}
                role="button"
                tabIndex={0}
                aria-label={`Select ${feature.title}`}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    setActiveTab(feature.id);
                    setActiveStep(1);
                    setIsInteracting(true);
                    setTimeout(() => setIsInteracting(false), 10000);
                  }
                }}
              >
                <div className="flex items-center gap-3">
                  <div className={`${
                    activeTab === feature.id ? 'bg-red-500' : 'bg-gray-900'
                  } p-2 rounded-lg transition-all duration-300`}>
                    {feature.icon}
                  </div>
                  <div className="text-left">
                    <h3 className="font-semibold">{feature.title}</h3>
                    <p className={`text-sm ${activeTab === feature.id ? 'text-gray-700' : 'text-gray-400'}`}>
                      {feature.description.split(' ').slice(0, 4).join(' ')}...
                    </p>
                  </div>
                </div>

                {/* Fun interactive elements */}
                {activeTab === feature.id && (
                  <div className="absolute -right-1 -top-1">
                    <div className="bg-red-500 text-white text-xs px-2 py-1 rounded-bl-lg">
                      Active
                    </div>
                  </div>
                )}

                <div className={`absolute bottom-0 left-0 h-1 bg-red-500 transition-all duration-700 ${
                  activeTab === feature.id ? 'w-full' : 'w-0 group-hover:w-1/4'
                }`}></div>
              </div>
            ))}
          </div>

          {/* Main Content Area */}
          <div className="bg-black border border-gray-800 rounded-lg p-6 sm:p-8 transition-all duration-500 hover:border-red-500 relative">
            {/* Feature description */}
            <div className="mb-8">
              <p className="text-lg sm:text-xl mb-4">{currentFeature.description}</p>
              <div className="w-16 h-1 bg-red-500"></div>
            </div>

            {/* Interactive Steps */}
            <div className="mt-10">
              <h4 className="text-lg font-medium mb-6 flex items-center gap-2">
                <Zap size={18} className="text-red-500" />
                How it works:
              </h4>

              <div className="space-y-6 sm:space-y-0 sm:flex gap-4"
                   onMouseEnter={() => setIsInteracting(true)}
                   onMouseLeave={() => setIsInteracting(false)}>
                {currentFeature.steps.map((step, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      setActiveStep(index + 1);
                      setIsInteracting(true);
                      setTimeout(() => setIsInteracting(false), 10000);
                    }}
                    className={`relative cursor-pointer p-5 rounded-lg transition-all duration-300 ${
                      activeStep === index + 1
                        ? 'bg-white text-black transform scale-105 shadow-lg'
                        : 'bg-gray-900 hover:bg-gray-800'
                    }`}
                    role="button"
                    tabIndex={0}
                    aria-label={`Select step ${index + 1}`}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        setActiveStep(index + 1);
                        setIsInteracting(true);
                        setTimeout(() => setIsInteracting(false), 10000);
                      }
                    }}
                  >
                    <div className="absolute -top-3 -left-1">
                      <div className={`rounded-full w-6 h-6 flex items-center justify-center text-xs font-medium ${
                        activeStep === index + 1
                          ? 'bg-red-500 text-white'
                          : 'bg-gray-800 text-gray-400 border border-gray-700'
                      }`}>
                        {index + 1}
                      </div>
                    </div>

                    <h5 className={`font-semibold mb-2 ${
                      activeStep === index + 1 ? 'text-black' : 'text-white'
                    }`}>
                      {step.title}
                    </h5>

                    <p className={`text-sm ${
                      activeStep === index + 1 ? 'text-gray-700' : 'text-gray-400'
                    }`}>
                      {step.description}
                    </p>

                    {activeStep === index + 1 && (
                      <div className="absolute top-2 right-2">
                        <Check size={16} className="text-red-500" />
                      </div>
                    )}
                  </div>
                ))}
              </div>

              {/* Progress indicators */}
              <div className="flex justify-center mt-8 gap-2">
                {currentFeature.steps.map((_, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      setActiveStep(index + 1);
                      setIsInteracting(true);
                      setTimeout(() => setIsInteracting(false), 10000);
                    }}
                    className={`w-2 h-2 rounded-full transition-all duration-300 ${
                      activeStep === index + 1 ? 'bg-red-500 w-8' : 'bg-gray-700 hover:bg-gray-500'
                    }`}
                    role="button"
                    tabIndex={0}
                    aria-label={`Step ${index + 1}`}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        setActiveStep(index + 1);
                        setIsInteracting(true);
                        setTimeout(() => setIsInteracting(false), 10000);
                      }
                    }}
                  />
                ))}
              </div>
            </div>

            {/* CTA Button */}
            <div className="mt-10 flex justify-center">
  <div
    className="bg-red-500 hover:bg-red-600 text-white px-8 py-3 rounded-lg font-medium transition-all flex items-center gap-2 group cursor-pointer"
    role="button"
    tabIndex={0}
    aria-label={`Try ${currentFeature.title} Now`}
    onClick={() => {
      // Redirect to /signup
      window.location.href = '/signup';
    }}
    onKeyDown={(e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        // Redirect to /signup
        window.location.href = '/signup';
      }
    }}
  >
    Try {currentFeature.title} Now
    <Play size={16} className="group-hover:translate-x-1 transition-transform" />
  </div>
</div>

          </div>
        </div>
      </section>
    );
  };

  

// Interactive Demo Section
const InteractiveDemo = () => {
    const [activeTab, setActiveTab] = useState('reviews');
    const [typing, setTyping] = useState(false);
    const [text, setText] = useState("");
    const fullText = "Based on review analysis, 76% of users want dark mode. Priority recommendation: Implement system-wide dark theme in next sprint.";
  
    useEffect(() => {
      if (activeTab === 'reviews') {
        setText("");
        setTyping(true);
        let i = 0;
        const interval = setInterval(() => {
          setText(fullText.substring(0, i));
          i++;
          if (i > fullText.length) {
            clearInterval(interval);
            setTyping(false);
          }
        }, 30);
  
        return () => clearInterval(interval);
      }
    }, [activeTab]);
  
    return (
      <section className="py-8 px-4 border-t border-gray-800">
        <div className="max-w-xl mx-auto">
          <h2 className="text-3xl font-bold mb-4 text-center">See it in action</h2>
          <p className="text-lg opacity-80 text-center mb-8 max-w-md mx-auto">
            Experience how Redstone AI transforms product validation
          </p>
  
          <div className="border border-gray-800 bg-black rounded-lg overflow-hidden">
            <div className="flex border-b border-gray-800">
              <button
                className={`px-4 py-2 flex-1 ${activeTab === 'reviews' ? 'bg-gray-800' : ''}`}
                onClick={() => setActiveTab('reviews')}
              >
                Review Analysis
              </button>
              <button
                className={`px-4 py-2 flex-1 ${activeTab === 'personas' ? 'bg-gray-800' : ''}`}
                onClick={() => setActiveTab('personas')}
              >
                AI Personas
              </button>
            </div>
  
            <div className="p-6">
              {activeTab === 'reviews' ? (
                <div className="min-h-48">
                  <div className="flex gap-2 mb-4">
                    <div className="w-1 bg-red-600"></div>
                    <div>
                      <h3 className="font-bold text-lg mb-1">AI Review Analysis</h3>
                      <p className="opacity-70 text-sm">Scanning 1,432 reviews across 5 competitor products...</p>
                    </div>
                  </div>
                  <div className="pl-4 font-mono text-sm">
                    {text}
                    {typing && <span className="animate-pulse">|</span>}
                  </div>
                </div>
              ) : (
                <div className="min-h-48">
                  <div className="flex gap-2 mb-4">
                    <div className="w-1 bg-red-600"></div>
                    <div>
                      <h3 className="font-bold text-lg mb-1">AI CEO Simulation</h3>
                      <p className="opacity-70 text-sm">Real-time feedback from an AI CEO persona</p>
                    </div>
                  </div>
                  <div className="border border-gray-800 p-3 mb-3 rounded">
                    <p className="text-xs opacity-70">You:</p>
                    <p className="text-sm">Our product offers a unique dark mode feature that significantly enhances user experience and engagement...</p>
                  </div>
                  <div className="border border-gray-800 p-3 rounded bg-gray-900">
                    <p className="text-xs opacity-70">AI CEO:</p>
                    <p className="text-sm">This sounds promising. Dark mode has been a top request from our users. Let's discuss how we can integrate this into our platform to boost user satisfaction and retention.</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  };
  

// Pricing Section

const Pricing = () => {
    const [isHovered, setIsHovered] = useState(false);
    const navigate = useNavigate();
  
    const handleRedirect = () => {
      navigate('/signup');
    };
  
    return (
      <section className="py-24 px-8 border-t border-gray-800">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-4xl font-bold mb-4">Simple, transparent pricing</h2>
          <p className="text-xl opacity-80 mb-16 max-w-2xl mx-auto">
            Start for free, upgrade as you grow
          </p>
  
          <div className="grid md:grid-cols-2 gap-8 max-w-3xl mx-auto">
            <div className="border border-gray-800 p-8 text-center relative overflow-hidden flex flex-col">
              <h3 className="text-2xl font-bold mb-2">Free Starter</h3>
              <p className="opacity-70 mb-6">Perfect for testing the waters</p>
              <div className="text-4xl font-bold mb-6">15 credits</div>
              <ul className="space-y-3 mb-8 flex-grow">
                <li className="flex items-center gap-2 justify-center">
                  <Check size={18} className="text-green-500" /> Full feature access
                </li>
                <li className="flex items-center gap-2 justify-center">
                  <Check size={18} className="text-green-500" /> Both validation tools
                </li>
                <li className="flex items-center gap-2 justify-center">
                  <Check size={18} className="text-green-500" /> No credit card required
                </li>
              </ul>
              <div
                className="w-full border border-white py-3 hover:bg-white hover:text-black transition-colors cursor-pointer mt-auto"
                onClick={handleRedirect}
              >
                Start for free
              </div>
            </div>
  
            <div
              className="border border-gray-800 p-8 text-center relative overflow-hidden flex flex-col"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <div className="absolute -right-10 -top-10 bg-red-600 w-40 h-40 rotate-45 transform origin-bottom-left">
                <span className="absolute bottom-0 left-5 text-sm font-bold">EARLY BIRD</span>
              </div>
              <h3 className="text-2xl font-bold mb-2">Pro</h3>
              <p className="opacity-70 mb-6">For serious product validation</p>
              <div className="flex items-center mb-6 justify-center">
                <div className="text-4xl font-bold">$2.89</div>
                <div className="ml-2 opacity-70 line-through">$5.00</div>
              </div>
              <ul className="space-y-3 mb-8 flex-grow">
                <li className="flex items-center gap-2 justify-center">
                  <Check size={18} className="text-green-500" /> 200 credits
                </li>
                <li className="flex items-center gap-2 justify-center">
                  <Check size={18} className="text-green-500" /> Priority processing
                </li>
                <li className="flex items-center gap-2 justify-center">
                  <Check size={18} className="text-green-500" /> Full feature access
                </li>
                <li className="flex items-center gap-2 justify-center">
                  <Check size={18} className="text-green-500" /> Both validation tools
                </li>
                <li className="flex items-center gap-2 justify-center">
                  <Check size={18} className="text-green-500" /> No credit card required
                </li>
              </ul>
              <div
                className="w-full bg-white text-black py-3 relative overflow-hidden group cursor-pointer mt-auto"
                style={{
                  transform: isHovered ? 'scale(1.03)' : 'scale(1)',
                  transition: 'transform 0.3s ease'
                }}
                onClick={handleRedirect}
              >
                <span>Get early bird offer</span>
                <div
                  className="absolute inset-0 bg-red-600 transform origin-left scale-x-0 group-hover:scale-x-100 transition-transform"
                  style={{
                    transitionDuration: '0.5s'
                  }}
                ></div>
               
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };

// Interactive Element
const InteractiveElement = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isVisible, setIsVisible] = useState(false);
  
  useEffect(() => {
    const handleMouseMove = (e) => {
      setPosition({ x: e.clientX, y: e.clientY });
      setIsVisible(true);
    };
    
    const handleMouseLeave = () => {
      setIsVisible(false);
    };
    
    window.addEventListener('mousemove', handleMouseMove);
    document.body.addEventListener('mouseleave', handleMouseLeave);
    
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      document.body.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);
  
  return (
    <div 
      className="fixed pointer-events-none mix-blend-difference"
      style={{
        left: `${position.x}px`,
        top: `${position.y}px`,
        transform: 'translate(-50%, -50%)',
        opacity: isVisible ? 1 : 0,
        transition: 'opacity 0.3s ease, width 0.2s ease, height 0.2s ease',
      }}
    >
      <div className="bg-white rounded-full w-6 h-6"></div>
    </div>
  );
};

// Footer
const Footer = () => {
    return (
      <footer className="py-12 px-8 border-t border-gray-800">
        <div className="max-w-5xl mx-auto flex flex-col md:flex-row justify-between items-center">
          <div className="mb-6 md:mb-0">
            <div className="text-xl font-bold flex items-center">
              <span>Red</span>
              <span className="bg-red-600 px-1">stone</span>
              <span> AI</span>
            </div>
            <p className="opacity-60 text-sm mt-2">Ship what customers actually want</p>
          </div>
          <div className="flex gap-8">
            <a href="/contact" className="hover:text-red-600 transition-colors">Support</a>
          </div>
        </div>
        <div className="max-w-5xl mx-auto mt-12 text-center text-sm opacity-60">
          © 2025 Redstone AI. All rights reserved. Built by Metabees AI Labs.
        </div>
      </footer>
    );
  };
  

// Main Component
const RedLanding = () => {
  return (
    <div className="bg-black text-white min-h-screen font-sans">
      <InteractiveElement />
      <Hero />
      <Features />
      <InteractiveDemo />
      <Pricing />
      <Footer />
    </div>
  );
};

export default RedLanding;