import React from "react";
import Axios from 'axios';
import './Published.css';

export default function Published() {
  const handleViewLinkClick = () => {
    // Retrieve fileNameWithoutExtension from session storage
    const fileNameWithoutExtension = sessionStorage.getItem('fileNameWithoutExtension');
    
    if (fileNameWithoutExtension) {
      const vrurl = `https://www.metabees.in/vr/${fileNameWithoutExtension}`;
      const arurl = `https://www.metabees.in/ar/${fileNameWithoutExtension}`;
      // Display the URL in an alert
      alert(`View the object at: ${vrurl} for VR and ${arurl} for AR`);
    } else {
      alert('File name not found in session storage.');
    }
  };

  return (
    <div className="notifications-container">
      <div className="success">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="success-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path>
            </svg>
          </div>
          <div className="success-prompt-wrap">
            <p className="success-prompt-heading">Object uploaded</p>
            <div className="success-prompt-prompt">
              <p>Your object is now up and running on the internet.</p>
            </div>
            <div className="success-button-container">
              <button type="button" className="success-button-main" onClick={handleViewLinkClick}>View Link</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
