import React, { useState, useEffect, useRef } from 'react';

// --- Animation Utility Hook ---
const useOnScreen = (options = { threshold: 0.1 }) => {
  const ref = useRef();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIsVisible(true);
        observer.unobserve(entry.target);
      }
    }, options);

    const currentRef = ref.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [options]); // Added options to dependency array

  const hookRef = (node) => {
    ref.current = node;
  };

  return [hookRef, isVisible];
};


// --- Shared Section Component ---
const FeatureSection = ({ id, title, description, visualContent, textPosition = 'left', children }) => {
    const [ref, isVisible] = useOnScreen();
    const textOrder = textPosition === 'left' ? 'md:order-1' : 'md:order-2';
    const visualOrder = textPosition === 'left' ? 'md:order-2' : 'md:order-1';
    const textAnimation = textPosition === 'left' ? 'opacity-0 -translate-x-5' : 'opacity-0 translate-x-5';
    const visualAnimation = textPosition === 'left' ? 'opacity-0 translate-x-5' : 'opacity-0 -translate-x-5';

    return (
        <section id={id} ref={ref} className="bg-black text-white py-20 md:py-28 border-t border-gray-800/50 overflow-hidden relative">
             <div className="container mx-auto px-4 relative z-10">
                <div className="flex flex-col md:flex-row items-center gap-10 md:gap-16">
                    {/* Text Content */}
                    <div className={`md:w-1/2 ${textOrder} transition-all duration-1000 ease-out ${isVisible ? 'opacity-100 translate-x-0 translate-y-0' : textAnimation}`}>
                        <h2 className="text-2xl md:text-3xl font-bold mb-4 text-gray-100">{title}</h2>
                        <p className="text-base text-gray-400 mb-6">{description}</p>
                        <div className="prose prose-sm prose-invert text-gray-400 marker:text-purple-400">
                            {children}
                        </div>
                    </div>
                    {/* Visual Content */}
                    <div className={`md:w-1/2 ${visualOrder} transition-all duration-1000 ease-out delay-200 ${isVisible ? 'opacity-100 translate-x-0 translate-y-0' : visualAnimation}`}>
                        {visualContent}
                    </div>
                </div>
            </div>
        </section>
    );
};


// --- Header Component ---
const Header = () => {
  return (
    <header className="w-full bg-black text-white py-3 sticky top-0 z-50 border-b border-gray-800 backdrop-blur-md bg-opacity-80">
      <div className="container mx-auto px-4 flex justify-between items-center">
        <div className="flex items-center">
          <svg className="w-7 h-7 mr-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2L2 7L12 12L22 7L12 2Z" fill="#8B5CF6" stroke="#8B5CF6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2 17L12 22L22 17" stroke="#8B5CF6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2 12L12 17L22 12" stroke="#8B5CF6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          <span className="text-lg font-bold">Echidna AI</span>
        </div>
        <nav className="hidden md:flex">
          <ul className="flex space-x-5 text-sm">
            <li><a href="#feature-knowledge" className="hover:text-purple-400 transition-colors duration-200">Knowledge</a></li>
            <li><a href="#feature-integration" className="hover:text-purple-400 transition-colors duration-200">Integration</a></li>
            <li><a href="#feature-api" className="hover:text-purple-400 transition-colors duration-200">Tools</a></li>
             <li><a href="#demos" className="hover:text-purple-400 transition-colors duration-200">Demos</a></li>
            <li><a href="#workflows" className="hover:text-purple-400 transition-colors duration-200">Workflows</a></li>
             <li><a href="#pricing" className="hover:text-purple-400 transition-colors duration-200">Models & Pricing</a></li>
             <li><a href="#how-it-works" className="hover:text-purple-400 transition-colors duration-200">How It Works</a></li>
          </ul>
        </nav>
        <div className="flex items-center space-x-3">
          <a href="/signup" className="text-sm text-gray-300 hover:text-white transition-colors duration-200">Sign up</a> {/* Updated Link */}
          <a href="https://calendly.com/manas-o4af/30min" target="_blank" rel="noopener noreferrer" className="bg-purple-600 hover:bg-purple-700 px-4 py-1.5 rounded-md text-xs font-medium transition-colors duration-200">Book a demo</a> {/* Updated Link */}
        </div>
      </div>
    </header>
  );
};

// --- Hero Component ---
const Hero = () => {
    const [refLeft, isVisibleLeft] = useOnScreen({ threshold: 0.1 });
    const [refRight, isVisibleRight] = useOnScreen({ threshold: 0.1 });

  return (
    <section className="bg-black text-white pt-24 pb-16 md:pt-32 md:pb-24 relative overflow-hidden">
        <div className="absolute inset-0 z-0 opacity-20 animate-gradient-slow">
            <div className="absolute inset-0 bg-gradient-to-br from-purple-900 via-indigo-900 to-black"></div>
            <div className="absolute inset-0 bg-gradient-to-tl from-black via-purple-900 to-indigo-900"></div>
        </div>
        <div className="container mx-auto px-4 flex flex-col md:flex-row items-center relative z-10">
            <div ref={refLeft} className={`md:w-1/2 mb-10 md:mb-0 md:pr-10 transition-all duration-1000 ease-out ${isVisibleLeft ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-5'}`}>
                <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-5 leading-tight tracking-tight">
                    Build Custom AI Within Seconds
                </h1>
                <p className="text-base lg:text-lg text-gray-300 mb-8">
                    Echidna AI foundry empowers you to create, deploy, and manage intelligent assistants tailored to your business needs, powered by your data.
                </p>
                <div className="flex space-x-4">
                    <a href="/signup" className="bg-purple-600 hover:bg-purple-700 px-5 py-2.5 rounded-lg font-semibold text-sm transition-colors duration-200 shadow-lg shadow-purple-600/30">Get Started Free</a> {/* Updated Link */}
                    <a href="#feature-knowledge" className="border border-gray-700 hover:border-purple-400 hover:text-purple-400 bg-black/20 hover:bg-purple-900/20 px-5 py-2.5 rounded-lg font-semibold text-sm transition-colors duration-200 backdrop-blur-sm">Explore Features</a>
                </div>
            </div>
            <div ref={refRight} className={`md:w-1/2 flex justify-center transition-all duration-1000 ease-out delay-200 ${isVisibleRight ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-5'}`}>
                <div className="bg-gray-950/80 backdrop-blur-md p-5 rounded-lg w-full max-w-md shadow-xl border border-gray-700/50">
                    <div className="flex items-center mb-4">
                        <div className="w-2.5 h-2.5 rounded-full bg-red-500 mr-1.5"></div>
                        <div className="w-2.5 h-2.5 rounded-full bg-yellow-500 mr-1.5"></div>
                        <div className="w-2.5 h-2.5 rounded-full bg-green-500"></div>
                    </div>
                    <div className="font-mono text-xs">
                        <div className="text-green-500">// Initialize your AI assistant</div>
                        <div className="text-gray-300">const assistant = new <span className="text-purple-400">EchidnaAI</span>({`{`}</div>
                        <div className="text-gray-300 ml-4">knowledge: [<span className="text-yellow-400">'./docs/'</span>, <span className="text-yellow-400">'https://docs.echidna.ai'</span>],</div>
                        <div className="text-gray-300 ml-4">model: <span className="text-yellow-400">'echidna-v4-turbo'</span>,</div>
                        <div className="text-gray-300 ml-4">tools: [<span className="text-blue-400">crmLookupTool</span>, <span className="text-blue-400">supportTicketTool</span>],</div>
                        <div className="text-gray-300 ml-4">persona: <span className="text-yellow-400">'expert support agent'</span>,</div>
                        <div className="text-gray-300">{`});`}</div>
                        <div className="mt-2 text-gray-300">assistant.<span className="text-blue-400">run</span>(<span className="text-yellow-400">'How to reset password?'</span>);</div>
                        <div className="mt-2 text-green-500">// =&gt; AI responds instantly with relevant info</div>
                    </div>
                </div>
            </div>
        </div>
        <style jsx global>{`
            @keyframes gradient-animation { 0% { background-position: 0% 50%; } 50% { background-position: 100% 50%; } 100% { background-position: 0% 50%; } }
            .animate-gradient-slow { background-size: 200% 200%; animation: gradient-animation 15s ease infinite; }
        `}</style>
    </section>
  );
};

// --- TrustedBy Component ---
const TrustedBy = () => {
    const [ref, isVisible] = useOnScreen({ threshold: 0.1 });
  return (
    <section ref={ref} className={`bg-black py-12 border-y border-gray-800/50 transition-opacity duration-1000 ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
      <div className="container mx-auto px-4 text-center">
        <p className="text-gray-500 mb-6 uppercase tracking-wider text-xs">Powering experiences for innovative teams</p>
        <div className="flex flex-wrap justify-center items-center gap-6 md:gap-10 opacity-60">
          {['AI21labs', 'tl;dv', 'copy.ai', 'Ema', 'Brevan', 'yurts', 'you.com', 'Perplexity'].map((company, index) => (
             <div key={index}
                  className={`text-gray-400 font-medium text-base italic transition-all duration-500 ease-out hover:opacity-100 hover:text-white ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-3'}`}
                  style={{ transitionDelay: `${index * 100}ms` }}>
               {company}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

// --- Feature Sections ---

// 1. Knowledge Base Section
const FeatureKnowledgeBase = () => {
    const visual = (
        <div className="bg-gray-950 p-5 rounded-lg border border-gray-800/50 shadow-lg">
            <div className="flex items-stretch justify-center space-x-3 md:space-x-4">
                {/* Files Input */}
                <div className="flex flex-col items-center text-center p-2 bg-gray-900/50 rounded border border-gray-700/50 flex-1">
                    <svg className="w-8 h-8 text-blue-400 mb-1" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"></path></svg>
                    <span className="text-xs font-medium text-gray-300 mb-1">Files</span>
                    <span className="text-xs text-gray-400">PDF, DOCX</span>
                    <span className="text-xs text-gray-400">CSV, JSON...</span>
                </div>
                 {/* Web Links Input */}
                 <div className="flex flex-col items-center text-center p-2 bg-gray-900/50 rounded border border-gray-700/50 flex-1">
                    <svg className="w-8 h-8 text-green-400 mb-1" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"></path></svg>
                     <span className="text-xs font-medium text-gray-300 mb-1">Web Links</span>
                     <span className="text-xs text-gray-400">Docs Sites</span>
                     <span className="text-xs text-gray-400">Blog Posts...</span>
                </div>

                {/* Arrow */}
                <div className="flex items-center justify-center px-1 md:px-2">
                    <svg className="w-6 h-6 md:w-8 md:h-8 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                </div>

                {/* Echidna Processing */}
                <div className="flex flex-col items-center justify-center text-center p-2 bg-purple-900/20 rounded border border-purple-800/50 flex-1">
                   <svg className="w-8 h-8 text-purple-400 mb-1" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-14v4m-2-2h4m2 10.5a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm-10.47.15a4.5 4.5 0 10-9 0 4.5 4.5 0 009 0zm19.94-4.3a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm-10.47 8.5a4.5 4.5 0 10-9 0 4.5 4.5 0 009 0z"></path></svg>
                    <span className="text-xs font-medium text-gray-300 mb-1">Processing</span>
                    <span className="text-xs text-gray-400">Indexing &</span>
                    <span className="text-xs text-gray-400">Vectorizing</span>
                </div>
            </div>
            <p className="text-xs text-gray-500 text-center mt-4">Connect diverse data sources including live web content.</p>
        </div>
    );

    return (
        <FeatureSection
            id="feature-knowledge"
            title="Flexible & Live Knowledge Base"
            description="Fuel your AI with your specific domain expertise. Upload documents, scrape websites for live data, or connect databases to create a comprehensive, always up-to-date knowledge base."
            visualContent={visual}
            textPosition="left"
        >
            <ul>
                <li>Upload files: PDF, DOCX, TXT, CSV, JSON, and more.</li>
                <li>Add web links (docs, blogs, articles) for **live data scraping**.</li>
                <li>Connect databases or other APIs as real-time sources (via API Connect feature).</li>
                <li>Efficient indexing and vectorization for fast, relevant retrieval.</li>
                <li>Automatic syncing options for web sources to keep knowledge current.</li>
            </ul>
        </FeatureSection>
    );
};

// 2. Easy Integration Section
const FeatureEasyIntegration = () => {
    const visual = (
        <div className="bg-gray-950 p-5 rounded-lg border border-gray-800/50 shadow-lg font-mono text-xs space-y-3">
            <div>
                <span className="text-purple-400">{'// 1. Embed Widget'}</span>
                <div className="mt-1 p-2 bg-black rounded border border-gray-700/50">
                    <div className="text-gray-300">&lt;script src=<span className="text-yellow-400">"//cdn.echidna.ai/widget.js"</span>&gt;&lt;/script&gt;</div>
                    <div className="text-gray-300">&lt;script&gt;</div>
                    <div className="text-gray-300 ml-2">EchidnaAI.init({`{`} apiKey: <span className="text-yellow-400">'pk_...'</span> {`}});`}</div>
                    <div className="text-gray-300">&lt;/script&gt;</div>
                </div>
            </div>
            <div>
                <span className="text-purple-400">{'// 2. Use API (Example: Node.js)'}</span>
                 <div className="mt-1 p-2 bg-black rounded border border-gray-700/50">
                    <div className="text-gray-300"><span className="text-blue-400">import</span> Echidna <span className="text-blue-400">from</span> <span className="text-yellow-400">'@echidna-ai/sdk'</span>;</div>
                    <div className="text-gray-300">const echidna = Echidna({`{`} apiKey: <span className="text-yellow-400">'sk_...'</span> {`}});`}</div>
                    <div className="text-gray-300">const response = <span className="text-blue-400">await</span> echidna.chat(<span className="text-yellow-400">'...'</span>);</div>
                 </div>
            </div>
             <div>
                <span className="text-purple-400">{'// 3. Standalone Page'}</span>
                 <div className="mt-1 p-2 bg-black rounded border border-gray-700/50">
                    <div className="text-gray-300">Generate a shareable, branded page</div>
                     <div className="text-gray-300">from the dashboard with <span className="text-blue-400">one click</span>.</div>
                 </div>
            </div>
        </div>
    );

     return (
        <FeatureSection
            id="feature-integration"
            title="Seamless Integration Options"
            description="Integrate your custom AI into your existing workflows and products effortlessly. Choose the method that best suits your technical needs."
            visualContent={visual}
            textPosition="right" // Alternate layout
        >
            <ul>
                <li>Embed a customizable chat widget with minimal code.</li>
                <li>Utilize our comprehensive REST API for full backend control.</li>
                <li>Leverage official SDKs (Node.js, Python available).</li>
                <li>Generate a shareable, branded standalone page instantly.</li>
                 <li>Works with React, Vue, Angular, Webflow, or any web tech.</li>
            </ul>
         </FeatureSection>
    );
};

// 3. Connect Your Own API Section
const FeatureConnectAPI = () => {
    const visual = (
        <div className="bg-gray-950 p-5 rounded-lg border border-gray-800/50 shadow-lg">
            <div className="flex flex-col md:flex-row items-center justify-between gap-4">
                 {/* AI */}
                <div className="flex flex-col items-center text-center">
                    <div className="p-3 bg-purple-600/20 rounded-full mb-2 group hover:scale-110 transition-transform duration-200">
                        <svg className="w-10 h-10 text-purple-400 group-hover:animate-pulse" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 2L2 7L12 12L22 7L12 2Z" fill="#8B5CF6" stroke="#8B5CF6" strokeWidth="1.5"/>
                            <path d="M2 17L12 22L22 17" stroke="#8B5CF6" strokeWidth="1.5"/>
                            <path d="M2 12L12 17L22 12" stroke="#8B5CF6" strokeWidth="1.5"/>
                        </svg>
                    </div>
                    <span className="text-sm font-semibold text-gray-200">Echidna AI</span>
                    <span className="text-xs text-gray-400">Needs Action/Data</span>
                </div>

                 {/* Connection Arrows */}
                <div className="flex flex-col items-center my-2 md:my-0 text-gray-600">
                     <svg className="w-6 h-6 " fill="none" stroke="currentColor" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8l4 4m0 0l-4 4m4-4H3"></path></svg>
                     <span className="text-xs my-1">Calls Tool</span>
                     <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16l-4-4m0 0l4-4m-4 4h18"></path></svg>
                </div>

                {/* Your API */}
                 <div className="flex flex-col items-center text-center">
                    <div className="p-3 bg-gray-700/30 rounded-full mb-2 border border-gray-700/50 group hover:scale-110 transition-transform duration-200">
                         <svg className="w-10 h-10 text-gray-400 group-hover:text-purple-400 transition-colors" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path></svg>
                     </div>
                    <span className="text-sm font-semibold text-gray-200">Your API</span>
                     <span className="text-xs text-gray-400">(e.g., CRM lookup)</span>
                </div>

                {/* Connection Arrows */}
                 <div className="flex flex-col items-center my-2 md:my-0 text-gray-600">
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8l4 4m0 0l-4 4m4-4H3"></path></svg>
                     <span className="text-xs my-1">Interacts</span>
                     <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16l-4-4m0 0l4-4m-4 4h18"></path></svg>
                </div>

                 {/* External Service */}
                 <div className="flex flex-col items-center text-center">
                    <div className="p-3 bg-gray-700/30 rounded-full mb-2 border border-gray-700/50 group hover:scale-110 transition-transform duration-200">
                        <svg className="w-10 h-10 text-gray-400 group-hover:text-purple-400 transition-colors" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"></path></svg>
                     </div>
                    <span className="text-sm font-semibold text-gray-200">Your Systems</span>
                     <span className="text-xs text-gray-400">(Database, CRM)</span>
                </div>
            </div>
             <p className="text-xs text-gray-500 text-center mt-4">Give your AI assistant superpowers to interact with your world.</p>
        </div>
    );

    return (
        <FeatureSection
            id="feature-api"
            title="Connect Your APIs as Tools"
            description="Empower your AI assistant to perform actions and retrieve real-time data by connecting it to your existing APIs. Define custom tools effortlessly using OpenAPI specs or simple configuration."
            visualContent={visual}
            textPosition="left"
        >
            <ul>
                <li>Define tools using standard OpenAPI (Swagger) specifications.</li>
                <li>Enable actions like booking appointments, processing orders, or updating CRM records.</li>
                <li>Securely handles authentication (OAuth, API Keys) and data transfer.</li>
                <li>The AI intelligently decides *when* and *how* to use the tools you provide.</li>
                 <li>Build sophisticated workflows combining knowledge and actions.</li>
            </ul>
        </FeatureSection>
    );
};

// 4. Chat Analysis Section
const FeatureChatAnalysis = () => {
    const visual = (
        <div className="bg-gray-950 p-5 rounded-lg border border-gray-800/50 shadow-lg">
           <h4 className="text-sm font-semibold text-gray-300 mb-3 text-center">Chat Insights Example</h4>
            <div className="grid grid-cols-2 gap-3">
                {/* Topics */}
                <div className="bg-gray-900 p-3 rounded border border-gray-700/50 hover:border-purple-600/50 transition-colors">
                    <h5 className="text-xs font-medium text-gray-400 mb-1.5">Top Topics</h5>
                     <ul className="text-xs text-gray-400 space-y-1">
                        <li className="flex justify-between"><span>Pricing</span> <span className="text-purple-400">35%</span></li>
                        <li className="flex justify-between"><span>Integration</span> <span className="text-purple-400">22%</span></li>
                        <li className="flex justify-between"><span>Feature X</span> <span className="text-purple-400">18%</span></li>
                         <li className="flex justify-between"><span>Other</span> <span className="text-gray-500">25%</span></li>
                    </ul>
                </div>
                 {/* Sentiment */}
                <div className="bg-gray-900 p-3 rounded border border-gray-700/50 hover:border-purple-600/50 transition-colors">
                     <h5 className="text-xs font-medium text-gray-400 mb-1.5">Sentiment</h5>
                     <div className="h-16 flex items-center justify-center">
                         <span className="text-2xl font-bold text-green-400">92%</span>
                         <span className="text-xs text-gray-400 ml-1">Positive</span>
                     </div>
                </div>
                 {/* Resolution */}
                 <div className="bg-gray-900 p-3 rounded border border-gray-700/50 hover:border-purple-600/50 transition-colors">
                     <h5 className="text-xs font-medium text-gray-400 mb-1.5">Resolution Rate</h5>
                    <div className="h-16 flex items-center justify-center">
                         <span className="text-2xl font-bold text-blue-400">85%</span>
                          <span className="text-xs text-gray-400 ml-1">Resolved</span>
                     </div>
                </div>
                 {/* Unresolved */}
                 <div className="bg-gray-900 p-3 rounded border border-gray-700/50 hover:border-purple-600/50 transition-colors">
                     <h5 className="text-xs font-medium text-gray-400 mb-1.5">Needs Review</h5>
                     <ul className="text-xs text-gray-400 space-y-1">
                        <li>API Limit Question</li>
                        <li>Login Issue (User ID: 5)</li>
                        <li>Billing Discrepancy</li>
                    </ul>
                </div>
            </div>
             <p className="text-xs text-gray-500 text-center mt-4">Understand user interactions to improve AI performance and knowledge.</p>
        </div>
    );
    return (
        <FeatureSection
            id="feature-analysis"
            title="Analyze and Improve with Chat Insights"
            description="Gain valuable insights from user conversations. Understand common questions, identify knowledge gaps, track sentiment, and continuously refine your AI assistant's performance."
            visualContent={visual}
            textPosition="right" // Alternate layout
        >
            <ul>
                <li>Identify frequently asked questions and emerging topics automatically.</li>
                <li>Pinpoint areas where the AI struggles or lacks specific knowledge.</li>
                <li>Track conversation resolution rates and user satisfaction signals.</li>
                <li>Use analytics to guide knowledge base updates and configuration tweaks.</li>
                 <li>Export conversation data for deeper analysis in external tools.</li>
            </ul>
        </FeatureSection>
    );
};

// 5. Admin Dashboard Section
const FeatureAdminDashboard = () => {
    const visual = (
         // Simplified Dashboard Mockup
         <div className="bg-gray-950 p-1.5 rounded-lg border border-gray-800/50 shadow-lg aspect-video overflow-hidden hover:shadow-purple-900/20 transition-shadow">
             <div className="flex h-full">
                 {/* Sidebar */}
                <div className="w-1/5 bg-gray-900/50 p-2 border-r border-gray-800/50">
                    <div className="h-3 w-1/2 bg-purple-500 rounded-sm mb-3"></div>
                     <div className="space-y-1.5">
                        <div className="h-2 w-full bg-gray-700 rounded-sm"></div>
                        <div className="h-2 w-3/4 bg-gray-700 rounded-sm opacity-50"></div>
                        <div className="h-2 w-full bg-gray-700 rounded-sm opacity-50"></div>
                        <div className="h-2 w-2/3 bg-gray-700 rounded-sm opacity-50"></div>
                         <div className="h-2 w-full bg-gray-700 rounded-sm opacity-50 mt-4"></div>
                         <div className="h-2 w-3/4 bg-gray-700 rounded-sm opacity-50"></div>
                    </div>
                 </div>
                 {/* Main Content Area */}
                <div className="w-4/5 p-2 flex flex-col">
                    {/* Header */}
                     <div className="h-3 w-1/3 bg-gray-700 rounded-sm mb-3 flex-shrink-0"></div>
                    {/* Cards */}
                     <div className="grid grid-cols-2 gap-2 mb-2 flex-shrink-0">
                        <div className="h-8 bg-gray-900 rounded border border-gray-700/50"></div>
                         <div className="h-8 bg-gray-900 rounded border border-gray-700/50"></div>
                    </div>
                    {/* Table/List */}
                     <div className="flex-grow bg-gray-900 rounded border border-gray-700/50 p-1 space-y-1 overflow-hidden">
                        <div className="h-2 w-full bg-gray-700 rounded-sm"></div>
                         <div className="h-2 w-full bg-gray-700 rounded-sm opacity-60"></div>
                         <div className="h-2 w-full bg-gray-700 rounded-sm opacity-60"></div>
                         <div className="h-2 w-5/6 bg-gray-700 rounded-sm opacity-60"></div>
                         <div className="h-2 w-full bg-gray-700 rounded-sm opacity-60"></div>
                    </div>
                 </div>
            </div>
        </div>
    );
    return (
        <FeatureSection
            id="feature-dashboard"
            title="Comprehensive Admin Dashboard"
            description="Manage all your AI assistants, monitor performance, track usage, and configure settings from a centralized, intuitive dashboard."
            visualContent={visual}
            textPosition="left"
        >
            <ul>
                <li>Oversee multiple AI assistants and their specific configurations.</li>
                <li>Monitor conversation volume, API usage patterns, and associated costs.</li>
                <li>Manage knowledge sources (files, web links) and trigger re-syncs.</li>
                <li>Review conversation logs with robust search and filtering (PII masking available).</li>
                <li>Control team access with role-based permissions.</li>
            </ul>
         </FeatureSection>
    );
};

// 6. Lightning Fast Deployment Section
const FeatureLightningFast = () => {
    const visual = (
        <div className="bg-gray-950 p-5 rounded-lg border border-gray-800/50 shadow-lg flex flex-col items-center justify-center aspect-[4/3] md:aspect-square">
            <div className="relative w-24 h-24 md:w-32 md:h-32 group">
                 {/* Animated Circle */}
                <svg className="w-full h-full" viewBox="0 0 100 100">
                     <circle cx="50" cy="50" r="45" stroke="#4B5563" strokeWidth="5" fill="none" />
                      <circle
                         cx="50" cy="50" r="45"
                         stroke="#8B5CF6" strokeWidth="5" fill="none"
                         strokeDasharray="283" strokeDashoffset="283" /* Initial state for animation */
                         strokeLinecap="round"
                         transform="rotate(-90 50 50)"
                         className="animate-progress-fast group-hover:stroke-purple-400 transition-colors"
                     />
                </svg>
                 {/* Icon inside */}
                <div className="absolute inset-0 flex items-center justify-center">
                     <svg className="w-12 h-12 md:w-16 md:h-16 text-purple-400 group-hover:scale-110 transition-transform duration-300" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M13 10V3L4 14h7v7l9-11h-7z"></path></svg>
                </div>
            </div>
            <p className="text-sm text-gray-300 font-semibold mt-4">Deploy in Seconds</p>
            <p className="text-xs text-gray-500 text-center mt-1">Go from configuration to live AI assistant instantly.</p>
             <style jsx>{`
                @keyframes progress-fast { from { stroke-dashoffset: 283; } to { stroke-dashoffset: 0; } }
                .animate-progress-fast { animation: progress-fast 1s ease-out forwards; }
             `}</style>
        </div>
    );
     return (
        <FeatureSection
            id="feature-fast"
            title="Lightning-Fast Deployment & Iteration"
            description="Don't wait weeks or months. Configure, deploy, and update your AI assistants in seconds through our streamlined platform. Get value faster."
            visualContent={visual}
            textPosition="right" // Alternate layout
        >
             <ul>
                <li>Deploy new assistants or push updates to existing ones instantly.</li>
                <li>Changes to knowledge, tools, or personality reflect immediately.</li>
                <li>Simple integration methods minimize developer onboarding time.</li>
                <li>Focus on building great AI experiences, not complex infrastructure pipelines.</li>
                 <li>Rapidly test and iterate on different AI configurations.</li>
            </ul>
        </FeatureSection>
    );
};

// --- Demo Agents Section ---
const DemoAgentCard = ({ icon, title, description, link, isVisible, delay }) => (
     <div className={`bg-gray-950 rounded-lg border border-gray-800/50 p-5 text-center transition-all duration-500 ease-out hover:border-purple-600/50 hover:shadow-xl hover:-translate-y-1 ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
          style={{ transitionDelay: `${delay}ms` }}
     >
         <div className="inline-flex items-center justify-center p-3 bg-purple-600/10 border border-purple-800/50 rounded-full mb-4 text-purple-400">
             {icon}
         </div>
         <h4 className="text-base font-semibold text-gray-100 mb-2">{title}</h4>
         <p className="text-xs text-gray-400 mb-4">{description}</p>
         <a href={link}
            target="_blank" // Open demos in new tab if they are external/separate pages
            rel="noopener noreferrer"
            className="inline-block bg-purple-600 hover:bg-purple-700 text-white text-xs font-medium px-4 py-1.5 rounded-md transition-colors"
         >
             Try Demo
        </a>
     </div>
 );

const DemoAgents = () => {
     const [ref, isVisible] = useOnScreen({ threshold: 0.05 });
     const demos = [
         {
             icon: <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"></path></svg>,
             title: "Customer Support Agent",
             description: "Answers product questions and guides users through troubleshooting based on documentation.",
             link: "/demo/support-agent" // Placeholder link
         },
         {
             icon: <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>,
             title: "Sales Assistant",
             description: "Qualifies leads, answers pricing inquiries, and summarizes CRM data on request.",
             link: "/demo/sales-assistant" // Placeholder link
         },
         {
            icon: <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2zM12 9v4m0 4h.01"></path></svg>, // Doc search icon
             title: "Documentation Search",
             description: "Provides accurate answers and code snippets by searching technical documentation.",
             link: "/demo/doc-search" // Placeholder link
         },
     ];

    return (
        <section id="demos" ref={ref} className="bg-black text-white py-20 md:py-28 border-t border-gray-800/50">
            <div className="container mx-auto px-4">
                <h2 className={`text-2xl md:text-3xl font-bold mb-4 text-center transition-opacity duration-1000 ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
                    Explore Demo Agents
                </h2>
                <p className={`text-base text-gray-400 mb-12 text-center max-w-xl mx-auto transition-opacity duration-1000 delay-100 ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
                    Experience pre-built assistants showcasing common use cases. See the power of Echidna AI in action.
                </p>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-4xl mx-auto">
                    {demos.map((demo, index) => (
                        <DemoAgentCard
                            key={demo.title}
                            {...demo}
                            isVisible={isVisible}
                            delay={index * 150}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};


// --- Workflow Personas Section ---
const WorkflowPersonaCard = ({ icon, role, painPoint, aiTask, outcome, isVisible, delay }) => (
     <div
        className={`bg-gray-950 p-5 rounded-lg border border-gray-800/50 shadow-lg flex flex-col md:flex-row items-center gap-5 transition-all duration-700 ease-out hover:border-purple-600/50 hover:shadow-purple-900/10 ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}
        style={{ transitionDelay: `${delay}ms` }}
    >
        {/* Icon & Role */}
        <div className="flex flex-col items-center text-center md:w-1/6 flex-shrink-0">
             <div className="p-3 bg-purple-600/20 text-purple-400 rounded-full mb-2 border border-purple-800/50">
                {icon}
            </div>
            <h4 className="text-sm font-semibold text-gray-200">{role}</h4>
        </div>
        {/* Flow */}
        <div className="flex flex-col md:flex-row items-center gap-2 md:gap-3 text-center md:text-left flex-grow">
            <div className="md:w-1/3"> <p className="text-xs text-gray-400 leading-relaxed">{painPoint}</p> </div>
            <svg className="w-5 h-5 text-gray-600 mx-1 my-1 md:my-0 transform rotate-90 md:rotate-0" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
            <div className="md:w-1/3"> <p className="text-xs text-purple-300 leading-relaxed"><strong className="text-purple-400">Echidna AI:</strong> {aiTask}</p> </div>
            <svg className="w-5 h-5 text-gray-600 mx-1 my-1 md:my-0 transform rotate-90 md:rotate-0" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
            <div className="md:w-1/3"> <p className="text-xs text-green-400 leading-relaxed">{outcome}</p> </div>
        </div>
    </div>
);
const WorkflowPersonas = () => {
     const [ref, isVisible] = useOnScreen({ threshold: 0.05 });
    const personas = [
        {
            icon: <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>,
            role: "Sales Rep",
            painPoint: "Spending hours searching CRM & docs for specific customer info before calls.",
            aiTask: "Instantly summarizes customer history, related deals, and relevant product docs.",
            outcome: "Prep time cut by 80%, more informed and personalized calls.",
        },
        {
            icon: <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"></path></svg>,
            role: "Support Agent",
            painPoint: "Manually finding troubleshooting steps across multiple knowledge bases.",
            aiTask: "Provides precise steps from all connected sources based on user issue description.",
            outcome: "Faster resolution times, improved customer satisfaction (CSAT).",
        },
        {
             icon: <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a2 2 0 01-2 2H6a2 2 0 01-2-2V4z"></path></svg>,
            role: "Marketer",
            painPoint: "Struggling to quickly generate draft copy for new feature announcements based on internal docs.",
            aiTask: "Drafts blog posts, email snippets, and social media updates using product specs.",
            outcome: "Content creation accelerated, consistent messaging ensured.",
        },
    ];

    return (
        <section id="workflows" ref={ref} className="bg-black text-white py-20 md:py-28 border-t border-gray-800/50">
            <div className="container mx-auto px-4">
                 <h2 className={`text-2xl md:text-3xl font-bold mb-4 text-center transition-opacity duration-1000 ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
                    Transform Your Daily Workflows
                 </h2>
                 <p className={`text-base text-gray-400 mb-12 text-center max-w-2xl mx-auto transition-opacity duration-1000 delay-100 ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
                     Discover how bespoke AI assistants, crafted with Echidna, automate processes and elevate productivity for various roles.
                 </p>
                 <div className="space-y-6 max-w-4xl mx-auto">
                     {personas.map((persona, index) => (
                        <WorkflowPersonaCard
                            key={persona.role}
                            {...persona}
                            isVisible={isVisible}
                            delay={index * 200} // Stagger animation
                        />
                     ))}
                 </div>
            </div>
        </section>
    );
 };

// --- Technology Peek Section ---


// --- How It Works Section ---
const HowItWorks = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [contentVisible, setContentVisible] = useState(true);
  const [sectionRef, isSectionVisible] = useOnScreen({ threshold: 0.1 });

  const handleStepClick = (stepNumber) => {
      if (stepNumber !== activeStep) {
          setContentVisible(false);
          setTimeout(() => {
              setActiveStep(stepNumber);
              setContentVisible(true);
          }, 150);
      }
  };

   const steps = [
        { number: 1, title: "Connect Knowledge", description: "Upload files or link websites" }, // Rephrased
        { number: 2, title: "Configure Assistant", description: "Define persona, tools, model" }, // Rephrased
        { number: 3, title: "Deploy & Integrate", description: "Embed widget or use API" } // Rephrased
    ];

  return (
    <section id="how-it-works" ref={sectionRef} className="bg-black text-white py-20 md:py-24 border-t border-gray-800/50">
      <div className="container mx-auto px-4">
         <h2 className={`text-2xl font-bold mb-12 text-center transition-opacity duration-1000 ${isSectionVisible ? 'opacity-100' : 'opacity-0'}`}>
            Building Your AI: 3 Simple Steps
        </h2>
        <div className={`flex flex-col md:flex-row gap-8 transition-opacity duration-1000 delay-200 ${isSectionVisible ? 'opacity-100' : 'opacity-0'}`}>
          <div className="md:w-1/3">
            <div className="space-y-3">
              {steps.map((step) => (
                <div
                  key={step.number}
                  className={`p-3 rounded-lg cursor-pointer transition-all duration-200 border group ${activeStep === step.number ? 'bg-purple-600 border-purple-600 shadow-lg' : 'bg-gray-950 border-gray-800/50 hover:bg-gray-900 hover:border-gray-700'}`}
                  onClick={() => handleStepClick(step.number)}
                >
                  <div className="flex items-center">
                    <div className={`w-6 h-6 rounded-full flex items-center justify-center mr-2.5 text-xs font-bold flex-shrink-0 transition-colors ${activeStep === step.number ? 'bg-white text-purple-600' : 'bg-gray-700 text-white group-hover:bg-purple-500'}`}>
                      {step.number}
                    </div>
                    <div>
                      <h3 className={`font-semibold text-sm transition-colors ${activeStep === step.number ? 'text-white' : 'text-gray-200 group-hover:text-white'}`}>{step.title}</h3>
                      <p className={`text-xs transition-colors ${activeStep === step.number ? 'text-purple-100' : 'text-gray-400'}`}>{step.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="md:w-2/3 bg-gray-950 rounded-lg p-5 border border-gray-800/50 min-h-[350px] flex flex-col overflow-hidden">
             <div className={`transition-opacity duration-150 ${contentVisible ? 'opacity-100' : 'opacity-0'}`}>
                {activeStep === 1 && (
                  <div className="h-full flex flex-col">
                    <h3 className="text-lg font-semibold mb-3 text-gray-100">1. Connect Knowledge Sources</h3>
                    <div className="flex-grow border-2 border-dashed border-gray-700 rounded-lg p-5 flex flex-col items-center justify-center hover:border-purple-500 transition-colors bg-black/30">
                      <svg className="w-12 h-12 text-gray-600 mb-3" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M4 7v10m16-10v10M3 7h18M3 17h18M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"></path></svg>
                      <p className="text-center text-gray-400 text-sm mb-1">Drag files, paste web links, or connect APIs</p>
                      <p className="text-center text-gray-500 text-xs">PDF, DOCX, URLs, Databases & more supported</p>
                      <button className="mt-3 bg-purple-600 hover:bg-purple-700 px-3 py-1.5 rounded-md text-xs transition-colors">Add Data Source</button>
                    </div>
                  </div>
                )}
                {activeStep === 2 && (
                     <div className="h-full flex flex-col">
                        <h3 className="text-lg font-semibold mb-3 text-gray-100">2. Configure Assistant Behavior</h3>
                        <div className="space-y-4">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                                <div className="bg-gray-900 p-3 rounded-lg border border-gray-800/50">
                                    <h4 className="font-medium text-sm mb-2 text-gray-300">Define Persona</h4>
                                    <div className="space-y-1.5">
                                        {["Helpful Assistant", "Expert Consultant", "Witty Companion"].map((option) => (
                                            <div key={option} className="flex items-center">
                                                <input type="radio" id={`p-${option}`} name="personality" value={option} className="mr-1.5 custom-radio-sm" defaultChecked={option === "Helpful Assistant"} />
                                                <label htmlFor={`p-${option}`} className="text-xs cursor-pointer text-gray-400">{option}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="bg-gray-900 p-3 rounded-lg border border-gray-800/50">
                                    <h4 className="font-medium text-sm mb-2 text-gray-300">Assign Tools (APIs)</h4>
                                     <div className="space-y-1.5">
                                        {["CRM Lookup", "Calendar Check", "Ticket Creator"].map((option) => (
                                            <div key={option} className="flex items-center">
                                                <input type="checkbox" id={`c-${option}`} name="capabilities" value={option} className="mr-1.5 custom-checkbox-sm" defaultChecked={["CRM Lookup"].includes(option)} />
                                                <label htmlFor={`c-${option}`} className="text-xs cursor-pointer text-gray-400">{option}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                             <div className="bg-gray-900 p-3 rounded-lg border border-gray-800/50">
                                <h4 className="font-medium text-sm mb-2 text-gray-300">Select AI Engine</h4>
                                <select className="bg-black/30 border border-gray-700/50 rounded text-xs p-1 w-full text-gray-300 focus:ring-purple-500 focus:border-purple-500">
                                    <option>Echidna-v4-turbo (Recommended)</option>
                                    <option>Echidna-v4o</option>
                                    <option>Use My Own Key...</option>
                                </select>
                            </div>
                        </div>
                        <button className="mt-4 ml-auto bg-purple-600 hover:bg-purple-700 py-1.5 px-4 rounded text-xs font-semibold transition-colors">Save Configuration</button>
                    </div>
                )}
                {activeStep === 3 && (
                  <div className="h-full flex flex-col">
                    <h3 className="text-lg font-semibold mb-3 text-gray-100">3. Deploy & Integrate</h3>
                    <div className="bg-gray-900 p-3 rounded-lg mb-3 border border-gray-800/50 flex-shrink-0">
                      <div className="flex justify-between items-center mb-2">
                        <h4 className="font-medium text-sm text-gray-300">Support Assistant Pro</h4>
                        <span className="bg-green-600/20 text-green-400 px-1.5 py-0.5 rounded text-xs font-medium">Ready</span>
                      </div>
                      <div className="space-y-1 text-xs mb-3">
                         <p className="text-gray-400">Knowledge: <span className="text-gray-300">Help Docs (Live), Tickets DB</span></p>
                         <p className="text-gray-400">Persona: <span className="text-gray-300">Expert Consultant</span></p>
                         <p className="text-gray-400">Tools: <span className="text-gray-300">Ticket Creator</span></p>
                      </div>
                      <button className="w-full bg-purple-600 hover:bg-purple-700 py-1.5 rounded text-xs font-semibold transition-colors">Deploy Assistant</button>
                    </div>
                    <div className="bg-black p-3 rounded-lg font-mono text-xs flex-grow overflow-auto border border-gray-800/50 relative">
                       <button className="absolute top-2 right-2 text-xs text-gray-500 hover:text-white flex items-center bg-gray-800/50 px-1.5 py-0.5 rounded">
                         <svg className="w-3 h-3 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"></path></svg> Copy
                       </button>
                      <div className="text-green-500">// Integrate via API or Widget</div>
                      <div className="text-gray-300">&lt;script src=<span className="text-yellow-400">"//cdn.echidna.ai/widget.js"</span>&gt;&lt;/script&gt;</div>
                      <div className="text-gray-300">&lt;script&gt;</div>
                      <div className="text-gray-300 ml-2">EchidnaAI.init({`{`} apiKey: <span className="text-yellow-400">'pk_...'</span> {`}});`}</div>
                      <div className="text-gray-300">&lt;/script&gt;</div>
                    </div>
                  </div>
                )}
             </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .custom-radio-sm, .custom-checkbox-sm {
          appearance: none; display: inline-block; vertical-align: middle;
          height: 0.85em; width: 0.85em; background-color: #374151;
          border: 1px solid #4b5563; position: relative; cursor: pointer;
          margin-right: 0.35em; flex-shrink: 0;
        }
        .custom-radio-sm { border-radius: 50%; }
        .custom-checkbox-sm { border-radius: 0.15em; }
        .custom-radio-sm:checked, .custom-checkbox-sm:checked {
          background-color: #8B5CF6; border-color: #8B5CF6;
        }
         .custom-radio-sm:checked::after {
            content: ''; display: block; width: 0.4em; height: 0.4em;
            border-radius: 50%; background: white; position: absolute;
            top: 50%; left: 50%; transform: translate(-50%, -50%);
        }
         .custom-checkbox-sm:checked::after {
            content: '✔'; color: white; font-size: 0.7em; line-height: 0.85em;
            position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);
            text-align: center;
        }
      `}</style>
    </section>
  );
};

// --- Models & Pricing Section (Rephrased & Simplified) ---
const PricingTable = ({ rows }) => (
    <div className="overflow-x-auto">
        <table className="w-full text-xs border-collapse">
            <thead>
                <tr className="border-b border-gray-700/50">
                    <th className="text-left font-semibold text-gray-300 py-2 px-2">Cost Type</th>
                    <th className="text-left font-semibold text-gray-300 py-2 px-2">Free Tier</th>
                    <th className="text-left font-semibold text-gray-300 py-2 px-2">Paid Tier <span className="text-gray-500">(per 1M tokens USD)</span></th>
                </tr>
            </thead>
            <tbody>
                {rows.map((row, index) => (
                    <tr key={index} className="border-b border-gray-800/50 last:border-b-0">
                        <td className="py-2.5 px-2 text-gray-400 align-top">{row.feature}</td>
                        <td className="py-2.5 px-2 text-gray-200 align-top">{row.free}</td>
                        <td className="py-2.5 px-2 text-gray-200 align-top" dangerouslySetInnerHTML={{ __html: row.paid }} />
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
);

const ModelsPricing = () => {
    const [ref, isVisible] = useOnScreen({ threshold: 0.05 });

    // Simplified & Rephrased Pricing Data
    const v4TurboPricing = [
        { feature: "Input Cost", free: "Free", paid: "$0.15 (text/image/video)<br/>$1.05 (audio)" },
        { feature: "Output Cost", free: "Free", paid: "$0.60" },
    ];

    const v4oPricing = [
        { feature: "Input Cost", free: "Free", paid: "$0.1125" },
        { feature: "Output Cost", free: "Free", paid: "$0.45" },
    ];

    return (
        <section id="pricing" ref={ref} className="bg-black text-white py-20 md:py-28 border-t border-gray-800/50">
            <div className="container mx-auto px-4">
                 {/* Rephrased Titles/Text */}
                <h2 className={`text-2xl md:text-3xl font-bold mb-4 text-center transition-opacity duration-1000 ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
                    AI Engine Choices & Usage Costs
                </h2>
                <p className={`text-base text-gray-400 mb-12 text-center max-w-2xl mx-auto transition-opacity duration-1000 delay-100 ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
                    Select the ideal engine for your application, from advanced multi-capability processing to high-efficiency tasks. Begin at no cost and scale affordably.
                </p>

                 {/* Models Grid */}
                 <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-16">
                     {/* v4-turbo Card */}
                     <div className={`bg-gray-950 p-6 rounded-lg border border-gray-800/50 shadow-lg transition-all duration-700 ease-out ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`} style={{ transitionDelay: `200ms` }}>
                          <div className="flex items-center mb-3">
                             <svg className="w-6 h-6 text-purple-400 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"></path></svg>
                             <h3 className="text-lg font-semibold text-gray-100">v4-turbo Engine</h3>
                          </div>
                           {/* Rephrased Description */}
                          <p className="text-sm text-gray-400 mb-4">Our flagship multi-capability engine. Excels across modalities with a large 1M token context, ideal for sophisticated agent tasks.</p>
                           {/* Simplified Table */}
                          <PricingTable rows={v4TurboPricing} />
                    </div>

                     {/* v4o Card */}
                     <div className={`bg-gray-950 p-6 rounded-lg border border-gray-800/50 shadow-lg transition-all duration-700 ease-out ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`} style={{ transitionDelay: `400ms` }}>
                         <div className="flex items-center mb-3">
                            <svg className="w-6 h-6 text-purple-400 mr-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z" opacity="0.6"></path></svg>
                             <h3 className="text-lg font-semibold text-gray-100">v4o Engine</h3>
                         </div>
                          {/* Rephrased Description */}
                          <p className="text-sm text-gray-400 mb-4">Our compact and economical engine, designed for efficient, large-volume applications.</p>
                           {/* Simplified Table */}
                          <PricingTable rows={v4oPricing} />
                     </div>
                 </div>

                 {/* Bring Your Own API Key Section (Rephrased) */}
                 <div className={`bg-gray-950 p-6 rounded-lg border border-gray-800/50 shadow-lg transition-all duration-700 ease-out text-center ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`} style={{ transitionDelay: `600ms` }}>
                     <h3 className="text-lg font-semibold text-gray-100 mb-2">Integrate Your Own Provider Keys</h3>
                     <p className="text-sm text-gray-400 max-w-xl mx-auto">
                        Gain extra versatility by connecting your existing API keys from services like OpenAI or Anthropic. Seamlessly utilize your established accounts via the Echidna platform. <a href="#contact-sales" className="text-purple-400 hover:text-purple-300">Get in touch</a> to learn more.
                    </p>
                 </div>
            </div>
        </section>
    );
};

// --- Technology Peek Section ---
const TechnologyPeek = () => {
    const [ref, isVisible] = useOnScreen({ threshold: 0.5 });

    return (
        <section ref={ref} className="bg-gradient-to-b from-black to-gray-950/30 text-white py-16 md:py-20 border-t border-gray-800/50">
            <div className={`container mx-auto px-4 text-center transition-all duration-1000 ease-out ${isVisible ? 'opacity-100 scale-100' : 'opacity-0 scale-95'}`}>
                <div className="inline-block bg-purple-600/10 border border-purple-800/50 rounded-full px-3 py-1 text-xs text-purple-300 mb-4">
                    Core Technology
                </div>
                <h3 className="text-xl md:text-2xl font-bold mb-3">Powered by the Echidna AI Foundry</h3>
                <p className="text-sm md:text-base text-gray-400 max-w-xl mx-auto">
                    Leveraging state-of-the-art models like Echidna-v4-turbo, optimized for speed, accuracy, and efficient tool usage on your custom knowledge.
                </p>
            </div>
        </section>
    );
 };

// --- CallToAction & Footer ---
const CallToAction = () => {
    const [ref, isVisible] = useOnScreen({ threshold: 0.2 });
    return (
        <section id="getstarted" ref={ref} className="bg-purple-700 text-white py-16 md:py-20 relative overflow-hidden">
            <div className="absolute -top-10 -left-10 w-40 h-40 bg-purple-600/50 rounded-full filter blur-3xl opacity-50"></div>
            <div className="absolute -bottom-10 -right-10 w-40 h-40 bg-purple-600/50 rounded-full filter blur-3xl opacity-50"></div>
            <div className={`container mx-auto px-4 text-center transition-all duration-1000 ease-out relative z-10 ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-5'}`}>
                <h2 className="text-2xl md:text-3xl font-bold mb-4">Ready to build your custom AI?</h2>
                <p className="text-base mb-8 text-purple-100 max-w-2xl mx-auto">Start creating intelligent assistants tailored to your needs in minutes. No complex setup required.</p>
                <div className="flex flex-col sm:flex-row justify-center space-y-3 sm:space-y-0 sm:space-x-4">
                    <a href="/signup" className="bg-white text-purple-700 hover:bg-gray-100 px-6 py-2.5 rounded-lg font-semibold text-sm shadow-md transition-colors duration-200"> Sign Up for Free </a> {/* Updated Link */}
                    <a href="https://calendly.com/manas-o4af/30min" target="_blank" rel="noopener noreferrer" className="border-2 border-white hover:bg-white hover:text-purple-700 px-6 py-2.5 rounded-lg font-semibold text-sm transition-colors duration-200"> Book a Demo </a> {/* Updated Link */}
                </div>
            </div>
        </section>
    );
};

const Footer = () => {
     return (
        <footer className="bg-black text-gray-500 py-10 border-t border-gray-800/50">
            <div className="container mx-auto px-4">
                <div className="grid grid-cols-2 md:grid-cols-5 gap-8 mb-8">
                    <div className="col-span-2 md:col-span-1"> {/* Brand */}
                         <div className="flex items-center mb-3">
                             <svg className="w-7 h-7 mr-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 2L2 7L12 12L22 7L12 2Z" fill="#8B5CF6" stroke="#8B5CF6" strokeWidth="1.5"/>
                                <path d="M2 17L12 22L22 17" stroke="#8B5CF6" strokeWidth="1.5"/>
                                <path d="M2 12L12 17L22 12" stroke="#8B5CF6" strokeWidth="1.5"/>
                            </svg>
                            <span className="text-lg font-bold text-white">Echidna AI</span>
                        </div>
                        <p className="text-xs">Build custom AI assistants, powered by your data.</p>
                    </div>
                    <div> {/* Product */}
                        <h5 className="font-semibold text-gray-300 mb-3 text-sm">Product</h5>
                        <ul className="space-y-1.5 text-xs">
                            <li><a href="#feature-knowledge" className="hover:text-white transition-colors">Knowledge Base</a></li>
                            <li><a href="#feature-integration" className="hover:text-white transition-colors">Integration</a></li>
                            <li><a href="#feature-api" className="hover:text-white transition-colors">API Connect</a></li>
                             <li><a href="#feature-dashboard" className="hover:text-white transition-colors">Dashboard</a></li>
                              <li><a href="#feature-fast" className="hover:text-white transition-colors">Deployment</a></li>
                               <li><a href="#pricing" className="hover:text-white transition-colors">Models & Pricing</a></li>
                        </ul>
                    </div>
                    <div> {/* Use Cases */}
                         <h5 className="font-semibold text-gray-300 mb-3 text-sm">Use Cases</h5>
                        <ul className="space-y-1.5 text-xs">
                            <li><a href="#workflows" className="hover:text-white transition-colors">Sales Assist</a></li>
                            <li><a href="#workflows" className="hover:text-white transition-colors">Support Auto</a></li>
                            <li><a href="#workflows" className="hover:text-white transition-colors">Marketing Gen</a></li>
                             <li><a href="#demos" className="hover:text-white transition-colors">Demo Agents</a></li>
                              <li><a href="#workflows" className="hover:text-white transition-colors">Dev Helper</a></li>
                        </ul>
                    </div>
                    
                </div>
                <div className="border-t border-gray-800/50 pt-6 flex flex-col md:flex-row justify-between items-center text-xs"> {/* Bottom Footer */}
                     <p>&copy; {new Date().getFullYear()} METABEES AI. All rights reserved.</p>
                    <div className="flex space-x-4 mt-3 md:mt-0">
                    </div>
                </div>
            </div>
        </footer>
    );
 };


// --- Main Component (Renamed to Echland, Updated Order) ---
function Echland() { // Renamed
  return (
    <div className="bg-black font-sans antialiased">
      <Header />
      <main>
        <Hero />
     
        {/* Feature Sections */}
        <FeatureKnowledgeBase />
        <FeatureEasyIntegration />
        <FeatureConnectAPI />
        <FeatureChatAnalysis />
        <FeatureAdminDashboard />
        <FeatureLightningFast />
         {/* New/Updated Sections */}
         <DemoAgents /> {/* Added Demo Agents */}
         <WorkflowPersonas />
         <TechnologyPeek />
        {/* Remaining Section */}
        <HowItWorks />
        <ModelsPricing /> {/* Added Models & Pricing here */}
        {/* Analytics & IntegrationDemo Removed */}
        <CallToAction />
      </main>
      <Footer />
    </div>
  );
}

// Export renamed component
export default Echland; // Renamed export