import React, { useState, useCallback } from 'react';
import { Upload, Users, Plus, FileSpreadsheet, AlertCircle, CheckCircle, Search, Bell, ChevronDown, Trash2, Edit2, Save } from 'lucide-react';
import { useDropzone } from 'react-dropzone';

// Existing NavButton component remains the same
const NavButton = ({ children }) => (
  <button className="px-3 py-2 text-gray-300 hover:text-white hover:bg-gray-800 rounded text-sm font-medium transition-colors">
    {children}
  </button>
);

const createNewLeadSet = () => {
  // Mock function to create a new lead set
  alert('Creating new lead set');
};

const EnterpriseHeader = () => (
  <div className="bg-gray-900 sticky top-0 z-10">
    <div className="max-w-7xl mx-auto">
      <div className="flex h-16 items-center justify-between px-4">
        <div className="flex items-center space-x-8">
          <div className="flex items-center space-x-2">
            <img src="/api/placeholder/32/32" alt="EchidnaMail" className="h-8" />
            <span className="text-white font-bold">EchidnaMail</span>
            <span className="text-red-500 font-bold">v2</span>
          </div>
          <div className="relative">
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            <input
              type="text"
              placeholder="Search services, documentation, or resources"
              className="pl-10 pr-4 py-2 w-96 bg-gray-800 border border-gray-700 rounded text-gray-200 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <NavButton>API Reference</NavButton>
          <NavButton>Enterprise Support</NavButton>
          <button className="p-2 text-gray-300 hover:text-white hover:bg-gray-800 rounded-full relative">
            <Bell size={20} />
            <span className="absolute top-1 right-1 h-2 w-2 bg-blue-500 rounded-full"></span>
          </button>
          <div className="flex items-center space-x-3 ml-4 px-3 py-2 bg-gray-800 rounded cursor-pointer">
            <div className="h-8 w-8 bg-blue-600 rounded-full flex items-center justify-center text-white text-sm font-medium">
              EC
            </div>
            <div>
              <div className="text-gray-200 text-sm">Enterprise</div>
              <div className="text-gray-400 text-xs">us-west-2</div>
            </div>
            <ChevronDown size={16} className="text-gray-400" />
          </div>
        </div>
      </div>
    </div>
  </div>
);

const ProspectUpload2 = () => {
  const [uploadStatus, setUploadStatus] = useState('idle');
  const [selectedLeadSet, setSelectedLeadSet] = useState('');
  const [newLeadSetName, setNewLeadSetName] = useState('');
  const [isCreatingLeadSet, setIsCreatingLeadSet] = useState(false);
  const [prospects, setProspects] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [formData, setFormData] = useState({
    id: '',
    name: '',
    email: '',
    company: '',
    website: ''
  });
  const [uploadStats, setUploadStats] = useState({
    total: 0,
    processed: 0,
    failed: 0
  });

  // Mock lead sets
  const leadSets = [
    { id: '1', name: 'Tech Companies' },
    { id: '2', name: 'Healthcare' },
    { id: '3', name: 'Enterprise' }
  ];

  const onDrop = useCallback(async (acceptedFiles) => {
    if (!selectedLeadSet) {
      alert('Please select or create a lead set first');
      return;
    }

    const file = acceptedFiles[0];
    if (file) {
      setUploadStatus('processing');
      
      // Mock CSV parsing - replace with actual CSV parsing logic
      const reader = new FileReader();
      reader.onload = async (e) => {
        const text = e.target.result;
        const rows = text.split('\n').slice(1); // Skip header row
        const newProspects = rows.map((row, index) => {
          const [name, email, company, website] = row.split(',');
          return {
            id: `temp-${Date.now()}-${index}`,
            name: name?.trim(),
            email: email?.trim(),
            company: company?.trim(),
            website: website?.trim(),
            leadSet: selectedLeadSet
          };
        });

        setProspects(prev => [...prev, ...newProspects]);
        setUploadStats({
          total: newProspects.length,
          processed: newProspects.length,
          failed: 0
        });
        setUploadStatus('success');
      };
      reader.readAsText(file);
    }
  }, [selectedLeadSet]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'text/csv': ['.csv'],
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
    }
  });

  const handleManualSubmit = async (e) => {
    e.preventDefault();
    if (!selectedLeadSet) {
      alert('Please select or create a lead set first');
      return;
    }

    const newProspect = {
      id: `manual-${Date.now()}`,
      ...formData,
      leadSet: selectedLeadSet
    };

    setProspects(prev => [...prev, newProspect]);
    setFormData({ name: '', email: '', company: '', website: '' });
    setUploadStatus('success');
  };

  const handleEdit = (prospect) => {
    setEditingId(prospect.id);
    setFormData(prospect);
  };

  const handleUpdate = (id) => {
    setProspects(prev => prev.map(p => 
      p.id === id ? { ...formData, id } : p
    ));
    setEditingId(null);
    setFormData({ name: '', email: '', company: '', website: '' });
  };

  const handleDelete = (id) => {
    setProspects(prev => prev.filter(p => p.id !== id));
  };


  return (
    <div className="min-h-screen bg-gray-50">
      <EnterpriseHeader />

      <div className="max-w-7xl mx-auto px-4 py-8 bg-white">
        <div className="flex items-center justify-between mb-8">
          <div>
            <div className="flex items-center space-x-4">
              <h1 className="text-2xl font-semibold text-gray-900">Prospect Upload</h1>
              <span className="px-3 py-1 bg-blue-50 text-blue-700 text-sm font-medium rounded-full">
                Enterprise
              </span>
            </div>
            <p className="mt-2 text-sm text-gray-600">Upload new prospects via CSV or manual entry</p>
          </div>
        </div>

        {/* Lead Set Selection */}
        <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-medium text-gray-900">Lead Set</h2>
            <button
              onClick={() => setIsCreatingLeadSet(!isCreatingLeadSet)}
              className="text-blue-600 hover:text-blue-700 text-sm font-medium"
            >
              {isCreatingLeadSet ? 'Cancel' : 'Create New Lead Set'}
            </button>
          </div>

          {isCreatingLeadSet ? (
            <div className="flex items-center space-x-2">
              <input
                type="text"
                value={newLeadSetName}
                onChange={(e) => setNewLeadSetName(e.target.value)}
                placeholder="Enter lead set name"
                className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button
                onClick={createNewLeadSet}
                className="px-4 py-2 bg-blue-600 text-white rounded-lg text-sm font-medium hover:bg-blue-700"
              >
                Create
              </button>
            </div>
          ) : (
            <select
              value={selectedLeadSet}
              onChange={(e) => setSelectedLeadSet(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Select a Lead Set</option>
              {leadSets.map((set) => (
                <option key={set.id} value={set.id}>
                  {set.name}
                </option>
              ))}
            </select>
          )}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* CSV Upload Section */}
          <div className="bg-white rounded-lg shadow-sm p-6">
            <div className="flex items-center space-x-2 mb-4">
              <FileSpreadsheet className="text-blue-600" />
              <h2 className="text-lg font-medium text-gray-900">CSV Upload</h2>
            </div>
            
            <div 
              {...getRootProps()} 
              className={`border-2 border-dashed border-gray-200 rounded-lg p-8 text-center cursor-pointer
                ${isDragActive ? 'border-blue-500 bg-blue-50' : ''}
                ${!selectedLeadSet ? 'opacity-50' : ''}`}
            >
              <input {...getInputProps()} disabled={!selectedLeadSet} />
              <Upload className="mx-auto h-12 w-12 text-gray-400" />
              <p className="mt-2 text-sm text-gray-600">
                {isDragActive ? 'Drop your file here' : 'Drop your file here or click to browse'}
              </p>
              <p className="text-xs text-gray-500 mt-1">
                Supported formats: CSV, Excel (.xlsx, .xls)
              </p>
            </div>
          </div>

          {/* Manual Entry Section */}
          <div className="bg-white rounded-lg shadow-sm p-6">
  <div className="flex items-center space-x-2 mb-4">
    <Users className="text-blue-600" />
    <h2 className="text-lg font-medium text-gray-900">Manual Entry</h2>
  </div>

  <form onSubmit={handleManualSubmit} className="space-y-4">
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-1">Name</label>
      <input
        type="text"
        value={formData.name}
        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white text-black"
        required
      />
    </div>

    <div>
      <label className="block text-sm font-medium text-gray-700 mb-1">Email</label>
      <input
        type="email"
        value={formData.email}
        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white text-black"
        required
      />
    </div>

    <div>
      <label className="block text-sm font-medium text-gray-700 mb-1">Company</label>
      <input
        type="text"
        value={formData.company}
        onChange={(e) => setFormData({ ...formData, company: e.target.value })}
        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white text-black"
        required
      />
    </div>

    <div>
      <label className="block text-sm font-medium text-gray-700 mb-1">Website</label>
      <input
        type="url"
        value={formData.website}
        onChange={(e) => setFormData({ ...formData, website: e.target.value })}
        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white text-black"
        required
      />
    </div>

    <button
      type="submit"
      disabled={!selectedLeadSet}
      className={`w-full px-4 py-2 bg-blue-600 text-white rounded-lg text-sm font-medium hover:bg-blue-700 ${
        !selectedLeadSet ? "opacity-50 cursor-not-allowed" : ""
      }`}
    >
      Add Prospect
    </button>
  </form>
</div>

        </div>

        {/* Prospects Table */}
        {prospects.length > 0 && (
  <div className="mt-8 bg-white rounded-lg shadow-sm overflow-hidden">
    <div className="px-6 py-4 border-b border-gray-200">
      <h2 className="text-lg font-medium text-gray-900">Prospects</h2>
    </div>
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Company</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Website</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {prospects.map((prospect) => (
            <tr key={prospect.id}>
              <td className="px-6 py-4 whitespace-nowrap">
                {editingId === prospect.id ? (
                  <input
                    type="text"
                    value={formData.name}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    className="w-full px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-black text-white"
                  />
                ) : (
                  <div className="text-sm text-gray-900">{prospect.name}</div>
                )}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                {editingId === prospect.id ? (
                  <input
                    type="email"
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    className="w-full px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-black text-white"
                  />
                ) : (
                  <div className="text-sm text-gray-900">{prospect.email}</div>
                )}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                {editingId === prospect.id ? (
                  <input
                    type="text"
                    value={formData.company}
                    onChange={(e) => setFormData({ ...formData, company: e.target.value })}
                    className="w-full px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-black text-white"
                  />
                ) : (
                  <div className="text-sm text-gray-900">{prospect.company}</div>
                )}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                {editingId === prospect.id ? (
                  <input
                    type="url"
                    value={formData.website}
                    onChange={(e) => setFormData({ ...formData, website: e.target.value })}
                    className="w-full px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-black text-white"
                  />
                ) : (
                  <div className="text-sm text-gray-900">{prospect.website}</div>
                )}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {editingId === prospect.id ? (
                  <button
                    onClick={() => handleUpdate(prospect.id)}
                    className="text-green-600 hover:text-green-900 mr-3"
                  >
                    <Save size={16} />
                  </button>
                ) : (
                  <button
                    onClick={() => handleEdit(prospect)}
                    className="text-blue-600 hover:text-blue-900 mr-3"
                  >
                    <Edit2 size={16} />
                  </button>
                )}
                <button
                  onClick={() => handleDelete(prospect.id)}
                  className="text-red-600 hover:text-red-900"
                >
                  <Trash2 size={16} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
)}



      </div>
    </div>
  );
};

export default ProspectUpload2;