import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { PlusIcon, TrashIcon, XIcon, BarChart2, ChevronDownIcon, ChevronRightIcon, PlusCircleIcon, MinusCircleIcon } from 'lucide-react';
import { useUser } from '@clerk/clerk-react';
import ReactMarkdown from 'react-markdown';
import GamefiedAIAssistant from './intercom';

const Redstoneland = () => {
  const { user } = useUser();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [showResultsModal, setShowResultsModal] = useState(false);
  const [analysisResults, setAnalysisResults] = useState(null);
  const [expandedSections, setExpandedSections] = useState({
    customerNeeds: false,
    suggestions: false,
    competitiveGaps: false,
    competitorAnalysis: false,
    sentimentAnalysis: false,
    keyTrends: false,
    summary: true
  });
  const [formData, setFormData] = useState({
    productUrl: '',
    reviews: [{ url: '', type: 'product' }],
    competitors: [{ url: '', gaps: '' }],
    name: '',
    useCompetitorGaps: false
  });

  // Loading messages to make loading more interesting
  const loadingMessages = [
    "Scraping the web for product reviews...",
    "Reading Reddit conversations about your product...",
    "Analyzing customer sentiment patterns...",
    "Digging through competitor features...",
    "Extracting key customer pain points...",
    "Identifying market trends from social media...",
    "Comparing with similar products on the market...",
    "Processing user feedback across platforms...",
    "Calculating sentiment scores from reviews...",
    "Summarizing critical feedback points..."
  ];

  useEffect(() => {
    if (user?.id) {
      fetchProducts();
    }
  }, [user]);

  // Function to rotate loading messages
  useEffect(() => {
    if (loading) {
      const messageInterval = setInterval(() => {
        const randomIndex = Math.floor(Math.random() * loadingMessages.length);
        setLoadingMessage(loadingMessages[randomIndex]);
      }, 3000);

      // Set initial message
      setLoadingMessage(loadingMessages[Math.floor(Math.random() * loadingMessages.length)]);

      return () => clearInterval(messageInterval);
    }
  }, [loading]);

  const fetchProducts = async () => {
    try {
      setLoading(true);
      const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/getproducts', { cid: user?.id });
      if (response.data.success) setProducts(response.data.products);
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false);
    }
  };

  const addProduct = async () => {
    if (!formData.productUrl || formData.reviews.length === 0 || formData.reviews[0].url === '') return;

    setLoading(true);
    try {
      // Process competitors to include gaps information
      const processedCompetitors = formData.competitors
        .filter(comp => comp.url.trim() !== '' || comp.gaps.trim() !== '')
        .map(comp => ({
          url: comp.url.trim(),
          gaps: comp.gaps.trim()
        }));

      const productData = {
        url: formData.productUrl,
        // Process multiple review sources
        reviews: formData.reviews.filter(rev => rev.url.trim() !== ''),
        competitors: processedCompetitors,
        name: formData.name || 'Unnamed Product', // Default name if none provided
        useCompetitorGaps: formData.useCompetitorGaps
      };

      const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/addproduct', {
        cid: user?.id, productData
      });

      if (response.data.success) {
        setShowAddProductModal(false);
        // Reset form data
        setFormData({
          productUrl: '',
          reviews: [{ url: '', type: 'product' }],
          competitors: [{ url: '', gaps: '' }],
          name: '',
          useCompetitorGaps: false
        });
        // Force a page refresh
        window.location.reload();
      }
    } catch (error) {
      console.error('Error adding product:', error);
      alert('Failed to add product. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const deleteProduct = async (productId) => {
    try {
      setLoading(true);
      const response = await axios.post('http://localhost:5001/echidna/deleteproduct', {
        productId, cid: user?.id
      });
      if (response.data.success) {
        setProducts(products.filter(product => product._id !== productId));
      }
    } catch (error) {
      console.error('Error deleting product:', error);
    } finally {
      setLoading(false);
    }
  };

  const analyzeProduct = async (product) => {
    if (!product || !product._id || !product.name) {
      console.error('Invalid product data:', product);
      return;
    }

    console.log('Analyzing product:', product.name);

    try {
      setLoading(true);

      // Debug the request payload
      const payload = {
        cid: user?.id,
        productId: product._id,
        productName: product.name
      };
      console.log('Sending analysis request with payload:', payload);

      const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/reviewanalyze', payload);
      console.log('Analysis response:', response.data);

      if (response.data.success) {
        setAnalysisResults(response.data.analysisResults);
        setProducts(products.map(p => p._id === product._id ? { ...p, status: 'analyzed' } : p));
        setShowResultsModal(true);
      } else {
        alert('Analysis failed: ' + (response.data.message || 'Unknown error'));
      }
    } catch (error) {
      console.error('Error analyzing product:', error);
      alert('Failed to analyze product. Check console for details.');
    } finally {
      setLoading(false);
    }
  };

  const handleFormChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  // Handle competitor changes - both URL and gaps
  const handleCompetitorChange = (index, field, value) => {
    const updatedCompetitors = [...formData.competitors];
    updatedCompetitors[index] = { ...updatedCompetitors[index], [field]: value };
    setFormData({ ...formData, competitors: updatedCompetitors });
  };

  // Handle review source changes
  const handleReviewChange = (index, field, value) => {
    const updatedReviews = [...formData.reviews];
    updatedReviews[index] = { ...updatedReviews[index], [field]: value };
    setFormData({ ...formData, reviews: updatedReviews });
  };

  // Add new competitor field
  const addCompetitor = () => {
    setFormData({
      ...formData,
      competitors: [...formData.competitors, { url: '', gaps: '' }]
    });
  };

  // Remove competitor field
  const removeCompetitor = (index) => {
    const updatedCompetitors = [...formData.competitors];
    updatedCompetitors.splice(index, 1);
    setFormData({ ...formData, competitors: updatedCompetitors });
  };

  // Add new review source field
  const addReview = () => {
    setFormData({
      ...formData,
      reviews: [...formData.reviews, { url: '', type: 'product' }]
    });
  };

  // Remove review source field
  const removeReview = (index) => {
    const updatedReviews = [...formData.reviews];
    updatedReviews.splice(index, 1);
    setFormData({ ...formData, reviews: updatedReviews });
  };

  const toggleSection = (section) => {
    setExpandedSections({
      ...expandedSections,
      [section]: !expandedSections[section]
    });
  };

  // Helper component for collapsible section
  const CollapsibleSection = ({ title, content, sectionKey, bgColor = "bg-black" }) => {
    return (
      <div className="mb-6">
        <div
          onClick={() => toggleSection(sectionKey)}
          className="flex justify-between items-center w-full text-left mb-2 bg-zinc-900 rounded p-3 hover:bg-zinc-800 transition-colors cursor-pointer"
        >
          <h4 className="text-sm font-medium">{title}</h4>
          {expandedSections[sectionKey] ? (
            <ChevronDownIcon size={18} className="flex-shrink-0 text-zinc-400" />
          ) : (
            <ChevronRightIcon size={18} className="flex-shrink-0 text-zinc-400" />
          )}
        </div>

        {expandedSections[sectionKey] && (
          <div className={`${bgColor} rounded p-4 overflow-auto max-h-96`}>
            <ReactMarkdown
              components={{
                // Apply styling to markdown components
                h3: ({node, ...props}) => <h3 className="text-xl font-semibold mt-6 mb-3 text-white" {...props} />,
                h4: ({node, ...props}) => <h4 className="text-lg font-medium mt-5 mb-2 text-white" {...props} />,
                p: ({node, ...props}) => <p className="mb-3" {...props} />,
                ul: ({node, ...props}) => <ul className="ml-6 mb-4 list-disc" {...props} />,
                ol: ({node, ...props}) => <ol className="ml-6 mb-4 list-decimal" {...props} />,
                li: ({node, ...props}) => <li className="mb-1" {...props} />,
                strong: ({node, ...props}) => <strong className="font-semibold text-white" {...props} />,
                blockquote: ({node, ...props}) => <blockquote className="border-l-3 border-zinc-600 pl-4 italic text-zinc-400" {...props} />
              }}
            >
              {content}
            </ReactMarkdown>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-black text-white">
      {/* Header */}
      <header className="border-b border-zinc-900 px-4 py-3">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <h1 className="text-xl font-bold bg-gradient-to-r from-red-500 to-orange-500 bg-clip-text text-transparent">Redstone AI</h1>
            <span className="ml-2 text-xs text-zinc-500">by Metabees</span>
          </div>
          <div className="flex items-center gap-4">
            <div
              onClick={() => setShowAddProductModal(true)}
              className="flex items-center gap-1 bg-zinc-900 hover:bg-zinc-800 px-3 py-1 rounded text-sm cursor-pointer"
            >
              <PlusIcon size={14} className="flex-shrink-0" />
              <span>Add</span>
            </div>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className="p-4">
        {loading && (
          <div className="fixed inset-0 bg-black bg-opacity-90 flex flex-col items-center justify-center z-50">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-red-500 mb-4"></div>
            <div className="text-zinc-400 text-sm animate-pulse">{loadingMessage}</div>
          </div>
        )}

        {products.length === 0 ? (
          <div className="py-12 text-center">
            <p className="text-zinc-500 text-sm mb-4">No products yet</p>
            <div
              onClick={() => setShowAddProductModal(true)}
              className="inline-flex items-center gap-1 bg-zinc-900 hover:bg-zinc-800 px-3 py-2 rounded text-sm cursor-pointer"
            >
              <PlusIcon size={14} className="flex-shrink-0" />
              <span>Add Product</span>
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {products.map((product) => (
              <div key={product._id} className="bg-zinc-950 rounded border border-zinc-900 hover:border-zinc-800">
                <div className="p-4">
                  <h3 className="text-base font-medium mb-1">{product.name || 'Unnamed Product'}</h3>
                  <p className="text-zinc-500 text-xs truncate mb-3">{product.url}</p>

                  <div className="flex justify-between items-center">
                    <div className="text-xs">
                      {product.status === 'analyzed' ? (
                        <span className="text-green-500">Analyzed</span>
                      ) : (
                        <span className="text-zinc-500">Not analyzed</span>
                      )}
                    </div>

                    <div className="flex gap-2">
                      <div
                        onClick={() => deleteProduct(product._id)}
                        className="p-1 rounded bg-zinc-900 hover:bg-zinc-800 text-red-500 w-6 h-6 flex items-center justify-center cursor-pointer"
                        title="Delete"
                      >
                        <TrashIcon size={14} className="flex-shrink-0" />
                      </div>
                      <div
                        onClick={() => analyzeProduct(product)}
                        className="p-1 rounded bg-zinc-900 hover:bg-zinc-800 text-blue-500 w-6 h-6 flex items-center justify-center cursor-pointer"
                        title="Analyze"
                      >
                        <BarChart2 size={14} className="flex-shrink-0" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </main>

      {/* Add Product Modal */}
      {showAddProductModal && (
        <div className="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center z-50 p-4">
          <div className="bg-zinc-950 rounded w-full max-w-md border border-zinc-900">
            <div className="flex justify-between items-center border-b border-zinc-900 p-3">
              <h3 className="text-sm font-medium">Add New Product</h3>
              <div
                onClick={() => setShowAddProductModal(false)}
                className="text-zinc-500 hover:text-white w-6 h-6 flex items-center justify-center cursor-pointer"
              >
                <XIcon size={16} className="flex-shrink-0" />
              </div>
            </div>

            <div className="p-4">
              <div className="mb-3">
                <label className="block text-xs text-zinc-400 mb-1">Product Name</label>
                <input
                  type="text"
                  value={formData.name}
                  onChange={(e) => handleFormChange('name', e.target.value)}
                  className="w-full bg-black border border-zinc-900 rounded px-2 py-1 text-sm"
                />
              </div>

              <div className="mb-3">
                <label className="block text-xs text-zinc-400 mb-1">Product URL *</label>
                <input
                  type="text"
                  value={formData.productUrl}
                  onChange={(e) => handleFormChange('productUrl', e.target.value)}
                  className="w-full bg-black border border-zinc-900 rounded px-2 py-1 text-sm"
                  required
                />
              </div>

              {/* Multiple Review Sources */}
              <div className="mb-3">
                <div className="flex justify-between items-center mb-1">
                  <label className="block text-xs text-zinc-400">Review Sources *</label>
                  <div
                    onClick={addReview}
                    className="text-xs text-zinc-500 hover:text-white flex items-center cursor-pointer"
                  >
                    <PlusCircleIcon size={12} className="mr-1" />
                    <span>Add Source</span>
                  </div>
                </div>

                {formData.reviews.map((review, index) => (
                  <div key={index} className="flex mb-1 gap-1">
                    <div className="flex-grow">
                      <input
                        type="text"
                        value={review.url}
                        onChange={(e) => handleReviewChange(index, 'url', e.target.value)}
                        className="w-full bg-black border border-zinc-900 rounded px-2 py-1 text-sm"
                        placeholder="Product reviews, Reddit, forum URL"
                        required={index === 0}
                      />
                    </div>
                    <select
                      value={review.type}
                      onChange={(e) => handleReviewChange(index, 'type', e.target.value)}
                      className="bg-black border border-zinc-900 rounded px-2 py-1 text-sm"
                    >
                      <option value="product">Product Reviews</option>
                      <option value="reddit">Reddit</option>
                      <option value="forum">Forum</option>
                      <option value="other">Other</option>
                    </select>
                    {index > 0 && (
                      <div
                        onClick={() => removeReview(index)}
                        className="p-1 rounded bg-zinc-900 hover:bg-zinc-800 text-red-500 w-6 h-6 flex items-center justify-center cursor-pointer"
                      >
                        <MinusCircleIcon size={12} className="flex-shrink-0" />
                      </div>
                    )}
                  </div>
                ))}
                <p className="text-xs text-zinc-600 mt-1">Add multiple sources like product reviews, Reddit threads, or forum discussions</p>
              </div>

              {/* Competitor Analysis Section - Limited to 2 */}
              <div className="mb-3">
                <div className="flex justify-between items-center mb-1">
                  <label className="block text-xs text-zinc-400">Competitors</label>
                  <div
                    onClick={addCompetitor}
                    className={`text-xs ${formData.competitors.length >= 2 ? 'text-zinc-600 cursor-not-allowed' : 'text-zinc-500 hover:text-white'} flex items-center cursor-pointer`}
                    disabled={formData.competitors.length >= 2}
                  >
                    <PlusCircleIcon size={12} className="mr-1" />
                    <span>Add Competitor</span>
                  </div>
                </div>

                {/* Competitor Gap Analysis Toggle */}
                <div className="flex items-center mb-2">
                  <input
                    type="checkbox"
                    id="useCompetitorGaps"
                    checked={formData.useCompetitorGaps}
                    onChange={(e) => handleFormChange('useCompetitorGaps', e.target.checked)}
                    className="mr-2"
                  />
                  <label htmlFor="useCompetitorGaps" className="text-xs text-zinc-400">
                    Validate idea by analyzing competitor gaps
                  </label>
                </div>

                {formData.competitors.map((competitor, index) => (
                  <div key={index} className="mb-2">
                    <div className="flex mb-1 gap-1">
                      <input
                        type="text"
                        value={competitor.url}
                        onChange={(e) => handleCompetitorChange(index, 'url', e.target.value)}
                        className="flex-grow bg-black border border-zinc-900 rounded px-2 py-1 text-sm"
                        placeholder="Competitor URL"
                      />
                      {index > 0 && (
                        <div
                          onClick={() => removeCompetitor(index)}
                          className="p-1 rounded bg-zinc-900 hover:bg-zinc-800 text-red-500 w-6 h-6 flex items-center justify-center cursor-pointer"
                        >
                          <MinusCircleIcon size={12} className="flex-shrink-0" />
                        </div>
                      )}
                    </div>

                    {formData.useCompetitorGaps && (
                      <textarea
                        value={competitor.gaps}
                        onChange={(e) => handleCompetitorChange(index, 'gaps', e.target.value)}
                        className="w-full bg-black border border-zinc-900 rounded px-2 py-1 text-sm"
                        placeholder="What gaps does this competitor have that your product addresses?"
                        rows="2"
                      />
                    )}
                  </div>
                ))}
                {formData.competitors.length === 2 && (
                  <p className="text-xs text-zinc-600 mt-1">Maximum of 2 competitors reached</p>
                )}
              </div>

              <div className="flex justify-end mt-3">
                <div
                  onClick={(e) => {
                    // Change button appearance
                    e.target.disabled = true;
                    e.target.textContent = "Adding...";
                    e.target.className = "bg-zinc-700 text-zinc-400 px-3 py-1 rounded text-sm cursor-not-allowed";

                    // Call the addProduct function
                    addProduct();

                    // Wait and then refresh the page
                    setTimeout(() => {
                      window.location.reload();
                    }, 4500); // 4.5 seconds delay before refresh
                  }}
                  className="bg-zinc-900 hover:bg-zinc-800 text-white px-3 py-1 rounded text-sm cursor-pointer"
                  disabled={!formData.productUrl || !formData.reviews[0].url}
                >
                  Add
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Results Modal */}
      {showResultsModal && analysisResults && (
        <div className="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center z-50 p-4">
          <div className="bg-zinc-950 rounded w-full max-w-5xl border border-zinc-900 max-h-screen overflow-auto">
            <div className="flex justify-between items-center border-b border-zinc-900 p-3 sticky top-0 bg-zinc-950 z-10">
              <h3 className="text-sm font-medium">Analysis Results</h3>
              <div
                onClick={() => setShowResultsModal(false)}
                className="text-zinc-500 hover:text-white w-6 h-6 flex items-center justify-center cursor-pointer"
              >
                <XIcon size={16} className="flex-shrink-0" />
              </div>
            </div>

            <div className="p-4">
              {/* Summary Section */}
              <CollapsibleSection
                title="Summary"
                content={analysisResults.summary || "No summary available"}
                sectionKey="summary"
                bgColor="bg-zinc-900"
              />

              {/* Customer Needs Section */}
              <CollapsibleSection
                title="Customer Needs and Pain Points"
                content={analysisResults.customerNeeds || "No customer needs data available"}
                sectionKey="customerNeeds"
              />

              {/* Suggestions Section */}
              <CollapsibleSection
                title="Actionable Suggestions"
                content={analysisResults.suggestions || "No suggestions available"}
                sectionKey="suggestions"
              />

              {/* Competitive Gaps Section */}
              <CollapsibleSection
                title="Competitive Gaps"
                content={analysisResults.competitiveGaps || "No competitive gaps data available"}
                sectionKey="competitiveGaps"
              />

              {/* Competitor Analysis Section */}
              <CollapsibleSection
                title="Competitor Analysis"
                content={analysisResults.competitorAnalysis || "No competitor analysis available"}
                sectionKey="competitorAnalysis"
              />

              {/* Sentiment Analysis Section */}
              <CollapsibleSection
                title="Sentiment Analysis"
                content={analysisResults.sentimentAnalysis || "No sentiment analysis available"}
                sectionKey="sentimentAnalysis"
              />

              {/* Key Trends Section */}
              <CollapsibleSection
                title="Key Trends"
                content={analysisResults.keyTrends || "No key trends data available"}
                sectionKey="keyTrends"
              />
            </div>
          </div>
        </div>
      )}
      <GamefiedAIAssistant />
    </div>
  );
};

export default Redstoneland;
