import React, { useEffect,useMemo , useState, useRef } from 'react';
 import { Users, Mail, CheckCircle, Settings, Database, BarChart2, Layout, Search, Bell, ChevronDown, HelpCircle, Globe, Shield, Clock, Activity, AlertTriangle, Send } from 'lucide-react';
 import logo from './logo.png';
 import { useUser } from '@clerk/clerk-react';
 import axios from 'axios';
 import { useNavigate } from 'react-router-dom';
 import { format, parseISO } from 'date-fns';
 import GamefiedAIAssistant from '../redstone/intercom';
 import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
 
 const NavButton = ({ children }) => (
  <button className="px-3 py-2 text-gray-300 hover:text-white hover:bg-gray-800 rounded text-sm font-medium transition-colors">
   {children}
  </button>
 );

 const MetricCard = ({ label, value }) => (
  <div className="bg-white p-4 rounded-lg shadow-sm">
   <div className="text-sm text-gray-600 mb-1">{label}</div>
   <div className="flex items-end space-x-2">
    <div className="text-2xl font-semibold text-gray-900">{value}</div>
   </div>
  </div>
 );

 const QuickAction = ({ icon: Icon, text, onClick }) => (
  <button onClick={onClick} className="flex items-center space-x-2 px-3 py-2 bg-white rounded-lg border border-gray-200 hover:border-blue-500 text-sm font-medium text-gray-700">
   <Icon size={16} />
   <span>{text}</span>
  </button>
 );

 const StatusIndicator = () => (
  <div className="bg-white p-4 rounded-lg shadow-sm">
   <div className="flex items-center justify-between mb-3">
    <h3 className="font-medium text-gray-900">Service Health</h3>
   </div>
   <div className="space-y-2">
    <div className="flex items-center justify-between">
     <span className="text-sm text-gray-600">API Endpoints</span>
     <span className="flex items-center text-green-600">
      <CheckCircle size={16} className="mr-1" /> 99.99%
     </span>
    </div>
    <div className="flex items-center justify-between">
     <span className="text-sm text-gray-600">Email Services</span>
     <span className="flex items-center text-green-600">
      <CheckCircle size={16} className="mr-1" /> 100%
     </span>
    </div>
    <div className="flex items-center justify-between">
     <span className="text-sm text-gray-600">Analytics Pipeline</span>
     <span className="flex items-center text-yellow-600">
      <AlertTriangle size={16} className="mr-1" /> 97.5%
     </span>
    </div>
   </div>
  </div>
 );

 const FeatureCard = ({ icon: Icon, text, description, link, onClick, productionReady = true }) => (
  <a
   onClick={onClick}
   className="block bg-white rounded-lg shadow-sm hover:shadow-md border-l-4 border-transparent hover:border-l-blue-600 transition-all duration-200 no-underline group cursor-pointer"
  >
   <div className="p-6">
    <div className="flex items-center justify-between mb-4">
     <div className="h-12 w-12 bg-blue-50 rounded-lg flex items-center justify-center group-hover:bg-blue-100 transition-colors">
      <Icon size={24} className="text-blue-600" />
     </div>
     <div className="flex items-center space-x-2">
      {productionReady && (
       <span className="bg-green-50 px-3 py-1 rounded-full text-green-700 text-xs font-medium">
        Production Ready
       </span>
      )}
     </div>
    </div>
    <h3 className="text-base font-semibold mb-2 text-gray-900">{text}</h3>
    <p className="text-gray-600 text-sm leading-relaxed">{description}</p>
   </div>
   <div className="px-6 py-4 bg-gray-50 rounded-b-lg border-t border-gray-100">
    <div className="flex justify-between items-center">
     <div className="text-sm font-medium text-blue-600">
      Access Console →
     </div>
    </div>
   </div>
  </a>
 );

 const EnterpriseHeader = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
   setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
   const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
     setIsDropdownOpen(false);
    }
   };

   document.addEventListener("mousedown", handleClickOutside);
   return () => {
    document.removeEventListener("mousedown", handleClickOutside);
   };
  }, [dropdownRef]);

  return (
   <div className="bg-gray-900 sticky top-0 z-10">
    <div className="max-w-7xl mx-auto">
     <div className="flex h-16 items-center justify-between px-4">
      <div className="flex items-center space-x-8">
       <div className="flex items-center space-x-2">
        <img src={logo} alt="EchidnaMail" className="h-8" />
        <span className="text-white font-bold">EchidnaMail</span>
        <span className="text-red-500 font-bold">v2</span>
       </div>
       <div className="relative">
        <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        <input
         type="text"
         placeholder="Search services, documentation, or resources"
         className="pl-10 pr-4 py-2 w-96 bg-gray-800 border border-gray-700 rounded text-gray-200 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
       </div>
      </div>
      <div className="flex items-center space-x-4">
       <NavButton>API Reference</NavButton>
       <NavButton>Enterprise Support</NavButton>
       <button className="p-2 text-gray-300 hover:text-white hover:bg-gray-800 rounded-full relative">
        <Bell size={20} />
        <span className="absolute top-1 right-1 h-2 w-2 bg-blue-500 rounded-full"></span>
       </button>
       <div className="relative" ref={dropdownRef}>
        <button
         onClick={toggleDropdown}
         className="flex items-center space-x-3 ml-4 px-3 py-2 bg-gray-800 rounded cursor-pointer"
        >
         <div className="h-8 w-8 bg-blue-600 rounded-full flex items-center justify-center text-white text-sm font-medium">
          EC
         </div>
         <div>
          <div className="text-gray-200 text-sm">Enterprise</div>
          <div className="text-gray-400 text-xs">Southeast-mb-2</div>
          <div className="text-gray-400 text-xs"></div>
         </div>
         <ChevronDown size={16} className="text-gray-400" />
        </button>
        {isDropdownOpen && (
         <div className="absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none origin-top-right">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="dropdown">
           <button
            className="text-gray-700 flex w-full px-4 py-2 text-left text-sm hover:bg-gray-100" role="menuitem"  onClick={toggleDropdown}
           >
            Southeast-mb-2 <br/> <span className="text-xs text-gray-500">Bengaluru</span>
           </button>
           <button
            className="text-gray-400 flex w-full px-4 py-2 text-left text-sm cursor-not-allowed hover:bg-gray-100" role="menuitem" disabled onClick={toggleDropdown}
           >
            us-west-san Fransisco <br/> <span className="text-xs text-gray-500">San Fransisco (Unavailable)</span>
           </button>
          </div>
         </div>
        )}
       </div>
      </div>
     </div>
    </div>
   </div>
  );
 };



 const EmailSentChart = ({ chartData, loading }) => {
  const [selectedPeriod, setSelectedPeriod] = useState('1 Mo');
  
  const periods = {
    '1 Mo': 30,
    '3 Mo': 90,
    '6 Mo': 180,
    '1 Yr': 365
  };
  
  // Extract the correct data from the API response
  const processedChartData = useMemo(() => {
    // For debugging
    console.log("Raw chartData received:", chartData);
    
    // Check if chartData exists
    if (!chartData) {
      console.log("No chartData provided to component");
      return [];
    }
    
    // Check if chartData is the full API response with nested structure
    if (chartData.success && chartData.data && chartData.data.dailyStats) {
      console.log("Found nested dailyStats in API response");
      return chartData.data.dailyStats;
    }
    
    // If chartData is already the dailyStats array
    if (Array.isArray(chartData)) {
      console.log("chartData is already an array with length:", chartData.length);
      return chartData;
    }
    
    // If chartData.dailyStats exists
    if (chartData.dailyStats && Array.isArray(chartData.dailyStats)) {
      console.log("Found dailyStats property in chartData");
      return chartData.dailyStats;
    }
    
    console.error("Unexpected chartData structure:", chartData);
    return [];
  }, [chartData]);
  
  // Filter data based on selected period
  const filteredData = useMemo(() => {
    if (!processedChartData || processedChartData.length === 0) {
      console.log("No processed chart data available to filter");
      return [];
    }
    
    const daysToShow = periods[selectedPeriod];
    if (!daysToShow) return processedChartData;
    
    // Filter out any items with undefined date
    const validData = processedChartData.filter(item => item && item.date);
    console.log("Valid data items with dates:", validData.length);
    
    if (validData.length === 0) {
      return [];
    }
    
    // Sort data by date in ascending order
    const sortedData = [...validData].sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateA - dateB;
    });
    
    // Get only the last N days based on the selected period
    const result = sortedData.slice(-daysToShow);
    console.log(`Filtered data for ${selectedPeriod}:`, result);
    return result;
  }, [processedChartData, selectedPeriod]);
  
  // Format date for display
  const formattedData = useMemo(() => {
    if (!filteredData || filteredData.length === 0) {
      console.log("No filtered data to format");
      return [];
    }
    
    const result = filteredData.map(item => {
      if (!item || !item.date) {
        console.error("Invalid data item or missing date:", item);
        return {
          name: "Missing Date",
          emails: item?.count || 0,
          campaigns: item?.uniqueCampaigns || 0,
          fullDate: "Unknown"
        };
      }
      
      try {
        // Make sure we have a string
        const dateStr = String(item.date).trim();
        
        // Use parseISO for ISO date strings
        const date = parseISO(dateStr);
        
        if (isNaN(date.getTime())) {
          console.error("Invalid date after parsing:", dateStr);
          throw new Error("Invalid date");
        }
        
        return {
          name: format(date, 'MMM d'),
          emails: item.count || 0,
          campaigns: item.uniqueCampaigns || 0,
          fullDate: item.date
        };
      } catch (error) {
        console.error("Error parsing date:", item.date, error);
        return {
          name: String(item.date || "Unknown"),
          emails: item.count || 0,
          campaigns: item.uniqueCampaigns || 0,
          fullDate: String(item.date || "Unknown")
        };
      }
    });
    
    console.log("Formatted data:", result);
    return result;
  }, [filteredData]);
  
  if (loading) {
    return <p>Loading chart data...</p>;
  }
  
  if (!processedChartData || processedChartData.length === 0) {
    return (
      <div className="w-full">
        <p>No email data available for chart.</p>
        <div className="mt-2 text-sm text-gray-500">
          The API response does not contain any daily statistics.
        </div>
      </div>
    );
  }
  
  return (
    <div className="w-full">
      <div className="mb-4 flex space-x-2">
        {Object.keys(periods).map((period) => (
          <button
            key={period}
            onClick={() => setSelectedPeriod(period)}
            className={`px-3 py-1 text-sm rounded ${
              selectedPeriod === period
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200 hover:bg-gray-300'
            }`}
          >
            {period}
          </button>
        ))}
      </div>
      
      {formattedData.length > 0 ? (
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            data={formattedData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="name"
              tickFormatter={(tick, index) => {
                // Show fewer ticks for better readability
                return index % Math.ceil(formattedData.length / 10) === 0 ? tick : '';
              }}
            />
            <YAxis />
            <Tooltip 
              labelFormatter={(value) => `Date: ${value}`}
              formatter={(value, name) => {
                if (name === 'emails') return [`${value} emails`, 'Emails Sent'];
                if (name === 'campaigns') return [`${value} campaigns`, 'Unique Campaigns'];
                return [value, name];
              }}
            />
            <Legend />
            <Line
              type="monotone"
              dataKey="emails"
              name="Emails Sent"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
              strokeWidth={2}
            />
            <Line
              type="monotone"
              dataKey="campaigns"
              name="Unique Campaigns"
              stroke="#82ca9d"
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <div>
          <p>No data available for the selected period.</p>
          <p className="text-sm text-gray-500 mt-2">
            Selected period: {selectedPeriod} ({periods[selectedPeriod]} days)
          </p>
          <p className="text-sm text-gray-500">
            Available data range: {processedChartData.length > 0 ? 
              `${processedChartData[0]?.date || 'Unknown'} to ${processedChartData[processedChartData.length-1]?.date || 'Unknown'}` : 
              'None'}
          </p>
        </div>
      )}
    </div>
  );
};
 

 const Echidnadash = () => {
  const features = [
   {
    icon: Users,
    text: "Identity & Access Management",
    description: "Enterprise-grade user management with SSO integration, role-based access control, and audit logging",
    link: "/iam",
    redirect: '/echidnamail/organizations',
    productionReady: true
   },
   {
    icon: Mail,
    text: "Communication Suite",
    description: "Advanced email infrastructure with automated workflows, engagement analytics, and deliverability monitoring",
    link: "/communication",
    redirect: '/echidnamail/campaigns',
    productionReady: true
   },
   {
    icon: Database,
    text: "Data Platform",
    description: "Scalable data infrastructure with real-time analytics, ETL pipelines, and compliance controls",
    link: "/data",
    redirect: '/echidnamail/dap',
    productionReady: true
   },
   {
    icon: BarChart2,
    text: "Analytics & Insights",
    description: "Advanced analytics suite with ML-powered predictions, custom dashboards, and automated reporting",
    link: "/analytics",
    redirect: '/echidnamail/analytics',
    productionReady: false
   },
   {
    icon: Layout,
    text: "Workflow Automation",
    description: "Visual workflow builder with pre-built templates, custom actions, and integration capabilities",
    link: "/automation",
    redirect: '/echidnamail/automations',
    productionReady: false
   },
   {
    icon: Settings,
    text: "Enterprise Settings",
    description: "Centralized configuration for security, compliance, and organizational preferences",
    link: "/settings",
    redirect: '/echidnamail/organizations',
    productionReady: true
   }
  ];

  const { user } = useUser();
  const [stats, setStats] = useState({
   totalMailsSent: 0,
   activeCampaigns: 0,
   mailSentRate: '0.00%',
   loading: true,
   error: null,
  });
  const navigate = useNavigate();

  const [chartData, setChartData] = useState([]);
  const [chartLoading, setChartLoading] = useState(true);
  const [chartError, setChartError] = useState(null);
  const [apiResponse, setApiResponse] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      if (!user) return;
      setStats(prevState => ({ ...prevState, loading: true, error: null }));
      try {
        const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/getstats', { cid: user?.id });
        setStats({
          totalMailsSent: response.data.totalMailsSent,
          activeCampaigns: response.data.activeCampaigns,
          mailSentRate: response.data.mailSentRate,
          loading: false,
          error: null,
        });
      } catch (error) {
        console.error('Error fetching stats:', error);
        setStats(prevState => ({ ...prevState, loading: false, error: 'Could not load stats.' }));
      }
    };

    fetchStats();
  }, [user]);



  useEffect(() => {
    const fetchChartData = async () => {
      if (!user) return;
      setChartLoading(true);
      setChartError(null);
      
      try {
        // Just fetch the full year of data once
        const response = await axios.post(`https://web-production-5ee8.up.railway.app/echidna/estats`, {
          cid: user?.id,
          days: 365  // Fetch a full year
        });
        
        // Set the full response to be processed by the chart component
        setApiResponse(response.data);
        setChartLoading(false);
        
      } catch (error) {
        console.error('Error fetching chart data:', error);
        setChartError('Could not load chart data.');
        setChartLoading(false);
      }
    };
    
    fetchChartData();
  }, [user]);


  return (
   <div className="min-h-screen bg-gray-50">
    <EnterpriseHeader />

    <div className="max-w-7xl mx-auto px-4 py-8">
     <div className="flex items-center justify-between mb-8">
      <div>
       <div className="flex items-center space-x-4">
        <h1 className="text-2xl font-semibold text-gray-900">Enterprise Console</h1>
        <span className="px-3 py-1 bg-blue-50 text-blue-700 text-sm font-medium rounded-full">
         Enterprise
        </span>
       </div>
       <p className="mt-2 text-sm text-gray-600">Organization: Echidna Enterprise Solutions · Region: Southeast-mb-2</p>
      </div>
      <div className="flex items-center space-x-4">
       <button className="flex items-center space-x-2 px-4 py-2 bg-white border border-gray-200 rounded-lg text-gray-700 hover:bg-gray-50">
        <HelpCircle size={20} />
        <span className="text-sm font-medium">Documentation</span>
       </button>
       <a href='/echidnamail/campaigns'> <button className="px-4 py-2 bg-blue-600 text-white rounded-lg text-sm font-medium hover:bg-blue-700">
        Launch Quick Setup
       </button>
       </a>
      </div>
     </div>

     <div className="grid grid-cols-4 gap-4 mb-6">
      <MetricCard label="Total Messages Sent" value={stats.loading ? "Loading..." : stats.totalMailsSent}  />
      <MetricCard label="Delivery Rate" value={stats.loading ? "Loading..." : stats.mailSentRate} />
      <MetricCard label="Open Rate" value="NA" />
      <MetricCard label="Active Campaigns" value={stats.loading ? "Loading..." : stats.activeCampaigns} />
     </div>

     <div className="grid grid-cols-3 gap-4 mb-6">
      <div className="col-span-2 bg-white p-4 rounded-lg shadow-sm">
       <h3 className="font-medium text-gray-900 mb-4">Quick Actions</h3>
       <div className="flex space-x-3">
        <QuickAction icon={Mail} text="New Campaign" onClick={() => navigate('/echidnamail/new-campaign')} />
        <QuickAction icon={Users} text="Manage prospects" onClick={() => navigate('/prospect')} />
        <QuickAction icon={Globe} text="Domain Settings" onClick={() => navigate('/echidnamail/domains')} />
        <QuickAction icon={Shield} text="Errors" onClick={() => navigate('/echidnamail/mailfail')} />
        <QuickAction icon={Send} text="View Current Campaigns" onClick={() => navigate('/echidnamail/campaigns')} />
       </div>
      </div>
      <StatusIndicator />
     </div>

     <div className="bg-white p-4 rounded-lg shadow-sm mb-6"> {/* Chart container */}
      <h3 className="font-medium text-gray-900 mb-4">Email Sent Over Time</h3>
      <EmailSentChart chartData={apiResponse} loading={chartLoading} />
      {chartError && <p className="text-red-500 text-sm mt-2">{chartError}</p>}
     </div>


     <h2 className="text-lg font-medium text-gray-900 mb-4">Services & Resources</h2>
     <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {features.map((feature, index) => (
       <FeatureCard
        key={index}
        icon={feature.icon}
        text={feature.text}
        description={feature.description}
        link={feature.link}
        onClick={() => navigate(feature.redirect)}
        productionReady={feature.productionReady}
       />
      ))}
     </div>
    </div>
    <GamefiedAIAssistant />
   </div>
  );
 };

 export default Echidnadash;