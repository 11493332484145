import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { ClerkProvider, SignedIn, SignedOut, RedirectToSignIn } from '@clerk/clerk-react'
import ArcLandingPage from './pages/arc/arc.jsx';
import { Toaster } from 'react-hot-toast';
import IMap from './pages/redstone/Layout.jsx';
import EchidnaSuiteLanding from './pages/Echidnamail/elanding2.jsx';
import Navbar from './components/navbar/Navbar'
import { AgentChat } from './pages/Androforce/agent.jsx';
import EFoundry from './pages/echpilot/efoundry.jsx';
import Echpilot from './pages/echpilot/echpilot.jsx';
import { HelmetProvider } from 'react-helmet-async';
import PrivateRoute from './components/PrivateRoute/PrivateRoute'
import SignUp from './pages/signup/SignUp'
import Redstoneland from './pages/redstone/rdash.jsx';
import LogIn from './pages/login/LogIn'
import ESuccess from './pages/paymentsucess/esuccess'
import ComsDashboard from './pages/echidnamail2/coms.jsx';
import ProspectUpload2 from './pages/echidnamail2/newprosps.jsx';
import Home from './pages/home/Home'
import DataAnalyticsDashboard from './pages/echidnamail2/dap.jsx';
import EnterpriseDashboard from './pages/Echidnamail/Edash.jsx'
import View from './pages/view/View'
import About from './pages/about/About'
import ForgotPassword from './pages/ForgotPassword/ForgotPassword'
import Echdinachat from './pages/cyberlake/echidnachat'
import Echidnamail from './pages/cyberlake/echidnamail'
import PasswordReset from './pages/PasswordReset/PasswordReset'
import Waitlist from './pages/waitlist/Waitlist'
import ProspectsView from './pages/echidnamail2/porsps.jsx';
import Contact from './pages/contact/Contact'
import Dashboard from './pages/dashboard/Dashboard'
import Upload from './pages/addproduct/Upload'
import ViewProduct from './pages/viewproduct/ViewProduct'
import AIDealsRoom from './pages/Echidnamail/aidealroom.jsx'
import Market from './pages/marketplace/Market'
import Preview from './pages/previewobj/prev'
import Published from './pages/Published/Published'
import ViewObject from './pages/VR/vr'
import {NextUIProvider} from "@nextui-org/system";
import ARScene from './pages/AR/ar'
import Arsettings from './pages/arSettings/arsettings'
import Error from './pages/Echidnamail/mailfail.jsx';
import Cyberdash from './pages/cyberlake/cyberlakedash'
import Cyberlakeupload from './pages/cyberlake/cyberupload'
import FuturisticCreditPage from './pages/cyberlake/addcredits'
import AISalesCall from './pages/Androforce/aiteam.jsx';
import PricingCard from './pages/cyberlake/plans'
import PaymentPage from './pages/cyberlake/paymentpage'
import OrganizationsPage from './pages/Echidnamail/Eorg.jsx'
import CompetitorIntelligenceDashboard from './pages/Echidnamail/compana.jsx'
import TlandingPage from './pages/Androforce/tlanding'
import ThreeDViewer from './pages/cyberlake/cyberlake3d'
import PublicView from './pages/cyberlake/cyberpublicview'
import Cyberhome from './pages/cyberhome/cyberhome'
import Neptadash from './pages/neptaverse/neptadash'
import { Theme } from '@radix-ui/themes';
import CampaignsPage from './pages/Echidnamail/Eall.jsx';
import Echidna from './pages/Androforce/echidna'
import BuyCredits from './pages/cyberlake/echidnacredits'
import AgentAna from './pages/echpilot/agentana.jsx';
import PaymentPage5 from './pages/cyberlake/echpayments/5moplan'
import PaymentPage20 from './pages/cyberlake/echpayments/20moplan'
import PaymentPage150 from './pages/cyberlake/echpayments/150moplan'
import ProspectPage from './pages/cyberlake/cyberpro1'
import FrontendGenerator from './pages/neptaverse/codegen'
import EpStats from './pages/echpilot/echpistats.jsx';
import TextGenPay from './pages/Androforce/textgenpay'
import AIResearchWriterApp from './pages/Androforce/resphrase'
import StudyApp from './pages/Androforce/Echstudy'
import UserInfo from './pages/Echidnamail/userinfo.jsx'
import EmailDashboard from './pages/Androforce/echmail'
import CampaignDashboard from './pages/Echidnamail/Ecamp'
import ProspectManagement from './pages/Echidnamail/Elead'
import Analytics from './pages/Echidnamail/Eana'
import Automations from './pages/Echidnamail/Eauto'
import AIModelDeployment from './pages/Androforce/aimodeldeploy'
import RedLanding from './pages/redstone/redlanding.jsx';
import EmailTemplates from './pages/Echidnamail/Etemp'
import CampaignSpecific from './pages/Echidnamail/campaignspecific.jsx'
import ResistanceDashboard from './pages/home/aiwaht.jsx'
import ProspectsPage2 from './pages/echidnamail2/prospectspage2.jsx';
import EmailInbox from './pages/Echidnamail/Emaildata.jsx'
import RPaymentPage from './pages/redstone/rcreds.jsx';
import NewCampaign from './pages/echidnamail2/newcamp.jsx';
import CRM from './pages/Echidnamail/Ecrm.jsx'
import AutonomousWebAgentUI from './pages/Echidnamail/eemp.jsx'
import ProspectManagement1 from './pages/Echidnamail/Prospectvalidation.jsx'

import AIChatInterface from './pages/Androforce/AIchat.jsx'
import ProductDashboard from './pages/dashboard/metadash.jsx'
import RLandingPage from './pages/Androforce/ellm.jsx'
import EchidnaDash from './pages/dashboard/echidnadash.jsx'
import AICRM from './pages/crm/crm.jsx'
import Ecode from './pages/Echidnamail/ecode.jsx'
import TesseraDocs from './pages/blogs/Tessera.jsx';
import Carc from './pages/signup/cacc.jsx';
import DomainManagementDashboard from './pages/Androforce/domain.jsx';
import NPage from './pages/Echidnamail/nlanding.jsx';
import Redstoneland1 from './pages/redstone/rhome.jsx';
import ProdVali from './pages/redstone/prodvali.jsx';
import MyAgents from './pages/echpilot/myagents.jsx';
import Echland from './pages/echpilot/epilot.jsx';

const ProtectedRoute = ({ children }) => {
  return (
      <>
          <SignedIn>
              {children}
          </SignedIn>
          <SignedOut>
              <RedirectToSignIn />
          </SignedOut>
      </>
  );
};

const UnprotectedRoute = ({ children }) => {
  return children;
};

// Modified PublicRoute to show content with optional sign-in
const PublicRoute = ({ children }) => {
  return (
      <>
          <SignedIn>
              {children}
          </SignedIn>
          <SignedOut>
              {children}
          </SignedOut>
      </>
  );
};

function App() {
  return (
<ClerkProvider publishableKey={process.env.REACT_APP_CLERK_PUBLISHABLE_KEY}
signUpForceRedirectUrl ="/creatingacc">
      <NextUIProvider>
          <Theme>
              <Toaster position="top-right" />
              <HelmetProvider>
              <Router>
                  <Routes>
                      {/* --- Unprotected Routes --- */}
                      <Route path="/signup" element={<UnprotectedRoute><SignUp /></UnprotectedRoute>} />
                      <Route path="/login" element={<UnprotectedRoute><LogIn /></UnprotectedRoute>} />
                      <Route path="/forgotpassword" element={<UnprotectedRoute><ForgotPassword /></UnprotectedRoute>} />
                      <Route path="/auth/reset-password/:id/:token" element={<UnprotectedRoute><PasswordReset /></UnprotectedRoute>} />

                      {/* --- Public Routes --- */}
                      
                      <Route path="/" element={<PublicRoute><Home /></PublicRoute>} />
                      <Route path="/view" element={<PublicRoute><View /></PublicRoute>} />
                      <Route path="/contact" element={<PublicRoute><Waitlist /></PublicRoute>} />
                      <Route path="/blogs/tessera" element={<PublicRoute><TesseraDocs /></PublicRoute>} />
                      <Route path="/Echidna-suite" element={<PublicRoute><EchidnaSuiteLanding /></PublicRoute>} />
                      <Route path="/Echidna-LLM" element={<PublicRoute><RLandingPage /></PublicRoute>} />
                      <Route path="/redstone" element={<PublicRoute><RedLanding /></PublicRoute>} />
                      <Route path="/echidna/agent/:agentId" element={<PublicRoute><AgentChat /></PublicRoute>} />
                      <Route path="/Echidna-ai-foundry" element={<PublicRoute><Echland /></PublicRoute>} />
             

                      {/* --- CyberLake Product Routes --- */}
                      <Route path="/cyberlake" element={<PublicRoute><Cyberhome /></PublicRoute>} />
                      <Route path="/cyberlake/:objectname" element={<PublicRoute><PublicView /></PublicRoute>} />
                      <Route path="/cyberlake/privateview/:objectname" element={<ProtectedRoute><ThreeDViewer /></ProtectedRoute>} />
                      <Route path="/cyberlake/upload" element={<ProtectedRoute><Cyberlakeupload /></ProtectedRoute>} />
                      <Route path="/cyberlake/credits" element={<ProtectedRoute><FuturisticCreditPage /></ProtectedRoute>} />
                      <Route path="/cyberlake/pricing" element={<ProtectedRoute><PricingCard /></ProtectedRoute>} />
                      <Route path="/cyberlake/pay/:plan" element={<ProtectedRoute><PaymentPage /></ProtectedRoute>} />
                      <Route path="/cyberlake/chat" element={<ProtectedRoute><Echdinachat /></ProtectedRoute>} />
                      <Route path="/cyberlake/echpayments/5moplan" element={<ProtectedRoute><PaymentPage5 /></ProtectedRoute>} />
                      <Route path="/cyberlake/echpayments/20moplan" element={<ProtectedRoute><PaymentPage20 /></ProtectedRoute>} />
                      <Route path="/cyberlake/echpayments/150moplan" element={<ProtectedRoute><PaymentPage150 /></ProtectedRoute>} />
                       <Route path="/cyberlake/dashboard" element={<ProtectedRoute><Cyberdash /></ProtectedRoute>} /> {/* Assuming Cyberdash is part of Cyberlake Product */}
                       <Route path="/cyberlake/prospectpage" element={<ProtectedRoute><ProspectsPage2 /></ProtectedRoute>} /> {/* Assuming ProspectPage is part of Cyberlake Product */}


                      {/* --- EchidnaMail Product Routes (and potentially Androforce related) --- */}
                      <Route path="/echidnamail" element={<ProtectedRoute><EmailDashboard /></ProtectedRoute>} />
                      <Route path="/echidnamail/new-campaign" element={<ProtectedRoute><NewCampaign /></ProtectedRoute>} />
                      <Route path="/echidnamail/campaigns" element={<ProtectedRoute><CampaignsPage /></ProtectedRoute>} />
                      <Route path="/prospect" element={<ProtectedRoute><ProspectsPage2 /></ProtectedRoute>} />
                      <Route path="/echidnamail/analytics" element={<ProtectedRoute><Analytics /></ProtectedRoute>} />
                      <Route path="/echidnamail/automations" element={<ProtectedRoute><Automations /></ProtectedRoute>} />
                      <Route path="/echidnamail/templates" element={<ProtectedRoute><EmailTemplates /></ProtectedRoute>} />
                      <Route path="/echidnamail/buycredits" element={<ProtectedRoute><BuyCredits /></ProtectedRoute>} />
                      <Route path="/echidnamail/organizations" element={<ProtectedRoute><OrganizationsPage /></ProtectedRoute>} />
                      <Route path="/echidnamail/:token" element={<ProtectedRoute><ESuccess /></ProtectedRoute>} />
                      <Route path="/echidna/:campaign" element={<ProtectedRoute><CampaignSpecific /></ProtectedRoute>} />
                      <Route path="/echidnamail/userinfo" element={<ProtectedRoute><UserInfo /></ProtectedRoute>} />
                      <Route path="/echidnamail/emaildata" element={<ProtectedRoute><EmailInbox /></ProtectedRoute>} />
                      <Route path="/echidnamail/mailfail" element={<ProtectedRoute><Error /></ProtectedRoute>} />
                        <Route path="/echidnamail/dap" element={<ProtectedRoute><DataAnalyticsDashboard /></ProtectedRoute>} />
                      <Route path="/echidnamail/crm" element={<ProtectedRoute><AICRM /></ProtectedRoute>} />
                      <Route path="/echidnamail/domains" element={<ProtectedRoute><DomainManagementDashboard /></ProtectedRoute>} />
                      <Route path="/echidnamail/prospectvalidation" element={<ProtectedRoute><ProspectManagement1 /></ProtectedRoute>} />
                      <Route path="/echidnamail/dashboard" element={<ProtectedRoute><EchidnaDash /></ProtectedRoute>} />
                      <Route path="/Echidnacode" element={<ProtectedRoute><Ecode /></ProtectedRoute>} />
                      <Route path="/echidnamail/aidealroom" element={<ProtectedRoute><AIDealsRoom /></ProtectedRoute>}/>
                      <Route path="/echidnamail/compana" element={<ProtectedRoute><CompetitorIntelligenceDashboard /></ProtectedRoute>}/>
                      <Route path="/echidnamail/eemp" element={<ProtectedRoute><AutonomousWebAgentUI /></ProtectedRoute>}/>
                      <Route path="/communication" element={<ProtectedRoute><ComsDashboard /></ProtectedRoute>} /> {/* Assuming ComsDashboard is related to EchidnaMail/Communication */}
                      <Route path="/prospectupload2" element={<ProtectedRoute><ProspectUpload2 /></ProtectedRoute>} /> {/* Assuming ProspectUpload2 is related to EchidnaMail/Prospects */}
                      <Route path="/prospectsview" element={<ProtectedRoute><ProspectsView /></ProtectedRoute>} /> {/* Assuming ProspectsView is related to EchidnaMail/Prospects */}
                      <Route path="/enterpriseDashboard" element={<ProtectedRoute><EnterpriseDashboard /></ProtectedRoute>}/> {/* Assuming EnterpriseDashboard is related to EchidnaMail Enterprise features */}
                      <Route path="/echidnamail/Ecrm" element={<ProtectedRoute><CRM /></ProtectedRoute>} /> {/* AICRM route already exists, assuming Ecrm is another CRM dashboard for EchidnaMail */}
                      <Route path="/echidnamail/Ecamp" element={<ProtectedRoute><CampaignDashboard /></ProtectedRoute>} /> {/* CampaignDashboard route already exists, assuming Ecamp is another campaign dashboard view */}
                      <Route path="/echidnamail/Elead" element={<ProtectedRoute><ProspectManagement /></ProtectedRoute>} /> {/* ProspectManagement route already exists, assuming Elead is another prospect management view */}
                      <Route path="/echidnamail/Eana" element={<ProtectedRoute><Analytics /></ProtectedRoute>} /> {/* Analytics route already exists, assuming Eana is another analytics dashboard view */}
                      <Route path="/echidnamail/Eauto" element={<ProtectedRoute><Automations /></ProtectedRoute>} /> {/* Automations route already exists, assuming Eauto is another automations dashboard view */}
                      <Route path="/echidnamail/Etemp" element={<ProtectedRoute><EmailTemplates /></ProtectedRoute>} /> {/* EmailTemplates route already exists, assuming Etemp is another email templates view */}
                      <Route path="/ai-sales-team" element={<ProtectedRoute><AISalesCall /></ProtectedRoute>} /> {/* Assuming AISalesCall is related to Androforce/AI Sales Team */}

                     
                      <Route path="/echidna/foundry/stats" element={<ProtectedRoute><EpStats /></ProtectedRoute>} /> {/* Assuming EpStats is a feature under Echidna */}
                      <Route path ="/echidna/efoundry/myagents" element={<ProtectedRoute><MyAgents /></ProtectedRoute>} /> {/* Assuming MyAgents is a feature under Echidna */}
                      <Route path="/echidna/copilot" element={<ProtectedRoute><Echpilot /></ProtectedRoute>} /> {/* Assuming Echidna is a product under Androforce */}
                      <Route path="/echidna/efoundry/dashboard" element={<ProtectedRoute><EFoundry /></ProtectedRoute>} /> {/* Assuming EFoundry is a product under Androforce */}
                      <Route path="/echidna/foundry/agentanalytics" element={<ProtectedRoute><AgentAna /></ProtectedRoute>} /> {/* Assuming AgentAna is a feature under Androforce */}


                      {/* --- Androforce Product Routes (if distinct from EchidnaMail - clarify if these are separate or grouped under EchidnaMail) --- */}
                      <Route path="/textgen" element={<ProtectedRoute><TlandingPage /></ProtectedRoute>} />
                      <Route path="/textgenv0/pay" element={<ProtectedRoute><TextGenPay /></ProtectedRoute>} />
                      <Route path="/echedu" element={<ProtectedRoute><StudyApp /></ProtectedRoute>} />
                      <Route path="/aimodeldeploy" element={<ProtectedRoute><AIModelDeployment /></ProtectedRoute>} />
                      <Route path="/echidna/chat" element={<ProtectedRoute><AIChatInterface /></ProtectedRoute>} />
                      <Route path="/ai" element={<ProtectedRoute><AutonomousWebAgentUI /></ProtectedRoute>} /> {/*  'ai' route also listed under EchidnaMail, clarify if duplicate or different feature */}
                      <Route path="/echidna/codegenv0" element={<ProtectedRoute><FrontendGenerator /></ProtectedRoute>} /> {/* 'echidna/codegenv0' also listed under EchidnaMail, clarify if duplicate or different feature */}
                      <Route path="/echidna/arc" element={<ProtectedRoute><ArcLandingPage /></ProtectedRoute>} /> {/* 'echidna/ellm' also listed under CyberLake, clarify if duplicate or different feature */}
                     
                      <Route path="/redstone/dash" element={<ProtectedRoute><Redstoneland1 /></ProtectedRoute>} /> 
                      <Route path="/redstone/product-validation" element={<ProtectedRoute><ProdVali /></ProtectedRoute>} /> {/* Redstoneland route also listed under Home, clarify if intended for both or one product */}
                      <Route path="/redstone/review-validate" element={<ProtectedRoute><Redstoneland /></ProtectedRoute>} /> {/* Redstoneland route also listed under Home, clarify if intended for both or one product */}
                      <Route path="/redstone/getcredits" element={<ProtectedRoute><RPaymentPage /></ProtectedRoute>} /> {/* RPaymentPage route also listed under CyberLake, clarify if intended for both or one product */}
                    
                    
                      {/* --- Neptaverse Product Routes --- */}z
                      <Route path="/neptaverse" element={<PublicRoute><Neptadash /></PublicRoute>} />
                      <Route path="/echidna/codegenv0" element={<ProtectedRoute><FrontendGenerator /></ProtectedRoute>} /> {/* FrontendGenerator route is also listed under Androforce, clarify if intended for both or one product */}
                     {/* AgentChat route is also listed under Androforce, clarify if intended for both or one product */}

                      {/* --- Metaverse/VR/AR Product Routes (Likely related to CyberLake or General Products) --- */}
                      <Route path="/vr/:objectname" element={<PublicRoute><ViewObject /></PublicRoute>} />
                      <Route path="/ar/:objectname" element={<PublicRoute><ARScene /></PublicRoute>} />
                      <Route path="/preview" element={<ProtectedRoute><Preview /></ProtectedRoute>} /> {/* Assuming Preview is related to VR/AR content creation */}
                      <Route path="/published" element={<ProtectedRoute><Published /></ProtectedRoute>} /> {/* Assuming Published is related to VR/AR content management */}
                      <Route path="/addproduct" element={<ProtectedRoute><Upload /></ProtectedRoute>} /> {/* Assuming addproduct (Upload component) is for Metaverse/VR/AR product uploading */}
                      <Route path="/viewproduct" element={<ProtectedRoute><ViewProduct /></ProtectedRoute>} />{/* Assuming viewproduct (ViewProduct component) is for Metaverse/VR/AR product viewing/management */}
                      <Route path="/dashboard" element={<ProtectedRoute><ProductDashboard /></ProtectedRoute>} /> {/* ProductDashboard - likely dashboard for Metaverse/VR/AR products */}
                      <Route path="/arsettings" element={<ProtectedRoute><Arsettings /></ProtectedRoute>} /> {/* Arsettings - likely settings related to AR features */}
                      <Route path="/market" element={<ProtectedRoute><Market /></ProtectedRoute>} /> {/* Market -  likely marketplace for Metaverse/VR/AR products */}
                      <Route path="/IMAP" element={<ProtectedRoute><IMap /></ProtectedRoute>} />

                      {/* --- Resistance Dashboard (Home/General?) --- */}
                      <Route path="/aiwaht" element={<PublicRoute><ResistanceDashboard /></PublicRoute>} /> {/* Placed under Public Routes as it's on 'home', adjust if it's product-specific and protected */}


                      {/* --- Account Creation/Management Routes --- */}
                      <Route path="/creatingacc" element={<ProtectedRoute><Carc /></ProtectedRoute>} />
                      <Route path="/echidnamail/userinfo" element={<ProtectedRoute><UserInfo /></ProtectedRoute>} /> {/* UserInfo route also listed under EchidnaMail, dedup if needed */}


                      {/* --- Fallback Route --- */}
                      <Route path="*" element={<Navigate to="/login" replace />} />
                  </Routes>
              </Router>
              </HelmetProvider>
          </Theme>
      </NextUIProvider>
  </ClerkProvider>
  );
}

export default App;