import React, { useState, useReducer } from 'react';
import { 
  FileText, 
  Users, 
  Zap, 
  Clock, 
  TrendingUp, 
  AlertTriangle, 
  MessageCircle, 
  Check,
  X,
  PlusCircle,
  Edit2,
  MoreVertical,
  Upload,
  Download,
  Archive
} from 'lucide-react';
import { 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  Tooltip, 
  ResponsiveContainer, 
  PieChart, 
  Pie, 
  Cell, 
  LineChart, 
  Line 
} from 'recharts';

// Deal Management Reducer
const dealReducer = (state, action) => {
  switch (action.type) {
    case 'MOVE_DEAL':
      return {
        ...state,
        deals: state.deals.map(deal => 
          deal.id === action.payload.dealId 
            ? { ...deal, stage: action.payload.newStage }
            : deal
        )
      };
    case 'ADD_DEAL':
      return {
        ...state,
        deals: [...state.deals, action.payload]
      };
    default:
      return state;
  }
};

const AIDealsRoom = () => {
  // Initial state for deals
  const initialState = {
    deals: [
      {
        id: 1,
        name: 'TechCorp Enterprise Solution',
        company: 'TechCorp Inc.',
        stage: 'Prospecting',
        value: 250000,
        probability: 40,
        aiInsight: 'Moderate potential, requires strategic approach'
      },
      {
        id: 2,
        name: 'GlobalInc Cloud Migration',
        company: 'GlobalInc Solutions',
        stage: 'Negotiation',
        value: 750000,
        probability: 65,
        aiInsight: 'High likelihood of closure, proactive follow-up recommended'
      }
    ]
  };

  const [state, dispatch] = useReducer(dealReducer, initialState);

  // Deal Stages for Drag and Drop
  const dealStages = [
    'Prospecting', 
    'Qualification', 
    'Proposal', 
    'Negotiation', 
    'Closure', 
    'Won', 
    'Lost'
  ];

  // Performance Analytics Data
  const performanceData = [
    { name: 'Win Rate', value: 65 },
    { name: 'Avg Deal Size', value: 350000 },
    { name: 'Close Time', value: 45 }
  ];

  // Revenue Projection Data
  const revenueData = [
    { month: 'Jan', revenue: 250000 },
    { month: 'Feb', revenue: 320000 },
    { month: 'Mar', revenue: 450000 },
    { month: 'Apr', revenue: 380000 }
  ];

  return (
    <div className="min-h-screen bg-black text-white p-4">
      <div className="container mx-auto">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold text-gray-200 flex items-center">
            <Zap className="mr-2 text-blue-500" /> AI Deal Room
          </h1>
          <div className="flex space-x-2">
            <button className="bg-blue-600 hover:bg-blue-700 px-3 py-1 rounded text-sm flex items-center">
              <PlusCircle className="mr-1 w-4 h-4" /> New Deal
            </button>
            <button className="bg-gray-800 hover:bg-gray-700 px-3 py-1 rounded text-sm flex items-center">
              <Upload className="mr-1 w-4 h-4" /> Import
            </button>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          {/* Deal Pipeline */}
          <div className="lg:col-span-2 bg-gray-900/70 rounded-lg p-4">
            <h2 className="text-lg font-semibold mb-4 flex items-center">
              <Clock className="mr-2 text-blue-500" /> Deal Pipeline
            </h2>
            <div className="grid grid-cols-7 gap-2">
              {dealStages.map(stage => (
                <div key={stage} className="bg-gray-800 rounded p-2">
                  <h3 className="text-sm font-medium mb-2 text-gray-300">{stage}</h3>
                  {state.deals
                    .filter(deal => deal.stage === stage)
                    .map(deal => (
                      <div 
                        key={deal.id} 
                        className="bg-gray-700 rounded p-2 mb-2 hover:bg-gray-600 cursor-move"
                      >
                        <div className="flex justify-between items-center">
                          <span className="text-xs font-semibold">{deal.name}</span>
                          <MoreVertical className="w-4 h-4 text-gray-400" />
                        </div>
                        <div className="text-xs text-gray-400 mt-1">
                          ${deal.value.toLocaleString()} | {deal.probability}% 
                        </div>
                      </div>
                    ))}
                </div>
              ))}
            </div>
          </div>

          {/* AI Insights & Performance */}
          <div className="space-y-4">
            <div className="bg-gray-900/70 rounded-lg p-4">
              <h2 className="text-lg font-semibold mb-4 flex items-center">
                <AlertTriangle className="mr-2 text-yellow-500" /> AI Recommendations
              </h2>
              <div className="space-y-2">
                {[
                  {
                    title: 'TechCorp Deal',
                    recommendation: 'Schedule technical discovery call',
                    priority: 'Medium'
                  },
                  {
                    title: 'GlobalInc Opportunity',
                    recommendation: 'Prepare detailed proposal',
                    priority: 'High'
                  }
                ].map((insight, index) => (
                  <div 
                    key={index} 
                    className="bg-gray-800 rounded p-2 flex justify-between items-center"
                  >
                    <div>
                      <div className="text-sm font-semibold">{insight.title}</div>
                      <div className="text-xs text-gray-400">{insight.recommendation}</div>
                    </div>
                    <span className={`
                      px-2 py-1 rounded-full text-xs
                      ${insight.priority === 'High' ? 'bg-red-500/10 text-red-400' : 
                        'bg-yellow-500/10 text-yellow-400'}
                    `}>
                      {insight.priority}
                    </span>
                  </div>
                ))}
              </div>
            </div>

            {/* Performance Analytics */}
            <div className="bg-gray-900/70 rounded-lg p-4">
              <h2 className="text-lg font-semibold mb-4 flex items-center">
                <TrendingUp className="mr-2 text-green-500" /> Performance
              </h2>
              <ResponsiveContainer width="100%" height={200}>
                <BarChart data={performanceData}>
                  <XAxis dataKey="name" stroke="#6b7280" />
                  <YAxis stroke="#6b7280" />
                  <Tooltip 
                    contentStyle={{ 
                      backgroundColor: '#1f2937', 
                      border: 'none', 
                      color: 'white' 
                    }} 
                  />
                  <Bar dataKey="value" fill="#3b82f6" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>

        {/* Document & Communication Section */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
          <div className="bg-gray-900/70 rounded-lg p-4">
            <h2 className="text-lg font-semibold mb-4 flex items-center">
              <FileText className="mr-2 text-purple-500" /> Recent Documents
            </h2>
            <div className="space-y-2">
              {[
                { name: 'GlobalInc Proposal', type: 'PDF', size: '2.3 MB' },
                { name: 'TechCorp Contract Draft', type: 'DOCX', size: '1.7 MB' }
              ].map((doc, index) => (
                <div 
                  key={index} 
                  className="flex justify-between items-center bg-gray-800 p-2 rounded"
                >
                  <div className="flex items-center space-x-2">
                    <FileText className="w-5 h-5 text-blue-400" />
                    <div>
                      <div className="text-sm">{doc.name}</div>
                      <div className="text-xs text-gray-400">{doc.type} • {doc.size}</div>
                    </div>
                  </div>
                  <div className="flex space-x-2">
                    <Download className="w-4 h-4 text-gray-400 hover:text-white" />
                    <Edit2 className="w-4 h-4 text-gray-400 hover:text-white" />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="bg-gray-900/70 rounded-lg p-4">
            <h2 className="text-lg font-semibold mb-4 flex items-center">
              <MessageCircle className="mr-2 text-teal-500" /> Recent Communications
            </h2>
            <div className="space-y-2">
              {[
                {
                  sender: 'Sarah Johnson',
                  company: 'TechCorp Inc.',
                  message: 'Follow-up on technical requirements',
                  time: '2h ago'
                },
                {
                  sender: 'Michael Chen',
                  company: 'GlobalInc Solutions',
                  message: 'Requesting additional pricing details',
                  time: '5h ago'
                }
              ].map((comm, index) => (
                <div 
                  key={index} 
                  className="bg-gray-800 p-2 rounded flex justify-between items-center"
                >
                  <div>
                    <div className="text-sm font-semibold">{comm.sender}</div>
                    <div className="text-xs text-gray-400">{comm.company}</div>
                    <div className="text-xs text-gray-500 mt-1">{comm.message}</div>
                  </div>
                  <span className="text-xs text-gray-400">{comm.time}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIDealsRoom;