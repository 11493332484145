import React, { useState, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const MyAgents = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [confirmText, setConfirmText] = useState('');
  const [secretKey, setSecretKey] = useState('');
  const [showKillModal, setShowKillModal] = useState(false);
  const cid = user?.id;

  useEffect(() => {
    if (cid) {
      fetchAgents();
    }
  }, [cid]);

  const fetchAgents = async () => {
    try {
      setLoading(true);
      const response = await axios.post('http://localhost:5001/echidna/myagents', { cid });
      setAgents(response.data.agents || []);
      setError(null);
    } catch (err) {
      setError(err.response?.data?.details || 'Failed to fetch agents');
      setAgents([]);
    } finally {
      setLoading(false);
    }
  };

  const handleUseAgent = (agentId) => {
    // Implement your use agent functionality here
    console.log('Using agent:', agentId);
  };

  const handleViewAgent = (agentId) => {
    navigate(`/echidna/agent/${agentId}`);
  };

  const handleViewStats = (agentId) => {
    navigate(`/echidna/stats/${agentId}`);
  };

  const openKillModal = (agent) => {
    setSelectedAgent(agent);
    setShowKillModal(true);
    setConfirmText('');
    setSecretKey('');
  };

  const handleKillAgent = async () => {
    if (confirmText !== 'i confirm') {
      return;
    }

    try {
      const response = await axios.post('http://localhost:5001/echidna/killagent', {
        secretKey,
        agentId: selectedAgent._id,
        cid
      });

      if (response.data.success) {
        setShowKillModal(false);
        fetchAgents();
      }
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to delete agent');
    }
  };

  if (loading && !agents.length) {
    return (
      <div className="min-h-screen bg-black text-white p-8 flex items-center justify-center">
        <div className="w-6 h-6 border-t-2 border-white rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black text-white p-8">
      <header className="mb-12">
        <h1 className="text-3xl font-light tracking-tight mb-2">My Agents</h1>
        <div className="h-px w-24 bg-gray-800"></div>
      </header>

      {error && (
        <div className="mb-8 p-4 border border-gray-800 bg-gray-900">
          <p className="text-red-500">{error}</p>
        </div>
      )}

      {agents.length === 0 && !loading ? (
        <div className="flex flex-col items-center justify-center h-64">
          <p className="text-gray-500 mb-4">No agents found</p>
          <button 
            className="px-4 py-2 border border-white hover:bg-white hover:text-black transition-colors"
            onClick={() => navigate('/echidna/new-agent')}
          >
            Create an agent
          </button>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {agents.map((agent) => (
            <div 
              key={agent._id} 
              className="border border-gray-800 p-6 hover:border-gray-600 transition-colors"
            >
              <h2 className="text-xl mb-4 font-medium">{agent.agentName}</h2>
              <div className="text-gray-400 text-sm mb-6">
                <p>Created: {new Date(agent.createdAt).toLocaleDateString()}</p>
                <p className="truncate">ID: {agent._id}</p>
              </div>
              
              <div className="grid grid-cols-3 gap-2 mt-4">
               
                <div 
                  onClick={() => handleViewAgent(agent._id)}
                  className="border border-gray-700 py-2 text-center cursor-pointer hover:bg-gray-900 transition-colors"
                >
                  View
                </div>
                <div 
                  onClick={() => handleViewStats(agent._id)}
                  className="border border-gray-700 py-2 text-center cursor-pointer hover:bg-gray-900 transition-colors"
                >
                  Stats
                </div>
              </div>
              
              <div 
                onClick={() => openKillModal(agent)}
                className="mt-4 border border-gray-700 py-2 text-center cursor-pointer hover:bg-red-900 hover:border-red-800 transition-colors text-gray-400 hover:text-red-300"
              >
                Kill Agent
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Kill Agent Modal */}
      {showKillModal && (
        <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-10">
          <div className="bg-gray-900 border border-gray-800 p-6 max-w-md w-full">
            <h2 className="text-xl mb-6 font-light">Kill Agent: {selectedAgent.agentName}</h2>
            
            <div className="mb-6">
              <p className="text-red-500 mb-4">Warning: This action cannot be undone.</p>
              <p className="text-gray-400">All agent data, chats, and interactions will be permanently deleted.</p>
            </div>
            
            <div className="mb-4">
              <label className="block text-sm text-gray-400 mb-2">Type "i confirm" to proceed</label>
              <input
                type="text"
                value={confirmText}
                onChange={(e) => setConfirmText(e.target.value)}
                className="w-full bg-black border border-gray-700 p-2 text-white focus:outline-none focus:border-gray-500"
              />
            </div>
            
            <div className="mb-6">
              <label className="block text-sm text-gray-400 mb-2">Enter Agent Secret Key</label>
              <input
                type="password"
                value={secretKey}
                onChange={(e) => setSecretKey(e.target.value)}
                className="w-full bg-black border border-gray-700 p-2 text-white focus:outline-none focus:border-gray-500"
              />
            </div>
            
            <div className="flex justify-between">
              <button 
                onClick={() => setShowKillModal(false)}
                className="px-4 py-2 border border-gray-700 hover:bg-gray-800 transition-colors"
              >
                Cancel
              </button>
              <button 
                onClick={handleKillAgent}
                disabled={confirmText !== 'i confirm' || !secretKey}
                className={`px-4 py-2 border ${
                  confirmText === 'i confirm' && secretKey 
                    ? 'border-red-800 bg-red-900 hover:bg-red-800' 
                    : 'border-gray-700 bg-gray-800 text-gray-500 cursor-not-allowed'
                } transition-colors`}
              >
                Delete Permanently
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyAgents;