import React, { useState } from 'react';
import { useUser } from '@clerk/clerk-react';
import GamefiedAIAssistant from '../redstone/intercom';

const ArcLandingPage = () => {
  const [url, setUrl] = useState('');
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [result, setResult] = useState(null);
  const [error, setError] = useState('');
  const [showFullReport, setShowFullReport] = useState(false);
  const { user } = useUser();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!url) {
      setError('Please enter a valid URL');
      return;
    }

    setIsAnalyzing(true);
    setError('');

    try {
      new URL(url);

      const response = await fetch('https://web-production-5ee8.up.railway.app/echidna/arc', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          url,
          cid: user?.id,
        }),
      });

      if (!response.ok) {
        throw new Error('Analysis failed. Please try again.');
      }

      const data = await response.json();
      const processedResult = data.success && data.result ? data.result : data;
      setResult(processedResult);
    } catch (err) {
      if (err.name === 'TypeError' && err.message.includes('URL')) {
        setError('Please enter a valid URL including http:// or https://');
      } else {
        setError(err.message || 'Something went wrong. Please try again.');
      }
    } finally {
      setIsAnalyzing(false);
    }
  };

  const viewFullReport = () => {
    setShowFullReport(true);
  };

  const closeFullReport = () => {
    setShowFullReport(false);
  };

  const formatStack = (stack) => {
    if (!stack) return 'Not detected';
    if (Array.isArray(stack)) return stack.join(', ');
    if (typeof stack === 'object') return JSON.stringify(stack);
    return String(stack);
  };

  const calculateOverallScore = (result) => {
    if (!result) return 0;

    let sum = 0;
    let count = 0;

    if (result.performance && typeof result.performance === 'number') {
      sum += result.performance;
      count++;
    }
    if (result.SEO && typeof result.SEO === 'number') {
      sum += result.SEO;
      count++;
    }
    if (result.technical && typeof result.technical === 'number') {
      sum += result.technical;
      count++;
    }

    return count > 0 ? Math.round(sum / count) : 0;
  };

  const getRecommendationsArray = (recommendations, category = null) => {
    if (!recommendations) {
      return [
        "Optimize image sizes to improve load time",
        "Add meta descriptions to improve SEO",
        "Enable HTTP/2 for faster resource loading"
      ];
    }

    if (Array.isArray(recommendations)) {
      return recommendations;
    }

    if (typeof recommendations === 'object') {
      if (category && recommendations[category]) {
        return Array.isArray(recommendations[category]) ? recommendations[category] : [String(recommendations[category])];
      }

      const allRecs = [];
      for (const cat in recommendations) {
        if (recommendations[cat]) {
          if (Array.isArray(recommendations[cat])) {
            allRecs.push(...recommendations[cat]);
          } else if (typeof recommendations[cat] === 'string') {
            allRecs.push(recommendations[cat]);
          }
        }
      }
      return allRecs.length > 0 ? allRecs : ["No specific recommendations available"];
    }

    return [String(recommendations)];
  };

  const renderNumericValue = (value, defaultValue = 'N/A') => {
    if (typeof value === 'number') return value;
    return defaultValue;
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-gray-100 flex flex-col">
      <header className="py-6 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <div className="w-8 h-8 bg-blue-500 rounded-full"></div>
          <span className="font-bold text-xl">Cyberlake Arc</span>
        </div>
        <nav className="hidden md:flex space-x-8">
          <a href="#features" className="hover:text-blue-400 transition-colors">Features</a>
          <a href="#how-it-works" className="hover:text-blue-400 transition-colors">How It Works</a>
          <a href="#pricing" className="hover:text-blue-400 transition-colors">Pricing</a>
        </nav>
      </header>

      <main className="flex-grow flex flex-col items-center justify-center px-4 sm:px-6 lg:px-8 py-12">
        <div className="max-w-3xl w-full text-center mb-12">
          <h1 className="text-4xl sm:text-5xl font-bold mb-6">
            Comprehensive Website Analysis in Seconds
          </h1>
          <p className="text-xl text-gray-300 mb-8">
            Get a complete analysis of your website's performance, SEO, and technical health with a single scan.
          </p>

          <form onSubmit={handleSubmit} className="max-w-2xl mx-auto">
            <div className="flex flex-col sm:flex-row gap-4">
              <input
                type="text"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder="Enter your website URL"
                className="flex-grow px-4 py-3 rounded-lg bg-gray-700 border border-gray-600 text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button
                type="submit"
                disabled={isAnalyzing}
                className="px-6 py-3 bg-blue-600 hover:bg-blue-700 rounded-lg font-medium transition-colors disabled:opacity-70 disabled:cursor-not-allowed"
              >
                {isAnalyzing ? 'Analyzing...' : 'Analyze Site'}
              </button>
            </div>
            {error && <p className="mt-2 text-red-400">{error}</p>}
          </form>
        </div>

        {result && (
          <div className="w-full max-w-4xl bg-gray-800 border border-gray-700 rounded-xl p-6 shadow-lg">
            <div className="flex justify-between items-start mb-6">
              <div>
                <h2 className="text-2xl font-bold">{result.domain || url}</h2>
                <p className="text-gray-400">Analysis completed on {new Date().toLocaleDateString()}</p>
              </div>
              <div className="flex items-center justify-center w-16 h-16 bg-gray-700 rounded-full">
                <span className="text-2xl font-bold">{calculateOverallScore(result)}/10</span>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
              <div className="bg-gray-700 p-4 rounded-lg">
                <h3 className="font-medium mb-2">Performance</h3>
                <div className="flex justify-between">
                  <span>Speed Score</span>
                  <span className={`font-bold ${renderNumericValue(result.performance, 0) >= 7 ? 'text-green-400' : renderNumericValue(result.performance, 0) >= 5 ? 'text-yellow-400' : 'text-red-400'}`}>
                    {renderNumericValue(result.performance, 'N/A')}/10
                  </span>
                </div>
                <div className="mt-2 flex justify-between">
                  <span>Load Time</span>
                  <span>{typeof result.loadTime === 'string' || typeof result.loadTime === 'number' ? result.loadTime : '2.3s'}</span>
                </div>
              </div>

              <div className="bg-gray-700 p-4 rounded-lg">
                <h3 className="font-medium mb-2">SEO</h3>
                <div className="flex justify-between">
                  <span>SEO Score</span>
                  <span className={`font-bold ${renderNumericValue(result.SEO, 0) >= 7 ? 'text-green-400' : renderNumericValue(result.SEO, 0) >= 5 ? 'text-yellow-400' : 'text-red-400'}`}>
                    {renderNumericValue(result.SEO, 'N/A')}/10
                  </span>
                </div>
                <div className="mt-2 flex justify-between">
                  <span>Issues</span>
                  <span>{typeof result.seoIssues === 'string' || typeof result.seoIssues === 'number' ? result.seoIssues : '4'} found</span>
                </div>
              </div>

              <div className="bg-gray-700 p-4 rounded-lg">
                <h3 className="font-medium mb-2">Technical</h3>
                <div className="flex justify-between">
                  <span>Tech Score</span>
                  <span className={`font-bold ${renderNumericValue(result.technical, 0) >= 7 ? 'text-green-400' : renderNumericValue(result.technical, 0) >= 5 ? 'text-yellow-400' : 'text-red-400'}`}>
                    {renderNumericValue(result.technical, 'N/A')}/10
                  </span>
                </div>
                <div className="mt-2 flex justify-between">
                  <span>Stack</span>
                  <span>{formatStack(result.stack)}</span>
                </div>
              </div>
            </div>

            <div className="bg-gray-700 p-4 rounded-lg mb-6">
              <h3 className="font-medium mb-3">Top Recommendations</h3>
              <ul className="space-y-2">
                {result.recommendations && typeof result.recommendations === 'object' && !Array.isArray(result.recommendations)
                  ? Object.keys(result.recommendations).flatMap(category =>
                    Array.isArray(result.recommendations[category])
                      ? result.recommendations[category].slice(0, 1).map((rec, index) => (
                        <li key={`${category}-${index}`} className="flex items-start">
                          <span className="mr-2 text-blue-400">•</span>
                          {rec.details}
                        </li>
                      ))
                      : []
                  ).slice(0, 3)
                  : getRecommendationsArray(result.recommendations).slice(0, 3).map((rec, index) => (
                    <li key={index} className="flex items-start">
                      <span className="mr-2 text-blue-400">•</span>
                      {typeof rec === 'object' ? rec.details : rec}
                    </li>
                  ))
                }
              </ul>
            </div>

            <div className="flex justify-center">
              <button
                onClick={viewFullReport}
                className="px-5 py-2 bg-blue-600 hover:bg-blue-700 rounded-lg font-medium transition-colors">
                View Full Report
              </button>
            </div>
          </div>
        )}

        {showFullReport && result && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
            <div className="bg-gray-800 border border-gray-700 rounded-xl p-6 max-w-4xl w-full max-h-[90vh] overflow-y-auto">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-bold">Full Report: {result.domain || url}</h2>
                <button
                  onClick={closeFullReport}
                  className="p-2 rounded-full hover:bg-gray-700 transition-colors"
                >
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                  </svg>
                </button>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                <div className="bg-gray-700 p-4 rounded-lg">
                  <h3 className="font-medium mb-4 border-b border-gray-600 pb-2">Overview</h3>
                  <div className="space-y-2">
                    <div className="flex justify-between">
                      <span>URL</span>
                      <span className="text-right">{typeof result.url === 'string' ? result.url : url}</span>
                    </div>
                    <div className="flex justify-between">
                      <span>Overall Score</span>
                      <span className={`font-bold ${calculateOverallScore(result) >= 7 ? 'text-green-400' : calculateOverallScore(result) >= 5 ? 'text-yellow-400' : 'text-red-400'}`}>
                        {calculateOverallScore(result)}/10
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span>Analysis Date</span>
                      <span>{new Date(result.timestamp || Date.now()).toLocaleString()}</span>
                    </div>
                  </div>
                </div>

                <div className="bg-gray-700 p-4 rounded-lg">
                  <h3 className="font-medium mb-4 border-b border-gray-600 pb-2">Technology Stack</h3>
                  <div className="space-y-2">
                    <div className="mb-2">
                      <span className="font-medium">Detected Technologies:</span>
                    </div>
                    <ul className="list-disc pl-5 space-y-1">
                      {Array.isArray(result.stack) ?
                        result.stack.map((tech, index) => (
                          <li key={index}>{typeof tech === 'object' ? JSON.stringify(tech) : String(tech)}</li>
                        )) :
                        <li>{formatStack(result.stack)}</li>
                      }
                    </ul>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
                <div className="bg-gray-700 p-4 rounded-lg">
                  <h3 className="font-medium mb-4 border-b border-gray-600 pb-2">Performance Details</h3>
                  <div className="space-y-3">
                    <div>
                      <div className="flex justify-between mb-1">
                        <span>Score</span>
                        <span className={`font-bold ${renderNumericValue(result.performance, 0) >= 7 ? 'text-green-400' : renderNumericValue(result.performance, 0) >= 5 ? 'text-yellow-400' : 'text-red-400'}`}>
                          {renderNumericValue(result.performance, 'N/A')}/10
                        </span>
                      </div>
                      <div className="w-full bg-gray-600 rounded-full h-2">
                        <div
                          className={`h-2 rounded-full ${renderNumericValue(result.performance, 0) >= 7 ? 'bg-green-400' : renderNumericValue(result.performance, 0) >= 5 ? 'bg-yellow-400' : 'bg-red-400'}`}
                          style={{ width: `${renderNumericValue(result.performance, 0) * 10}%` }}
                        ></div>
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <span>Load Time</span>
                      <span>{typeof result.loadTime === 'string' || typeof result.loadTime === 'number' ? result.loadTime : '2.3s'}</span>
                    </div>
                    <div className="flex justify-between">
                      <span>First Contentful Paint</span>
                      <span>0.8s</span>
                    </div>
                    <div className="flex justify-between">
                      <span>Time to Interactive</span>
                      <span>1.2s</span>
                    </div>
                  </div>
                </div>

                <div className="bg-gray-700 p-4 rounded-lg">
                  <h3 className="font-medium mb-4 border-b border-gray-600 pb-2">SEO Details</h3>
                  <div className="space-y-3">
                    <div>
                      <div className="flex justify-between mb-1">
                        <span>Score</span>
                        <span className={`font-bold ${renderNumericValue(result.SEO, 0) >= 7 ? 'text-green-400' : renderNumericValue(result.SEO, 0) >= 5 ? 'text-yellow-400' : 'text-red-400'}`}>
                          {renderNumericValue(result.SEO, 'N/A')}/10
                        </span>
                      </div>
                      <div className="w-full bg-gray-600 rounded-full h-2">
                        <div
                          className={`h-2 rounded-full ${renderNumericValue(result.SEO, 0) >= 7 ? 'bg-green-400' : renderNumericValue(result.SEO, 0) >= 5 ? 'bg-yellow-400' : 'bg-red-400'}`}
                          style={{ width: `${renderNumericValue(result.SEO, 0) * 10}%` }}
                        ></div>
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <span>Issues Found</span>
                      <span>{typeof result.seoIssues === 'string' || typeof result.seoIssues === 'number' ? result.seoIssues : '4'}</span>
                    </div>
                    <div className="flex justify-between">
                      <span>Meta Tags</span>
                      <span>Partial</span>
                    </div>
                    <div className="flex justify-between">
                      <span>Mobile Friendly</span>
                      <span>Yes</span>
                    </div>
                  </div>
                </div>

                <div className="bg-gray-700 p-4 rounded-lg">
                  <h3 className="font-medium mb-4 border-b border-gray-600 pb-2">Technical Details</h3>
                  <div className="space-y-3">
                    <div>
                      <div className="flex justify-between mb-1">
                        <span>Score</span>
                        <span className={`font-bold ${renderNumericValue(result.technical, 0) >= 7 ? 'text-green-400' : renderNumericValue(result.technical, 0) >= 5 ? 'text-yellow-400' : 'text-red-400'}`}>
                          {renderNumericValue(result.technical, 'N/A')}/10
                        </span>
                      </div>
                      <div className="w-full bg-gray-600 rounded-full h-2">
                        <div
                          className={`h-2 rounded-full ${renderNumericValue(result.technical, 0) >= 7 ? 'bg-green-400' : renderNumericValue(result.technical, 0) >= 5 ? 'bg-yellow-400' : 'bg-red-400'}`}
                          style={{ width: `${renderNumericValue(result.technical, 0) * 10}%` }}
                        ></div>
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <span>Security</span>
                      <span>Moderate</span>
                    </div>
                    <div className="flex justify-between">
                      <span>Accessibility</span>
                      <span>Needs Improvement</span>
                    </div>
                    <div className="flex justify-between">
                      <span>Browser Compatibility</span>
                      <span>Good</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-gray-700 p-4 rounded-lg mb-6">
                <h3 className="font-medium mb-4 border-b border-gray-600 pb-2">All Recommendations</h3>
                {result.recommendations && typeof result.recommendations === 'object' && !Array.isArray(result.recommendations) ? (
                  Object.entries(result.recommendations).map(([category, recs]) => (
                    <div key={category} className="mb-5">
                      <h4 className="text-lg font-medium mb-2 text-blue-400 capitalize">{category} Recommendations</h4>
                      <div className="space-y-4">
                        {Array.isArray(recs) ? recs.map((rec, index) => (
                          <div key={index} className="flex items-start">
                            <div className="mr-3 mt-1 flex-shrink-0 w-6 h-6 flex items-center justify-center bg-blue-500 rounded-full text-white font-bold">
                              {index + 1}
                            </div>
                            <div>
                              <p className="font-medium">{rec.details}</p>
                              <p className="text-gray-400 mt-1 text-sm">
                                Implementing this recommendation could improve your {category.toLowerCase()} and overall user experience.
                              </p>
                            </div>
                          </div>
                        )) : (
                          <div className="flex items-start">
                            <div className="mr-3 mt-1 flex-shrink-0 w-6 h-6 flex items-center justify-center bg-blue-500 rounded-full text-white font-bold">
                              1
                            </div>
                            <div>
                              <p className="font-medium">{typeof recs === 'object' ? recs.details : String(recs)}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="space-y-4">
                    {getRecommendationsArray(result.recommendations).map((rec, index) => (
                      <div key={index} className="flex items-start">
                        <div className="mr-3 mt-1 flex-shrink-0 w-6 h-6 flex items-center justify-center bg-blue-500 rounded-full text-white font-bold">
                          {index + 1}
                        </div>
                        <div>
                          <p className="font-medium">{typeof rec === 'object' ? rec.details : rec}</p>
                          <p className="text-gray-400 mt-1 text-sm">
                            Implementing this recommendation could improve your overall site performance and user experience.
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className="flex justify-between">
                <button
                  onClick={closeFullReport}
                  className="px-5 py-2 bg-gray-700 hover:bg-gray-600 rounded-lg font-medium transition-colors">
                  Close Report
                </button>
                <button
                  className="px-5 py-2 bg-blue-600 hover:bg-blue-700 rounded-lg font-medium transition-colors">
                  Download PDF
                </button>
              </div>
            </div>
          </div>
        )}

        {!result && (
          <div className="w-full max-w-4xl mt-12 grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="bg-gray-800 border border-gray-700 p-6 rounded-lg">
              <div className="w-12 h-12 bg-blue-500 rounded-lg mb-4 flex items-center justify-center">
                <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"></path>
                </svg>
              </div>
              <h3 className="text-xl font-bold mb-2">Speed Analysis</h3>
              <p className="text-gray-300">Measure loading performance, time to interactive, and identify bottlenecks.</p>
            </div>

            <div className="bg-gray-800 border border-gray-700 p-6 rounded-lg">
              <div className="w-12 h-12 bg-blue-500 rounded-lg mb-4 flex items-center justify-center">
                <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z"></path>
                </svg>
              </div>
              <h3 className="text-xl font-bold mb-2">SEO Audit</h3>
              <p className="text-gray-300">Check meta tags, headings, link structure, and content optimization.</p>
            </div>

            <div className="bg-gray-800 border border-gray-700 p-6 rounded-lg">
              <div className="w-12 h-12 bg-blue-500 rounded-lg mb-4 flex items-center justify-center">
                <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z"></path>
                </svg>
              </div>
              <h3 className="text-xl font-bold mb-2">Technical Health</h3>
              <p className="text-gray-300">Detect tech stack, security issues, and accessibility compliance.</p>
            </div>
          </div>
        )}
      </main>

      <footer className="py-8 px-4 sm:px-6 lg:px-8 border-t border-gray-700">
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row justify-between items-center">
          <div className="mb-4 md:mb-0">
            <div className="flex items-center space-x-2">
              <div className="w-6 h-6 bg-blue-500 rounded-full"></div>
              <span className="font-medium">Cyberlake Arc</span>
            </div>
          </div>
          <div className="flex space-x-6">
            <a href="#" className="text-gray-400 hover:text-white transition-colors">Privacy</a>
            <a href="#" className="text-gray-400 hover:text-white transition-colors">Terms</a>
            <a href="#" className="text-gray-400 hover:text-white transition-colors">Contact</a>
          </div>
          <div className="mt-4 md:mt-0 text-gray-400 text-sm">
            © 2025 Cyberlake. All rights reserved.
          </div>
        </div>
      </footer>
      <GamefiedAIAssistant />
    </div>
  );
};

export default ArcLandingPage;
