import React, { useState, useCallback } from 'react';
import { Copy, Upload, Send, Star, Target, Briefcase, Brain, Settings, Loader2, Info, X } from 'lucide-react'; // Added X for close icon
import { useUser } from '@clerk/clerk-react';

const Echpilot = () => {
  const [inputData, setInputData] = useState('');
  const [logoUrl, setLogoUrl] = useState('');
  const [agentPurpose, setAgentPurpose] = useState('');
  const [keyResponsibilities, setKeyResponsibilities] = useState('');
  const [communicationStyle, setCommunicationStyle] = useState('');
  const [agentName, setAgentName] = useState('');
  const [constraintsAndEthics, setConstraintsAndEthics] = useState('');
  const [generatedScript, setGeneratedScript] = useState('');
  const [generatedUrls, setGeneratedUrls] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const [lastCopiedText, setLastCopiedText] = useState(''); // To track which text was copied
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [secret, setSecret] = useState('');
  const [showSecret, setShowSecret] = useState(false);
  const [showAgentLinkInfo, setShowAgentLinkInfo] = useState(false);
  const { user } = useUser();

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    const cid = user?.id;
    if (!cid) {
        setError("User not logged in or user ID not available.");
        return;
    }
    setIsLoading(true);
    setError(null);
    setGeneratedUrls(null); // Reset previous results
    setGeneratedScript('');
    setSecret('');
    setShowSecret(false);

    try {
        const response = await fetch('https://web-production-5ee8.up.railway.app/echidna/addagent', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                contextData: inputData,
                logoUrl,
                agentPurpose,
                keyResponsibilities,
                communicationStyle,
                constraintsAndEthics,
                cid,
                agentName // Corrected line: Include agentName
            })
        });

        if (!response.ok) {
            const errorData = await response.json().catch(() => ({ message: 'Agent script generation failed with status: ' + response.status }));
            throw new Error(errorData.message || 'Agent script generation failed');
        }

        const result = await response.json();

        if (!result.agentId || !result.agentLink || !result.componentAgentLink || !result.secret) {
            throw new Error('Incomplete response received from the server.');
        }

        const urls = {
            agentId: result.agentId,
            agentLink: result.agentLink,
            componentAgentLink: result.componentAgentLink
        };
        setGeneratedUrls(urls);
        setSecret(result.secret);

        const componentAgentScript = `<script src="${urls.componentAgentLink}" async defer></script>`;
        setGeneratedScript(componentAgentScript);

    } catch (error) {
        console.error('Error generating agent script:', error);
        setError(error.message || 'An unexpected error occurred');
    } finally {
        setIsLoading(false);
    }
}, [inputData, logoUrl, agentPurpose, keyResponsibilities, communicationStyle, constraintsAndEthics, agentName, user]); // agentName added to dependency array.


  const handleCopyScript = useCallback((textToCopy) => {
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        setLastCopiedText(textToCopy); // Store the text that was just copied
        setIsCopied(true);
        setTimeout(() => {
            // Only reset if the currently copied text matches the one that triggered the timeout
            if (lastCopiedText === textToCopy) {
              setIsCopied(false);
              setLastCopiedText('');
            }
        }, 2000);
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
        // Optionally show an error message to the user
      });
  }, [lastCopiedText]); // Include lastCopiedText in dependencies

  const handleShowSecretKey = useCallback(() => {
    setShowSecret(true);
  }, []);

  const handleShowAgentLinkInfoPopup = useCallback(() => {
    setShowAgentLinkInfo(true);
  }, []);

  const handleCloseAgentLinkInfoPopup = useCallback(() => {
    setShowAgentLinkInfo(false);
  }, []);

  // Reusable Input Component
  const SuggestionInput = useCallback(({
    icon: Icon,
    label,
    placeholder,
    value,
    onChange,
    type = 'text',
    maxLength // Add maxLength prop
  }) => (
    <div className="relative group mb-3"> {/* Added margin-bottom */}
      <label
        className="block mb-1 text-xs font-medium text-gray-400 flex items-center"
      >
        {Icon && <Icon className="mr-1.5 text-gray-600" size={12} />}
        {label}
      </label>
      {type === 'textarea' ? (
        <textarea
          value={value}
          onChange={(e) => onChange(e.target.value)}
          maxLength={maxLength} // Apply maxLength
          className="w-full bg-black/70 border border-gray-800 rounded-md p-2 text-xs text-white
                     focus:outline-none focus:ring-1 focus:ring-gray-600
                     transition-all duration-300 min-h-[60px] resize-y" // Reduced min-height, enabled vertical resize
          placeholder={placeholder}
        />
      ) : (
        <input
          type={type}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          maxLength={maxLength} // Apply maxLength
          className="w-full bg-black/70 border border-gray-800 rounded-md p-2 text-xs text-white
                     focus:outline-none focus:ring-1 focus:ring-gray-600
                     transition-all duration-300"
          placeholder={placeholder}
        />
      )}
      {maxLength && type === 'textarea' && ( // Character counter for textarea
          <span className="absolute bottom-2 right-2 text-[10px] text-gray-500">
             {value.length}/{maxLength}
           </span>
      )}
    </div>
  ), []);


  // Helper function to render copy button state
  const renderCopyButton = (textToCopy, contextId) => (
    <div
      onClick={() => handleCopyScript(textToCopy)}
      className="text-gray-400 hover:text-white transition-colors cursor-pointer flex items-center space-x-1"
    >
      <Copy size={12} />
      {isCopied && lastCopiedText === textToCopy ? (
          <span className="text-green-400 text-[10px]">Copied!</span>
       ) : null
      }
    </div>
  );


  return (
    <div className="min-h-screen bg-black text-white flex items-center justify-center p-4 relative overflow-hidden">
      {/* Dot Pattern Background */}
      <div className="absolute inset-0 pointer-events-none" style={{
        backgroundImage: `
          radial-gradient(#374151 1px, transparent 1px),
          radial-gradient(#374151 1px, transparent 1px)
        `,
        backgroundPosition: '0 0, 25px 25px',
        backgroundSize: '50px 50px',
        opacity: 0.15 // Slightly increased opacity
      }}></div>

      <div className="w-full max-w-3xl bg-black/80 border border-gray-800 rounded-xl shadow-2xl p-6 space-y-4 relative z-10 backdrop-blur-sm">
        <h1 className="text-2xl font-bold text-center mb-4 tracking-tight flex items-center justify-center">
          <Brain className="mr-2 text-gray-500" size={20} />
          Echpilot AI Agent Creator
        </h1>

        {error && (
          <div className="bg-red-900/50 border border-red-700 rounded-md p-3 text-xs text-red-300 mb-4">
            <strong>Error:</strong> {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4"> {/* Changed to gap-x-4 */}
            <div>
              <SuggestionInput
                icon={Star}
                label="Agent Name"
                placeholder="e.g., SupportBot V2, Sales Assistant"
                value={agentName}
                onChange={setAgentName}
                type="text"
                maxLength={50}
              />
              <SuggestionInput
                icon={Target}
                label="Agent Purpose"
                placeholder="Define the primary goal or objective of this agent (e.g., answer customer support questions, qualify leads)."
                value={agentPurpose}
                onChange={setAgentPurpose}
                type="textarea"
                maxLength={500}
              />
            </div>
            <div>
              <SuggestionInput
                icon={Briefcase}
                label="Key Responsibilities"
                placeholder="List specific tasks the agent should perform (e.g., explain pricing, troubleshoot common issues, book meetings)."
                value={keyResponsibilities}
                onChange={setKeyResponsibilities}
                type="textarea"
                maxLength={1000}
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
             <div>
                 <SuggestionInput
                   icon={Star} // Using Star again, consider a different icon if available for style/tone
                   label="Communication Style"
                   placeholder="Describe the desired tone and personality (e.g., Formal and helpful, Casual and friendly, Empathetic)."
                   value={communicationStyle}
                   onChange={setCommunicationStyle}
                   type="textarea" // Changed to textarea for potentially longer descriptions
                   maxLength={500}
                 />
             </div>
             <div>
                 <SuggestionInput
                   icon={Settings}
                   label="Constraints & Ethics"
                   placeholder="Define boundaries, what the agent should NOT do, and ethical guidelines (e.g., Do not give medical advice, Avoid political topics, Be truthful)."
                   value={constraintsAndEthics}
                   onChange={setConstraintsAndEthics}
                   type="textarea" // Changed to textarea
                   maxLength={1000}
                 />
             </div>
          </div>

          <div className="relative"> {/* Added relative positioning for character counter */}
            <label
              htmlFor="context-input"
              className="block mb-1 text-xs font-medium text-gray-400"
            >
              Contextual Data (Max 5000 chars approx ~1000 words)
            </label>
            <textarea
              id="context-input"
              value={inputData}
              onChange={(e) => setInputData(e.target.value)}
              maxLength={5000} // Character limit
              className="w-full bg-black/70 border border-gray-800 rounded-md p-2 text-xs text-white
                         focus:outline-none focus:ring-1 focus:ring-gray-600
                         transition-all duration-300 min-h-[120px] resize-y" // Enabled vertical resize
              placeholder="Provide relevant information, documents, FAQs, or knowledge base content the agent should use..."
            />
             <span className="absolute bottom-2 right-2 text-[10px] text-gray-500">
               {inputData.length}/5000
             </span>
          </div>

          <div> {/* Wrapped Logo URL in its own div for better spacing control */}
              <label
                htmlFor="logo-url"
                className="block mb-1 text-xs font-medium text-gray-400"
              >
                Company Logo URL (Optional)
              </label>
              <div className="relative">
                <input
                  type="url"
                  id="logo-url"
                  value={logoUrl}
                  onChange={(e) => setLogoUrl(e.target.value)}
                  className="w-full bg-black/70 border border-gray-800 rounded-md p-2 text-xs text-white
                             pl-2 pr-8 focus:outline-none focus:ring-1 focus:ring-gray-600
                             transition-all duration-300" // Added padding for icon
                  placeholder="https://example.com/logo.png"
                />
                <Upload className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-500 pointer-events-none" size={14} />
              </div>
           </div>


          <button
            type="submit"
            disabled={isLoading} // Disable button while loading
            className="w-full bg-white text-black rounded-md p-2 text-sm font-semibold // Increased text size
                       hover:bg-gray-300 transition-colors duration-300
                       flex items-center justify-center space-x-1 group
                       disabled:bg-gray-500 disabled:cursor-not-allowed" // Style for disabled state
          >
            {isLoading ? (
              <Loader2 className="mr-1 animate-spin" size={16} />
            ) : (
              <Send className="mr-1" size={16} />
            )}
            {isLoading ? 'Generating Agent...' : 'Generate AI Agent'}
          </button>
        </form>

        {/* --- Display Generated Details --- */}
        {generatedUrls && (
          <div className="mt-6 bg-black/80 border border-gray-800 rounded-lg p-4 space-y-3"> {/* Increased padding and spacing */}
            <h3 className="text-base font-medium text-gray-300 mb-2">Generated AI Agent Details</h3> {/* Increased heading size */}

            {/* Agent ID */}
            <div className="bg-black/60 rounded p-3"> {/* Increased padding */}
              <div className="flex justify-between items-center mb-1">
                <span className="text-sm text-gray-500">Agent ID</span> {/* Increased text size */}
                 {renderCopyButton(generatedUrls.agentId, 'agentId')}
              </div>
              <p className="text-sm text-gray-400 truncate">{generatedUrls.agentId}</p> {/* Increased text size */}
            </div>

            {/* Agent Link (POST API) */}
            <div className="bg-black/60 rounded p-3">
              <div className="flex justify-between items-center mb-1">
                <span className="text-sm text-gray-500">Agent API Endpoint (POST)</span>
                <div className="flex items-center space-x-2">
                   {renderCopyButton(generatedUrls.agentLink, 'agentApiLink')}
                  <div onClick={handleShowAgentLinkInfoPopup} title="Show API Usage Info" className="text-gray-400 hover:text-white transition-colors cursor-pointer">
                    <Info size={14} /> {/* Increased icon size */}
                  </div>
                </div>
              </div>
              <p className="text-sm text-gray-400 truncate">{generatedUrls.agentLink}</p>
              <p className="text-xs text-yellow-500 mt-1"> {/* Adjusted text size and color */}
                Use this URL to interact with the agent programmatically via POST requests. Click <Info size={11} className="inline -mt-0.5"/> for details.
              </p>
            </div>

            {/* Secret Key */}
            {secret && (
              <div className="bg-black/60 rounded p-3 border border-red-900/50"> {/* Added border for emphasis */}
                <div className="flex justify-between items-center mb-1">
                  <span className="text-sm text-red-400 font-medium">Secret Key</span> {/* Highlighted label */}
                  {!showSecret ? (
                    <button onClick={handleShowSecretKey} className="bg-red-700 hover:bg-red-600 text-white text-xs rounded py-1 px-2 focus:outline-none cursor-pointer transition-colors">
                      Show Secret
                    </button>
                  ) : (
                    <div className="flex items-center space-x-2">
                      <p className="text-sm text-red-300 font-mono truncate">{secret}</p> {/* Monospace font */}
                      {renderCopyButton(secret, 'secret')}
                    </div>
                  )}
                </div>
                {!showSecret ? (
                  <p className="text-xs text-red-500 mt-1 font-medium">
                    ⚠️ Important: This secret key is shown only ONCE. Save it securely now. You'll need it for API calls.
                  </p>
                 ) : (
                   <p className="text-xs text-red-500 mt-1">
                    Ensure you have saved this secret key securely. It is required for all API interactions.
                   </p>
                 )
                }
              </div>
            )}

             {/* Component Agent Script Source URL (for reference) */}
             {generatedUrls.componentAgentLink && (
                 <div className="bg-black/60 rounded p-3">
                   <div className="flex justify-between items-center mb-1">
                     <span className="text-sm text-gray-500">Embed Script Source URL</span>
                     {renderCopyButton(generatedUrls.componentAgentLink, 'scriptSrc')}
                   </div>
                   <p className="text-sm text-gray-400 truncate">{generatedUrls.componentAgentLink}</p>
                    <p className="text-xs text-gray-500 mt-1">
                       This is the source URL used within the embeddable script tag below.
                    </p>
                 </div>
             )}


            {/* Tester / Metabase Link */}
            <div className="bg-black/60 rounded p-3">
              <div className="flex justify-between items-center mb-1">
                <span className="text-sm text-gray-500">Tester / Interface Link</span>
                {renderCopyButton(`https://metabees.org/echidna/agent/${generatedUrls.agentId}`, 'testerLink')}
              </div>
              <a
                href={`https://metabees.org/echidna/agent/${generatedUrls.agentId}`}
                target="_blank" // Open in a new tab
                rel="noopener noreferrer" // Security measure for target="_blank"
                className="text-sm text-blue-400 hover:text-blue-300 underline truncate block break-all" // Ensure long IDs wrap/break
              >
                {`https://metabees.org/echidna/agent/${generatedUrls.agentId}`}
              </a>
               <p className="text-xs text-gray-500 mt-1">
                 Click this link to open the agent's testing interface directly in a new tab.
               </p>
            </div>

          </div>
        )}


       {/* Embeddable Script Display */}
        {generatedScript && (
          <div className="mt-6 bg-black/80 border border-gray-800 rounded-lg p-4"> {/* Increased padding */}
            <div className="flex justify-between items-center mb-2"> {/* Added margin bottom */}
              <h3 className="text-base font-medium text-gray-300">Embeddable Script Tag</h3> {/* Increased text size */}
               {renderCopyButton(generatedScript, 'embedScript')}
            </div>
            <pre className="text-sm text-gray-300 overflow-x-auto p-3 bg-black/70 rounded my-1 font-mono"> {/* Monospace font, increased padding/text size */}
              <code>{generatedScript}</code>
            </pre>
            <p className="text-xs text-gray-500 mt-2">
              Copy this <code>&lt;script&gt;</code> tag and paste it into your website's HTML, preferably just before the closing <code>&lt;/body&gt;</code> tag, where you want the AI chat widget to appear.
            </p>
          </div>
        )}


      {/* Agent Link Info Popup */}
      {showAgentLinkInfo && generatedUrls?.agentLink && secret && (
         <div className="fixed inset-0 bg-black/60 backdrop-blur-md flex items-center justify-center z-50 p-4"> {/* Changed inset-0, adjusted blur/bg */}
           <div className="bg-gray-900 border border-gray-700 rounded-lg p-6 max-w-3xl w-full relative overflow-hidden flex flex-col max-h-[90vh]"> {/* Changed bg color, max-width */}
              <div className="flex justify-between items-center mb-4 pb-3 border-b border-gray-700"> {/* Header section */}
                  <h4 className="text-xl font-semibold text-gray-200">Using the Agent API Endpoint</h4> {/* Increased size */}
                  <button onClick={handleCloseAgentLinkInfoPopup} className="text-gray-500 hover:text-gray-300 cursor-pointer" aria-label="Close popup">
                     <X size={24} /> {/* Use X icon */}
                  </button>
              </div>

             <div className="overflow-y-auto pr-2 space-y-5 flex-grow"> {/* Scrollable content area */}
                  <p className="text-sm text-gray-400"> {/* Increased size */}
                      This API endpoint allows you to interact with your agent programmatically. Send a <strong>POST</strong> request containing your unique <strong>Secret Key</strong> and the user's <strong>message</strong> in the JSON body.
                  </p>

                  <div className="space-y-1">
                    <h5 className="text-base font-medium text-gray-300">Endpoint URL:</h5> {/* Increased size */}
                     <pre className="text-sm text-gray-400 bg-gray-800 p-2 rounded overflow-x-auto font-mono"><code>{generatedUrls.agentLink}</code></pre> {/* Adjusted colors/size */}
                  </div>
                  <div className="space-y-1">
                    <h5 className="text-base font-medium text-gray-300">Required JSON Body:</h5>
                     <pre className="text-sm text-gray-400 bg-gray-800 p-3 rounded overflow-x-auto font-mono"><code>{`{
  "secret": "YOUR_SECRET_KEY_HERE",
  "message": "The user's input/message to the agent"
}`}</code></pre>
                    <p className="text-xs text-gray-500">Replace <code>YOUR_SECRET_KEY_HERE</code> with the actual secret key provided.</p>
                  </div>

                  {/* --- Usage Examples --- */}
                  <div>
                     <h5 className="text-lg font-semibold text-gray-300 mb-3 pt-3 border-t border-gray-700">Usage Examples</h5> {/* Section title */}

                     <div className="mb-5">
                       <h6 className="text-base font-medium text-gray-400 mb-1">cURL:</h6>
                       <pre className="text-sm text-gray-400 bg-gray-800 p-3 rounded overflow-x-auto font-mono">
                         <code>{`curl -X POST \\
  -H "Content-Type: application/json" \\
  -d '{
    "secret": "${secret}",
    "message": "Hello, how can you assist me today?"
  }' \\
  "${generatedUrls.agentLink}"`}</code>
                       </pre>
                     </div>

                     <div className="mb-5">
                       <h6 className="text-base font-medium text-gray-400 mb-1">JavaScript (fetch API):</h6>
                       <pre className="text-sm text-gray-400 bg-gray-800 p-3 rounded overflow-x-auto font-mono">
                         <code>
                           {`const agentApiUrl = '${generatedUrls.agentLink}';
const agentSecret = '${secret}'; // Store securely, avoid hardcoding in client-side JS
const userMessage = 'What are your main functions?';

async function callAgent(message) {
  try {
    const response = await fetch(agentApiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        secret: agentSecret,
        message: message
      }),
    });

    if (!response.ok) {
      const errorBody = await response.text();
      throw new Error(\`HTTP error! status: \${response.status}, body: \${errorBody}\`);
    }

    const data = await response.json();
    console.log('Agent Response:', data);
    // Process the agent's reply (e.g., data.reply)
    return data;

  } catch (error) {
    console.error('Error interacting with agent API:', error);
    // Handle error appropriately in UI
  }
}

// Example usage:
callAgent(userMessage);`}
                         </code>
                       </pre>
                       <p className="text-xs text-yellow-500 mt-1">Note: Exposing the secret key directly in client-side JavaScript is insecure. Prefer using this API from a backend server.</p>
                     </div>

                     <div>
                       <h6 className="text-base font-medium text-gray-400 mb-1">Python (requests library):</h6>
                       <pre className="text-sm text-gray-400 bg-gray-800 p-3 rounded overflow-x-auto font-mono">
                         <code>
                           {`import requests
import json
import os # Recommended way to handle secrets

agent_api_url = "${generatedUrls.agentLink}"
# Best practice: Load secret from environment variable or secure config
agent_secret = os.getenv("ECHPILOT_AGENT_SECRET", "${secret}") # Fallback for example only
user_message = "Tell me about your constraints."

payload = {
    "secret": agent_secret,
    "message": user_message
}
headers = {
    'Content-Type': 'application/json'
}

try:
    response = requests.post(agent_api_url, json=payload, headers=headers, timeout=30) # Add timeout
    response.raise_for_status() # Raise HTTPError for bad responses (4xx or 5xx)

    agent_response = response.json()
    print("Agent Response:")
    print(json.dumps(agent_response, indent=2))
    # Example: Access the reply if the key is 'reply'
    # reply_text = agent_response.get('reply', 'No reply found.')
    # print("\\nAgent says:", reply_text)

except requests.exceptions.RequestException as e:
    print(f"Error interacting with agent API: {e}")
except json.JSONDecodeError:
    print(f"Error decoding JSON response: {response.text}")`}
                         </code>
                       </pre>
                     </div>
                  </div>
              </div> {/* End scrollable content */}
           </div>
         </div>
      )}
      {/* --- End Popup --- */}

      </div> {/* End Main Content Card */}
    </div> // End Root Div
  );
};

export default Echpilot;