import React, { useState, useEffect, useRef } from 'react';

function AISalesCall() {
  const [transcript, setTranscript] = useState('');
  const [audioStream, setAudioStream] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorder = useRef(null);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setAudioStream(stream);

      mediaRecorder.current = new MediaRecorder(stream);
      let chunks = [];

      mediaRecorder.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data);
        }
      };

      mediaRecorder.current.onstop = async () => {
        const audioBlob = new Blob(chunks, { type: 'audio/webm' });
        chunks = [];

        const formData = new FormData();
        formData.append('audio', audioBlob, 'recording.webm');

        try {
          const response = await fetch('/api/transcribe', {
            method: 'POST',
            body: formData,
          });

          const data = await response.json();
          setTranscript((prevTranscript) => prevTranscript + ' ' + data.transcript);

          // Simulate AI response (replace with actual AI logic)
          const aiResponse = await fetch('/api/ai-response', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify({transcript: data.transcript})

          });

          const aiData = await aiResponse.json();
          const aiAudio = new Audio(aiData.audioUrl);
          aiAudio.play();

        } catch (error) {
          console.error('Error transcribing audio:', error);
        }
      };

      mediaRecorder.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
      mediaRecorder.current.stop();
      setIsRecording(false);
      if (audioStream) {
        audioStream.getTracks().forEach((track) => track.stop());
        setAudioStream(null);
      }
    }
  };

  return (
    <div>
      <button onClick={isRecording ? stopRecording : startRecording}>
        {isRecording ? 'Stop Recording' : 'Start Recording'}
      </button>
      <p>Transcript:</p>
      <p>{transcript}</p>
    </div>
  );
}

export default AISalesCall;