import React, { useState, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import { BarChart2, AlertCircle, Info, ExternalLink } from 'lucide-react';

const CompetitorAnalysisPage2 = () => {
  const { user } = useUser();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [analysis, setAnalysis] = useState(null);
  const [pipelineExists, setPipelineExists] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    // Check if pipeline exists on component mount
    const checkPipelineExists = async () => {
      if (!user) return;

      try {
        const response = await fetch(`https://web-production-5ee8.up.railway.app/echidna/checkpipeline`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId: user.id }),
        });
        const data = await response.json();

        setPipelineExists(response.ok && data.hasPipeline);
        
        if (!(response.ok && data.hasPipeline)) {
          setError("Data pipeline setup required for competitor analysis.");
        }
      } catch (err) {
        console.error("Error checking pipeline existence:", err);
        setError("Could not verify data pipeline status.");
      }
    };

    checkPipelineExists();
  }, [user]);

  const runCompetitorAnalysis = async () => {
    if (!user) {
      setError("User authentication required.");
      return;
    }

    if (!pipelineExists) {
      setError("Data pipeline setup required for competitor analysis.");
      return;
    }

    setLoading(true);
    setError(null);
    setAnalysis(null);
    
    // Progress indicator
    const interval = setInterval(() => {
      setProgress(prev => {
        const newProgress = prev + 10;
        return newProgress >= 90 ? 90 : newProgress;
      });
    }, 1000);

    try {
      const response = await fetch(`https://web-production-5ee8.up.railway.app/echidna/competitor-analysis/${user.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();

      clearInterval(interval);
      setProgress(100);

      if (response.ok) {
        setTimeout(() => {
          setAnalysis(data.analysis);
        }, 500);
      } else {
        setError(data.message || 'Unable to generate competitor analysis');
        console.error('Analysis failed:', data.message);
      }
    } catch (err) {
      clearInterval(interval);
      setError('Server connection error. Please try again later.');
      console.error('Error fetching competitor analysis:', err);
    } finally {
      setTimeout(() => {
        setLoading(false);
        setProgress(0);
      }, 500);
    }
  };

  // Function to safely render a value, handling objects and arrays
  const renderValue = (value) => {
    if (value === null || value === undefined) {
      return "N/A";
    }
    
    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        return (
          <ul className="list-disc pl-5 space-y-1">
            {value.map((item, index) => (
              <li key={index}>{renderValue(item)}</li>
            ))}
          </ul>
        );
      }
      
      // If it has title and description
      if (value.title && value.description) {
        return (
          <div className="p-3 bg-gray-50 rounded-md">
            <h5 className="font-medium text-gray-800">{value.title}</h5>
            <p className="text-gray-600">{value.description}</p>
          </div>
        );
      }
      
      // For other objects
      return (
        <div className="pl-4 border-l-2 border-gray-200">
          {Object.entries(value).map(([subKey, subValue]) => (
            <div key={subKey} className="mb-2">
              <span className="font-medium text-gray-700">{subKey.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}:</span>{' '}
              {renderValue(subValue)}
            </div>
          ))}
        </div>
      );
    }
    
    return String(value);
  };

  // Function to render each analysis section
  const renderAnalysisSection = (title, data, icon) => {
    if (!data) return null;
    
    return (
      <div className="bg-white rounded-lg shadow-md p-6 mb-6 border border-gray-100">
        <div className="flex items-center mb-4 pb-3 border-b border-gray-100">
          {icon && <div className="mr-3">{icon}</div>}
          <h3 className="text-xl font-semibold text-gray-900">{title}</h3>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {Object.entries(data).map(([key, value]) => (
            <div key={key} className="p-4 border border-gray-100 rounded-lg bg-gray-50">
              <h4 className="text-md font-medium text-gray-800 mb-2">
                {key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
              </h4>
              <div className="text-gray-600">
                {renderValue(value)}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderCompetitorSection = () => {
    if (!analysis || !analysis.competitors || analysis.competitors.length === 0) return null;
    
    return (
      <div className="bg-white rounded-lg shadow-md p-6 border border-gray-100">
        <div className="flex items-center mb-4 pb-3 border-b border-gray-100">
          <ExternalLink size={20} className="text-gray-600 mr-3" />
          <h3 className="text-xl font-semibold text-gray-900">Competitor Data</h3>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {analysis.competitors.map((competitor, index) => (
            <div key={index} className="border border-gray-200 rounded-lg p-4">
              <h4 className="text-md font-medium text-gray-800 mb-3">
                {new URL(competitor.url).hostname.replace('www.', '')}
              </h4>
              
              {competitor.snippets && Array.isArray(competitor.snippets) && competitor.snippets.length > 0 ? (
                <ul className="space-y-2">
                  {competitor.snippets.map((snippet, idx) => (
                    <li key={idx} className="p-2 bg-gray-50 rounded text-sm text-gray-600">
                      {renderValue(snippet)}
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-gray-500 italic">No key data available</p>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="flex-1 p-6 bg-gray-50">
      <div className="max-w-4xl mx-auto">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">Competitor Analysis</h2>
        
        <div className="bg-white rounded-lg shadow-md p-6 mb-8 border border-gray-100">
          <div className="flex items-start">
            <div className="flex-1">
              <h3 className="text-lg font-semibold text-gray-800 mb-3">Market Positioning Analysis</h3>
              <p className="text-gray-600 mb-6">
                Analyze your competitive landscape to identify strengths, weaknesses, and market opportunities.
              </p>
              
              <div className="mb-6">
                <h4 className="text-md font-medium text-gray-700 mb-2">Analysis Components:</h4>
                <ul className="grid grid-cols-1 md:grid-cols-2 gap-2">
                  <li className="text-gray-600 flex items-center">
                    <div className="w-1 h-4 bg-blue-500 mr-2"></div>
                    Competitive positioning
                  </li>
                  <li className="text-gray-600 flex items-center">
                    <div className="w-1 h-4 bg-blue-500 mr-2"></div>
                    Strengths & weaknesses assessment
                  </li>
                  <li className="text-gray-600 flex items-center">
                    <div className="w-1 h-4 bg-blue-500 mr-2"></div>
                    Content effectiveness evaluation
                  </li>
                  <li className="text-gray-600 flex items-center">
                    <div className="w-1 h-4 bg-blue-500 mr-2"></div>
                    Market opportunities & threats
                  </li>
                </ul>
              </div>

              {error && (
                <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded mb-4 flex items-center" role="alert">
                  <AlertCircle size={18} className="mr-2" />
                  <span className="text-sm">{error}</span>
                </div>
              )}

              {!pipelineExists && (
                <div className="bg-yellow-50 border border-yellow-200 text-yellow-700 px-4 py-3 rounded mb-4 flex items-center" role="alert">
                  <Info size={18} className="mr-2" />
                  <span className="text-sm">Data pipeline setup required before running analysis.</span>
                </div>
              )}

              {loading && (
                <div className="mb-4">
                  <div className="flex justify-between items-center mb-1">
                    <span className="text-sm text-gray-600">Analyzing competitors</span>
                    <span className="text-sm text-gray-600">{progress}%</span>
                  </div>
                  <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
                    <div 
                      className="h-full bg-blue-500 rounded-full transition-all duration-500"
                      style={{ width: `${progress}%` }}
                    ></div>
                  </div>
                </div>
              )}

              <button
                onClick={runCompetitorAnalysis}
                disabled={loading || !pipelineExists}
                className="inline-flex justify-center items-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-gray-400 disabled:cursor-not-allowed"
              >
                {loading ? (
                  <>
                    <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg> 
                    Processing Analysis
                  </>
                ) : (
                  "Run Competitor Analysis"
                )}
              </button>
            </div>
            
            <div className="hidden md:block ml-6">
              <BarChart2 size={100} className="text-gray-300" />
            </div>
          </div>
        </div>

        {analysis && (
          <div className="mb-8">
            <div className="bg-blue-50 rounded-lg shadow-md p-6 mb-6 border border-blue-100">
              <h3 className="text-lg font-semibold text-gray-800 mb-3">Analysis Summary</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="p-3 bg-white rounded-md border border-gray-100">
                  <span className="text-sm text-gray-500">Competitors Analyzed</span>
                  <p className="text-xl font-semibold text-gray-900">{analysis.summary.totalCompetitorsAnalyzed}</p>
                </div>
                <div className="p-3 bg-white rounded-md border border-gray-100">
                  <span className="text-sm text-gray-500">Analysis Date</span>
                  <p className="text-xl font-semibold text-gray-900">{new Date(analysis.summary.timestamp).toLocaleDateString()}</p>
                </div>
              </div>
            </div>

            {renderAnalysisSection("Strengths & Weaknesses", analysis.strengthsWeaknesses)}
            {renderAnalysisSection("Market Positioning", analysis.marketPositioning)}
            {renderAnalysisSection("Content Analysis", analysis.contentAnalysis)}
            {renderAnalysisSection("Opportunities & Threats", analysis.opportunitiesThreats)}
            {renderAnalysisSection("Technical Insights", analysis.technicalInsights)}
            {renderCompetitorSection()}
          </div>
        )}
      </div>
    </div>
  );
};

export default CompetitorAnalysisPage2;