import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useUser } from '@clerk/clerk-react';
import { Users, Mail, CheckCircle, Settings, Database, BarChart2, Layout, Search, Bell, ChevronDown, HelpCircle, Globe, Shield, Clock, Activity, AlertTriangle, Plus, XCircle } from 'lucide-react';
import logo from './logo.png';
import { motion, AnimatePresence } from 'framer-motion';
import GamefiedAIAssistant from '../redstone/intercom';

const NavButton = ({ children }) => (
    <button className="px-3 py-2 text-gray-300 rounded text-sm font-medium transition-colors">
        {children}
    </button>
);

const MetricCard = ({ label, value, trend, color = "blue" }) => (
    <div className="bg-white p-4 rounded-lg shadow-sm">
        <div className="text-sm text-gray-600 mb-1">{label}</div>
        <div className="flex items-end space-x-2">
            <div className="text-2xl font-semibold text-gray-900">{value}</div>
            <div className={`text-sm ${trend >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                {trend >= 0 ? '+' : ''}{trend}%
            </div>
        </div>
    </div>
);

const QuickAction = ({ icon: Icon, text }) => (
    <button className="flex items-center space-x-2 px-3 py-2 bg-white rounded-lg border border-gray-200 text-sm font-medium text-gray-700">
        <Icon size={16} />
        <span>{text}</span>
    </button>
);

const StatusIndicator = () => (
    <div className="bg-white p-4 rounded-lg shadow-sm">
        <div className="flex items-center justify-between mb-3">
            <h3 className="font-medium text-gray-900">Service Health</h3>
            <span className="text-sm text-blue-600">View History</span>
        </div>
        <div className="space-y-2">
            <div className="flex items-center justify-between">
                <span className="text-sm text-gray-600">API Endpoints</span>
                <span className="flex items-center text-green-600">
                    <CheckCircle size={16} className="mr-1" /> 99.99%
                </span>
            </div>
            <div className="flex items-center justify-between">
                <span className="text-sm text-gray-600">Email Services</span>
                <span className="flex items-center text-green-600">
                    <CheckCircle size={16} className="mr-1" /> 100%
                </span>
            </div>
            <div className="flex items-center justify-between">
                <span className="text-sm text-gray-600">Analytics Pipeline</span>
                <span className="flex items-center text-yellow-600">
                    <AlertTriangle size={16} className="mr-1" /> 97.5%
                </span>
            </div>
        </div>
    </div>
);

const FeatureCard = ({ icon: Icon, text, description, link }) => (
    <a
        href={link}
        className="block bg-white rounded-lg shadow-sm border-l-4 border-transparent transition-all duration-200 no-underline group"
    >
        <div className="p-6">
            <div className="flex items-center justify-between mb-4">
                <div className="h-12 w-12 bg-blue-50 rounded-lg flex items-center justify-center transition-colors">
                    <Icon size={24} className="text-blue-600" />
                </div>
                <div className="flex items-center space-x-2">
                    <span className="bg-green-50 px-3 py-1 rounded-full text-green-700 text-xs font-medium">
                        Production Ready
                    </span>
                </div>
            </div>
            <h3 className="text-base font-semibold mb-2 text-gray-900">{text}</h3>
            <p className="text-gray-600 text-sm leading-relaxed">{description}</p>
        </div>
        <div className="px-6 py-4 bg-gray-50 rounded-b-lg border-t border-gray-100">
            <div className="flex justify-between items-center">
                <div className="text-sm font-medium text-blue-600">
                    Access Console →
                </div>
            </div>
        </div>
    </a>
);

const EnterpriseHeader = () => (
    <div className="bg-gray-900 sticky top-0 z-10">
        <div className="max-w-7xl mx-auto">
            <div className="flex h-16 items-center justify-between px-4">
                <div className="flex items-center space-x-8">
                    <div className="flex items-center space-x-2">
                        <img src={logo} alt="EchidnaMail" className="h-8" />
                        <span className="text-white font-bold">EchidnaMail</span>
                        <span className="text-red-500 font-bold">v2</span>
                    </div>
                    <div className="relative">
                        <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                        <input
                            type="text"
                            placeholder="Search services, documentation, or resources"
                            className="pl-10 pr-4 py-2 w-96 bg-gray-800 border border-gray-700 rounded text-gray-200 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-white text-gray-700"
                        />
                    </div>
                </div>
                <div className="flex items-center space-x-4">
                    <NavButton>API Reference</NavButton>
                    <NavButton>Enterprise Support</NavButton>
                    <button className="p-2 text-gray-300 rounded-full relative">
                        <Bell size={20} />
                        <span className="absolute top-1 right-1 h-2 w-2 bg-blue-500 rounded-full"></span>
                    </button>
                    <div className="flex items-center space-x-3 ml-4 px-3 py-2 bg-gray-800 rounded cursor-pointer">
                        <div className="h-8 w-8 bg-blue-600 rounded-full flex items-center justify-center text-white text-sm font-medium">
                            EC
                        </div>
                        <div>
                            <div className="text-gray-200 text-sm">Enterprise</div>
                            <div className="text-gray-400 text-xs">us-west-2</div>
                        </div>
                        <ChevronDown size={16} className="text-gray-400" />
                    </div>
                </div>
            </div>
        </div>
    </div>
);


const DomainManagementDashboard = () => {
    const { user } = useUser();

    const [verificationStatus, setVerificationStatus] = useState(false);
    const [domainInfo, setDomainInfo] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [showConfigureDomainPopup, setShowConfigureDomainPopup] = useState(false);
    const [selectedService, setSelectedService] = useState({ name: 'SMTPPro', host: 'smtppro.net' });
    const [customHost, setCustomHost] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [view, setView] = useState('main');
    const [showDocumentationPopup, setShowDocumentationPopup] = useState(false);
    const [currentDocStep, setCurrentDocStep] = useState(0);

    const handleAddDomain = async () => {
        setError('');
        setLoading(true);
        try {
            await axios.post('https://web-production-5ee8.up.railway.app/auth/configuredomain', {
                email,
                password,
                host: selectedService.name === 'Custom' ? customHost : selectedService.host,
                cid: user?.id,
            });
            setView('main');
            setShowConfigureDomainPopup(false);
            await fetchDomainInfo();
            await checkDomainVerification();
        } catch (err) {
            setError('Failed to add domain. Please check your inputs and try again.');
            console.error("Add domain error:", err);
        } finally {
            setLoading(false);
        }
    };
    const ConfigureDomainPopup = () => {
        const services = [
            { name: 'SendGrid', host: 'smtp.sendgrid.net' },
            { name: 'MailGun', host: 'smtp.mailgun.org' },
            { name: 'Yahoo', host: 'smtp.mail.yahoo.com' },
            { name: 'Gmail', host: 'smtp.gmail.com' },
            { name: 'Outlook', host: 'smtp.office365.com' },
            { name: 'Custom' }
        ];
    
        // Use React.useState directly to ensure no unnecessary re-renders
        const [localEmail, setLocalEmail] = useState(email);
        const [localPassword, setLocalPassword] = useState(password);
    
        // Update parent state only when the popup is submitted or closed
        const handleEmailChange = (e) => {
            const newEmail = e.target.value;
            setLocalEmail(newEmail);
        };
    
        const handlePasswordChange = (e) => {
            const newPassword = e.target.value;
            setLocalPassword(newPassword);
        };
    
        // Modify handleAddDomain to use the local state
        const handleSubmit = async () => {
            // Update the parent state before submitting
            setEmail(localEmail);
            setPassword(localPassword);
            
            // Call the original handleAddDomain
            await handleAddDomain();
        };
    
        return (
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center">
                <div className="bg-white p-8 rounded-xl shadow-xl w-full max-w-md">
                    <div className="flex justify-between items-center mb-6">
                        <h2 className="text-xl font-semibold text-gray-900">Configure New Domain</h2>
                        <button 
                            onClick={() => {
                                // Reset local state when closing
                                setEmail(localEmail);
                                setPassword(localPassword);
                                setShowConfigureDomainPopup(false);
                            }} 
                            className="text-gray-500 transition-colors"
                        >
                            <XCircle size={20} />
                        </button>
                    </div>
    
                    <div className="mb-5">
                        <label htmlFor="service" className="block text-sm font-medium text-gray-700 mb-2">Service Provider</label>
                        <select
                            id="service"
                            className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md bg-white text-gray-700"
                            value={selectedService.name}
                            onChange={(e) => {
                                const service = services.find(s => s.name === e.target.value) || { name: e.target.value };
                                setSelectedService(service);
                            }}
                        >
                            {services.map(service => (
                                <option key={service.name} value={service.name}>{service.name}</option>
                            ))}
                        </select>
                    </div>
    
                    {selectedService.name === 'Custom' && (
                        <div className="mb-5">
                            <label htmlFor="customHost" className="block text-sm font-medium text-gray-700 mb-2">Custom Host</label>
                            <input
                                type="text"
                                id="customHost"
                                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md bg-white text-gray-700"
                                placeholder="e.g., your-smtp-server.com"
                                value={customHost}
                                onChange={(e) => setCustomHost(e.target.value)}
                            />
                        </div>
                    )}
    
                    <div className="mb-5">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-2">Email/Username</label>
                        <input
                            type="email"
                            id="email"
                            className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md bg-white text-gray-700"
                            placeholder="your-email@example.com"
                            value={localEmail}
                            onChange={handleEmailChange}
                        />
                    </div>
    
                    <div className="mb-5">
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-2">Password/API Key</label>
                        <input
                            type="password"
                            id="password"
                            className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md bg-white text-gray-700"
                            value={localPassword}
                            onChange={handlePasswordChange}
                        />
                    </div>
    
                    <div className="mt-8 flex justify-end">
                        <button
                            onClick={() => setShowConfigureDomainPopup(false)}
                            type="button"
                            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 transition-colors mr-3"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleSubmit}
                            type="submit"
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 transition-colors"
                            disabled={loading}
                        >
                            {loading ? 'Adding...' : 'Add Domain'}
                        </button>
                    </div>
                    {error && <p className="text-red-500 text-sm mt-3 text-center">{error}</p>}
                </div>
            </div>
        );
    };

   
    const checkDomainVerification = async () => {
        if (!user?.id) {
            console.log("checkDomainVerification skipped: no user.");
            return;
        }
    
        try {
            console.log("Calling checkDomainVerification API...");
            const response = await axios.post('http://localhost:5001/echidna/verifycheck', {
                cid: user?.id,
            });
    
            console.log("checkDomainVerification API Response:", response.data);
    
            if (response.data?.success) {
                const isVerified = response.data.verified;
                setVerificationStatus(isVerified); // Set the verification status
    
                setDomainInfo(prevDomainInfo => {
                    const updatedDomainInfo = prevDomainInfo.map(domain => {
                        const newDomain = {
                            ...domain,
                            verified: isVerified,
                            status: isVerified ? 'Verified' : 'Pending'
                        };
                        console.log("Updated domain:", newDomain);
                        return newDomain;
                    });
    
                    console.log("Full updated domain info:", updatedDomainInfo);
                    return updatedDomainInfo;
                });
            }
        } catch (err) {
            console.error("Verification check error:", err);
            setError('Failed to check domain verification status.');
        }
    };
    
    const fetchDomainInfo = async () => {
        if (!user?.id) return;
        setLoading(true);
        setError('');
        try {
            const response = await axios.post('https://web-production-5ee8.up.railway.app/auth/domaininfo', {
                cid: user?.id,
            });
    
            let newDomainInfo = [];
            if (Array.isArray(response.data)) {
                newDomainInfo = response.data;
            } else if (response.data && response.data.email) {
                newDomainInfo = [response.data];
            }
    
            setDomainInfo(newDomainInfo);
            return newDomainInfo; // Return the data
        } catch (err) {
            setError('Failed to fetch domain information.');
            console.error("Fetch domain info error:", err);
            return [];
        } finally {
            setLoading(false);
        }
    };


  

    const handleVerifyDomain = async (domainToVerify) => {
        setError('');
        setLoading(true);
        try {
            const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/verifydomain', {
                cid: user?.id,
            });
    
            if (response.data.success) {
                // Update the verified status of the specific domain in domainInfo
                setDomainInfo(prevDomainInfo => prevDomainInfo.map(domain => 
                    domain.email === domainToVerify.email? {...domain, verified: true }: domain
                ));
            } else {
                setError(response.data.message || 'Failed to verify domain. Please try again.');
            }
        } catch (err) {
            setError('Failed to verify domain. Please try again.');
            console.error("Verify domain error:", err);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        const initializeDomains = async () => {
            if (user?.id) {
                try {
                    // First fetch domain info
                    await fetchDomainInfo();
                    // Then check verification after we have the domain info
                    await checkDomainVerification();
                } catch (error) {
                    console.error("Initialization error:", error);
                }
            }
        };
    
        initializeDomains();
    }, [user]);


    const DomainList = () => {
        return (
            <div className="bg-white p-6 rounded-lg shadow-md">
                <div className="flex items-center justify-between mb-5">
                    <h3 className="font-semibold text-lg text-gray-900">Manage Domains</h3>
                    {domainInfo.length === 0 && (
                        <button
                            onClick={() => setShowConfigureDomainPopup(true)}
                            className="px-4 py-2 bg-blue-600 text-white rounded-md text-sm font-medium transition-colors flex items-center space-x-2"
                        >
                            <Plus size={16} />
                            <span>Add Domain</span>
                        </button>
                    )}
                </div>
                {error && <p className="text-red-500 text-sm mb-3">{error}</p>}
                {loading ? (
                    <p>Loading domains...</p>
                ) : (
                    <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="px-4 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                                        Domain
                                    </th>
                                    <th scope="col" className="px-4 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                                        Status
                                    </th>
                                    <th scope="col" className="px-4 py-3 text-right text-xs font-semibold text-gray-700 uppercase tracking-wider">
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {domainInfo.map((domain, index) => (
                                    <tr key={index} className="transition-colors">
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                            {domain.email}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                                            <span className={verificationStatus ? 'text-green-600' : 'text-yellow-600'}>
                                                {verificationStatus ? 'Verified' : 'Pending'}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                            <div className="flex justify-end">
                                                {!verificationStatus && (
                                                    <button
                                                        onClick={() => handleVerifyDomain(domain)}
                                                        className="text-blue-600 hover:text-blue-800 transition-colors"
                                                        disabled={loading}
                                                    >
                                                        Verify
                                                    </button>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                {domainInfo.length === 0 && !loading && (
                                    <tr>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center" colSpan={3}>
                                            No domains configured yet.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        );
    };
    
   
    const DocStepCard = ({ title, step, children }) => (
        <div className="rounded-lg border border-gray-200 shadow-sm p-6">
            <h3 className="font-semibold text-gray-800 mb-3 flex items-center space-x-3">
                <span>{title}</span>
                <span className="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-blue-600 bg-blue-100 rounded-full">Step {step}</span>
            </h3>
            {children}
        </div>
    );


    const DocumentationPopup = () => {
        const docSteps = [
            {
                title: "Adding a Domain",
                step: 1,
                content: (
                    <>
                        <p className="text-gray-700 mb-3">
                            To start managing your domains, you first need to add them to EchidnaMail. Follow these steps:
                        </p>
                        <ol className="list-decimal pl-5 mt-2 space-y-2">
                            <li>Click the <button className="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-blue-600 rounded-full"><Plus size={12} className="mr-1"/> Add Domain</button> button located in the domain management dashboard.</li>
                            <li>A popup titled <span className="font-semibold">"Configure New Domain"</span> will appear.</li>
                            <li>In the popup form:
                                <ul className="list-disc pl-5 mt-2 space-y-1">
                                    <li>Choose your <span className="font-semibold">Service Provider</span> from the dropdown. This is your email sending service (e.g., SMTPPro, SendGrid).</li>
                                    <li>If using a less common provider, select <span className="font-semibold">"Custom"</span> and enter your SMTP server's host address.</li>
                                    <li>Enter your <span className="font-semibold">Email/Username</span> and <span className="font-semibold">Password/API Key</span> for your selected service provider.</li>
                                </ul>
                            </li>
                            <li>Click the <button className="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-blue-600 rounded-md">Add Domain</button> button to save the configuration.</li>
                        </ol>
                    </>
                )
            },
            {
                title: "Verifying Your Domain",
                step: 2,
                content: (
                    <>
                        <p className="text-gray-700 mb-3">
                            Verification is crucial to confirm domain ownership and improve email deliverability. Here’s how to verify:
                        </p>
                        <ol className="list-decimal pl-5 mt-2 space-y-2">
                            <li>After adding your domain, find it in the domain list. Its initial status is <span className="font-semibold">"Pending"</span>.</li>
                            <li>Click the <button className="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-blue-600">Verify</button> button next to the domain.</li>

                            <li>Once verification is successful, the domain status will update to <span className="font-semibold">"Verified"</span> or <span className="font-semibold">"Active"</span>, indicating your domain is ready for sending emails.</li>
                        </ol>
                    </>
                )
            }
        ];


        const currentStepData = docSteps[currentDocStep];
        const isFirstStep = currentDocStep === 0;
        const isLastStep = currentDocStep === docSteps.length - 1;


        return (
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center overflow-y-auto p-4">
                <div
                    className="bg-white p-8 rounded-xl shadow-xl w-full max-w-3xl"
                >
                    <div className="flex justify-between items-center mb-6">
                        <h2 className="text-xl font-semibold text-gray-900">Domain Management Guide</h2>
                        <button onClick={() => setShowDocumentationPopup(false)} className="text-gray-500 transition-colors">
                            <XCircle size={20} />
                        </button>
                    </div>

                    <div> {/* Changed to a simple div to remove flex and horizontal scroll */}
                        <DocStepCard title={currentStepData.title} step={currentStepData.step}> {/* Render only the current step */}
                            {currentStepData.content}
                        </DocStepCard>
                    </div>


                    <div className="mt-6 flex justify-between">
                        <button
                            onClick={() => setCurrentDocStep(currentDocStep - 1)}
                            disabled={isFirstStep}
                            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 transition-colors"
                        >
                            Previous Step
                        </button>
                        <button
                            onClick={() => {
                                if (!isLastStep) {
                                    setCurrentDocStep(currentDocStep + 1);
                                } else {
                                    setShowDocumentationPopup(false);
                                }
                            }}
                            className="bg-blue-600 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white transition-colors"
                        >
                            {isLastStep ? 'Close Documentation' : 'Next Step'}
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="min-h-screen bg-gray-50">
            <EnterpriseHeader />

            <div className="max-w-7xl mx-auto px-6 py-10">
                <div className="mb-10">
                    <div>
                        <div className="flex items-center space-x-5 mb-3">
                            <h1 className="text-3xl font-bold text-gray-900">Domain Management</h1>
                            <span className="px-3 py-1 bg-blue-50 text-blue-700 text-sm font-medium rounded-full">
                                Enterprise
                            </span>
                        </div>
                        <p className="text-lg text-gray-600 leading-relaxed">
                            Effortlessly manage your sending domains and ensure seamless verification for optimal email delivery and security.
                        </p>
                    </div>
                    <div className="mt-8 flex items-center space-x-4">
                        <button
                            onClick={() => { setShowDocumentationPopup(true); setCurrentDocStep(0); }}
                            className="flex items-center space-x-2 px-4 py-2 bg-white border border-gray-200 rounded-md text-gray-700 transition-colors"
                        >
                            <HelpCircle size={20} />
                            <span className="text-sm font-medium">Documentation</span>
                        </button>
                    </div>
                </div>

                <div className="grid grid-cols-1 gap-6">
                    <DomainList />
                </div>
            </div>
            <AnimatePresence>
                {showConfigureDomainPopup && (
                    <motion.div
                        key="config-popup"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2 }}
                    >
                        <ConfigureDomainPopup />
                    </motion.div>
                )}
            </AnimatePresence>
            <AnimatePresence>
                {showDocumentationPopup && (
                    <motion.div
                        key="doc-popup"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2 }}
                    >
                        <DocumentationPopup />
                    </motion.div>
                )}
            </AnimatePresence>
            <GamefiedAIAssistant />
      
        </div>
    );
};

export default DomainManagementDashboard;