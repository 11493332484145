import React, { useState, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import axios from 'axios';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const Stats = () => {
  const { user } = useUser();
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [apiStats, setApiStats] = useState(null);
  const [componentStats, setComponentStats] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0], // 30 days ago
    endDate: new Date().toISOString().split('T')[0] // today
  });

  // Fetch user's agents on component mount
  useEffect(() => {
    if (user) {
      const fetchAgents = async () => {
        try {
          const cid = user.id;
          const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/myagents', { cid });
          setAgents(response.data.agents);
        } catch (err) {
          setError('Failed to fetch agents. Please try again later.');
          console.error('Error fetching agents:', err);
        }
      };

      fetchAgents();
    }
  }, [user]);

  // Fetch stats when an agent is selected
  useEffect(() => {
    if (selectedAgent) {
      fetchStats();
    }
  }, [selectedAgent, dateRange]);

// Replace the fetchStats function in your Stats.js file with this:

const fetchStats = async () => {
  if (!selectedAgent) return;
  
  setLoading(true);
  setError(null);
  
  try {
    const cid = user.id;
    const { startDate, endDate } = dateRange;
    
    // Fetch API interaction stats
    const apiResponse = await axios.post('http://localhost:5001/echidna/agentinteractionstats', {
      agentId: selectedAgent._id,
      cid,
      startDate,
      endDate
    });
    
    // Fetch component interaction stats
    const componentResponse = await axios.post('http://localhost:5001/echidna/componentinteractionstats', {
      agentId: selectedAgent._id,
      clientId: cid, // Note: Using clientId instead of cid to match backend
      startDate,
      endDate
    });
    
    setApiStats(apiResponse.data);
    setComponentStats(componentResponse.data);
  } catch (err) {
    setError('Failed to fetch statistics. Please try again later.');
    console.error('Error fetching stats:', err);
  } finally {
    setLoading(false);
  }
};

  // Prepare chart data
  const prepareChartData = () => {
    if (!apiStats || !componentStats) return [];
    
    // Get all unique days from both data sources
    const apiDays = apiStats.dailyStats?.map(item => item.day) || [];
    const componentDays = componentStats.dailyStats?.map(item => item.day) || [];
    const allDays = [...new Set([...apiDays, ...componentDays])].sort((a, b) => {
      // Sort dates - Convert "Mar 15" format to Date objects for comparison
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const [aMonth, aDay] = a.split(' ');
      const [bMonth, bDay] = b.split(' ');
      const aMonthIndex = monthNames.indexOf(aMonth);
      const bMonthIndex = monthNames.indexOf(bMonth);
      
      // Get current year
      const year = new Date().getFullYear();
      
      const aDate = new Date(year, aMonthIndex, parseInt(aDay));
      const bDate = new Date(year, bMonthIndex, parseInt(bDay));
      
      return aDate - bDate;
    });
    
    // Create a map for faster lookups
    const apiStatsMap = {};
    apiStats.dailyStats?.forEach(item => {
      apiStatsMap[item.day] = item;
    });
    
    const componentStatsMap = {};
    componentStats.dailyStats?.forEach(item => {
      componentStatsMap[item.day] = item;
    });
    
    // Create the combined chart data
    return allDays.map(day => {
      return {
        day,
        apiMessages: apiStatsMap[day]?.apiMessages || 0,
        componentMessages: componentStatsMap[day]?.componentMessages || 0,
        apiCost: apiStatsMap[day]?.apiCost || 0,
        componentCost: componentStatsMap[day]?.componentCost || 0
      };
    });
  };
  
  // Use only the most recent 7 days for the charts
  const getRecentData = (data, days = 7) => {
    return data.slice(-days);
  };

  // Format currency
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 4
    }).format(amount);
  };

  const chartData = prepareChartData();
  const recentChartData = getRecentData(chartData);

  // Calculate total cost from both services
  const totalCost = apiStats && componentStats
    ? apiStats.totalCostUSD + componentStats.totalCostUSD
    : 0;

  return (
    <div className="min-h-screen bg-black text-white font-sans p-8">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-3xl font-light tracking-tight mb-12 border-b border-gray-800 pb-4">Agent Analytics</h1>
        
        {/* Agent Selection */}
        <div className="mb-12">
          <h2 className="text-xl font-light mb-4">Select an Agent</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {agents.map(agent => (
              <div 
                key={agent._id}
                className={`p-6 border cursor-pointer transition-all duration-300 ${
                  selectedAgent && selectedAgent._id === agent._id
                    ? 'border-white bg-gray-900'
                    : 'border-gray-800 hover:border-gray-600'
                }`}
                onClick={() => setSelectedAgent(agent)}
              >
                <h3 className="text-lg font-medium mb-2">{agent.agentName}</h3>
                <div className="space-y-1">
                  <p className="text-gray-400 text-sm">ID: <span className="text-gray-300 font-mono">{agent._id}</span></p>
                  <p className="text-gray-400 text-sm">Created: {new Date(agent.createdAt).toLocaleDateString()}</p>
                </div>
              </div>
            ))}
          </div>
          
          {agents.length === 0 && !loading && !error && (
            <p className="text-gray-400">No agents found. Create an agent to see statistics.</p>
          )}
          
          {error && <p className="text-red-500 mt-4">{error}</p>}
        </div>
        
        {/* Date Range Selection */}
        {selectedAgent && (
          <div className="mb-12">
            <h2 className="text-xl font-light mb-4">Date Range</h2>
            <div className="flex items-center space-x-4">
              <div>
                <label className="block text-gray-400 text-sm mb-1">Start Date</label>
                <input 
                  type="date" 
                  value={dateRange.startDate}
                  onChange={e => setDateRange({...dateRange, startDate: e.target.value})}
                  className="bg-gray-900 border border-gray-700 p-2 text-white"
                />
              </div>
              <div>
                <label className="block text-gray-400 text-sm mb-1">End Date</label>
                <input 
                  type="date" 
                  value={dateRange.endDate}
                  onChange={e => setDateRange({...dateRange, endDate: e.target.value})}
                  className="bg-gray-900 border border-gray-700 p-2 text-white"
                />
              </div>
            </div>
          </div>
        )}
        
        {/* Loading State */}
        {loading && (
          <div className="flex justify-center items-center h-64">
            <div className="animate-pulse text-gray-400">Loading statistics...</div>
          </div>
        )}
        
        {/* Stats Display */}
        {selectedAgent && apiStats && componentStats && !loading && (
          <div className="stats-container">
            {/* Summary Stats */}
            <div className="mb-16">
              <h2 className="text-2xl font-light mb-6 border-b border-gray-800 pb-2">
                Summary for {selectedAgent.agentName}
              </h2>
              
              <div className="mb-4 text-sm">
                <p className="text-gray-400">Agent ID: <span className="text-gray-200 font-mono">{selectedAgent._id}</span></p>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
                <div className="p-6 border border-gray-800">
                  <h3 className="text-sm font-medium text-gray-400 mb-1">Total Conversations</h3>
                  <p className="text-4xl font-light">{apiStats.totalConversations + componentStats.totalConversations}</p>
                </div>
                <div className="p-6 border border-gray-800">
                  <h3 className="text-sm font-medium text-gray-400 mb-1">Total Messages</h3>
                  <p className="text-4xl font-light">{apiStats.totalMessages + componentStats.totalMessages}</p>
                </div>
                <div className="p-6 border border-gray-800">
                  <h3 className="text-sm font-medium text-gray-400 mb-1">Total Cost</h3>
                  <p className="text-4xl font-light">{formatCurrency(totalCost)}</p>
                </div>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="p-6 border border-gray-800">
                  <h3 className="text-lg font-medium mb-4">API Statistics</h3>
                  <ul className="space-y-2">
                    <li className="flex justify-between">
                      <span className="text-gray-400">Conversations:</span>
                      <span>{apiStats.totalConversations}</span>
                    </li>
                    <li className="flex justify-between">
                      <span className="text-gray-400">Total Messages:</span>
                      <span>{apiStats.totalMessages}</span>
                    </li>
                    <li className="flex justify-between">
                      <span className="text-gray-400">User Messages:</span>
                      <span>{apiStats.totalUserMessages}</span>
                    </li>
                    <li className="flex justify-between">
                      <span className="text-gray-400">Agent Messages:</span>
                      <span>{apiStats.totalAgentMessages}</span>
                    </li>
                    <li className="flex justify-between">
                      <span className="text-gray-400">Cost:</span>
                      <span>{formatCurrency(apiStats.totalCostUSD)}</span>
                    </li>
                    <li className="flex justify-between">
                      <span className="text-gray-400">Rate:</span>
                      <span>{apiStats.messageRate}</span>
                    </li>
                  </ul>
                </div>
                
                <div className="p-6 border border-gray-800">
                  <h3 className="text-lg font-medium mb-4">Component Statistics</h3>
                  <ul className="space-y-2">
                    <li className="flex justify-between">
                      <span className="text-gray-400">Conversations:</span>
                      <span>{componentStats.totalConversations}</span>
                    </li>
                    <li className="flex justify-between">
                      <span className="text-gray-400">Total Messages:</span>
                      <span>{componentStats.totalMessages}</span>
                    </li>
                    <li className="flex justify-between">
                      <span className="text-gray-400">User Messages:</span>
                      <span>{componentStats.totalUserMessages}</span>
                    </li>
                    <li className="flex justify-between">
                      <span className="text-gray-400">AI Messages:</span>
                      <span>{componentStats.totalAIMessages}</span>
                    </li>
                    <li className="flex justify-between">
                      <span className="text-gray-400">Cost:</span>
                      <span>{formatCurrency(componentStats.totalCostUSD)}</span>
                    </li>
                    <li className="flex justify-between">
                      <span className="text-gray-400">Rate:</span>
                      <span>{componentStats.messageRate}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            
            {/* Charts */}
            <div className="mb-16">
              <h2 className="text-2xl font-light mb-6 border-b border-gray-800 pb-2">Usage Trends</h2>
              
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-12">
                {/* Message Volume Chart */}
                <div className="p-6 border border-gray-800">
                  <h3 className="text-lg font-medium mb-4">Message Volume</h3>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={chartData}>
                      <CartesianGrid strokeDasharray="3 3" stroke="#333" />
                      <XAxis dataKey="day" stroke="#888" />
                      <YAxis stroke="#888" />
                      <Tooltip 
                        contentStyle={{ backgroundColor: '#111', borderColor: '#333' }}
                        itemStyle={{ color: '#fff' }}
                      />
                      <Legend />
                      <Bar dataKey="apiMessages" name="API Messages" fill="#fff" />
                      <Bar dataKey="componentMessages" name="Component Messages" fill="#888" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
                
                {/* Cost Chart */}
                <div className="p-6 border border-gray-800">
                  <h3 className="text-lg font-medium mb-4">Daily Cost</h3>
                  <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={chartData}>
                      <CartesianGrid strokeDasharray="3 3" stroke="#333" />
                      <XAxis dataKey="day" stroke="#888" />
                      <YAxis stroke="#888" />
                      <Tooltip 
                        contentStyle={{ backgroundColor: '#111', borderColor: '#333' }}
                        itemStyle={{ color: '#fff' }}
                        formatter={(value) => formatCurrency(value)}
                      />
                      <Legend />
                      <Line 
                        type="monotone" 
                        dataKey="apiCost" 
                        name="API Cost" 
                        stroke="#fff" 
                        strokeWidth={2}
                        dot={{ fill: '#fff', strokeWidth: 1 }} 
                      />
                      <Line 
                        type="monotone" 
                        dataKey="componentCost" 
                        name="Component Cost" 
                        stroke="#888" 
                        strokeWidth={2}
                        dot={{ fill: '#888', strokeWidth: 1 }} 
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
            
            {/* Additional Metrics */}
            <div className="mb-16">
              <h2 className="text-2xl font-light mb-6 border-b border-gray-800 pb-2">Efficiency Metrics</h2>
              
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                <div className="p-6 border border-gray-800">
                  <h3 className="text-sm font-medium text-gray-400 mb-1">Avg. Messages Per Conversation</h3>
                  <div className="flex justify-between items-end">
                    <div>
                      <p className="text-3xl font-light">{apiStats.averageMessagesPerConversation.toFixed(1)}</p>
                      <p className="text-xs text-gray-500">API</p>
                    </div>
                    <div>
                      <p className="text-3xl font-light">{componentStats.averageMessagesPerConversation.toFixed(1)}</p>
                      <p className="text-xs text-gray-500">Component</p>
                    </div>
                  </div>
                </div>
                
                <div className="p-6 border border-gray-800">
                  <h3 className="text-sm font-medium text-gray-400 mb-1">Avg. Cost Per Conversation</h3>
                  <div className="flex justify-between items-end">
                    <div>
                      <p className="text-3xl font-light">
                        {formatCurrency(apiStats.conversationCostRange.avg)}
                      </p>
                      <p className="text-xs text-gray-500">API</p>
                    </div>
                    <div>
                      <p className="text-3xl font-light">
                        {formatCurrency(componentStats.conversationCostRange.avg)}
                      </p>
                      <p className="text-xs text-gray-500">Component</p>
                    </div>
                  </div>
                </div>
                
                <div className="p-6 border border-gray-800">
                  <h3 className="text-sm font-medium text-gray-400 mb-1">Active Today</h3>
                  <div className="flex justify-between items-end">
                    <div>
                      <p className="text-3xl font-light">{apiStats.activeConversationsToday || apiStats.conversationsToday || 0}</p>
                      <p className="text-xs text-gray-500">API</p>
                    </div>
                    <div>
                      <p className="text-3xl font-light">{componentStats.activeConversationsToday || 0}</p>
                      <p className="text-xs text-gray-500">Component</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Stats;