import React, { useState, useEffect } from 'react';
import Navbar from "../../components/navbar/Navbar";
import "./arsettings.css";
import axios from 'axios'; 
import { useSelector } from 'react-redux';

const Arsettings = () => {
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState('');
    const [width, setWidth] = useState('');
    const [breadth, setBreadth] = useState('');
    const [height, setHeight] = useState('');
    const { userInfo } = useSelector((state) => state.auth);

    useEffect(() => {
        const fetchProducts = async () => {
            const formData = new FormData();
            formData.append('userId', userInfo._id);
            try {
                const response = await axios.post("https://web-production-5ee8.up.railway.app/products/userproducts", formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer ' + userInfo.token,
                    },
                });
                setProducts(response.data);
            } catch (error) {
                console.error('Error fetching products', error);
            }
        };

        fetchProducts();
    }, []); 

    const updateAR = async () => {
        try {
            await axios.put(`https://web-production-5ee8.up.railway.app/products/updateAR/${selectedProduct}`, {
                x: width,
                y: breadth,
                z: height
            });
            alert('AR Scale Updated Successfully');
        } catch (error) {
            console.error('Error updating AR scale:', error);
            alert('Error updating AR scale');
        }
    };

    return (
        <div>
            <Navbar />
            <div className="flex flex-col items-center justify-center h-screen">
                <div className="pb-10">
                    <select 
                        className="select select-bordered w-full max-w-xs"
                        onChange={(e) => setSelectedProduct(e.target.value)}
                    >
                        <option disabled selected>Select Product</option>
                        {products.map((product, index) => (
                            <option key={index} value={product.fileName}>{product.fileName}</option>
                        ))}
                    </select>
                </div> 
                <div className="box1">
                    <label className="form-control w-full max-w-xs ">
                        <div className="label">
                            <span className="label-text">Enter new width</span>
                        </div>
                        <input 
                            type="text" 
                            placeholder="default is 1px" 
                            className="input input-bordered w-full max-w-xs placeholder-gray-500 placeholder-opacity-50" 
                            value={width}
                            onChange={(e) => setWidth(e.target.value)}
                        />
                    </label>
                </div>
                <div className="box1">
                    <label className="form-control w-full max-w-xs">
                        <div className="label">
                            <span className="label-text">Enter new breadth</span>
                        </div>
                        <input 
                            type="text" 
                            placeholder="default is 1px" 
                            className="input input-bordered w-full max-w-xs placeholder-gray-500 placeholder-opacity-50" 
                            value={breadth}
                            onChange={(e) => setBreadth(e.target.value)}
                        />
                    </label>
                </div>
                <div className="box1">
                    <label className="form-control w-full max-w-xs">
                        <div className="label">
                            <span className="label-text">Enter new height</span>
                        </div>
                        <input 
                            type="text" 
                            placeholder="default is 1px" 
                            className="input input-bordered w-full max-w-xs placeholder-gray-500 placeholder-opacity-50" 
                            value={height}
                            onChange={(e) => setHeight(e.target.value)}
                        />
                    </label>
                </div>
                <button className="btn btn-primary mt-4" onClick={updateAR}>Update AR Scale</button>
            </div>
        </div>
    );
}

export default Arsettings;
