import React, { useEffect, useState } from 'react';
import { Plus, Check, Lightbulb, Search, ChevronRight, Info, X, Twitter } from 'lucide-react';
import { useUser } from '@clerk/clerk-react';
import axios from 'axios';
import GamefiedAIAssistant from './intercom';

const Redstoneland1 = () => {
  const { user } = useUser();
  const [credits, setCredits] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [showXPostPopup, setShowXPostPopup] = useState(false);
  const [xPostLink, setXPostLink] = useState('');
  const [xPostStatus, setXPostStatus] = useState('initial'); // initial, submitting, reviewing

  useEffect(() => {
    const fetchCredits = async () => {
      if (user?.id) {
        setLoading(true);
        setError(null);
        try {
          const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/rgens', {
            cid: user.id
          });
          setCredits(response.data.rgens);
        } catch (err) {
          console.error('Failed to fetch credits:', err);
          setError('Failed to load credits. Please try again later.');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchCredits();
  }, [user?.id]);

  const handleSubmitXPost = async () => {
    if (!xPostLink.trim()) {
      alert('Please enter a valid X post link');
      return;
    }

    setXPostStatus('submitting');

    try {
      // Assuming the server-side code will handle retrieving the user's IP address
      await axios.post('https://web-production-5ee8.up.railway.app/echidna/reviews', {
        cid: user?.id,
        link: xPostLink.trim(),
        ip: 'USER_IP_ADDRESS' // Replace with actual IP address retrieval logic
      });

      setXPostStatus('reviewing');
    } catch (err) {
      console.error('Failed to submit X post:', err);
      alert('Failed to submit your X post. Please try again later.');
      setXPostStatus('initial');
    }
  };

  const closeXPostPopup = () => {
    setShowXPostPopup(false);
    setXPostLink('');
    setXPostStatus('initial');
  };

  return (
    <div className="bg-black min-h-screen text-white p-6">
     
      <div className="max-w-6xl mx-auto">
        {/* Header */}
        <header className="flex items-center justify-between mb-10 border-b border-white/10 pb-4">
          <div className="flex items-center">
            <h1 className="text-2xl font-medium tracking-tight">Redstone AI</h1>
          </div>
      
        </header>

        {/* Main Content */}
        <div className="max-w-4xl mx-auto">
          <h2 className="text-lg font-medium mb-10 text-center">Dashboard</h2>

          {/* Credits Display */}
          <div className="mb-8 p-4 bg-white/5 rounded-lg">
            <div className="flex justify-between items-center">
              <div className="flex items-center relative">
                <Lightbulb size={18} className="text-white/70 mr-2 flex-shrink-0" />
                <span className="text-sm font-medium">Available Credits</span>

                {/* Simplified info button approach */}
                <div
                  onClick={() => setShowInfoPopup(!showInfoPopup)}
                  className="ml-2 cursor-pointer w-5 h-5 flex items-center justify-center flex-shrink-0"
                >
                  <Info
                    size={16}
                    className="text-white/70 hover:text-white transition-colors duration-200"
                  />
                </div>

                {showInfoPopup && (
                  <div className="absolute top-full left-0 mt-2 bg-black border border-white/20 p-3 rounded-md shadow-lg text-xs text-white/90 z-10 w-64">
                    <p className="mb-2">• Each review generation costs 5 credits</p>
                    <p>• Each chat costs 1 credit</p>
                    <div
                      onClick={() => setShowInfoPopup(false)}
                      className="absolute top-1 right-1 text-white/50 hover:text-white flex items-center justify-center w-4 h-4 cursor-pointer flex-shrink-0"
                    >
                      <X size={10} className="flex-shrink-0" />
                    </div>
                  </div>
                )}
              </div>

              <div className="flex items-center space-x-4">
                {loading ? (
                  <span className="text-sm text-white/50">Loading...</span>
                ) : error ? (
                  <span className="text-sm text-red-400">{error}</span>
                ) : (
                  <span className="text-sm font-medium">{credits !== null ? credits : '-'}</span>
                )}
                <div className="flex gap-2">
                  <div className="flex flex-col items-center">
                    <a href='/redstone/getcredits'>
                      <button
                        className="bg-white/10 hover:bg-white/20 text-white text-xs py-2 px-4 rounded-md"
                      >
                        Get More Credits
                      </button>
                    </a>
                    <div className="mt-1 text-white/70 text-[0.7rem]">
                      <span className="line-through">$5</span>
                      <span className="ml-1 text-green-400">Early Bird: $2.79 for 200 Credits</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <a href="/redstone/review-validate" className="bg-black border border-white/20 rounded-lg p-6 hover:bg-white/5 transition-all group relative flex flex-col">
              <div className="flex items-center mb-4">
                <div className="w-8 h-8 bg-white/5 rounded-md flex items-center justify-center mr-3">
                  <Plus size={18} className="text-white flex-shrink-0" />
                </div>
                <h3 className="text-base font-medium">Add Products</h3>
              </div>
              <p className="text-white/70 text-sm mb-4">Upload and manage new products in your catalog</p>
              <div className="mt-auto flex items-center text-white/50 text-xs">
                <span>Last updated 2 hours ago</span>
                <ChevronRight size={16} className="ml-auto invisible group-hover:visible transition-opacity flex-shrink-0" />
              </div>
            </a>

            <a href="/redstone/product-validation" className="bg-black border border-white/20 rounded-lg p-6 hover:bg-white/5 transition-all group relative flex flex-col">
              <div className="flex items-center mb-4">
                <div className="w-8 h-8 bg-white/5 rounded-md flex items-center justify-center mr-3">
                  <Check size={18} className="text-white flex-shrink-0" />
                </div>
                <h3 className="text-base font-medium">Validate</h3>
              </div>
              <p className="text-white/70 text-sm mb-4">AI validation of product content and metadata</p>
              <div className="mt-auto flex items-center text-white/50 text-xs">
                <span>18 products awaiting validation</span>
                <ChevronRight size={16} className="ml-auto invisible group-hover:visible transition-opacity flex-shrink-0" />
              </div>
            </a>
          </div>

          <div className="mt-16 border-t border-white/10 pt-6">
            <div className="flex justify-between items-center">
              <p className="text-white/50 text-sm">© 2025 Redstone AI</p>
              <div className="flex space-x-4">
                <div
                  onClick={() => setShowXPostPopup(true)}
                  className="bg-blue-600 hover:bg-blue-700 text-white text-xs py-1 px-3 rounded-md flex items-center cursor-pointer flex-shrink-0 transition-colors duration-200"
                >
                  <span className="flex-shrink-0 mr-1">
                    <Twitter size={14} className="flex-shrink-0" />
                  </span>
                  <span>Post on X for 50 Credits</span>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      {/* X Post Modal */}
      {showXPostPopup && (
  <div className="fixed inset-0 bg-black/80 flex items-center justify-center z-50">
    <div className="bg-black border border-white/20 p-6 rounded-lg max-w-lg w-full">
    <div className="flex justify-between items-center mb-4">
  <h3 className="text-lg font-medium">Post on X or LinkedIn for 50 Credits</h3>
  <div
    onClick={closeXPostPopup}
    className="text-white/50 hover:text-white flex items-center justify-center w-6 h-6 cursor-pointer flex-shrink-0 transition-colors duration-200"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      className="h-5 w-5"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  </div>
</div>

      {xPostStatus === 'initial' && (
        <>
          <p className="text-sm text-white/70 mb-4">
            Share your unfiltered review or anything about Redstone AI on X or LinkedIn, then paste the link below to earn 50 credits.
          </p>
          <div className="mb-4">
            <label className="block text-sm text-white/70 mb-2">X or LinkedIn Post Link</label>
            <input
              type="text"
              value={xPostLink}
              onChange={(e) => setXPostLink(e.target.value)}
              placeholder="https://x.com/... or https://linkedin.com/..."
              className="w-full bg-black border border-white/20 rounded-md p-2 text-white"
            />
          </div>
          <div className="flex justify-center">
            <button
              onClick={handleSubmitXPost}
              className="w-full max-w-xs bg-blue-600 hover:bg-blue-700 text-white py-2 rounded-md"
            >
              Complete
            </button>
          </div>
        </>
      )}

      {xPostStatus === 'submitting' && (
        <div className="text-center py-4">
          <p className="text-white/70">Submitting your post...</p>
        </div>
      )}

      {xPostStatus === 'reviewing' && (
        <div className="text-center py-4">
          <div className="flex items-center justify-center mb-4">
            <Check size={24} className="text-green-500 flex-shrink-0" />
          </div>
          <p className="text-white/70 mb-2">Thanks for sharing!</p>
          <p className="text-sm text-white/50">Our team is reviewing your post and will add 50 credits to your account within 24 hours.</p>
          <button
            onClick={closeXPostPopup}
            className="mt-4 w-full bg-white/10 hover:bg-white/20 text-white py-2 rounded-md"
          >
            Close
          </button>
        </div>
      )}
    </div>
  </div>
)}
 <GamefiedAIAssistant />

    </div>
  );
};

export default Redstoneland1;
