import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements, AddressElement } from '@stripe/react-stripe-js';
import { Loader2, Globe, MapPin } from 'lucide-react';
import { useUser } from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';
import GamefiedAIAssistant from './intercom';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const CountrySelector = ({ onSelect }) => {
    return (
      <div className="mb-8">
        <h2 className="text-2xl font-medium mb-4 text-white">Select Payment Region</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div
            onClick={() => onSelect('IN')}
            className="flex items-center justify-center border border-gray-700 bg-black hover:bg-gray-900 p-6 rounded-md transition-colors cursor-pointer"
          >
            <MapPin className="mr-2 text-white" size={24} />
            <span className="text-lg text-white">India (INR)</span>
          </div>
          
          <div
            onClick={() => onSelect('GLOBAL')}
            className="flex items-center justify-center border border-gray-700 bg-black hover:bg-gray-900 p-6 rounded-md transition-colors cursor-pointer"
          >
            <Globe className="mr-2 text-white" size={24} />
            <span className="text-lg text-white">Global (USD)</span>
          </div>
        </div>
      </div>
    );
  };

const CheckoutForm = ({ cid, currency, amount, formattedPrice }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const { user } = useUser();
    const navigate = useNavigate();
  
    useEffect(() => {
      let redirectTimer;
      if (success) {
        redirectTimer = setTimeout(() => {
          navigate('/redstone/dash');
        }, 3000);
      }
      return () => clearTimeout(redirectTimer);
    }, [success, navigate]);
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      setLoading(true);
      setMessage('');
  
      if (!stripe || !elements) {
        setLoading(false);
        return;
      }
  
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.href,
        },
        redirect: 'if_required',
      });
  
      if (error) {
        if (error.type === 'card_error' || error.type === 'validation_error') {
          setMessage(error.message);
        } else if (error.type === 'invalid_request_error' && 
                  error.code === 'payment_intent_unexpected_state') {
          const clientSecret = new URLSearchParams(window.location.search).get(
            'payment_intent_client_secret'
          );
          
          if (clientSecret) {
            const { paymentIntent: confirmedIntent } = await stripe.retrievePaymentIntent(clientSecret);
            
            if (confirmedIntent && confirmedIntent.status === 'succeeded') {
              await updateRegens(confirmedIntent.id);
              return;
            }
          }
          
          setMessage('There was an issue with your payment. Please try again.');
        } else {
          setMessage('An unexpected error occurred.');
        }
        setLoading(false);
        return;
      }
  
      if (paymentIntent && paymentIntent.status === 'succeeded') {
        await updateRegens(paymentIntent.id);
      } else {
        setMessage('Please complete the payment process.');
        setLoading(false);
      }
    };
  
    const updateRegens = async (paymentIntentId) => {
      try {
        // Use the appropriate endpoint based on currency
        const endpoint = currency === 'inr' 
          ? 'https://web-production-5ee8.up.railway.app/echidna/update-regens-india'
          : 'https://web-production-5ee8.up.railway.app/echidna/update-regens';
          
        const response = await fetch(endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 
            cid: cid,
            paymentIntentId: paymentIntentId
          }),
        });
        
        const data = await response.json();
        
        if (data.success) {
          setSuccess(true);
          setMessage('Payment successful! Your regens have been increased by 200. Redirecting to dashboard...');
        } else {
          setMessage(data.error || 'Failed to update regens. Please contact support.');
        }
      } catch (err) {
        setMessage('Payment processed but failed to update regens. Please contact support.');
      }
      setLoading(false);
    };
  
    useEffect(() => {
      if (!stripe) {
        return;
      }
  
      const clientSecret = new URLSearchParams(window.location.search).get(
        'payment_intent_client_secret'
      );
  
      if (!clientSecret) {
        return;
      }
  
      stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
        if (paymentIntent.status === 'succeeded') {
          setLoading(true);
          updateRegens(paymentIntent.id);
        } else if (paymentIntent.status === 'requires_payment_method') {
          setMessage('Your payment was not successful, please try again.');
        }
      });
    }, [stripe]);

  return (
    <form onSubmit={handleSubmit} className="w-full max-w-md">
      <div className="mb-8">
        <h2 className="text-2xl font-medium mb-1 text-white">Increase generations</h2>
        <p className="text-gray-400">Add 200 regens to your account for {formattedPrice}</p>
      </div>
      
      <div className="mb-8">
        <label className="block text-sm font-medium mb-3 text-gray-300">
          Customer Information
        </label>
        <div className="bg-black border border-gray-800 p-4 rounded-md hover:border-gray-700 transition-colors mb-4">
          <AddressElement
            options={{
              mode: 'shipping',
              allowedCountries: ['US', 'CA', 'GB', 'AU', 'IN'],
              defaultValues: {
                name: user?.fullName || '',
                address: {
                  line1: '',
                  line2: '',
                  city: '',
                  state: '',
                  postal_code: '',
                  country: currency === 'inr' ? 'IN' : '',
                },
              },
            }}
          />
        </div>
        
        <label className="block text-sm font-medium mb-3 text-gray-300">
          Payment Details
        </label>
        <div className="bg-black border border-gray-800 p-4 rounded-md hover:border-gray-700 transition-colors">
          <PaymentElement 
            options={{
              layout: 'tabs',
              theme: 'night',
              variables: {
                colorPrimary: '#ffffff',
                colorBackground: '#000000',
                colorText: '#ffffff',
                colorDanger: '#ff5252',
                spacingUnit: '4px',
                borderRadius: '4px'
              }
            }}
          />
        </div>
      </div>
      
      {message && (
        <div className={`mb-6 p-4 rounded-md border ${success ? 'border-green-600 text-green-400' : 'border-red-600 text-red-400'} bg-black`}>
          {message}
        </div>
      )}
      
      <button
        type="submit"
        disabled={!stripe || loading || success}
        className="w-full border border-white text-white py-3 rounded-md flex items-center justify-center hover:bg-white hover:text-black transition-colors duration-300"
      >
        {loading ? (
          <>
            <Loader2 className="animate-spin mr-2" size={18} />
            Processing...
          </>
        ) : success ? (
          'Payment Completed'
        ) : (
          `Pay ${formattedPrice}`
        )}
      </button>
    </form>
  );
};

const RPaymentPage = () => {
  const { user } = useUser();
  const [clientSecret, setClientSecret] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [currency, setCurrency] = useState('');
  const [amount, setAmount] = useState(0);
  const [countrySelected, setCountrySelected] = useState(false);
  const [country, setCountry] = useState('');
  const cid = user?.id;

  const handleCountrySelect = async (selectedCountry) => {
    setLoading(true);
    setCountry(selectedCountry);
    setCountrySelected(true);
    
    try {
      // Determine currency and endpoint based on country selection
      const currency = selectedCountry === 'IN' ? 'inr' : 'usd';
      const endpoint = selectedCountry === 'IN' 
        ? 'https://web-production-5ee8.up.railway.app/echidna/create-payment-intent-india'
        : 'https://web-production-5ee8.up.railway.app/echidna/create-payment-intent';
      
      // Different amount based on currency (289 cents USD, or ~24000 paise INR) 
      const amount = selectedCountry === 'IN' ? 24000 : 289;
      
      const userData = {
        name: user?.fullName || '',
        email: user?.primaryEmailAddress?.emailAddress || '',
      };

      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          amount: amount,
          cid: cid,
          description: "Purchase of digital regens - software service export",
          user: userData
        }),
      });

      const data = await response.json();
      
      if (data.error) {
        setError(data.error.message);
        setLoading(false);
        return;
      }
      
      setClientSecret(data.clientSecret);
      setCurrency(currency);
      setAmount(amount);
      setLoading(false);
    } catch (err) {
      setError('Failed to initialize payment. Please try again.');
      setLoading(false);
    }
  };

  const options = clientSecret ? {
    clientSecret,
    appearance: {
      theme: 'night',
      variables: {
        colorPrimary: '#ffffff',
        colorBackground: '#000000',
        colorText: '#ffffff'
      }
    }
  } : {};

  // Format price display based on currency
  const formatPrice = () => {
    if (currency === 'inr') {
      return `₹${(amount / 100).toFixed(2)}`;
    } else {
      return `$${(amount / 100).toFixed(2)}`;
    }
  };

  // Check for payment intent return before country selection
  useEffect(() => {
    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    if (clientSecret) {
      // Load Stripe first, then access it
      let stripeInstance = null;
      stripePromise.then((stripe) => {
        stripeInstance = stripe;
        if (stripe) {
          stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            // Determine currency from the payment intent
            const detectedCurrency = paymentIntent.currency;
            setCurrency(detectedCurrency);
            setAmount(paymentIntent.amount);
            setClientSecret(clientSecret);
            setCountrySelected(true);
            setCountry(detectedCurrency === 'inr' ? 'IN' : 'GLOBAL');
          });
        }
      });
    }
  }, []);

  return (
    <div className="min-h-screen bg-black flex flex-col items-center justify-center p-6">
      <div className="w-full max-w-md bg-black border border-gray-800 rounded-lg p-8 shadow-md">
        {!countrySelected && !clientSecret ? (
          <CountrySelector onSelect={handleCountrySelect} />
        ) : loading ? (
          <div className="flex items-center justify-center h-32">
            <Loader2 className="animate-spin mr-2 text-white" size={24} />
            <span className="text-white">Initializing payment...</span>
          </div>
        ) : error ? (
          <div className="p-4 rounded-md border border-red-600 text-red-400 bg-black">
            {error}
            <button 
              onClick={() => setCountrySelected(false)}
              className="mt-4 w-full border border-gray-700 text-white py-2 rounded-md flex items-center justify-center hover:bg-gray-900 transition-colors duration-300"
            >
              Try Again
            </button>
          </div>
        ) : clientSecret ? (
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm 
              cid={cid} 
              currency={currency} 
              amount={amount} 
              formattedPrice={formatPrice()}
            />
          </Elements>
        ) : (
          <div className="p-4 rounded-md border border-red-600 text-red-400 bg-black">
            Unable to initialize payment system.
            <button 
              onClick={() => setCountrySelected(false)}
              className="mt-4 w-full border border-gray-700 text-white py-2 rounded-md flex items-center justify-center hover:bg-gray-900 transition-colors duration-300"
            >
              Try Again
            </button>
          </div>
        )}
      </div>
      <GamefiedAIAssistant />
    </div>
  );
};

export default RPaymentPage;