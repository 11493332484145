import React, { useState } from 'react';
import { X, HelpCircle, Loader2 } from 'lucide-react';
import axios from 'axios';
import logo from './logo.png';
import { useUser } from '@clerk/clerk-react';

const GamefiedAIAssistant = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useUser();

  const suggestions = [
    { emoji: '🚀', text: 'Create New Campaign' },
    { emoji: '📬', text: 'Add Sending Domain' },
    { emoji: '⚙️', text: 'New Campaign Configuration' },
    { emoji: '✅', text: 'Validate Your Idea' },
    { emoji: '👥', text: 'Talk to Customers' },
    { emoji: '🌐', text: 'Run Website Analysis' },
    { emoji: '📊', text: 'Platform Data Analytics' }
  ];

  // Function to format text with line breaks, spacing, and clickable links
  const formatMessage = (text) => {
    const urlPattern = /(https?:\/\/[^\s]+)/g;

    // Split by line breaks and map each line
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line.split(urlPattern).map((part, i) =>
          urlPattern.test(part) ? (
            <a key={i} href={part} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
              {part}
            </a>
          ) : (
            part
          )
        )}
        <br />
      </React.Fragment>
    ));
  };

  const handleSendMessage = async (message) => {
    // Ensure user is logged in
    if (!user) {
      console.error('User not authenticated');
      return;
    }

    const newMessage = {
      id: Date.now(),
      text: typeof message === 'string' ? message : message.text,
      sender: 'user'
    };

    // Add the user message to the messages
    setMessages(prev => [...prev, newMessage]);
    setInputMessage('');
    setShowSuggestions(false);
    setIsLoading(true);

    try {
      // Send message to backend
      const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/intercom', {
        message: newMessage.text,
        cid: user.id // Assuming user.id is the unique identifier from Clerk
      });

      // Check if response has a message field
      if (response.data && response.data.message) {
        const aiResponse = {
          id: Date.now() + 1,
          text: response.data.message,
          sender: 'ai'
        };

        // Add AI response to messages
        setMessages(prev => [...prev, aiResponse]);
      } else {
        // Handle unexpected response format
        const errorResponse = {
          id: Date.now() + 1,
          text: 'Error: Received unexpected response format',
          sender: 'ai'
        };
        setMessages(prev => [...prev, errorResponse]);
      }
    } catch (error) {
      // Handle network or server errors
      console.error('Error sending message:', error);
      const errorResponse = {
        id: Date.now() + 1,
        text: `Error: ${error.message}`,
        sender: 'ai'
      };
      setMessages(prev => [...prev, errorResponse]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputSend = () => {
    if (!inputMessage.trim()) return;
    handleSendMessage(inputMessage);
  };

  return (
    <div className="pointer-events-none fixed right-0 top-0 h-full z-50">
      <div 
        className={`
          pointer-events-auto
          w-[350px]
          bg-white
          shadow-xl
          border-l
          border-gray-200
          h-full
          flex
          flex-col
          transform
          transition-transform
          duration-300
          ${isOpen ? 'translate-x-0' : 'translate-x-full'}
        `}
      >
        {/* Sidebar Header */}
        <div className="
          bg-gray-50
          p-3
          flex
          justify-between
          items-center
          border-b
          border-gray-200
        ">
          <div className="flex items-center space-x-2">
            <img
              src={logo}
              alt="MetaBees"
              className="w-8 h-8 object-contain"
            />
            <div className="flex flex-col">
              <span className="text-sm font-bold text-gray-700">MetaBees</span>
              <span className="text-xs text-gray-500">Copilot</span>
            </div>
          </div>
          <div
            onClick={() => setIsOpen(false)}
            className="
              text-gray-500
              hover:text-gray-700
              cursor-pointer
              transition-colors
            "
          >
            <X size={18} />
          </div>
        </div>

        {/* Suggestions Area */}
        {showSuggestions && (
          <div className="p-3 border-b border-gray-200">
            <div className="flex flex-wrap gap-2">
              {suggestions.map((suggestion, index) => (
                <div
                  key={index}
                  onClick={() => handleSendMessage(suggestion)}
                  className="
                    px-3
                    py-1
                    bg-gray-100
                    text-xs
                    text-gray-700
                    rounded-full
                    cursor-pointer
                    hover:bg-gray-200
                    transition-colors
                    flex
                    items-center
                    space-x-1
                  "
                >
                  <span>{suggestion.emoji}</span>
                  <span>{suggestion.text}</span>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Messages Container */}
        <div className="flex-grow overflow-y-auto p-3 space-y-2">
          {messages.map((msg) => (
            <div
              key={msg.id}
              className={`
                flex flex-col
                ${msg.sender === 'user' ? 'items-end' : 'items-start'}
              `}
            >
              <div className={`
                max-w-[80%]
                p-2
                rounded-lg
                text-xs
                whitespace-pre-wrap
                ${
                  msg.sender === 'user'
                    ? 'bg-blue-100 text-blue-800'
                    : 'bg-gray-100 text-gray-800'
                }
              `}>
                {formatMessage(msg.text)}
              </div>
            </div>
          ))}

          {/* Loading Indicator */}
          {isLoading && (
            <div className="flex justify-start items-center">
              <div className={`
                max-w-[80%]
                p-2
                rounded-lg
                text-xs
                bg-gray-100
                text-gray-800
                flex
                items-center
                space-x-2
              `}>
                <Loader2 className="animate-spin" size={16} />
                <span>Thinking...</span>
              </div>
            </div>
          )}
        </div>

        {/* Input Area */}
        <div className="p-3 border-t border-gray-200">
          <div className="flex space-x-2">
            <input
              type="text"
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleInputSend()}
              placeholder="Ask anything..."
              disabled={isLoading}
              className={`
                flex-grow
                bg-gray-100
                text-xs
                text-gray-800
                p-2
                rounded-lg
                border
                border-gray-200
                focus:outline-none
                focus:ring-1
                focus:ring-blue-300
                ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}
              `}
            />
            <button
              onClick={handleInputSend}
              disabled={isLoading}
              className={`
                p-2
                bg-blue-50
                text-blue-600
                rounded-lg
                hover:bg-blue-100
                transition-colors
                ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}
              `}
            >
              <HelpCircle size={16} />
            </button>
          </div>
        </div>
      </div>

      {/* Open Sidebar Button */}
      {!isOpen && (
        <div
          onClick={() => setIsOpen(true)}
          className="
            pointer-events-auto
            fixed
            right-0
            top-1/2
            transform
            -translate-y-1/2
            bg-blue-50
            text-blue-600
            p-2
            rounded-l-lg
            shadow-md
            cursor-pointer
            hover:bg-blue-100
            transition-colors
            z-40
          "
        >
          <HelpCircle size={16} />
        </div>
      )}
    </div>
  );
};

export default GamefiedAIAssistant;