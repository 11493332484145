import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { format, isValid, parseISO } from 'date-fns';
import { Edit2, Save, X, Users, Mail, CheckCircle, Settings, Database, BarChart2, Layout, Search, Bell, ChevronDown, HelpCircle, Globe, Shield, AlertTriangle, Send } from 'lucide-react';
import { useEchidnaAccess, LoadingScreen, NoAccessScreen  } from '../../assets/useraccess';
import logo from '../../assets/logo.png';
import { useUser } from '@clerk/clerk-react';
import GamefiedAIAssistant from '../redstone/intercom';


const NavButton = ({ children }) => (
    <button className="px-3 py-2 text-gray-300 hover:text-white hover:bg-gray-800 rounded text-sm font-medium transition-colors">
        {children}
    </button>
);


const EnterpriseHeader = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <div className="bg-gray-900 sticky top-0 z-10">
            <div className="max-w-7xl mx-auto">
                <div className="flex h-16 items-center justify-between px-4">
                    <div className="flex items-center space-x-8">
                        <div className="flex items-center space-x-2">
                            <img src={logo} alt="EchidnaMail" className="h-8" />
                            <span className="text-white font-bold">EchidnaMail</span>
                            <span className="text-red-500 font-bold">v2</span>
                        </div>
                        <div className="relative">
                            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                            <input
                                type="text"
                                placeholder="Search services, documentation, or resources"
                                className="pl-10 pr-4 py-2 w-96 bg-gray-800 border border-gray-700 rounded text-gray-200 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                            />
                        </div>
                    </div>
                    <div className="flex items-center space-x-4">
                        <NavButton>API Reference</NavButton>
                        <NavButton>Enterprise Support</NavButton>
                        <button className="p-2 text-gray-300 hover:text-white hover:bg-gray-800 rounded-full relative">
                            <Bell size={20} />
                            <span className="absolute top-1 right-1 h-2 w-2 bg-blue-500 rounded-full"></span>
                        </button>
                        <div className="relative" ref={dropdownRef}>
                            <button
                                onClick={toggleDropdown}
                                className="flex items-center space-x-3 ml-4 px-3 py-2 bg-gray-800 rounded cursor-pointer"
                            >
                                <div className="h-8 w-8 bg-blue-600 rounded-full flex items-center justify-center text-white text-sm font-medium">
                                    EC
                                </div>
                                <div>
                                    <div className="text-gray-200 text-sm">Enterprise</div>
                                    <div className="text-gray-400 text-xs">Southeast-mb-2</div>
                                    <div className="text-gray-400 text-xs"></div>
                                </div>
                                <ChevronDown size={16} className="text-gray-400" />
                            </button>
                            {isDropdownOpen && (
                                <div className="absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none origin-top-right">
                                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="dropdown">
                                        <button
                                            className="text-gray-700 flex w-full px-4 py-2 text-left text-sm hover:bg-gray-100" role="menuitem"  onClick={toggleDropdown}
                                        >
                                            Southeast-mb-2 <br/> <span className="text-xs text-gray-500">Bengaluru</span>
                                        </button>
                                        <button
                                            className="text-gray-400 flex w-full px-4 py-2 text-left text-sm cursor-not-allowed hover:bg-gray-100" role="menuitem" disabled onClick={toggleDropdown}
                                        >
                                            us-west-san Fransisco <br/> <span className="text-xs text-gray-500">San Fransisco (Unavailable)</span>
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


const CampaignSpecific = () => {
    const [campaignData, setCampaignData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editingEmail, setEditingEmail] = useState(null);
    const [saveLoading, setSaveLoading] = useState(false);
    const [validationError, setValidationError] = useState(null);
    const { eloading, hasAccess } = useEchidnaAccess();
    const { campaign } = useParams();
    const { /* userInfo */ } = useSelector((state) => state.auth); // userInfo is depricated
    const { user } = useUser(); // using clerk user id now
    const navigate = useNavigate();


    useEffect(() => {
        fetchCampaignData();
    }, [campaign, user?.publicMetadata.userId]); // using clerk user id

    const validateDateTime = (dateTimeStr) => {
        const date = parseISO(dateTimeStr);
        if (!isValid(date)) {
            throw new Error('Invalid date/time format');
        }

        const now = new Date();
        if (date < now) {
            throw new Error('Cannot schedule emails in the past');
        }

        return true;
    };

    const fetchCampaignData = async () => {
        try {
            const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/campinfo', {
                cid: user?.id, // using clerk user id as cid
                campaignName: campaign
            });

            const emailCopiesWithIds = response.data.emailCopies.map((email, index) => ({
                ...email,
                _id: email._id || `temp-id-${index}`
            }));

            setCampaignData({
                ...response.data,
                emailCopies: emailCopiesWithIds
            });
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch campaign data: ' + (err.response?.data?.error || err.message));
            setLoading(false);
        }
    };

    const handleEditEmail = (index) => {
        const emailToEdit = campaignData.emailCopies[index];
        setValidationError(null);
        setEditingEmail({
            ...emailToEdit,
            index,
            originalId: emailToEdit._id
        });
    };

    const handleSaveEmail = async () => {
        try {
            setSaveLoading(true);
            setError(null);
            setValidationError(null);
            
            // Validate required fields
            if (!editingEmail.mailSubject?.trim()) {
                throw new Error('Email subject is required');
            }
            if (!editingEmail.mailBody?.trim()) {
                throw new Error('Email body is required');
            }
            if (!editingEmail.mailTo?.trim()) {
                throw new Error('Recipient email is required');
            }
            if (!editingEmail.time) {
                throw new Error('Schedule time is required');
            }
            
            // Validate email format
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(editingEmail.mailTo)) {
                throw new Error('Invalid email address format');
            }
            
            // Validate date/time
            validateDateTime(editingEmail.time);
            
            // Make sure we have the index
            if (editingEmail.index === undefined) {
                // Find the index if it's not provided
                const index = campaignData.emailCopies.findIndex(
                    email => email._id === (editingEmail._id || editingEmail.originalId)
                );
                
                if (index === -1) {
                    throw new Error('Cannot identify which email to update. Email index not found.');
                }
                
                editingEmail.index = index;
            }
            
            const updatePayload = {
                cid: user.id, // using clerk user id as cid
                campaignName: campaign,
                emailIndex: editingEmail.index,
                updatedEmail: {
                    _id: editingEmail._id || editingEmail.originalId,
                    mailSubject: editingEmail.mailSubject.trim(),
                    mailBody: editingEmail.mailBody.trim(),
                    mailTo: editingEmail.mailTo.trim(),
                    time: new Date(editingEmail.time).toISOString(),
                    sent: editingEmail.sent === true,
                    cid: user?.id, // using clerk user id as cid
                    campaign: campaign
                }
            };
            
            console.log('Sending update payload:', updatePayload);
            
            const response = await axios.post(
                'https://web-production-5ee8.up.railway.app/echidna/updateemailcopy',
                updatePayload
            );
            
            if (response.data.updatedEmail) {
                const updatedEmailCopies = [...campaignData.emailCopies];
                updatedEmailCopies[editingEmail.index] = {
                    ...response.data.updatedEmail,
                    _id: response.data.updatedEmail._id || editingEmail._id || editingEmail.originalId
                };
                
                setCampaignData({
                    ...campaignData,
                    emailCopies: updatedEmailCopies
                });
                setEditingEmail(null);
            }
        } catch (err) {
            console.error('Error updating email:', err);
            setValidationError(err.message || 'Failed to update email');
        } finally {
            setSaveLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValidationError(null);

        try {
            if (name === 'time') {
                validateDateTime(value);
            }

            setEditingEmail(prev => {
                if (name === 'sent') {
                    return { ...prev, [name]: value === 'true' };
                }
                return { ...prev, [name]: value };
            });
        } catch (err) {
            setValidationError(err.message);
        }
    };

    if (loading) return <div className="flex items-center justify-center h-screen">Loading...</div>;
    if (!campaignData) return <div className="flex items-center justify-center h-screen">No campaign data found</div>;


    if (eloading) {
        return <LoadingScreen />;
    }

    if (!hasAccess) {
        return <NoAccessScreen />;
    }


    return (
        <div className="min-h-screen bg-gray-50">
            <EnterpriseHeader />

            <div className="max-w-7xl mx-auto px-4 py-8">
                <div className="mb-8">
                    <div className="flex items-center space-x-4">
                        <h1 className="text-2xl font-semibold text-gray-900">{campaignData.campaignName}</h1>
                    </div>
                </div>

                {/* Error Display */}
                {(error || validationError) && (
                    <div className="fixed top-4 right-4 z-50 max-w-sm bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded shadow-lg flex items-start justify-between">
                        <div className="flex items-center">
                            <svg className="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
                                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                            </svg>
                            <span>{error || validationError}</span>
                        </div>
                        <button
                            onClick={() => {
                                setError(null);
                                setValidationError(null);
                            }}
                            className="ml-4 text-red-700 hover:text-red-900"
                        >
                            <X size={16} />
                        </button>
                    </div>
                )}


                {/* Campaign Overview */}
                <div className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200 mb-6">
                    <div className="px-4 py-5 sm:px-6">
                        <h2 className="text-lg font-medium text-gray-900">Campaign Overview</h2>
                    </div>
                    <div className="px-4 py-5 sm:p-6">
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Campaign Type</dt>
                                <dd className="mt-1 text-sm text-gray-900">{campaignData.campaignType}</dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Status</dt>
                                <dd className="mt-1 text-sm text-gray-900">{campaignData.status}</dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Lead Count</dt>
                                <dd className="mt-1 text-sm text-gray-900">{campaignData.leadCount}</dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Schedule Date</dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                    {format(new Date(campaignData.scheduleDate), 'PPP')}
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>

                {/* Email Copies */}
                <div className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
                    <div className="px-4 py-5 sm:px-6">
                        <h2 className="text-lg font-medium text-gray-900">Email Copies</h2>
                    </div>
                    <div className="px-4 py-5 sm:p-6">
                        {campaignData.emailCopies.map((email, index) => (
                            <div key={email._id || index} className="mb-8 pb-8 border-b border-gray-200 last:mb-0 last:pb-0 last:border-b-0">
                                <div className="flex justify-between items-center mb-4">
                                    <h3 className="text-lg font-medium text-gray-900">Email {index + 1}</h3>
                                    {editingEmail?.index === index ? (
                                        <div className="flex items-center">
                                            <button
                                                onClick={handleSaveEmail}
                                                disabled={saveLoading}
                                                className={`mr-2 ${saveLoading ? 'text-gray-400' : 'text-green-600 hover:text-green-900'}`}
                                            >
                                                <Save size={20} />
                                            </button>
                                            <button
                                                onClick={() => setEditingEmail(null)}
                                                disabled={saveLoading}
                                                className={`${saveLoading ? 'text-gray-400' : 'text-red-600 hover:text-red-900'}`}
                                            >
                                                <X size={20} />
                                            </button>
                                        </div>
                                    ) : (
                                        <button
                                            onClick={() => handleEditEmail(index)}
                                            className="text-blue-600 hover:text-blue-900"
                                        >
                                            <Edit2 size={20} />
                                        </button>
                                    )}
                                </div>

                                <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Subject</label>
                                        {editingEmail?.index === index ? (
                                            <input
                                                type="text"
                                                name="mailSubject"
                                                value={editingEmail.mailSubject}
                                                onChange={handleInputChange}
                                                className="mt-1 focus:ring-blue-500 focus:border-blue-500 bg-white block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        ) : (
                                            <p className="mt-1 text-sm text-gray-900">{email.mailSubject}</p>
                                        )}
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Recipient</label>
                                        {editingEmail?.index === index ? (
                                            <input
                                                type="email"
                                                name="mailTo"
                                                value={editingEmail.mailTo}
                                                onChange={handleInputChange}
                                                className="mt-1 focus:ring-blue-500 focus:border-blue-500 bg-white block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        ) : (
                                            <p className="mt-1 text-sm text-gray-900">{email.mailTo}</p>
                                        )}
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Scheduled Time</label>
                                        {editingEmail?.index === index ? (
                                            <div className="relative">
                                                <input
                                                    type="datetime-local"
                                                    name="time"
                                                    value={editingEmail.time ? format(new Date(editingEmail.time), "yyyy-MM-dd'T'HH:mm") : ''}
                                                    onChange={handleInputChange}
                                                    min={format(new Date(), "yyyy-MM-dd'T'HH:mm")}
                                                    className={`mt-1 block w-full rounded-md shadow-sm sm:text-sm
                                                    ${validationError && validationError.includes('date')
                                                        ? 'border-red-500 focus:ring-red-500 focus:border-red-500'
                                                        : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'
                                                    }`}
                                                />
                                                {validationError && validationError.includes('date') && (
                                                    <p className="mt-1 text-sm text-red-600">{validationError}</p>
                                                )}
                                            </div>
                                        ) : (
                                            <p className="mt-1 text-sm text-gray-900">
                                                {format(new Date(email.time), 'PPP p')}
                                            </p>
                                        )}
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Status</label>
                                        {editingEmail?.index === index ? (
                                            <select
                                                name="sent"
                                                value={editingEmail.sent}
                                                onChange={handleInputChange}
                                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                            >
                                                <option value={false}>Pending</option>
                                                <option value={true}>Sent</option>
                                            </select>
                                        ) : (
                                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                                email.sent ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
                                            }`}>
                                                {email.sent ? 'Sent' : 'Pending'}
                                            </span>
                                        )}
                                    </div>

                                    <div className="sm:col-span-2">
                                        <label className="block text-sm font-medium text-gray-700">Email Body</label>
                                        {editingEmail?.index === index ? (
                                            <textarea
                                                name="mailBody"
                                                rows="4"
                                                value={editingEmail.mailBody}
                                                onChange={handleInputChange}
                                                className="mt-1 focus:ring-blue-500 bg-white focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        ) : (
                                            <p className="mt-1 text-sm text-gray-900 whitespace-pre-wrap">
                                                {email.mailBody}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <GamefiedAIAssistant />
        </div>
    );
};

export default CampaignSpecific;