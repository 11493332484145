import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import GamefiedAIAssistant from '../redstone/intercom';
import {
    Bell,
    ChevronDown,
    HelpCircle,
    Mail,
    Search,
    Send,
    Shield,
    Users,
    Globe,
    AlertTriangle,
    CheckCircle
} from 'lucide-react';
import logo from './logo.png';
import { useUser } from '@clerk/clerk-react';

const NavButton = ({ children }) => (
    <button className="px-3 py-2 text-gray-300 hover:text-white hover:bg-gray-800 rounded text-sm font-medium transition-colors">
        {children}
    </button>
);

const MetricCard = ({ label, value }) => (
    <div className="bg-white p-4 rounded-lg shadow-sm">
        <div className="text-sm text-gray-600 mb-1">{label}</div>
        <div className="flex items-end space-x-2">
            <div className="text-2xl font-semibold text-gray-900">{value}</div>
        </div>
    </div>
);

const QuickAction = ({ icon: Icon, text, onClick }) => (
    <button onClick={onClick} className="flex items-center space-x-2 px-3 py-2 bg-white rounded-lg border border-gray-200 hover:border-blue-500 text-sm font-medium text-gray-700">
        <Icon size={16} />
        <span>{text}</span>
    </button>
);

const StatusIndicator = () => (
    <div className="bg-white p-4 rounded-lg shadow-sm">
        <div className="flex items-center justify-between mb-3">
            <h3 className="font-medium text-gray-900">Service Health</h3>
        </div>
        <div className="space-y-2">
            <div className="flex items-center justify-between">
                <span className="text-sm text-gray-600">API Endpoints</span>
                <span className="flex items-center text-green-600">
                    <CheckCircle size={16} className="mr-1" /> 99.99%
                </span>
            </div>
            <div className="flex items-center justify-between">
                <span className="text-sm text-gray-600">Email Services</span>
                <span className="flex items-center text-green-600">
                    <CheckCircle size={16} className="mr-1" /> 100%
                </span>
            </div>
            <div className="flex items-center justify-between">
                <span className="text-sm text-gray-600">Analytics Pipeline</span>
                <span className="flex items-center text-yellow-600">
                    <AlertTriangle size={16} className="mr-1" /> 97.5%
                </span>
            </div>
        </div>
    </div>
);


const EnterpriseHeader = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = React.useRef(null);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);


    return (
        <div className="bg-gray-900 sticky top-0 z-10">
            <div className="max-w-7xl mx-auto">
                <div className="flex h-16 items-center justify-between px-4">
                    <div className="flex items-center space-x-8">
                        <div className="flex items-center space-x-2">
                            <img src={logo} alt="EchidnaMail" className="h-8" />
                            <span className="text-white font-bold">EchidnaMail</span>
                            <span className="text-red-500 font-bold">v2</span>
                        </div>
                        <div className="relative">
                            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                            <input
                                type="text"
                                placeholder="Search services, documentation, or resources"
                                className="pl-10 pr-4 py-2 w-96 bg-gray-800 border border-gray-700 rounded text-gray-200 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                            />
                        </div>
                    </div>
                    <div className="flex items-center space-x-4">
                        <NavButton>API Reference</NavButton>
                        <NavButton>Enterprise Support</NavButton>
                        <button className="p-2 text-gray-300 hover:text-white hover:bg-gray-800 rounded-full relative">
                            <Bell size={20} />
                            <span className="absolute top-1 right-1 h-2 w-2 bg-blue-500 rounded-full"></span>
                        </button>
                        <div className="relative" ref={dropdownRef}>
                            <button
                                onClick={toggleDropdown}
                                className="flex items-center space-x-3 ml-4 px-3 py-2 bg-gray-800 rounded cursor-pointer"
                            >
                                <div className="h-8 w-8 bg-blue-600 rounded-full flex items-center justify-center text-white text-sm font-medium">
                                    EC
                                </div>
                                <div>
                                    <div className="text-gray-200 text-sm">Enterprise</div>
                                    <div className="text-gray-400 text-xs">Southeast-mb-2</div>
                                    <div className="text-gray-400 text-xs"></div>
                                </div>
                                <ChevronDown size={16} className="text-gray-400" />
                            </button>
                            {isDropdownOpen && (
                                <div className="absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none origin-top-right">
                                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="dropdown">
                                        <button
                                            className="text-gray-700 flex w-full px-4 py-2 text-left text-sm hover:bg-gray-100" role="menuitem" onClick={toggleDropdown}
                                        >
                                            Southeast-mb-2 <br /> <span className="text-xs text-gray-500">Bengaluru</span>
                                        </button>
                                        <button
                                            className="text-gray-400 flex w-full px-4 py-2 text-left text-sm cursor-not-allowed hover:bg-gray-100" role="menuitem" disabled onClick={toggleDropdown}
                                        >
                                            us-west-san Fransisco <br /> <span className="text-xs text-gray-500">San Fransisco (Unavailable)</span>
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const CampaignStatus = ({ campaignId }) => {
    const [statusData, setStatusData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { user } = useUser();

    useEffect(() => {
        const fetchCampaignStatus = async () => {
            setLoading(true);
            setError(null);
            try {
                if (!user) {
                    setError("User information not available.");
                    setLoading(false);
                    return;
                }
                const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/campstatus', {
                    cid: user.id,
                    campaignId: campaignId // Use campaignId prop here
                });
                setStatusData(response.data);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching campaign status:", err);
                setError("Failed to load campaign status.");
                setLoading(false);
            }
        };

        fetchCampaignStatus();
    }, [user, campaignId]); // campaignId in dependency array

    if (loading) {
        return <p className="text-gray-500 text-sm">Loading status...</p>;
    }

    if (error) {
        return <p className="text-red-500 text-sm">Error: {error}</p>;
    }

    if (!statusData) {
        return <p className="text-gray-500 text-sm">Status unavailable</p>;
    }

    let statusColor = 'text-gray-700';
    if (statusData.status === 'success') {
        statusColor = 'text-green-600';
    } else if (statusData.status === 'failed' || statusData.status === 'at_risk') {
        statusColor = 'text-red-600';
    } else if (statusData.status === 'delayed') {
        statusColor = 'text-yellow-600';
    }


    return (
        <div className="flex items-center space-x-1">
            <span className={`text-sm font-medium ${statusColor}`}>Status: {statusData.status.replace('_', ' ')}</span>
        </div>
    );
};


const CampaignsPage = () => {
    const [campaigns, setCampaigns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { user } = useUser();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCampaigns = async () => {
            setLoading(true);
            setError(null);
            try {
                if (!user) {
                    setError("User information not available.");
                    setLoading(false);
                    return;
                }

                const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/allcamps', {
                    cid: user?.id
                });
                setCampaigns(response.data.campaigns || []);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching campaigns:", err);
                setError("Failed to load campaigns.");
                setLoading(false);
            }
        };

        fetchCampaigns();
    }, [user]);

    if (loading) {
        return <div className="min-h-screen bg-gray-50"><EnterpriseHeader /><div className="max-w-7xl mx-auto px-4 py-8">Loading campaigns...</div></div>;
    }

    if (error) {
        return <div className="min-h-screen bg-gray-50"><EnterpriseHeader /><div className="max-w-7xl mx-auto px-4 py-8">Error: {error}</div></div>;
    }

    return (
        <div className="min-h-screen bg-gray-50">
            <EnterpriseHeader />
            <div className="max-w-7xl mx-auto px-4 py-8">
                <div className="flex items-center justify-between mb-8">
                    <div>
                        <div className="flex items-center space-x-4">
                            <h1 className="text-2xl font-semibold text-gray-900">Campaigns</h1>
                            <span className="px-3 py-1 bg-blue-50 text-blue-700 text-sm font-medium rounded-full">
                                Enterprise
                            </span>
                        </div>
                        <p className="mt-2 text-sm text-gray-600">Organization: Echidna Enterprise Solutions · Region: Southeast-mb-2</p>
                    </div>
                    <div className="flex items-center space-x-4">
                        <button className="flex items-center space-x-2 px-4 py-2 bg-white border border-gray-200 rounded-lg text-gray-700 hover:bg-gray-50">
                            <HelpCircle size={20} />
                            <span className="text-sm font-medium">Documentation</span>
                        </button>
                        <button onClick={() => navigate('/echidnamail/new-campaign')} className="px-4 py-2 bg-blue-600 text-white rounded-lg text-sm font-medium hover:bg-blue-700">
                            Create New Campaign
                        </button>
                    </div>
                </div>

                {campaigns.length > 0 ? (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {campaigns.map((campaign, index) => (
                            <div key={index} className="bg-white rounded-lg shadow-sm p-4">
                                <h3 className="font-semibold text-gray-900 mb-2">{campaign.campaignName || 'Campaign Name Unavailable'}</h3>
                                <CampaignStatus campaignId={campaign.campaignId} /> {/* Use campaign.campaignId here */}
                                <div className="flex justify-between items-center mt-3">
                                    <Link to={`/echidna/${campaign.campaignName}`} className="text-sm font-medium text-blue-600">
                                        View Details →
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="bg-white rounded-lg shadow-sm p-4 text-center">
                        <p className="text-gray-700">No campaigns found.</p>
                    </div>
                )}
            </div>
            <GamefiedAIAssistant />
        </div>
    );
};

export default CampaignsPage;