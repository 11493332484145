import React, { useState, useEffect } from 'react';
import { ArrowRightIcon, CheckCircleIcon, Lightbulb,} from 'lucide-react';
import eemail from './echma.png'
import echat from './echat.png'
import { motion } from 'framer-motion';
import eana from './e.png'
import { HelmetProvider, Helmet } from 'react-helmet-async'
// Navigation Component
const Navigation = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className="bg-white border-b border-gray-100 py-4">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <div className="text-xl font-semibold text-gray-900">
              <span className="text-indigo-600">echidna</span>suite
            </div>
          </div>
          <div className="hidden md:flex items-center space-x-6">
            <a href="#products" className="text-gray-700 hover:text-indigo-600 transition">Products</a>
            <a href="#pricing" className="text-gray-700 hover:text-indigo-600 transition">Pricing</a>
            <button className="bg-indigo-600 text-white px-4 py-2 rounded-md font-medium hover:bg-indigo-700 transition">
              Get Started
            </button>
          </div>
          <div className="md:hidden">
            <button 
              className="text-gray-700" 
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      {/* Mobile menu */}
      {isMenuOpen && (
        <div className="md:hidden pt-2 pb-4 px-4">
          <a href="#features" className="block py-2 text-gray-700 hover:text-indigo-600">Features</a>
          <a href="#products" className="block py-2 text-gray-700 hover:text-indigo-600">Products</a>
          <a href="#pricing" className="block py-2 text-gray-700 hover:text-indigo-600">Pricing</a>
          <button className="mt-2 w-full bg-indigo-600 text-white px-4 py-2 rounded-md font-medium hover:bg-indigo-700">
            Get Started
          </button>
        </div>
      )}
    </nav>
  );
};

// Hero Section Component
const Hero = () => {
  return (
    <section className="py-16 px-4 sm:px-6 lg:px-8 bg-gradient-to-br from-gray-50 to-gray-100">
      <div className="max-w-5xl mx-auto">
        <div className="text-center">
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
            Sales Automation, <span className="text-indigo-600">Reimagined</span>
          </h1>
          <p className="mt-3 text-lg text-gray-600 sm:mt-5 sm:text-xl max-w-xl mx-auto">
            The all-in-one data-driven sales suite that helps you find, reach, and convert leads faster than ever before.
          </p>
          <div className="mt-8 flex justify-center">
           <a href="https://calendly.com/manas-o4af/30min"> <button className="ml-4 text-indigo-600 bg-white border border-indigo-600 px-6 py-3 rounded-md font-medium hover:bg-indigo-50 transition">
              Book a Demo
            </button></a>
          </div>
        </div>
      </div>
    </section>
  );
};

// Stats Component
const Stats = () => {
  return (
    <section className="py-12 px-4 sm:px-6 lg:px-8 bg-white">
      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="text-center">
          <div className="text-4xl font-bold text-indigo-600">95%</div>
          <div className="mt-2 text-gray-600">Improved outreach efficiency</div>
        </div>
        <div className="text-center">
          <div className="text-4xl font-bold text-indigo-600">3.2x</div>
          <div className="mt-2 text-gray-600">Higher conversion rate</div>
        </div>
        <div className="text-center">
          <div className="text-4xl font-bold text-indigo-600">100+</div>
          <div className="mt-2 text-gray-600">Active businesses</div>
        </div>
      </div>
    </section>
  );
};

// Sponsor Slider Component
const SponsorSlider = () => {
    const [position, setPosition] = useState(0);
    
    const sponsors = [
      { name: "Microsoft", logo: "M", color: "bg-blue-500", textColor: "text-blue-700" },
      { name: "Amazon", logo: "A", color: "bg-orange-500", textColor: "text-orange-700" },
      { name: "NVIDIA", logo: "N", color: "bg-green-500", textColor: "text-green-700" },
      { name: "Buildspace", logo: "B", color: "bg-purple-500", textColor: "text-purple-700" }
    ];
    
    useEffect(() => {
      const interval = setInterval(() => {
        setPosition((prev) => (prev + 1) % sponsors.length);
      }, 3000);
      return () => clearInterval(interval);
    }, [sponsors.length]);
  
    return (
      <section className="py-12 px-4 sm:px-6 lg:px-8 bg-gray-50">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-xl font-medium text-center text-gray-600 mb-2">Recognised By Industry Leaders</h2>
          
          <div className="relative h-20 overflow-hidden my-8">
            <div 
              className="absolute w-full flex transition-transform duration-500 ease-in-out"
              style={{ transform: `translateX(${-position * 100}%)` }}
            >
              {sponsors.map((sponsor, index) => (
                <div key={index} className="flex-none w-full flex justify-center items-center">
                  <div className="flex items-center">
                    <div className={`h-16 w-16 ${sponsor.color} rounded-md flex items-center justify-center text-3xl font-bold text-white mr-4 shadow-md`}>
                      {sponsor.logo}
                    </div>
                    <span className={`text-2xl font-semibold ${sponsor.textColor}`}>{sponsor.name}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          
          <div className="flex justify-center mt-4">
            {sponsors.map((_, index) => (
              <button
                key={index}
                className={`h-2 w-2 mx-1 rounded-full transition-all duration-300 ${position === index ? 'bg-indigo-600 w-4' : 'bg-gray-300'}`}
                onClick={() => setPosition(index)}
                aria-label={`Go to slide ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </section>
    );
  };
  



// ProductItem component that handles the layout for each product


const ProductItem = ({ title, subtitle, description, features, imagePosition, icon, image }) => {
    return (
      <div className="mb-24 lg:mb-32">
        <div className={`flex flex-col ${imagePosition === 'left' ? 'lg:flex-row-reverse' : 'lg:flex-row'} items-center`}>
          {/* Content Section */}
          <div className="w-full lg:w-1/2 px-4 mb-8 lg:mb-0">
            <div className="max-w-lg mx-auto lg:mx-0">
              <div className="inline-flex items-center justify-center w-12 h-12 mb-4 bg-indigo-100 rounded-xl">
                <svg className="w-6 h-6 text-indigo-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={icon}></path>
                </svg>
              </div>
              <h3 className="text-2xl font-bold text-gray-900">{title}</h3>
              <p className="mt-1 text-lg font-medium text-indigo-600">{subtitle}</p>
              <p className="mt-4 text-gray-600">{description}</p>
              <ul className="mt-6 space-y-2">
                {features.map((feature, i) => (
                  <li key={i} className="flex items-start">
                    <svg className="w-5 h-5 mt-1 text-indigo-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    <span className="ml-2 text-gray-600">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          
          {/* Image Section */}
          <div className="w-full lg:w-1/2 px-4">
            <div 
              className={`
                relative overflow-hidden rounded-lg shadow-lg h-auto
                ${imagePosition === 'left' ? 'lg:ml-8' : 'lg:mr-8'}
              `}
            >
              {/* Browser UI for all images */}
              <div className="w-full flex flex-col">
                {/* Browser header with exact styling from screenshots */}
                <div className="h-10 bg-gray-100 flex items-center px-4">
                  {/* Window control buttons */}
                  <div className="flex space-x-2">
                    <div className="w-3 h-3 rounded-full bg-red-500"></div>
                    <div className="w-3 h-3 rounded-full bg-yellow-400"></div>
                    <div className="w-3 h-3 rounded-full bg-green-500"></div>
                  </div>
                  
                  {/* URL bar */}
                  <div className="mx-auto bg-white rounded-full h-6 w-1/2 flex items-center justify-center text-xs text-gray-500 px-4">
                    echidna.app
                  </div>
                  
                  {/* Empty space to balance the layout */}
                  <div className="w-16"></div>
                </div>
                
                {/* Content area */}
                <div className="bg-white">
                  <img 
                    src={image} 
                    alt={title} 
                    className="w-full h-auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  const Products = () => {
    const productData = [
      {
        title: "Echidna Mail",
        subtitle: "Outreach Automation",
        description: "Our automated outreach system gives you the power to connect with prospects at scale while maintaining a personal touch.",
        features: [
          "Advanced compliance checking",
          "Smart scheduling and follow-ups",
          "Performance analytics dashboard"
        ],
        imagePosition: "right",
        icon: "M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z",
        image: eemail
      },
      {
        title: "Echidna LLM",
        subtitle: "AI-Powered Communication",
        description: "Our specialized large language model trained specifically for sales communication helps you craft the perfect message every time.",
        features: [
          "Context-aware message generation",
          "Personalized at scale",
          "Industry-specific training"
        ],
        imagePosition: "left",
        icon: "M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2",
        image: echat
      },
      {
        title: "Echidna Data Analytics",
        subtitle: "Market Intelligence",
        description: "Powerful analytics that provide actionable insights about your competitors and market trends.",
        features: [
          "Competitor analysis (SEO, messaging, tech stack)",
          "Live Product-Market Fit scoring",
          "Dynamic market trend reports"
        ],
        imagePosition: "right",
        icon: "M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z",
        image: eana
      }
    ];
  
    return (
      <section id="products" className="py-16 px-4 sm:px-6 lg:px-8 bg-gray-50">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">Meet the Echidna Suite</h2>
          
          {productData.map((product, index) => (
            <ProductItem key={index} {...product} />
          ))}
        </div>
      </section>
    );
  };
  
// Products Section Component


const WorkflowIntegration = () => {
    const [activeWorkflow, setActiveWorkflow] = useState(0);
    const [hoveredStep, setHoveredStep] = useState(null);
    
    const workflowData = [
      {
        title: "Prospect to Customer Journey",
        description: "See how Echidna products seamlessly work together to transform prospects into loyal customers.",
        steps: [
          {
            title: "Identify Prospects",
            product: "Echidna Data Analytics",
            description: "Use market intelligence to identify high-value prospects and target the right audience segments with precision.",
            icon: "M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2",
            color: "blue"
          },
          {
            title: "Craft Messaging",
            product: "Echidna LLM",
            description: "Generate personalized, industry-specific outreach content that resonates with your target audience's specific pain points.",
            icon: "M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z",
            color: "purple"
          },
          {
            title: "Automate Outreach",
            product: "Echidna Mail",
            description: "Schedule and automate follow-ups with compliance checks, ensuring timely engagement without overwhelming prospects.",
            icon: "M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z",
            color: "green"
          },
          {
            title: "Analyze & Optimize",
            product: "All Products",
            description: "Measure results and refine approach using cross-product analytics to continuously improve conversion rates and ROI.",
            icon: "M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z",
            color: "indigo"
          }
        ]
      },
      {
        title: "Customer Retention Strategy",
        description: "Discover how Echidna's suite helps you keep customers engaged, satisfied, and coming back for more.",
        steps: [
          {
            title: "Monitor Usage Patterns",
            product: "Echidna Analytics",
            description: "Track how customers use your products to identify opportunities for deeper engagement.",
            icon: "M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z",
            color: "cyan"
          },
          {
            title: "Personalize Communication",
            product: "Echidna LLM",
            description: "Craft tailored messaging based on customer behavior and preferences to increase relevance.",
            icon: "M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z",
            color: "pink"
          },
          {
            title: "Automate Loyalty Programs",
            product: "Echidna CRM",
            description: "Create and manage customized loyalty initiatives that reward your most valuable customers.",
            icon: "M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z",
            color: "amber"
          },
          {
            title: "Measure & Optimize",
            product: "All Products",
            description: "Analyze retention metrics and adjust strategies for maximum customer lifetime value.",
            icon: "M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z",
            color: "emerald"
          }
        ]
      }
    ];
  
    // Get color class based on color name
    const getColorClass = (color, element) => {
      const colorMap = {
        blue: {
          bg: 'bg-blue-500',
          bgLight: 'bg-blue-50',
          text: 'text-blue-600',
          hover: 'hover:bg-blue-600',
          border: 'border-blue-500'
        },
        purple: {
          bg: 'bg-purple-500',
          bgLight: 'bg-purple-50',
          text: 'text-purple-600',
          hover: 'hover:bg-purple-600',
          border: 'border-purple-500'
        },
        green: {
          bg: 'bg-green-500',
          bgLight: 'bg-green-50',
          text: 'text-green-600',
          hover: 'hover:bg-green-600',
          border: 'border-green-500'
        },
        indigo: {
          bg: 'bg-indigo-500',
          bgLight: 'bg-indigo-50',
          text: 'text-indigo-600',
          hover: 'hover:bg-indigo-600',
          border: 'border-indigo-500'
        },
        cyan: {
          bg: 'bg-cyan-500',
          bgLight: 'bg-cyan-50',
          text: 'text-cyan-600',
          hover: 'hover:bg-cyan-600',
          border: 'border-cyan-500'
        },
        pink: {
          bg: 'bg-pink-500',
          bgLight: 'bg-pink-50',
          text: 'text-pink-600',
          hover: 'hover:bg-pink-600',
          border: 'border-pink-500'
        },
        amber: {
          bg: 'bg-amber-500',
          bgLight: 'bg-amber-50',
          text: 'text-amber-600',
          hover: 'hover:bg-amber-600',
          border: 'border-amber-500'
        },
        emerald: {
          bg: 'bg-emerald-500',
          bgLight: 'bg-emerald-50',
          text: 'text-emerald-600',
          hover: 'hover:bg-emerald-600',
          border: 'border-emerald-500'
        }
      };
      
      return colorMap[color || 'blue'][element];
    };
  
    return (
      <section id="workflows" className="py-20 px-4 sm:px-6 lg:px-8 bg-gradient-to-b from-white to-gray-50">
        <div className="max-w-7xl mx-auto">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl font-bold mb-3 text-gray-900">Seamless Workflows</h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">See how our integrated products work together to drive your business forward</p>
          </motion.div>
          
          {/* Workflow tabs */}
          <div className="flex flex-wrap justify-center gap-4 mb-12">
            {workflowData.map((workflow, index) => (
              <button
                key={index}
                onClick={() => setActiveWorkflow(index)}
                className={`px-6 py-3 rounded-full text-lg font-medium transition-all duration-300 ${
                  activeWorkflow === index 
                    ? 'bg-blue-600 text-white shadow-lg' 
                    : 'bg-white text-gray-700 hover:bg-gray-100 shadow'
                }`}
              >
                {workflow.title}
              </button>
            ))}
          </div>
          
          {/* Active workflow */}
          {workflowData.map((workflow, index) => (
            <motion.div 
              key={index}
              initial={{ opacity: 0 }}
              animate={{ opacity: activeWorkflow === index ? 1 : 0, display: activeWorkflow === index ? 'block' : 'none' }}
              transition={{ duration: 0.5 }}
              className="mb-20"
            >
              <div className="text-center mb-12">
                <h3 className="text-3xl font-bold mb-4 text-gray-800">{workflow.title}</h3>
                <p className="text-gray-600 text-lg max-w-3xl mx-auto">{workflow.description}</p>
              </div>
              
              {/* Workflow steps */}
              <div className="grid grid-cols-1 lg:grid-cols-4 gap-8 mb-12">
                {workflow.steps.map((step, stepIndex) => (
                  <motion.div
                    key={stepIndex}
                    whileHover={{ 
                      y: -10,
                      boxShadow: '0 10px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)'
                    }}
                    onHoverStart={() => setHoveredStep(stepIndex)}
                    onHoverEnd={() => setHoveredStep(null)}
                    className="relative bg-white rounded-xl p-8 shadow-md overflow-hidden"
                  >
                    {/* Colored top bar */}
                    <div className={`absolute top-0 left-0 w-full h-2 ${getColorClass(step.color, 'bg')}`}></div>
                    
                    {/* Step number and title */}
                    <div className="flex items-center mb-6">
                      <div className={`w-12 h-12 flex items-center justify-center rounded-lg mr-4 ${getColorClass(step.color, 'bgLight')} ${getColorClass(step.color, 'text')}`}>
                        <span className="text-xl font-bold">{stepIndex + 1}</span>
                      </div>
                      <h4 className="text-xl font-bold text-gray-800">{step.title}</h4>
                    </div>
                    
                    {/* Product badge */}
                    <span className={`inline-block ${getColorClass(step.color, 'bgLight')} ${getColorClass(step.color, 'text')} px-3 py-1 text-sm font-medium rounded-md mb-4`}>
                      {step.product}
                    </span>
                    
                    {/* Description */}
                    <p className="text-gray-600 mb-6">{step.description}</p>
                    
                    {/* Icon */}
                    <motion.div
                      animate={{ 
                        scale: hoveredStep === stepIndex ? 1.2 : 1,
                        rotate: hoveredStep === stepIndex ? 360 : 0 
                      }}
                      transition={{ duration: 0.5 }}
                      className={`w-10 h-10 ${getColorClass(step.color, 'text')}`}
                    >
                      <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        fill="none" 
                        viewBox="0 0 24 24" 
                        stroke="currentColor"
                        className="w-full h-full"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={step.icon} />
                      </svg>
                    </motion.div>
                    
                    {/* Connection line (for desktop only) */}
                    {stepIndex < workflow.steps.length - 1 && (
                      <div className="hidden lg:block absolute top-1/2 -right-4 w-8 h-0.5 bg-gray-300">
                        <div className="absolute right-0 top-1/2 transform -translate-y-1/2 w-2 h-2 rounded-full bg-gray-300"></div>
                      </div>
                    )}
                  </motion.div>
                ))}
              </div>
              
              {/* CTA button */}
              <div className="text-center">
                <motion.a
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  href="#"
                  className={`inline-flex items-center px-6 py-3 rounded-lg ${getColorClass(workflow.steps[0].color, 'bg')} text-white font-medium text-lg shadow-lg hover:shadow-xl transition-all duration-300`}
                >
                  <span>See this workflow in action</span>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                  </svg>
                </motion.a>
                
                <p className="mt-4 text-gray-500">
                  Want to see how it fits your business? <a href="https://calendly.com/manas-o4af/30min"><p className="text-blue-600 hover:underline">Schedule a demo</p></a>
                </p>
              </div>
            </motion.div>
          ))}
        </div>
      </section>
    );
  };
  
  
  // Use Cases Component
  const UseCases = () => {
    const useCaseData = [
      {
        title: "Enterprise Sales Teams",
        description: "See how large sales organizations use Echidna Suite to increase conversions by 36% on average.",
        steps: [
          "Sales managers use Echidna Data Analytics to identify market trends and prioritize target accounts.",
          "SDRs leverage Echidna Mail to set up multi-touch campaigns for each target account segment.",
          "Account executives utilize Echidna LLM to personalize follow-up messages based on prospect engagement.",
          "Leadership tracks pipeline health through cross-product dashboards."
        ],
        stats: [
          { label: "Outreach efficiency", value: "+63%" },
          { label: "Response rate", value: "+28%" },
          { label: "Time saved per rep", value: "8.5 hrs/week" }
        ]
      },
      {
        title: "Startup Growth Teams",
        description: "Learn how agile teams use Echidna products to accelerate growth with limited resources.",
        steps: [
          "Founders use Echidna Data Analytics to refine product positioning against competitors.",
          "Growth marketers deploy Echidna Mail campaigns to test different market segments.",
          "The team uses Echidna LLM to iterate on messaging based on real-time feedback.",
          "Startups use integrated analytics to make data-driven decisions on go-to-market strategy."
        ],
        stats: [
          { label: "Market analysis time", value: "-72%" },
          { label: "Lead qualification", value: "+41%" },
          { label: "CAC reduction", value: "22%" }
        ]
      }
    ];
  
    return (
      <section id="use-cases" className="py-16 px-4 sm:px-6 lg:px-8 bg-gray-50">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-3xl font-bold text-center text-gray-900 mb-4">Real-World Applications</h2>
          <p className="text-xl text-center text-gray-600 mb-12">How companies like yours are using the Echidna Suite</p>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {useCaseData.map((useCase, index) => (
              <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden">
                <div className="p-6">
                  <h3 className="text-2xl font-bold text-gray-800 mb-3">{useCase.title}</h3>
                  <p className="text-gray-600 mb-6">{useCase.description}</p>
                  
                  <h4 className="text-lg font-semibold text-gray-700 mb-3">Implementation:</h4>
                  <ul className="space-y-3 mb-6">
                    {useCase.steps.map((step, stepIndex) => (
                      <li key={stepIndex} className="flex items-start">
                        <CheckCircleIcon className="w-5 h-5 text-green-500 mr-2 mt-1 flex-shrink-0" />
                        <span className="text-gray-600">{step}</span>
                      </li>
                    ))}
                  </ul>
                  
                  <h4 className="text-lg font-semibold text-gray-700 mb-3">Results:</h4>
                  <div className="grid grid-cols-3 gap-4">
                    {useCase.stats.map((stat, statIndex) => (
                      <div key={statIndex} className="text-center p-3 bg-blue-50 rounded-lg">
                        <p className="text-2xl font-bold text-blue-600">{stat.value}</p>
                        <p className="text-sm text-gray-600">{stat.label}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  };
  
  // Integration Partners Component
  const IntegrationPartners = () => {
    const categories = [
      {
        name: "CRM Systems",
        description: "Seamlessly connect your customer data",
        integrations: [
          { name: "Salesforce", logo: "/api/placeholder/120/40", popular: true },
          { name: "HubSpot", logo: "/api/placeholder/120/40", popular: true },
          { name: "Pipedrive", logo: "/api/placeholder/120/40", popular: false },
          { name: "Zoho CRM", logo: "/api/placeholder/120/40", popular: false }
        ]
      },
      {
        name: "Marketing Platforms",
        description: "Coordinate your multi-channel campaigns",
        integrations: [
          { name: "Marketo", logo: "/api/placeholder/120/40", popular: true },
          { name: "Mailchimp", logo: "/api/placeholder/120/40", popular: true },
          { name: "HubSpot Marketing", logo: "/api/placeholder/120/40", popular: false },
          { name: "ActiveCampaign", logo: "/api/placeholder/120/40", popular: false }
        ]
      },
      {
        name: "Analytics Tools",
        description: "Centralize your performance metrics",
        integrations: [
          { name: "Google Analytics", logo: "/api/placeholder/120/40", popular: true },
          { name: "Mixpanel", logo: "/api/placeholder/120/40", popular: false },
          { name: "Amplitude", logo: "/api/placeholder/120/40", popular: false },
          { name: "Tableau", logo: "/api/placeholder/120/40", popular: true }
        ]
      }
    ];
  
    return (
      <section id="integrations" className="py-16 px-4 sm:px-6 lg:px-8 bg-white">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-3xl font-bold text-center text-gray-900 mb-4">Works With Your Stack</h2>
          <p className="text-xl text-center text-gray-600 mb-12">The Echidna Suite integrates with your existing tools</p>
          
          <div className="space-y-12">
            {categories.map((category, index) => (
              <div key={index} className="bg-gray-50 rounded-lg p-8">
                <div className="flex flex-col md:flex-row md:items-center justify-between mb-6">
                  <div>
                    <h3 className="text-2xl font-bold text-gray-800">{category.name}</h3>
                    <p className="text-gray-600">{category.description}</p>
                  </div>
                  <a href="#" className="text-blue-600 hover:text-blue-800 font-medium mt-3 md:mt-0 inline-flex items-center">
                    View all integrations <ArrowRightIcon className="w-4 h-4 ml-1" />
                  </a>
                </div>
                
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                  {category.integrations.map((integration, integrationIndex) => (
                    <div key={integrationIndex} className="bg-white rounded-lg p-4 shadow-sm flex flex-col items-center justify-center relative h-24">
                      {integration.popular && (
                        <span className="absolute top-0 right-0 transform translate-x-1/3 -translate-y-1/3 bg-blue-500 text-white text-xs py-1 px-2 rounded-full">
                          Popular
                        </span>
                      )}
                      <img src={integration.logo} alt={integration.name} className="h-8 mb-2" />
                      <p className="text-sm font-medium text-gray-800">{integration.name}</p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  };
  
  // Getting Started Component
  const GettingStarted = () => {
    const steps = [
      {
        number: 1,
        title: "Schedule a Consultation",
        description: "Our solution architects will analyze your current workflow and recommend the right Echidna products.",
        icon: "M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
      },
      {
        number: 2,
        title: "Custom Implementation",
        description: "We'll help you integrate Echidna products with your existing tools and train your team.",
        icon: "M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
      },
      {
        number: 3,
        title: "Launch & Optimize",
        description: "Start using the Echidna Suite with ongoing support and regular optimization reviews.",
        icon: "M13 10V3L4 14h7v7l9-11h-7z"
      }
    ];
  
    return (
      <section id="get-started" className="py-16 px-4 sm:px-6 lg:px-8 bg-blue-50">
        <div className="max-w-6xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Ready to Get Started?</h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Implementing the Echidna Suite is a straightforward process designed to get you up and running quickly.
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
            {steps.map((step) => (
              <div key={step.number} className="bg-white rounded-lg shadow-md p-6 relative">
                <div className="absolute -top-5 -left-5 bg-blue-600 text-white rounded-full w-12 h-12 flex items-center justify-center text-2xl font-bold">
                  {step.number}
                </div>
                <div className="pt-6">
                  <div className="mb-4 text-blue-600">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-8 h-8">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={step.icon} />
                    </svg>
                  </div>
                  <h3 className="text-xl font-bold text-gray-800 mb-2">{step.title}</h3>
                  <p className="text-gray-600">{step.description}</p>
                </div>
              </div>
            ))}
          </div>
          
          <div className="bg-blue-600 rounded-lg p-8 text-center text-white">
            <h3 className="text-2xl font-bold mb-4">Begin Your Echidna Journey Today</h3>
           <p className="text-lg mb-6 max-w-2xl mx-auto">
              Join hundreds of companies who have transformed their sales and marketing operations with Echidna.
            </p>
            <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
            <a href="https://calendly.com/manas-o4af/30min">  <button className="bg-white text-blue-600 hover:bg-gray-100 px-6 py-3 rounded-lg font-bold flex items-center justify-center">
                <span>Schedule Demo</span>
                <Lightbulb className="w-5 h-5 ml-2" />
              </button></a>
            </div>
          </div>
        </div>
      </section>
    );
  };
  
  // ROI Calculator Component
  const ROICalculator = () => {
    const [formData, setFormData] = useState({
      salesReps: '',
      dealSize: '',
      winRate: '',
      leadToOppRate: ''
    });
    
    const [results, setResults] = useState(null);
    const [showResults, setShowResults] = useState(false);
    
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value
      });
    };
    
    const calculateROI = () => {
      // Convert inputs to numbers
      const reps = Number(formData.salesReps);
      const dealSize = Number(formData.dealSize);
      const currentWinRate = Number(formData.winRate) / 100;
      const currentLeadRate = Number(formData.leadToOppRate) / 100;
      
      // Apply the average improvements from our stats
      const improvedWinRate = currentWinRate * 1.42; // 42% increase in response/conversion
      const improvedLeadRate = currentLeadRate * 1.42; // 42% increase in lead conversion
      const timeEfficiencyGain = 0.67; // 67% decrease in prospecting time
      
      // Calculate current and improved deals
      const currentDealsPerRep = 50 * currentLeadRate * currentWinRate; // Assuming 50 leads per rep
      const improvedDealsPerRep = 50 * improvedLeadRate * improvedWinRate;
      
      // Calculate revenue impact
      const currentAnnualRevenue = reps * currentDealsPerRep * dealSize;
      const projectedAnnualRevenue = reps * improvedDealsPerRep * dealSize;
      const additionalRevenue = projectedAnnualRevenue - currentAnnualRevenue;
      
      // Calculate time savings
      const hoursSavedPerRep = 10 * 4 * 12 * timeEfficiencyGain; // 10 hours/week on prospecting
      const totalHoursSaved = hoursSavedPerRep * reps;
      const valuePerHour = 50; // Assuming $50/hour value of sales rep time
      const timeSavingsValue = totalHoursSaved * valuePerHour;
      
      
      // Calculate ROI
      const platformCost = 1000; // Flat $200 cost
      const totalBenefit = additionalRevenue + timeSavingsValue;
      const roi = totalBenefit / platformCost;
      const paybackMonths = (platformCost / (totalBenefit / 12)) >= 1 
        ? Math.ceil(platformCost / (totalBenefit / 12)) 
        : (platformCost / (totalBenefit / 12)).toFixed(1);
      
      setResults({
        additionalRevenue,
        timeSavingsValue,
        totalBenefit,
        roi,
        paybackMonths,
        platformCost,
        currentDeals: reps * currentDealsPerRep,
        projectedDeals: reps * improvedDealsPerRep,
        additionalDeals: reps * (improvedDealsPerRep - currentDealsPerRep),
        totalHoursSaved
      });
      
      setShowResults(true);
    };
    
    return (
      <section id="roi-calculator" className="py-16 px-4 sm:px-6 lg:px-8 bg-white">
        <div className="max-w-6xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-4xl font-bold text-gray-900 mb-4">Calculate Your ROI with Echidna Suite</h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              See the impact our platform can have on your business with just a $200 flat per month
            </p>
          </div>
          
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-start">
            <div>
              <div className="bg-blue-50 p-8 rounded-lg mb-8">
                <h3 className="text-2xl font-semibold text-gray-800 mb-6">Our Customers See Real Results</h3>
                <ul className="space-y-4">
                  <li className="flex items-start">
                    <svg className="w-6 h-6 text-green-500 mr-3 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <div>
                      <span className="text-lg font-bold text-blue-600">42% increase</span>
                      <p className="text-gray-700">in response rates and conversions</p>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <svg className="w-6 h-6 text-green-500 mr-3 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <div>
                      <span className="text-lg font-bold text-blue-600">28% reduction</span>
                      <p className="text-gray-700">in sales cycle length</p>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <svg className="w-6 h-6 text-green-500 mr-3 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <div>
                      <span className="text-lg font-bold text-blue-600">67% decrease</span>
                      <p className="text-gray-700">in time spent on prospecting</p>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <svg className="w-6 h-6 text-green-500 mr-3 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <div>
                      <span className="text-lg font-bold text-blue-600">4.2 months</span>
                      <p className="text-gray-700">average time to achieve ROI</p>
                    </div>
                  </li>
                </ul>
              </div>
              
              <div className="bg-gray-50 p-6 rounded-lg">
                <h3 className="text-xl font-bold text-gray-800 mb-4">Platform Investment</h3>
                <div className="flex items-center justify-between p-4 border border-blue-200 rounded bg-blue-50">
                  <span className="text-gray-700 font-medium">Flat fee</span>
                  <span className="text-2xl font-bold text-blue-600">$200</span>
                </div>
                <p className="mt-4 text-sm text-gray-600">
                  One-time payment gives you full access to all Echidna Suite features with no hidden costs or fees.
                </p>
              </div>
            </div>
            
            <div className="bg-white rounded-lg p-8 shadow-lg border border-gray-200">
              <h3 className="text-2xl font-bold text-gray-800 mb-6">Calculate Your ROI</h3>
              
              <form className="space-y-6">
  <div>
    <label className="block text-sm font-medium text-gray-700 mb-2">Number of sales reps</label>
    <input
      type="number"
      name="salesReps"
      value={formData.salesReps}
      onChange={handleInputChange}
      className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 bg-white"
      placeholder="e.g., 5"
    />
  </div>
  
  <div>
    <label className="block text-sm font-medium text-gray-700 mb-2">Average deal size ($)</label>
    <input
      type="number"
      name="dealSize"
      value={formData.dealSize}
      onChange={handleInputChange}
      className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 bg-white"
      placeholder="e.g., 10000"
    />
  </div>
  
  <div>
    <label className="block text-sm font-medium text-gray-700 mb-2">Current win rate (%)</label>
    <input
      type="number"
      name="winRate"
      value={formData.winRate}
      onChange={handleInputChange}
      className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 bg-white"
      placeholder="e.g., 20"
    />
  </div>
  
  <div>
    <label className="block text-sm font-medium text-gray-700 mb-2">Current lead-to-opportunity rate (%)</label>
    <input
      type="number"
      name="leadToOppRate"
      value={formData.leadToOppRate}
      onChange={handleInputChange}
      className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 bg-white"
      placeholder="e.g., 15"
    />
  </div>
  
  <div className="pt-2">
    <button
      type="button"
      onClick={calculateROI}
      className="w-full bg-blue-600 hover:bg-blue-700 text-white py-3 px-4 rounded-lg font-medium transition duration-200"
    >
      Calculate My ROI
    </button>
  </div>
</form>
              
              {showResults && results && (
                <div className="mt-8 p-6 bg-blue-50 rounded-lg border border-blue-200">
                  <h4 className="font-bold text-xl text-gray-800 mb-4">Your Projected Annual Impact</h4>
                  
                  <div className="grid grid-cols-2 gap-4 mb-6">
                    <div>
                      <p className="text-sm text-gray-600">Additional Revenue</p>
                      <p className="text-2xl font-bold text-blue-600">${results.additionalRevenue.toLocaleString()}</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-600">Return on Investment</p>
                      <p className="text-2xl font-bold text-blue-600">{results.roi.toFixed(1)}x</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-600">Additional Deals</p>
                      <p className="text-2xl font-bold text-green-600">{Math.round(results.additionalDeals)}</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-600">Time to ROI</p>
                      <p className="text-2xl font-bold text-green-600">{results.paybackMonths} {results.paybackMonths === 1 ? 'month' : 'months'}</p>
                    </div>
                  </div>
                  
                  <h5 className="font-semibold text-lg text-gray-800 mb-3">ROI Analysis</h5>
                  <div className="bg-white p-4 rounded border border-gray-200 mb-4">
                    <ul className="space-y-2 text-sm">
                      <li className="flex justify-between">
                        <span className="text-gray-600">Platform investment:</span>
                        <span className="font-medium">${results.platformCost}</span>
                      </li>
                      <li className="flex justify-between">
                        <span className="text-gray-600">Hours saved annually:</span>
                        <span className="font-medium">{Math.round(results.totalHoursSaved).toLocaleString()} hours</span>
                      </li>
                      <li className="flex justify-between">
                        <span className="text-gray-600">Value of time savings:</span>
                        <span className="font-medium">${results.timeSavingsValue.toLocaleString()}</span>
                      </li>
                      <li className="flex justify-between">
                        <span className="text-gray-600">Current annual deals:</span>
                        <span className="font-medium">{Math.round(results.currentDeals)}</span>
                      </li>
                      <li className="flex justify-between">
                        <span className="text-gray-600">Projected annual deals:</span>
                        <span className="font-medium">{Math.round(results.projectedDeals)}</span>
                      </li>
                      <li className="flex justify-between border-t border-gray-200 pt-2 mt-2">
                        <span className="text-gray-700 font-medium">Total annual benefit:</span>
                        <span className="font-bold">${results.totalBenefit.toLocaleString()}</span>
                      </li>
                    </ul>
                  </div>
                  
                  <p className="text-sm text-gray-600 italic">
                    Based on industry averages and customer success metrics. Your actual results may vary.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  };

// Pricing Component
const Pricing = () => {
  return (
    <section id="pricing" className="py-16 px-4 sm:px-6 lg:px-8 bg-white">
      <div className="max-w-4xl mx-auto">
        <h2 className="text-3xl font-bold text-center text-gray-900 mb-4">Simple, Transparent Pricing</h2>
        <p className="text-center text-gray-600 mb-12 max-w-2xl mx-auto">
          Everything you need to supercharge your sales process, all for one affordable monthly price.
        </p>
        
        <div className="bg-white rounded-lg shadow-xl overflow-hidden border border-gray-200">
          <div className="px-6 py-8 bg-indigo-600 sm:p-10 sm:pb-6">
            <div className="flex justify-between items-center">
              <div>
                <h3 className="text-2xl leading-8 font-extrabold text-white sm:text-3xl sm:leading-9">
                  Pro Suite
                </h3>
                <p className="mt-1 text-base leading-6 text-indigo-100">
                  Full access to all Echidna products
                </p>
              </div>
              <div className="text-right">
                <p className="text-4xl leading-none font-extrabold text-white">
                  $200
                </p>
                <p className="mt-1 text-base leading-6 text-indigo-100">
                  per month
                </p>
              </div>
            </div>
          </div>
          <div className="px-6 pt-6 pb-8 bg-gray-50 sm:p-10">
            <ul className="space-y-4">
              <li className="flex items-start">
                <div className="flex-shrink-0">
                  <svg className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <p className="ml-3 text-base leading-6 text-gray-700">
                  Echidna Mail with full compliance tools
                </p>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0">
                  <svg className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <p className="ml-3 text-base leading-6 text-gray-700">
                  Access to Echidna LLM for sales communications
                </p>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0">
                  <svg className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <p className="ml-3 text-base leading-6 text-gray-700">
                  Complete competitor and market analysis tools
                </p>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0">
                  <svg className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <p className="ml-3 text-base leading-6 text-gray-700">
                  Unlimited access to market reports
                </p>
              </li>
              <li className="flex items-start">
                <div className="flex-shrink-0">
                  <svg className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <p className="ml-3 text-base leading-6 text-gray-700">
                  Priority support and onboarding
                </p>
              </li>
            </ul>
            <div className="mt-8">
            <a href="https://calendly.com/manas-o4af/30min">     <button className="w-full bg-indigo-600 text-white py-3 px-6 rounded-md font-medium hover:bg-indigo-700 transition shadow-md">
                Get Started Now
              </button></a>
            </div>
            <p className="text-center text-sm text-gray-500 mt-4">
              14-day free trial. No credit card required.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

// CTA Component
const CallToAction = () => {
  return (
    <section className="py-16 px-4 sm:px-6 lg:px-8 bg-indigo-600">
      <div className="max-w-4xl mx-auto text-center">
        <h2 className="text-3xl font-bold text-white mb-4">Ready to transform your sales process?</h2>
        <p className="text-indigo-100 mb-8 text-lg">
          Join hundreds of growing companies using Echidna Suite to close more deals.
        </p>
        <div className="flex flex-col sm:flex-row justify-center gap-4">
          <button className="bg-white text-indigo-600 px-6 py-3 rounded-md font-medium hover:bg-gray-100 transition shadow-md">
            Start Free Trial
          </button>
          <button className="bg-indigo-700 text-white px-6 py-3 rounded-md font-medium hover:bg-indigo-800 transition border border-indigo-500">
            Schedule Demo
          </button>
        </div>
      </div>
    </section>
  );
};

// Footer Component
const Footer = () => {
  return (
    <footer className="bg-gray-800 text-gray-300 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-6xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <div className="text-xl font-semibold text-white mb-4">
              <span className="text-indigo-400">echidna</span>suite
            </div>
            <p className="text-gray-400">
              The data-driven sales automation platform for modern teams.
            </p>
          </div>
          <div>
            <h4 className="text-white text-sm font-semibold mb-4 uppercase tracking-wider">Products</h4>
            <ul className="space-y-2">
              <li><a href="/login" className="text-gray-400 hover:text-white transition">Echidna Mail</a></li>
              <li><a href="/login" className="text-gray-400 hover:text-white transition">Echidna LLM</a></li>
              <li><a href="/login" className="text-gray-400 hover:text-white transition">Echidna Analytics</a></li>
            </ul>
          </div>
          <div>
            <h4 className="text-white text-sm font-semibold mb-4 uppercase tracking-wider">Company</h4>
            <ul className="space-y-2">
              <li><a href="/" className="text-gray-400 hover:text-white transition">About</a></li>
            </ul>
          </div>
        
        </div>
        <div className="mt-8 pt-8 border-t border-gray-700 text-sm text-gray-400">
          <p>© 2025 metabees AI labs, Inc. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

// Main App Component
const EchidnaSuiteLanding = () => {
  return (
    <div className="min-h-screen bg-white font-sans">
   <Helmet>
  <title>Revolutionize Your Outreach: Enterprise-Grade Automation Platform | Echidna Suite</title>
  
  {/* Primary Meta Tags */}
  <meta
    name="description"
    content="Dominate your market with Echidna Suite's military-grade outreach automation. Our AI-powered platform delivers 3X higher response rates, handles unlimited contacts, and integrates with 70+ platforms. Join industry leaders using our battle-tested system to crush sales targets and outperform competitors."
  />
  <meta
    name="keywords"
    content="outreach automation, sales outreach automation, email outreach automation, marketing automation, lead outreach, automated outreach, echidna suite, campaign automation, personalized outreach, intelligent outreach, ai-powered outreach, multichannel automation, sales funnel automation, lead nurturing, customer engagement, email sequence automation, drip campaign software, sales acceleration, digital marketing tools, b2b outreach, outbound marketing, automated follow-ups, conversion optimization, targeted campaigns, performance analytics, marketing technology, saas outreach, cloud-based automation, scalable outreach, efficient lead management, advanced segmentation, behavior-based triggers, real-time reporting, data-driven outreach, enterprise automation, sales enablement, revenue acceleration, hyperpersonalization, predictive analytics, machine learning outreach, conversation intelligence, sales intelligence, intent data, account-based marketing, sales engagement platform"
  />
  
  {/* Open Graph / Facebook */}
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://www.echidnasuite.com/outreach-automation" />
  <meta property="og:title" content="Revolutionize Your Outreach: Enterprise-Grade Automation Platform | Echidna Suite" />
  <meta
    property="og:description"
    content="Dominate your market with Echidna Suite's military-grade outreach automation. Our AI-powered platform delivers 3X higher response rates, handles unlimited contacts, and integrates with 70+ platforms. Join industry leaders using our battle-tested system to crush sales targets and outperform competitors."
  />
  <meta property="og:image" content="https://www.echidnasuite.com/images/social-share-outreach.jpg" />
  <meta property="og:site_name" content="Echidna Suite" />
  <meta property="og:locale" content="en_US" />
  
  {/* Twitter */}
  <meta property="twitter:card" content="summary_large_image" />
  <meta property="twitter:url" content="https://www.echidnasuite.com/outreach-automation" />
  <meta property="twitter:title" content="Revolutionize Your Outreach: Enterprise-Grade Automation Platform | Echidna Suite" />
  <meta
    property="twitter:description"
    content="Dominate your market with Echidna Suite's military-grade outreach automation. Our AI-powered platform delivers 3X higher response rates, handles unlimited contacts, and integrates with 70+ platforms. Join industry leaders using our battle-tested system to crush sales targets and outperform competitors."
  />
  <meta property="twitter:image" content="https://www.echidnasuite.com/images/social-share-outreach.jpg" />
  <meta name="twitter:creator" content="@EchidnaSuite" />
  
  {/* Additional Technical SEO */}
  <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
  <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
  <meta name="author" content="Echidna Suite Team" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=5.0" />
  <meta name="language" content="en-US" />
  <meta http-equiv="content-language" content="en-US" />
  
  {/* Structured Data for Rich Snippets */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "SoftwareApplication",
        "name": "Echidna Suite Outreach Automation",
        "applicationCategory": "BusinessApplication",
        "operatingSystem": "Web-based, iOS, Android",
        "offers": {
          "@type": "Offer",
          "price": "149.00",
          "priceCurrency": "USD"
        },
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "4.8",
          "ratingCount": "1247"
        },
        "description": "Enterprise-grade outreach automation platform with AI-powered personalization, multi-channel campaign orchestration, and advanced analytics."
      }
    `}
  </script>
  
  {/* Canonical URL */}
  <link rel="canonical" href="https://www.echidnasuite.com/outreach-automation" />
  
  {/* Alternate Language Versions if applicable */}
  <link rel="alternate" hreflang="en" href="https://www.echidnasuite.com/outreach-automation" />
  <link rel="alternate" hreflang="es" href="https://www.echidnasuite.com/es/automatizacion-de-alcance" />
  <link rel="alternate" hreflang="fr" href="https://www.echidnasuite.com/fr/automatisation-de-prospection" />
  <link rel="alternate" hreflang="de" href="https://www.echidnasuite.com/de/outreach-automatisierung" />
  
  {/* Favicons */}
  <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
  <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
  <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
  <link rel="manifest" href="/site.webmanifest" />
  
  {/* Preload Critical Resources */}
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link rel="preload" href="/assets/css/critical.css" as="style" />
  <link rel="preload" href="/assets/js/main.js" as="script" />
  
  {/* Page-specific preload for hero image */}
  <link rel="preload" href="/assets/images/outreach-hero.webp" as="image" type="image/webp" />
</Helmet>
      <Navigation />
      <Hero />
      <Stats />
      <SponsorSlider />
      <Products />
        <WorkflowIntegration />
        <UseCases />
       
   <ROICalculator />
      <Pricing />
      <GettingStarted />
      <Footer />
    </div>
  );
};

export default EchidnaSuiteLanding;