import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom'

import './awitch.css';

const Preview = () => {
  const canvasRef = useRef(null);
  const [scaleFactors, setScaleFactors] = useState({ x: 1, y: 1, z: 1 });
  const [ARscale, setARscaleFactors] = useState({ x: 1, y: 1, z: 1 });
  const [productModel, setProductModel] = useState(null);
  const [position, setPosition] = useState({ x: 0, y: 0, z: 0 });
  const [yrotation, setyRotation] = useState(0);
  const [xrotation, setxRotation] = useState(0);
  const [zrotation, setzRotation] = useState(0);
  const [fileNameWithoutExtension, setFileNameWithoutExtension] = useState('');
  const { userInfo } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { token } = useParams();
  const scaleMin = 0.1;
  const scaleMax = 5;
  const scaleStep = 0.1;

  const validateToken = async () => {
    try {
      const fileNameWithoutExtension = sessionStorage.getItem('fileNameWithoutExtension');
      setFileNameWithoutExtension(fileNameWithoutExtension);
      const response = await fetch(`http://localhost:5000/products/tokenvalidate/${fileNameWithoutExtension}/${token}`);
      if (response.ok) {
        // Token is valid, continue rendering the component
      } else {
        // Token is not valid, redirect or show an error message
        navigate('/login'); // You can navigate to the login page or an error page
      }
    } catch (error) {
      console.error('Error validating token:', error);
    }
  };

  useEffect(() => {

    validateToken();
    
    if (!userInfo) {
      navigate('/login');
      return;
    }

    let scene, camera, renderer, controls , environmentUrl, newPosition;

    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    const setupScene = () => {
      scene = new THREE.Scene();

      camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
      camera.position.z = 0;
      camera.position.x = -3;
      camera.position.y = 3.5;
      camera.lookAt(new THREE.Vector3(-20, 5, -1));

      const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
      directionalLight.position.set(0, 10.5, 17);
      scene.add(directionalLight);

      const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
      scene.add(ambientLight);

      renderer = new THREE.WebGLRenderer({
        canvas: canvasRef.current,
        antialias: true
      });
      renderer.setSize(window.innerWidth, window.innerHeight);

      controls = new OrbitControls(camera, renderer.domElement);
      controls.target.set(-20, 9, -0.5);
      controls.update();

      const envi = sessionStorage.getItem('envi');
    const serverLocation = sessionStorage.getItem('serverLocation');
      const fileNameWithoutExtension = sessionStorage.getItem('fileNameWithoutExtension');
      setFileNameWithoutExtension(fileNameWithoutExtension);

      //const enviloader = new GLTFLoader();
      //enviloader.load('https://metabeesindata.s3.ap-northeast-1.amazonaws.com/envi1.glb', (gltf) => {
        //scene.add(gltf.scene);
     // });
       
     const updatePosition = (newPos) => {
      handlePositionInputChange('x', newPos.x);
      handlePositionInputChange('y', newPos.y);
      handlePositionInputChange('z', newPos.z);
    };
    
    // Set newPosition based on environment
    if (envi === 'Original') {
      environmentUrl = 'https://metabeesindata.s3.ap-northeast-1.amazonaws.com/envi1.glb'; 
      newPosition = new THREE.Vector3(-5.5, 3.3, -0.3); // Original environment position
      updatePosition(newPosition);
    } else if (envi === 'Highperf') {
      environmentUrl = 'https://mbesindia.s3.ap-south-1.amazonaws.com/highperfe.glb';
      newPosition = new THREE.Vector3(0, 10.5, 17); // High performance environment position
      updatePosition(newPosition);
    } else {
      console.log("envierror");
    }

    // Load environment
    const enviloader = new GLTFLoader();
    enviloader.load(environmentUrl, (gltf) => {
      scene.add(gltf.scene);
      console.log('Environment Model Position:', gltf.scene.position);
    });
      

    let productBaseUrl;
    if (serverLocation === 'Mumbai') {
      productBaseUrl = 'https://mbesindia.s3.ap-south-1.amazonaws.com/'; 
    } else if (serverLocation === 'Tokyo') {
      productBaseUrl = 'https://metabeesindata.s3.ap-northeast-1.amazonaws.com/'; 
    } else if (serverLocation === 'California') {
      productBaseUrl = 'https://mbeesusa.s3.us-west-1.amazonaws.com/'; 
    } else{
      productBaseUrl = 'https://mbeeseurope.s3.eu-west-3.amazonaws.com/';
    }

    const productUrl = `${productBaseUrl}${fileNameWithoutExtension}`;
    const productLoader = new GLTFLoader();
    productLoader.load(productUrl, (gltf) => {
      const productModel = gltf.scene;
      setProductModel(productModel);
      productModel.position.copy(newPosition);
      scene.add(productModel);
    });



  
      window.addEventListener('resize', handleResize);

      const animate = () => {
        renderer.render(scene, camera);
        requestAnimationFrame(animate);
      };
      animate();
    };

    setupScene();
    

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [userInfo, navigate]);
  

  const handlePositionInputChange = (axis, value) => {
    const numericValue = !isNaN(parseFloat(value)) ? parseFloat(value) : 0;
    setPosition(prev => ({ ...prev, [axis]: numericValue }));
    if (productModel) {
      productModel.position[axis] = numericValue;
    }
  };
  
  const handleXRotationChange = (value) => {
    const numericValue = !isNaN(parseFloat(value)) ? parseFloat(value) : 0;
    setxRotation(numericValue);
    if (productModel) {
      productModel.rotation.x = THREE.MathUtils.degToRad(numericValue);
    }
  };
  
  const handleYRotationChange = (value) => {
    const numericValue = !isNaN(parseFloat(value)) ? parseFloat(value) : 0;
    setyRotation(numericValue);
    if (productModel) {
      productModel.rotation.y = THREE.MathUtils.degToRad(numericValue);
    }
  };
  
  const handleZRotationChange = (value) => {
    const numericValue = !isNaN(parseFloat(value)) ? parseFloat(value) : 0;
    setzRotation(numericValue);
    if (productModel) {
      productModel.rotation.z = THREE.MathUtils.degToRad(numericValue);
    }
  };
  
  


  const handleScaleInputChange = (axis, value) => {
    const numericValue = parseFloat(value);
    setScaleFactors((prevScaleFactors) => ({
      ...prevScaleFactors,
      [axis]: numericValue,
    }));
    if (productModel) {
      productModel.scale[axis] = numericValue;
    }
  };

  // Add a useEffect to update the scaleFactors when productModel changes
  useEffect(() => {
    if (productModel) {
      setScaleFactors({
        x: productModel.scale.x,
        y: productModel.scale.y,
        z: productModel.scale.z,
      });
    }
  }, [productModel]);

  useEffect(() => {
    if (productModel) {
      productModel.position.set(position.x, position.y, position.z);
      productModel.rotation.x = THREE.MathUtils.degToRad(xrotation);
      productModel.rotation.y = THREE.MathUtils.degToRad(yrotation);
      productModel.rotation.z = THREE.MathUtils.degToRad(zrotation);
    }
  }, [productModel, position, xrotation, yrotation, zrotation]);
  
  const handlePublishClick = async () => {
  const url = `http://localhost:5000/products/update/${fileNameWithoutExtension}`;
    
    try {
      const payload = {
        scaleFactors,
        position,
        xrotation,
        yrotation,
        zrotation,
        ARscale
      };
    
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      
      if (response.ok) {
        navigate('/published');
      } else {
        // Handle any errors in the response, e.g., validation errors
        const data = await response.json();
        // Handle the error data here
      }
    } catch (error) {
      // Handle network errors, e.g., the backend is not reachable
      console.error('Error publishing:', error);
    }
  };
  const validateAndSetScale = (axis, value) => {
    // Use a regular expression to check for a valid number format
    const isValidNumber = /^[-+]?\d*\.?\d*$/.test(value);
  
    if (isValidNumber) {
      handleScaleInputChange(axis, value);
    } else {
      // If the input is not a valid number, reset the input value to the previous scale factor
      setScaleFactors((prevScaleFactors) => ({
        ...prevScaleFactors,
        [axis]: prevScaleFactors[axis],
      }));
    }
  };
 
  
  return (
    <div style={{ position: 'relative' }}>
    <canvas ref={canvasRef} style={{ width: '100%', height: '100%', display: 'block' }} />
    <div className="input-container">
      {/* Scale Sliders */}
      {['x', 'y', 'z'].map(axis => (
        <input
          key={axis}
          type="range"
          className="input"
          min={scaleMin}
          max={scaleMax}
          step={scaleStep}
          value={scaleFactors[axis]}
          onChange={(e) => handleScaleInputChange(axis, e.target.value)}
        />
      ))}

{['x', 'y', 'z'].map(axis => (
          <input
            key={axis}
            type="number"
            className="input"
            placeholder={`${axis.toUpperCase()} Position`}
            value={position[axis]}
            onChange={(e) => handlePositionInputChange(axis, e.target.value)}
          />
        ))}
        {/* Rotation Slider */}
        <input
  type="range"
  className="input"
  min="0"
  max="360"
  value={xrotation}
  onChange={(e) => handleXRotationChange(e.target.value)}
/>
<input
  type="range"
  className="input"
  min="0"
  max="360"
  value={yrotation}
  onChange={(e) => handleYRotationChange(e.target.value)}
/>
<input
  type="range"
  className="input"
  min="0"
  max="360"
  value={zrotation}
  onChange={(e) => handleZRotationChange(e.target.value)}
/>


      </div>
      <button
        className="cssbuttons-io-button"
        style={{ position: 'fixed', bottom: '20px', right: '20px' }}
        onClick={() => handlePublishClick(fileNameWithoutExtension)}
      >
        Publish
        <div className="icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              fill="currentColor"
              d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
            />
          </svg>
        </div>
      </button>
    </div>
  );
};

export default Preview;