import React, { useState, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';

const DataPipelineSetupPage = () => {
  const { user } = useUser();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [existingPipelineData, setExistingPipelineData] = useState(null); // State to store existing pipeline data

  useEffect(() => {
    // Function to check for existing pipeline on component mount
    const checkExistingPipeline = async () => {
      if (!user) return; // Don't check if no user

      try {
        const response = await fetch(`https://web-production-5ee8.up.railway.app/echidna/checkpipeline`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId: user.id }), // Send userId in body
        });
        const data = await response.json();

        if (response.ok && data.hasPipeline) {
          // If pipeline exists, fetch the data
          const pipelineDataResponse = await fetch(`https://web-production-5ee8.up.railway.app/echidna/pipeline/${user.id}`);
          const pipelineData = await pipelineDataResponse.json();
          if (pipelineDataResponse.ok) {
            setExistingPipelineData(pipelineData.pipeline); // Store existing pipeline data
          } else {
            console.error("Error fetching existing pipeline data:", pipelineData.message);
            setError("Error fetching existing pipeline data."); // Optionally show error to user
          }
        } else if (!response.ok) {
          console.error("Error checking pipeline existence:", data.message);
          setError("Error checking pipeline existence."); // Optionally show error to user
        }

      } catch (err) {
        console.error("Error checking for existing pipeline:", err);
        setError("Error checking for existing pipeline."); // Optionally show error to user
      }
    };

    checkExistingPipeline();
  }, [user]); // Re-run effect when user object changes


  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    setSuccessMessage(null);
    setLoading(true);

    if (!user) {
      setError("User not logged in.");
      setLoading(false);
      return;
    }

    const formData = {
      userId: user.id,
      website: event.target.website.value,
      whatYouBuild: event.target.whatYouBuild.value,
      problemsFixed: event.target.problemsFixed.value,
      competitorWebsites: [
        event.target.competitorWebsite1.value,
        event.target.competitorWebsite2.value,
        event.target.competitorWebsite3.value,
      ].filter(Boolean),
      industry: event.target.industry.value,
    };

    try {
      let response;
      
      if (existingPipelineData) {
        // Update existing pipeline - send userId in both URL and body to match backend expectation
        response = await fetch(`https://web-production-5ee8.up.railway.app/echidna/editpipeline/${user.id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
      } else {
        // Create new pipeline
        response = await fetch('https://web-production-5ee8.up.railway.app/echidna/pipelinesetup', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
      }

      const data = await response.json();

      if (response.ok) {
        setSuccessMessage(data.message);
        console.log('Pipeline setup successful:', data.message);
        // Optionally, reset form fields on success if creating new pipeline
        if (!existingPipelineData) {
          event.target.reset();
        }
        // After successful update or creation, refetch pipeline data to update UI
        const pipelineDataResponse = await fetch(`https://web-production-5ee8.up.railway.app/echidna/pipeline/${user.id}`);
        const pipelineData = await pipelineDataResponse.json();
        if (pipelineDataResponse.ok) {
          setExistingPipelineData(pipelineData.pipeline);
        }

      } else {
        setError(data.message || 'Pipeline setup failed');
        console.error('Pipeline setup failed:', data.message);
      }

    } catch (err) {
      setError('Error submitting form. Please check console.');
      console.error('Error submitting form:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex-1 p-6">
      <div className="max-w-3xl mx-auto">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">Data Pipeline Setup</h2>
        <p className="text-gray-700 mb-8">
          Provide information to setup your data pipeline for comprehensive business analysis.
        </p>

        {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
          <strong className="font-bold">Error!</strong>
          <span className="block sm:inline"> {error}</span>
        </div>}

        {successMessage && <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4" role="alert">
          <strong className="font-bold">Success!</strong>
          <span className="block sm:inline"> {successMessage}</span>
        </div>}


        <div className="bg-white rounded-xl shadow-md p-8">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="website" className="block text-sm font-semibold text-gray-700 mb-2">
                Your Website
              </label>
              <div className="mt-1">
                <input
                  type="url"
                  id="website"
                  name="website"
                  defaultValue={existingPipelineData?.website || ""} // Pre-fill if data exists
                  className="shadow-sm focus:ring-orange-400 focus:border-orange-400 block w-full border border-gray-300 rounded-md py-2 px-3 bg-white text-gray-900 text-sm"
                  placeholder="https://yourwebsite.com"
                  required
                />
              </div>
            </div>

            <div>
              <label htmlFor="whatYouBuild" className="block text-sm font-semibold text-gray-700 mb-2">
                What do you build/offer?
              </label>
              <div className="mt-1">
                <textarea
                  id="whatYouBuild"
                  name="whatYouBuild"
                  rows={4}
                  defaultValue={existingPipelineData?.whatYouBuild || ""} // Pre-fill if data exists
                  className="shadow-sm focus:ring-orange-400 focus:border-orange-400 block w-full border border-gray-300 rounded-md py-2 px-3 bg-white text-gray-900 text-sm"
                  placeholder="Describe your product, service, or offering"
                  required
                />
              </div>
            </div>

            <div>
              <label htmlFor="problemsFixed" className="block text-sm font-semibold text-gray-700 mb-2">
                Problems you solve for your customers
              </label>
              <div className="mt-1">
                <textarea
                  id="problemsFixed"
                  name="problemsFixed"
                  rows={4}
                  defaultValue={existingPipelineData?.problemsFixed || ""} // Pre-fill if data exists
                  className="shadow-sm focus:ring-orange-400 focus:border-orange-400 block w-full border border-gray-300 rounded-md py-2 px-3 bg-white text-gray-900 text-sm"
                  placeholder="List the key problems your product/service addresses"
                  required
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-semibold text-gray-700 mb-2">
                Competitor Website Links (up to 3)
              </label>
              <div className="mt-2 space-y-3">
                <div>
                  <input
                    type="url"
                    name="competitorWebsite1"
                    defaultValue={existingPipelineData?.competitorWebsites?.[0] || ""} // Pre-fill if data exists
                    className="shadow-sm focus:ring-orange-400 focus:border-orange-400 block w-full border border-gray-300 rounded-md py-2 px-3 bg-white text-gray-900 text-sm"
                    placeholder="Competitor Website 1 (optional)"
                  />
                </div>
                <div>
                  <input
                    type="url"
                    name="competitorWebsite2"
                    defaultValue={existingPipelineData?.competitorWebsites?.[1] || ""} // Pre-fill if data exists
                    className="shadow-sm focus:ring-orange-400 focus:border-orange-400 block w-full border border-gray-300 rounded-md py-2 px-3 bg-white text-gray-900 text-sm"
                    placeholder="Competitor Website 2 (optional)"
                  />
                </div>
                <div>
                  <input
                    type="url"
                    name="competitorWebsite3"
                    defaultValue={existingPipelineData?.competitorWebsites?.[2] || ""} // Pre-fill if data exists
                    className="shadow-sm focus:ring-orange-400 focus:border-orange-400 block w-full border border-gray-300 rounded-md py-2 px-3 bg-white text-gray-900 text-sm"
                    placeholder="Competitor Website 3 (optional)"
                  />
                </div>
              </div>
            </div>

            <div>
              <label htmlFor="industry" className="block text-sm font-semibold text-gray-700 mb-2">
                Industry
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="industry"
                  name="industry"
                  defaultValue={existingPipelineData?.industry || ""} // Pre-fill if data exists
                  className="shadow-sm focus:ring-orange-400 focus:border-orange-400 block w-full border border-gray-300 rounded-md py-2 px-3 bg-white text-gray-900 text-sm"
                  placeholder="e.g., SaaS, E-commerce, Healthcare"
                  required
                />
              </div>
            </div>

            <div className="pt-6">
              <button
                type="submit"
                className="inline-flex justify-center py-2 px-5 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                disabled={loading}
              >
                {loading ? <><svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg> {existingPipelineData ? "Updating Pipeline..." : "Setting Up Pipeline..."}</> : (existingPipelineData ? "Update Data Pipeline" : "Setup Data Pipeline")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DataPipelineSetupPage;