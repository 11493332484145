import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Users, Mail, CheckCircle, Settings, Database, BarChart2, Layout, Search, Bell, ChevronDown, HelpCircle, Globe, Shield, Clock, Activity, AlertTriangle, Send, Edit, Trash2, Plus, Upload, X, Calendar, MessageSquare, Zap, Cloud } from 'lucide-react';
import logo from './logo.png';
import { useUser } from '@clerk/clerk-react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Papa from 'papaparse';
import { useSelector } from 'react-redux';
import { useEchidnaAccess, LoadingScreen, NoAccessScreen } from '../../assets/useraccess';
import GamefiedAIAssistant from '../redstone/intercom';


const NavButton = ({ children }) => (
    <button className="px-3 py-2 text-gray-300 hover:text-white hover:bg-gray-800 rounded text-sm font-medium transition-colors">
        {children}
    </button>
);

const EnterpriseHeader = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <div className="bg-gray-900 sticky top-0 z-10">
            <div className="max-w-7xl mx-auto">
                <div className="flex h-16 items-center justify-between px-4">
                    <div className="flex items-center space-x-8">
                        <div className="flex items-center space-x-2">
                            <img src={logo} alt="EchidnaMail" className="h-8" />
                            <span className="text-white font-bold">EchidnaMail</span>
                            <span className="text-red-500 font-bold">v2</span>
                        </div>
                        <div className="relative">
                            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                            <input
                                type="text"
                                placeholder="Search services, documentation, or resources"
                                className="pl-10 pr-4 py-2 w-96 bg-gray-800 border border-gray-700 rounded text-gray-200 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                            />
                        </div>
                    </div>
                    <div className="flex items-center space-x-4">
                        <NavButton>API Reference</NavButton>
                        <NavButton>Enterprise Support</NavButton>
                        <button className="p-2 text-gray-300 hover:text-white hover:bg-gray-800 rounded-full relative">
                            <Bell size={20} />
                            <span className="absolute top-1 right-1 h-2 w-2 bg-blue-500 rounded-full"></span>
                        </button>
                        <div className="relative" ref={dropdownRef}>
                            <button
                                onClick={toggleDropdown}
                                className="flex items-center space-x-3 ml-4 px-3 py-2 bg-gray-800 rounded cursor-pointer"
                            >
                                <div className="h-8 w-8 bg-blue-600 rounded-full flex items-center justify-center text-white text-sm font-medium">
                                    EC
                                </div>
                                <div>
                                    <div className="text-gray-200 text-sm">Enterprise</div>
                                    <div className="text-gray-400 text-xs">Southeast-mb-2</div>
                                    <div className="text-gray-400 text-xs"></div>
                                </div>
                                <ChevronDown size={16} className="text-gray-400" />
                            </button>
                            {isDropdownOpen && (
                                <div className="absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none origin-top-right">
                                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="dropdown">
                                        <button
                                            className="text-gray-700 flex w-full px-4 py-2 text-left text-sm hover:bg-gray-100" role="menuitem" 	onClick={toggleDropdown}
                                        >
                                            Southeast-mb-2 <br/> <span className="text-xs text-gray-500">Bengaluru</span>
                                        </button>
                                        <button
                                            className="text-gray-400 flex w-full px-4 py-2 text-left text-sm cursor-not-allowed hover:bg-gray-100" role="menuitem" disabled onClick={toggleDropdown}
                                        >
                                            us-west-san Fransisco <br/> <span className="text-xs text-gray-500">San Fransisco (Unavailable)</span>
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const SidebarButton = ({ icon: Icon, text, to, isActive }) => {
    const navigate = useNavigate();
    return (
        <div
            className={`flex items-center space-x-3 px-4 py-3 rounded-lg cursor-pointer transition-colors duration-200 ${isActive ? 'bg-blue-100 text-blue-600' : 'text-gray-600 hover:bg-gray-100'
                }`}
            onClick={() => navigate(to)}
        >
            <Icon className="w-5 h-5" />
            <span className="text-sm font-medium">{text}</span>
        </div>
    );
};

const ProspectTable = ({ prospects, selectedProspects, setSelectedProspects, onEdit, onDelete , tableTitle}) => {
    const handleSelectAll = (e) => {
        if (e.target.checked) {
            setSelectedProspects(prospects.map(p => p.id));
        } else {
            setSelectedProspects([]);
        }
    };

    const handleSelectProspect = (id) => {
        setSelectedProspects(prev =>
            prev.includes(id) ? prev.filter(p => p !== id) : [...prev, id]
        );
    };

    return (
        <div className="overflow-x-auto mb-8"> {/* Added mb-8 for spacing between tables if both are visible */}
            <h3 className="text-lg font-semibold text-gray-900 mb-2">{tableTitle}</h3>
            <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                    <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            <input
                                type="checkbox"
                                checked={selectedProspects.length === prospects.length}
                                onChange={handleSelectAll}
                                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                            />
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Company</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email Validity</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Lead Set</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Synced</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {prospects.map((prospect) => (
                        <tr key={prospect.id}>
                            <td className="px-6 py-4 whitespace-nowrap">
                                <input
                                    type="checkbox"
                                    checked={selectedProspects.includes(prospect.id)}
                                    onChange={() => handleSelectProspect(prospect.id)}
                                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                />
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">{prospect.name}</td>
                            <td className="px-6 py-4 whitespace-nowrap">{prospect.email}</td>
                            <td className="px-6 py-4 whitespace-nowrap">{prospect.company}</td>
                            <td className="px-6 py-4 whitespace-nowrap">
                                <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${prospect.emailValidity === 'valid' ? 'bg-green-100 text-green-800' :
                                    prospect.emailValidity === 'invalid' ? 'bg-red-100 text-red-800' :
                                        'bg-yellow-100 text-yellow-800'
                                    }`}>
                                    {prospect.emailValidity}
                                </span>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">{prospect.leadSet || 'Not Assigned'}</td>
                            <td className="px-6 py-4 whitespace-nowrap">
                                {prospect.source === 'backend' ? (
                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                        Synced
                                    </span>
                                ) : (
                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                                        Not Synced
                                    </span>
                                )}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                                <button onClick={() => onEdit(prospect)} className="text-indigo-600 hover:text-indigo-900 mr-2"><Edit size={16} /></button>
                                <button onClick={() => onDelete(prospect.id)} className="text-red-600 hover:text-red-900"><Trash2 size={16} /></button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};
const NewLeadSetPopup = ({ onClose, selectedProspects, onCreateLeadSet }) => {
    const [leadSetName, setLeadSetName] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        onCreateLeadSet(leadSetName, selectedProspects);
        onClose();
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-8 rounded-lg max-w-md w-full shadow-xl">
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-2xl font-bold text-gray-900">Create New Lead Set</h2>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out">
                        <X className="w-6 h-6" />
                    </button>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-2">Lead Set Name</label>
                        <input
                            type="text"
                            value={leadSetName}
                            onChange={(e) => setLeadSetName(e.target.value)}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 bg-white"
                        />
                    </div>
                    <div className="mb-6">
                        <p className="text-sm text-gray-600">Selected Prospects: <span className="font-semibold">{selectedProspects.length}</span></p>
                    </div>
                    <button type="submit" className="w-full px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-300 ease-in-out">
                        Create Lead Set
                    </button>
                </form>
            </div>
        </div>
    );
};

const AddProspectsPopup = ({ onClose, onProspectsAdd }) => {
    const [addMethod, setAddMethod] = useState('csv');

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            Papa.parse(file, {
                complete: (results) => {
                    const prospects = results.data.map((row, index) => ({
                        id: Date.now() + index,
                        name: row[0],
                        website: row[1],
                        email: row[2],
                        emailValidity: 'unknown',
                        company: row[1] || 'N/A',
                        leadSet: 'Not Assigned', // default leadSet when adding
                        source: 'local' // Mark as local prospect
                    }));
                    onProspectsAdd(prospects);
                    onClose();
                },
                header: false,
            });
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-8 rounded-lg max-w-md w-full shadow-xl">
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-2xl font-bold text-gray-900">Add New Prospects</h2>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out">
                        <X className="w-6 h-6" />
                    </button>
                </div>
                <div className="mb-6">
                    <label className="block text-sm font-medium text-gray-700 mb-2">Add Method</label>
                    <select
                        value={addMethod}
                        onChange={(e) => setAddMethod(e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 bg-white"
                    >
                        <option value="csv">Upload CSV</option>
                        <option value="apollo" disabled>Connect to Apollo.io</option>
                    </select>
                </div>
                {addMethod === 'csv' ? (
                    <div className="mb-6">
                        <label className="block text-sm font-medium text-gray-700 mb-2">Upload CSV File</label>
                        <div className="bg-gray-50 p-4 rounded-md mb-4">
                            <h3 className="text-sm font-medium text-gray-900 mb-2">Required CSV Format:</h3>
                            <ul className="text-sm text-gray-600 space-y-1">
                                <li>• Column A: Prospect Name</li>
                                <li>• Column B: Prospect Website</li>
                                <li>• Column C: Prospect Email</li>
                            </ul>
                            <p className="text-xs text-gray-500 mt-2">Please ensure your CSV follows this exact column order</p>
                        </div>
                        <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                            <div className="space-y-1 text-center">
                                <Upload className="mx-auto h-12 w-12 text-gray-400" />
                                <div className="flex text-sm text-gray-600">
                                    <label htmlFor="file-upload" className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                        <span>Upload a file</span>
                                        <input id="file-upload" name="file-upload" type="file" className="sr-only" onChange={handleFileUpload} accept=".csv" />
                                    </label>
                                    <p className="pl-1">or drag and drop</p>
                                </div>
                                <p className="text-xs text-gray-500">CSV up to 10MB</p>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};



const EditProspectPopup = ({ prospect, onClose, onSave }) => {
    const [editedProspect, setEditedProspect] = useState(prospect);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditedProspect(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave(editedProspect);
        onClose();
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-8 rounded-lg max-w-md w-full shadow-xl">
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-2xl font-bold text-gray-900">Edit Prospect</h2>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out">
                        <X className="w-6 h-6" />
                    </button>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-2">Name</label>
                        <input
                            type="text"
                            name="name"
                            value={editedProspect.name}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 bg-white"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-2">Email</label>
                        <input
                            type="email"
                            name="email"
                            value={editedProspect.email}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 bg-white"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-2">Company</label>
                        <input
                            type="text"
                            name="company"
                            value={editedProspect.company}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 bg-white"
                        />
                    </div>
                    <button type="submit" className="w-full px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-300 ease-in-out">
                        Save Changes
                    </button>
                </form>
            </div>
        </div>
    );
};

const ProspectsPage2 = () => {
        const { user, isLoaded, isSignedIn } = useUser();
        const userInfo = useSelector((state) => state.auth.userInfo);
        // Separate lists for synced and unsynced prospects
        const [unsyncedProspects, setUnsyncedProspects] = useState([]);
        const [syncedProspects, setSyncedProspects] = useState([]);
        const [selectedProspects, setSelectedProspects] = useState([]);
        const [showNewLeadSetPopup, setShowNewLeadSetPopup] = useState(false);
        const [showAddProspectsPopup, setShowAddProspectsPopup] = useState(false);
        const [showEditProspectPopup, setShowEditProspectPopup] = useState(false);
        const [editingProspect, setEditingProspect] = useState(null);
        const [isSyncing, setIsSyncing] = useState(false);
        const [isLoading, setIsLoading] = useState(true);
        const [searchTerm, setSearchTerm] = useState('');
        const [leadSets, setLeadSets] = useState([]);
        const { eloading, hasAccess } = useEchidnaAccess();
        const [sortBy, setSortBy] = useState('name');
        const [sortOrder, setSortOrder] = useState('asc');
        const [isBackendLoading, setIsBackendLoading] = useState(false);
        const [syncError, setSyncError] = useState(null);
        const [showSyncWarning, setShowSyncWarning] = useState(false);
        const navigate = useNavigate();
    
    
        const fetchProspects = useCallback(async () => {
            if (!user?.id) {
                console.log("fetchProspects: userInfo._id is missing, returning early."); // Debugging log
                return;
            }
        
            setIsLoading(true);
            setIsBackendLoading(true);
            console.log("fetchProspects: Starting to fetch prospects for cid:", user?.id); // Debugging log
            try {
                const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/alleads', {
                    cid: user?.id
                });
                console.log("fetchProspects: Axios request successful, response:", response); // Debugging log
        
                if (response.data?.leads && Array.isArray(response.data.leads)) {
                    const backendProspects = response.data.leads.map(lead => ({
                        id: lead.did,
                        name: lead.name,
                        email: lead.email || lead.website,
                        company: lead.company || 'N/A',
                        emailValidity: lead.emailValidity || 'unknown',
                        source: 'backend',
                        leadSet: lead.leadSet || 'Not Assigned'
                    }));
        
                    setSyncedProspects(backendProspects);
                    console.log("fetchProspects: Synced prospects state updated:", backendProspects); // Debugging log
                    setSyncError(null);
                } else {
                    const errorMessage = 'Invalid data format received from server';
                    console.error('fetchProspects: Failed to fetch prospects - Invalid data format:', errorMessage, response.data);
                    setSyncedProspects([]);
                    setSyncError(`Failed to fetch synced prospects: ${errorMessage}`);
                }
            } catch (error) {
                console.error('fetchProspects: Error during fetch prospects:', error);
                setSyncedProspects([]);
                setSyncError(`Failed to fetch synced prospects: ${error.message}`);
            } finally {
                setIsLoading(false);
                setIsBackendLoading(false);
                console.log("fetchProspects: Fetch operation completed, isLoading and isBackendLoading set to false."); // Debugging log
            }
        }, [userInfo]);
        useEffect(() => {
            if (user?.id) {
                fetchProspects();
            }
        }, [userInfo, fetchProspects]);
    
    
        // Show warning dialog first
        const handleSyncToServer = useCallback(() => {
            setShowSyncWarning(true);
        }, []);
    
        // Handle actual sync after confirmation
        const handleConfirmedSync = useCallback(async () => {
            if (!user?.id) {
                setSyncError('User information is not available. Please log in and try again.');
                return;
            }
    
            setIsSyncing(true);
            setIsBackendLoading(true);
            setSyncError(null);
    
            try {
                const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/addp', {
                    prospects: unsyncedProspects, // Only sync unsynced prospects
                    leadSets, // Assuming lead sets are also managed locally and need to be synced - adjust if needed
                    cid: user?.id
                });
    
                if (response.data.success) {
                    // Handle response and update state accordingly
                    const syncedProspectIds = unsyncedProspects.map(p => p.id);
                    const newlySyncedBackendProspects = response.data.updatedProspects.filter(p => syncedProspectIds.includes(p.id));
    
    
                    setSyncedProspects(prevSynced => [...prevSynced, ...newlySyncedBackendProspects]); // Add synced to synced list
                    setUnsyncedProspects(prevUnsynced => prevUnsynced.filter(p => !syncedProspectIds.includes(p.id))); // Remove synced from unsynced
    
                    setLeadSets(response.data.updatedLeadSets);
                    setSyncError(null);
    
    
                } else if (response.data.message === 'Prospects processed successfully') {
                    await fetchProspects();
                    setSyncError(null);
    
                } else {
                    throw new Error(response.data.message || 'Unknown error');
                }
            } catch (error) {
                console.error('Sync failed:', error);
                setSyncError(`Sync failed: ${error.message}`);
            } finally {
                setIsSyncing(false);
                setIsBackendLoading(false);
                setShowSyncWarning(false);
            }
        }, [userInfo, unsyncedProspects, leadSets, fetchProspects]);
    
    
        const handleProspectsAdd = useCallback((newProspects) => {
            setUnsyncedProspects(prevProspects => [...prevProspects, ...newProspects]); // Add to unsynced list
        }, []);
    
        const handleEdit = (prospect) => {
            setEditingProspect(prospect);
            setShowEditProspectPopup(true);
        };
    
        // Revised handleDelete with synced/unsynced handling
        const handleDelete = useCallback(async (id, source) => { // Add source to handleDelete
            if (source === 'backend') {
                setIsBackendLoading(true);
                try {
                    // Find the prospect data from syncedProspects using the id
                    const prospectToDelete = syncedProspects.find(p => p.id === id);
                    if (!prospectToDelete) {
                        console.error('Prospect not found in synced prospects list.');
                        setSyncError('Prospect not found locally, refresh data.');
                        setIsBackendLoading(false);
                        return;
                    }
    
                    const prospectData = {
                        name: prospectToDelete.name,
                        email: prospectToDelete.email,
                        company: prospectToDelete.company
                    };
    
    
                    const response = await axios.post('https://web-production-5ee8.up.railway.app/echidna/deteteppros', {
                        cid: user?.id,
                        prospectId: id,
                        prospectData: prospectData // Send prospectData in the request
                    });
    
                    if (response.data.success) {
                        // Update synced prospects list
                        setSyncedProspects(prev => prev.filter(p => p.id !== id));
                        setSelectedProspects(prev => prev.filter(selectedId => selectedId !== id));
                        setLeadSets(prev => prev.map(set => ({
                            ...set,
                            prospectIds: set.prospectIds.filter(prospectId => prospectId !== id)
                        })));
                        setSyncError(null);
                    } else {
                        throw new Error(response.data.message || 'Failed to delete synced prospect');
                    }
                } catch (error) {
                    console.error('Error deleting synced prospect:', error);
                    setSyncError(`Error deleting synced prospect: ${error.message}`);
                } finally {
                    await fetchProspects(); // Refresh synced prospects
                    setIsBackendLoading(false);
                }
            } else {
                // Handle local prospect deletion - remove from unsyncedProspects
                setUnsyncedProspects(prev => prev.filter(p => p.id !== id));
                setSelectedProspects(prev => prev.filter(selectedId => selectedId !== id));
                setLeadSets(prev => prev.map(set => ({
                    ...set,
                    prospectIds: set.prospectIds.filter(prospectId => prospectId !== id)
                })));
            }
        }, [userInfo, fetchProspects, syncedProspects]);
    
    
        const handleCreateLeadSet = useCallback(async (name, selectedIds) => {
            const newLeadSet = {
                id: Date.now(),
                name,
                prospectIds: selectedIds
            };
    
            try {
                //Update lead set for both synced and unsynced prospects as needed - logic might need refinement based on your exact requirements
                setUnsyncedProspects(prev =>
                    prev.map(prospect => ({
                        ...prospect,
                        leadSet: selectedIds.includes(prospect.id) && prospect.source !== 'backend' ? name : prospect.leadSet // Apply lead set only to unsynced prospects in selection
                    }))
                );
                 setSyncedProspects(prev =>
                    prev.map(prospect => ({
                        ...prospect,
                        leadSet: selectedIds.includes(prospect.id) && prospect.source === 'backend' ? name : prospect.leadSet // Apply lead set only to synced prospects in selection
                    }))
                );
    
    
                setLeadSets(prev => [...prev, newLeadSet]);
                setSelectedProspects([]);
    
                // Sync to server - consider if lead sets need separate syncing or if prospect sync handles it
                setIsBackendLoading(true);
                await handleSyncToServer();
                setIsBackendLoading(false);
    
    
            } catch (error) {
                console.error('Error creating lead set:', error);
                setSyncError(`Error creating lead set: ${error.message}`);
            }
        }, [handleSyncToServer]);
    
    
        const getLeadSetName = (prospectId, source) => {
            const leadSet = leadSets.find(set => set.prospectIds.includes(prospectId));
            return leadSet ? leadSet.name : 'Not Assigned';
        };
    
        // --- Sorting and Filtering for Unsynced Prospects ---
        const sortedUnsyncedProspects = [...unsyncedProspects].sort((a, b) => {
            let aValue, bValue;
    
            if (sortBy === 'leadSet') {
                aValue = getLeadSetName(a.id, 'local'); // Differentiate source if needed
                bValue = getLeadSetName(b.id, 'local');
            } else {
                aValue = a[sortBy];
                bValue = b[sortBy];
            }
    
            if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
            if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
            return 0;
        });
    
        const filteredUnsyncedProspects = sortedUnsyncedProspects.filter(prospect =>
            prospect.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            prospect.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            prospect.company.toLowerCase().includes(searchTerm.toLowerCase()) ||
            getLeadSetName(prospect.id, 'local').toLowerCase().includes(searchTerm.toLowerCase())
        );
    
        // --- Sorting and Filtering for Synced Prospects ---
          const sortedSyncedProspects = [...syncedProspects].sort((a, b) => {
            let aValue, bValue;
    
            if (sortBy === 'leadSet') {
                aValue = getLeadSetName(a.id, 'backend'); // Differentiate source
                bValue = getLeadSetName(b.id, 'backend');
            } else {
                aValue = a[sortBy];
                bValue = b[sortBy];
            }
    
            if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
            if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
            return 0;
        });
    
        const filteredSyncedProspects = sortedSyncedProspects.filter(prospect =>
            prospect.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            prospect.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            prospect.company.toLowerCase().includes(searchTerm.toLowerCase()) ||
            getLeadSetName(prospect.id, 'backend').toLowerCase().includes(searchTerm.toLowerCase())
        );
    
    
        const handleSort = (column) => {
            if (sortBy === column) {
                setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
            } else {
                setSortBy(column);
                setSortOrder('asc');
            }
        };
    
        if (eloading) {
            return <LoadingScreen />;
        }
    
        if (!hasAccess) {
            return <NoAccessScreen />;
        }
    


    return (
        <div className="min-h-screen bg-gray-50">
            <EnterpriseHeader />

            <div className="max-w-7xl mx-auto px-4 py-8">
                <div className="flex justify-between items-center mb-4">
                    <div className="flex-1">
                        <h1 className="text-2xl font-bold text-gray-900">Prospects</h1>
                    </div>

                    <div className="flex space-x-4">
                        <button
                            onClick={() => setShowAddProspectsPopup(true)}
                            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center"
                        >
                            <Plus className="h-5 w-5 mr-2" /> Add Prospects
                        </button>


                        <button
                            onClick={() => setShowNewLeadSetPopup(true)}
                            className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 flex items-center"
                            disabled={selectedProspects.length === 0}
                        >
                            <Calendar className="h-5 w-5 mr-2" /> Create Lead Set
                        </button>

                        <button
                            onClick={handleSyncToServer}
                            disabled={isSyncing || unsyncedProspects.length === 0} // Disable if syncing or no unsynced prospects
                            className={`px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center ${isSyncing || unsyncedProspects.length === 0 ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-green-600 hover:bg-green-700 text-white'}`}
                        >
                            {isSyncing ? (
                                <>
                                    <svg className="animate-spin h-5 w-5 mr-2 text-white" viewBox="0 0 24 24">
                                        <path fill="currentColor" d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm0 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20zM2 12C2 6.477 6.477 2 12 2v2.001a7.98 7.98 0 0 0-5.98 5.999H2z"/>
                                    </svg>
                                    Syncing...
                                </>
                            ) : (
                                <>
                                    <Cloud className="h-5 w-5 mr-2" /> Sync to Server
                                </>
                            )}
                        </button>


                    </div>
                </div>
                <div className="mb-4">
                    <input
                        type="text"
                        placeholder="Search prospects..."
                        className="px-4 py-2 border rounded-md w-full bg-white"
                        onChange={e => setSearchTerm(e.target.value)}
                    />
                </div>


                {syncError && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
                        <strong className="font-bold">Sync Error!</strong>
                        <span className="block sm:inline"> {syncError}</span>
                        <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                            <svg onClick={() => setSyncError(null)} className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697L8.181 10l-3.029-2.651a1.2 1.2 0 0 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697L11.819 10l3.029 2.651a1.2 1.2 0 0 1 0 1.697z" /></svg>
                        </span>
                    </div>
                )}


                <ProspectTable
                    tableTitle="Synced Prospects"
                    prospects={filteredSyncedProspects}
                    selectedProspects={selectedProspects}
                    setSelectedProspects={setSelectedProspects}
                    onEdit={handleEdit}
                    onDelete={(id) => handleDelete(id, 'backend')}
                />

                {filteredUnsyncedProspects.length > 0 && ( // Conditionally render Unsynced Prospects table
                    <ProspectTable
                        tableTitle="Unsynced Prospects"
                        prospects={filteredUnsyncedProspects}
                        selectedProspects={selectedProspects}
                        setSelectedProspects={setSelectedProspects}
                        onEdit={handleEdit}
                        onDelete={(id) => handleDelete(id, 'local')}
                    />
                )}


                {showNewLeadSetPopup && (
                    <NewLeadSetPopup
                        onClose={() => setShowNewLeadSetPopup(false)}
                        selectedProspects={selectedProspects}
                        onCreateLeadSet={handleCreateLeadSet}
                    />
                )}

                {showAddProspectsPopup && (
                    <AddProspectsPopup
                        onClose={() => setShowAddProspectsPopup(false)}
                        onProspectsAdd={handleProspectsAdd}
                    />
                )}
                {}
                {showSyncWarning && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white p-8 rounded-lg max-w-md w-full shadow-xl">
                            <div className="flex justify-between items-center mb-6">
                                <h2 className="text-lg font-bold text-gray-900">Confirm Sync to Server</h2>
                                <button onClick={() => setShowSyncWarning(false)} className="text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out">
                                    <X className="w-5 h-5" />
                                </button>
                            </div>
                            <div className="mb-6">
                                <p className="text-sm text-gray-700">
                                    Are you sure you want to sync local prospects and lead set changes to the server?
                                    This action will attempt to sync all local changes with the backend. Please confirm to proceed.
                                </p>
                            </div>
                            <div className="flex justify-end space-x-2">
                                <button
                                    onClick={() => setShowSyncWarning(false)}
                                    className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 transition duration-300 ease-in-out"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleConfirmedSync}
                                    disabled={isBackendLoading}
                                    className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 transition duration-300 ease-in-out"
                                >
                                    Confirm Sync
                                </button>
                            </div>
                        </div>
                    </div>
                )}


            </div>
            <GamefiedAIAssistant />
        </div>
    );
};

export default ProspectsPage2;