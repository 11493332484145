import React, { useState, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import axios from 'axios';
import GamefiedAIAssistant from '../redstone/intercom';

const Error = () => {
  const { user } = useUser();
  const [failedEmails, setFailedEmails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedEmail, setExpandedEmail] = useState(null);

  useEffect(() => {
    const fetchFailedEmails = async () => {
      if (!user?.id) return;

      try {
        setLoading(true);
        
        const response = await axios.post(`https://web-production-5ee8.up.railway.app/echidna/checkmailpipeline/${user.id}`);
        
        if (response.data.success) {
          setFailedEmails(response.data.data);
        } else {
          setError('Failed to retrieve emails');
        }
      } catch (err) {
        setError(err.message || 'An error occurred while fetching failed emails');
        console.error('Error fetching failed emails:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchFailedEmails();
  }, [user?.id]);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString();
  };

  const toggleExpand = (emailId) => {
    if (expandedEmail === emailId) {
      setExpandedEmail(null);
    } else {
      setExpandedEmail(emailId);
    }
  };

  if (loading) {
    return <div className="p-4 text-center">Loading failed emails...</div>;
  }

  if (error) {
    return <div className="p-4 text-center text-red-500">Error: {error}</div>;
  }

  if (failedEmails.length === 0) {
    return <div className="p-4 text-center">No failed emails found.</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-6">Email Delivery Issues</h1>
      <p className="mb-6 text-gray-600">
        These emails failed to send from your account. Review the errors below.
      </p>
      
      <div className="space-y-4">
        {failedEmails.map((email) => (
          <div 
            key={email._id} 
            className="border rounded-lg overflow-hidden shadow-sm"
          >
            <div 
              className="bg-gray-100 p-4 cursor-pointer flex justify-between items-center"
              onClick={() => toggleExpand(email._id)}
            >
              <div>
                <p className="font-semibold">{email.mailSubject}</p>
                <p className="text-sm text-gray-600">To: {email.mailTo}</p>
                <p className="text-sm text-gray-600">Campaign: {email.campaign}</p>
              </div>
              <div className="text-right">
                <span className="text-sm text-gray-600">
                  {formatDate(email.updatedAt)}
                </span>
              </div>
            </div>

            {expandedEmail === email._id && (
              <div className="p-4 border-t">
                <div className="mb-4">
                  <h3 className="font-semibold mb-2">Email Content</h3>
                  <div className="bg-white p-3 rounded border whitespace-pre-wrap">
                    {email.mailBody}
                  </div>
                </div>
                
                <div className="mb-4">
                  <h3 className="font-semibold mb-2">Error Details</h3>
                  <div className="bg-red-50 p-3 rounded border text-red-800">
                    <p><strong>Error:</strong> {email.error}</p>
                    {email.errorDetails && (
                      <div className="mt-2">
                        <p><strong>Time:</strong> {formatDate(email.errorDetails.timestamp)}</p>
                        {email.errorDetails.stack && (
                          <details>
                            <summary className="cursor-pointer">Stack Trace</summary>
                            <pre className="text-xs mt-2 overflow-x-auto">
                              {email.errorDetails.stack}
                            </pre>
                          </details>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                
                <div className="text-sm text-gray-600">
                  <p>Retry Count: {email.retryCount || 0}</p>
                  <p>Scheduled Time: {formatDate(email.time)}</p>
                  <p>Created: {formatDate(email.createdAt)}</p>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <GamefiedAIAssistant />
    </div>
  );
};

export default Error;