import React, { useState } from 'react';
import DataPipelineSetupPages from './dpsetup';
import CompetitorAnalysisPage2 from './cana2';
import PMFAnalysisPage from './pmf';
import ProspectManagement from '../Echidnamail/Prospectvalidation';
import MarketAnalysisPage from './ma';
import ArcLandingPage from '../arc/arc';
import logo from './logo.png';
import PlatChat from './platchat';
import GamefiedAIAssistant from '../redstone/intercom';

const Header = () => (
  <div className="bg-gray-900 text-white px-4 py-2 flex justify-between items-center border-b border-gray-800">
    <div className="flex items-center">
      <span className="text-xl font-bold text-orange-400 mr-6">
      <div className="flex items-center">
        <img 
          src={logo} 
          alt="Metabees AI labs" 
          className="h-6 w-auto mr-4" 
        />
        <h1 className="text-xl font-bold text-gray-800">Metabees AI labs</h1>
      </div>
      </span>
      <div className="relative">
        <input
          type="text"
          placeholder="Search"
          className="bg-gray-800 border border-gray-700 rounded-md px-3 py-1.5 text-sm focus:outline-none focus:ring-1 focus:ring-orange-400 w-64 text-gray-200"
        />
        <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 text-xs">[Alt+S]</span>
      </div>
    </div>
    <div className="flex items-center space-x-4">
      <div className="text-sm text-gray-300 flex items-center">
        <span>Asia-South (Bengaluru)</span>
      </div>
      <div className="w-8 h-8 flex items-center justify-center text-gray-300 hover:text-white">
        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
        </svg>
      </div>
      <div className="w-8 h-8 flex items-center justify-center text-gray-300 hover:text-white">
        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
      </div>
    </div>
  </div>
);

const SidebarItem = ({ children, active, onClick, disabled }) => (
  <li
    onClick={disabled ? null : onClick}
    className={`p-2 text-sm cursor-pointer flex items-center rounded mb-1 ${
      disabled 
        ? 'text-gray-500 bg-gray-700 opacity-50 cursor-not-allowed' 
        : active 
          ? 'bg-blue-900 text-white' 
          : 'text-gray-300 hover:bg-gray-700'
    }`}
  >
    {children}
    {disabled && (
      <svg className="w-4 h-4 ml-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
      </svg>
    )}
  </li>
);

const Sidebar = ({ setActivePage }) => {
  const [activeItem, setActiveItem] = useState('Dashboard Home');

  const handleItemClick = (itemName, pageName) => {
    setActiveItem(itemName);
    setActivePage(pageName);
    // Basic URL update using history.pushState - for demonstration
    const path = `/${pageName.toLowerCase().replace(/\s+/g, '-')}`; // Create path from pageName
    window.history.pushState({page: pageName}, itemName, path); // Update URL
  };

  return (
    <div className="bg-gray-800 w-64 p-4 border-r border-gray-700 text-white">
      <div className="mb-6">
        <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wider mb-3">
          Analytics Dashboard
        </h3>
        <ul>
          <SidebarItem
            active={activeItem === 'Dashboard Home'}
            onClick={() => handleItemClick('Dashboard Home', 'DashboardHome')}
          >
            <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
              />
            </svg>
            Dashboard Home
          </SidebarItem>
          <SidebarItem
            active={activeItem === 'Data Pipeline Setup'}
            onClick={() => handleItemClick('Data Pipeline Setup', 'DataPipelineSetup')}
          >
            <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
              />
            </svg>
            Data Pipeline Setup
          </SidebarItem>
          <SidebarItem
            active={activeItem === 'Competitor Analysis'}
            onClick={() => handleItemClick('Competitor Analysis', 'CompetitorAnalysis')}
          >
            <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"
              />
            </svg>
            Competitor Analysis
          </SidebarItem>
          <SidebarItem
            active={activeItem === 'PMF Fit Analysis'}
            onClick={() => handleItemClick('PMF Fit Analysis', 'PMFFitAnalysis')}
          >
            <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
              />
            </svg>
            PMF Fit Analysis
          </SidebarItem>
          <SidebarItem
            active={activeItem === 'Prospect Analysis'}
            onClick={() => handleItemClick('Prospect Analysis', 'ProspectAnalysis')}
          >
            <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
              />
            </svg>
            Prospect Analysis
          </SidebarItem>
          <SidebarItem
            active={activeItem === 'Market State'}
            onClick={() => handleItemClick('Market State', 'MarketState')}
          >
            <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            Market State
          </SidebarItem>
        </ul>
      </div>
      <div className="mb-4">
        <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wider mb-3">
          AI Assistant
        </h3>
        <ul>
        <SidebarItem
    active={activeItem === 'Chat with Business AI'}
    onClick={() => handleItemClick('Chat with Business AI', 'businessaiassistant')} // Changed to lowercase 'businessaiassistant'
>
    <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
        />
    </svg>
    Chat with Business AI
</SidebarItem>
        </ul>
      </div>
      <div className="mb-4">
        <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wider mb-3">
          Website Analysis
        </h3>
        <ul>
          <SidebarItem
            active={activeItem === 'Cyberlake Arc'}
            onClick={() => handleItemClick('Cyberlake Arc', 'Cyberlake Arc')}
     
          >
           Cyberlake Arc
          </SidebarItem>
        
        </ul>
        <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wider mb-3">
          Rview analysis
        </h3>
        <ul>
          <SidebarItem
            active={activeItem === 'Cyberlake redstone'}
            onClick={() => handleItemClick('Cyberlake redstone', 'Cyberlake redstone')}
         
          >
            Cyberlake redstone
          </SidebarItem>
        
        </ul>
      </div>

      <div className="mt-auto pt-4 border-t border-gray-700">
        <div className="text-xs text-gray-400">
          <div className="flex items-center mb-2">
            <div className="w-2 h-2 rounded-full bg-green-400 mr-2"></div>
            <span>All services operational</span>
          </div>
          <div className="text-right text-gray-500 mt-2">
            © 2025,Metabees AI labs, Inc.
          </div>
        </div>
      </div>
    </div>
  );
};

const ConsoleHome = ({ activePage }) => {
  return (
    <div className="flex-1 p-4">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-xl font-bold text-gray-800">
          {activePage === 'DashboardHome' ? 'Dashboard Home' : activePage} <span className="text-sm text-gray-500">Info</span>
        </h1>
        <div className="space-x-2">
        </div>
      </div>

      {activePage === 'DashboardHome' && (
        <div className="bg-white rounded-lg shadow-md p-6 mb-6">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">Welcome to MetaBees Next-Gen Data Analytics Platform</h2>
          <p className="text-gray-600 mb-6">
            Our powerful analytics platform helps you transform raw data into actionable insights. Discover market trends, analyze competitors, validate product-market fit, and optimize your business strategy with ease.
          </p>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-4">
            <div className="bg-blue-50 p-4 rounded-lg border border-blue-100">
              <div className="flex items-center mb-3">
                <svg className="w-6 h-6 text-blue-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                </svg>
                <h3 className="font-semibold text-blue-700">Data Pipeline Setup</h3>
              </div>
              <p className="text-sm text-gray-600">Configure and manage your data sources, transformations, and analytics workflows</p>
            </div>
            
            <div className="bg-purple-50 p-4 rounded-lg border border-purple-100">
              <div className="flex items-center mb-3">
                <svg className="w-6 h-6 text-purple-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
                </svg>
                <h3 className="font-semibold text-purple-700">Competitor Analysis</h3>
              </div>
              <p className="text-sm text-gray-600">Track and benchmark against competitors to identify opportunities and threats</p>
            </div>
            
            <div className="bg-green-50 p-4 rounded-lg border border-green-100">
              <div className="flex items-center mb-3">
                <svg className="w-6 h-6 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                </svg>
                <h3 className="font-semibold text-green-700">PMF Fit Analysis</h3>
              </div>
              <p className="text-sm text-gray-600">Evaluate your product-market fit and identify opportunities for improvement</p>
            </div>
            
            <div className="bg-indigo-50 p-4 rounded-lg border border-indigo-100">
              <div className="flex items-center mb-3">
                <svg className="w-6 h-6 text-indigo-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                </svg>
                <h3 className="font-semibold text-indigo-700">Prospect Analysis</h3>
              </div>
              <p className="text-sm text-gray-600">Segment and analyze prospects to optimize your sales and marketing efforts</p>
            </div>
            
            <div className="bg-yellow-50 p-4 rounded-lg border border-yellow-100">
              <div className="flex items-center mb-3">
                <svg className="w-6 h-6 text-yellow-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <h3 className="font-semibold text-yellow-700">Market State</h3>
              </div>
              <p className="text-sm text-gray-600">Get real-time insights on market trends, opportunities, and potential threats</p>
            </div>
            
            <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
              <div className="flex items-center mb-3">
                <svg className="w-6 h-6 text-gray-400 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                </svg>
                <h3 className="font-semibold text-gray-500">Premium Features (Coming Soon)</h3>
              </div>
              <p className="text-sm text-gray-600">AI Assistant and Website Analysis tools are currently in development</p>
            </div>
          </div>
        </div>
      )}

      {activePage === 'DataPipelineSetup' && (
        <DataPipelineSetupPages />
      )}

      {activePage === 'CompetitorAnalysis' && (
        <CompetitorAnalysisPage2 />
      )}

      {activePage === 'PMFFitAnalysis' && (
        <PMFAnalysisPage />
      )}

      {activePage === 'ProspectAnalysis' && (
        <ProspectManagement />
      )}

      {activePage === 'MarketState' && (
        <MarketAnalysisPage />
      )}

      {activePage === 'businessaiassistant' && (
        <PlatChat />
      )}
    {activePage === 'Cyberlake Arc' && (
         window.location.href = '/echidna/arc'
      )}
       {activePage === 'Cyberlake redstone' && (
         window.location.href = '/redstone/dash'
      )}


      
    </div>
  );
};

const DataAnalyticsDashboard = () => {
  const [activePage, setActivePage] = useState('DashboardHome');

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <Header />
      <div className="flex flex-1">
        <Sidebar setActivePage={setActivePage} />
        <ConsoleHome activePage={activePage} />
      </div>
      <GamefiedAIAssistant />
    </div>
  );
};

export default DataAnalyticsDashboard;